import { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import LogoImg from "./images/footer_logo.png";
import { Link } from "react-router-dom";
import ScrollToHashElement from "../../../utils/ScrollToHashElement"
import { Button } from "../../atoms/buttons";
import { HashLink } from "react-router-hash-link";
import Facebook from "./images/facebook.svg";
// import InstaIcon from "./images/insta.svg";
import TwitterIcon from "./images/twitter.svg";
import itunes from "./images/itunes.png"
import youtube from "./images/youtube.png"
import FooterLogo from "./images/footerLogo.png"
import "./style.css";
import WebMailListingDialog from "../webMailListingDialog";
import WebMailSuccessDialog from "../webMailSuccessDialog";
import useHttp from "../../../utils/hooks/useHttp";
import AlertCmp from "../../atoms/alert";
const footerSectionContent: any = {
  section1: [
    {
      label: "Home",
      link: "/",
    },
    {
      
      label: "About Us",
      link: "/about-us",
    },
    {
      label: "Events",
      link: "/all-events",
      },
    {
      label: "Membership",
      link: "/memberships",
    },
    {
      label: "Donation",
      link: "/donate",
    },
  ],
  section2: [
  
    {
      label: "Feedback",
      link: "/feedback",
    },
   
    {
      label: "Artists",
      link: "/all-artists",
    },
    {
      label: "Music Masala",
      link: "/music-masala",
    },
    {
      label: "Past Events",
      link: "/past-events",
    },
    {
      label: "FAQs",
      HashLink: "/about-us#FAQs",
    }
  ],
  // section3: [
  //   {
  //     label: "Music Masala",
  //     link: "/music-masala",
  //   },
  //   {
  //     label: "Store",
  //     link: "/buy-cd",
  //   },
  // ],
  // section4: [
  //   {
  //     label: "Contact Us",
  //     link: "/contact-us",
  //   },
  //   {
  //     label: "Terms of use",
  //     link: "/terms",
  //   },
  // ],
  // section5: [
  //   // {
  //   //   label: "Store",
  //   //   link: "/buy-cd",
  //   // },
  //   {
  //     label: "Privacy",
  //     link: "/privacy",
  //   },
  // ]
};
const FooterSection = () => {
  const [openMailDialog, setOpenMailDialog] = useState(false);
  const [openMailSuccessDialog, setOpenMailSuccessDialog] = useState(false);
  const { loading, error, sendRequestWithoutToken } = useHttp(transformData);

  const [showSuccessAlert, setShowSuccessAlert] = useState({
    show: false,
    message: "",
  });
  const [showErrorAlert, setShowErrorAlert] = useState({
    show: false,
    message: "",
  });

  function transformData(data: any) {
    if (data.status) {
      setShowSuccessAlert({
        show: true,
        message: data.message,
      });

      setOpenMailSuccessDialog(true)

      setTimeout(() => {
        setShowSuccessAlert({
          show: false,
          message: "",
        });
      }, 5000);
    }else{
      setShowErrorAlert({
        show: true,
        message: data?.error || data?.message,
      });
      setTimeout(() => {
        setShowErrorAlert({
          show: false,
          message: "",
        });
      }, 1500);
    }
  }

  const handleOpenMailDialog = () => {
    let user = localStorage.getItem('web_user')
    if(user != null) {
      let web_user:any = JSON.parse(user)
      const email = web_user?.email
      const reqOptions = {
        url: `/mailing_lists/create_mailing_list`,
        method: "POST",
        data: {
          email
        },
      };
      sendRequestWithoutToken(reqOptions);
    }else{
      setOpenMailDialog(true);
    }
  };
  const handleCloseMailDialog = () => {
    setOpenMailDialog(false);
  };
  const handleOpenMailSuccessDialog = () => {
    setOpenMailSuccessDialog(true);
  };
  const handleCloseMailSuccessDialog = () => {
    setOpenMailSuccessDialog(false);
    setOpenMailDialog(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const displayFooterItems = (i: number) => {
    if (i === 2) {
      return { xs: "none", sm: "none", md: "flex" };
    } else if (i === 3 || i === 4) {
      return { xs: "flex", sm: "flex", md: "none" };
    } else {
      return { xs: "flex", sm: "flex" };
    }
  };
  return (
    <>
    {showSuccessAlert.show && (
        <AlertCmp type="success_web" position="user_side">
          {showSuccessAlert.message}
        </AlertCmp>
      )}

      {showErrorAlert.show && (
        <AlertCmp type="error" position="user_side">
          {showErrorAlert.message}
        </AlertCmp>
      )}
      <Box className="footer_section">
      <ScrollToHashElement />
        <Box className="footer_options">
          <Grid container spacing={2} className="footer_grid">
            {/* logo */}
            <Grid item xs={12} sm={3} md={4} className="footer_logo_grid">
              <img style={{
                width: '150px'
              }} src={LogoImg} />
            </Grid>
            {/* {console.log("====>", footerSectionContent)} */}
            {Object.keys(footerSectionContent).map(
              (section: any, i: number) => {
                return (
                  <Grid
                    item
                    xs={6}
                    // display={displayFooterItems(i)}
                    sm={3}
                    md={2}
                    key={i}
                    // className={`footer_section_grid_${i}`}
                  >
                    <ul className="footer_options_list">
                      {footerSectionContent[section].map(
                        (option: any, j: number) => {
                          return (<>
                            <li key={j} className="footer_options_li">
                              {
                                option.link?
                                <Link
                                to={option.link}
                                className="footer_options_link"
                                >
                                {option.label}
                              </Link>:
                              <HashLink
                              to={option.HashLink}
                              className="footer_options_link"
                              >
                              {option.label}
                            </HashLink>
                              }
                            </li>
                            </>
                          );
                        }
                      )}
                    </ul>
                  </Grid>
                );
              }
            )}
            <Grid item xs={10} sm={3} md={4}>
             <Box
              className="socialWrap"
             >
              <Box className="footer_icons_button1">
                {/* <Box className="footer_mailing_button">
                    <Button
                      size="s"
                      color="info"
                      fullWidth
                      sx={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "14px",
                        width: "100%",
                        padding: "10px !important",
                        borderRadius: "4px !important",
                      }}
                      btnClass="mail_button"
                      handleClick={handleOpenMailDialog}
                    >
                      Join our Mailing List
                    </Button>
                  </Box> */}
                  <Box className="footer_contact_icons">
                    <a
                      href="https://www.facebook.com/groups/381720247728"
                      target="_blank"
                      className="footer_contact_link"
                    >
                      <img style={{marginTop:"3px"}} src={Facebook}  />
                    </a>
                    <a
                      href="https://www.youtube.com/@imsommn5260/videos"
                      target="_blank"
                      // className="footer_contact_link"
                    >
                      <img style={{width: '40px', height: '40px'}} src={youtube} />
                    </a>
                    <a
                      href="https://twitter.com/login"
                      target="_blank"
                      className="footer_contact_link"
                    >
                      <img style={{width: '40px', height: '40px'}} src={itunes} />
                    </a>
                  </Box>
                </Box>
             </Box>
            </Grid>
          </Grid>
        </Box>
        {/* Mail dialog box */}
        <WebMailListingDialog
          open={openMailDialog}
          handleClose={handleCloseMailDialog}
          handleOpenDialog={handleOpenMailSuccessDialog}
        />
        {/* Mail Success dialog box */}
        <WebMailSuccessDialog
          open={openMailSuccessDialog}
          handleClose={handleCloseMailSuccessDialog}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            height: '100px',
            alignItems: 'center'
          }}
        >
          <p className="footer_copyright_para" style={{ color: "#BFBFBF" }}>
            Copyright: IMSOM  <span style={{fontWeight:"bold"}}> {new Date().getFullYear()}</span>
          </p>
          <Link to="https://www.i2pify.ai/">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <p
              className={"footer_powered_para"}
             
            >
              Powered by
            </p>
            <img 
              src={FooterLogo}
              style={{
                height: '40px',
                width: '46.5px',
                marginLeft: '10px'
              }}
            />
          </div>
          </Link>
        </div>
      </Box>
    </>
  );
};
export default FooterSection;
