import React from "react";
import { PropType } from "./types/propTypes";
import TextLabels from "../../atoms/textLabels";
import "./style.css";

const MenuItem = ({ image, label, active, handleClick }: PropType) => {
  return (
    <>
      {handleClick ? (
        <div className="menu_item" onClick={handleClick}>
          <img src={image} className="menu_img" />
          {label === "Logout" ? (
            <TextLabels
              variant="h200"
              color={`${active ? "#ffffff" : "#8A6528"}`}
              weight={400}
              fontFamily={"Poppins"}
              size="12px"
            >
              {label}
            </TextLabels>
          ) : (
            <TextLabels
              variant="h200"
              color={`${active ? "#ffffff" : "#8A6528"}`}
              weight={400}
              fontFamily={"Inter"}
            >
              {label}
            </TextLabels>
          )}
        </div>
      ) : (
        <div className="menu_item">
          <img src={image} className="menu_img" />
          <TextLabels
            variant="h200"
            color={`${active ? "#ffffff" : "#8A6528"}`}
            weight={400}
          >
            {label}
          </TextLabels>
        </div>
      )}
    </>
  );
};

export default MenuItem;
