const Facebook = ()=>{
    return <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
    <g clip-path="url(#clip0_5942_38392)">
      <path d="M32.124 16.7031C32.124 7.86662 24.9605 0.703125 16.124 0.703125C7.28752 0.703125 0.124023 7.8665 0.124023 16.7031C0.124023 24.6891 5.97502 31.3085 13.624 32.5088V21.3281H9.56152V16.7031H13.624V13.1781C13.624 9.16813 16.0128 6.95312 19.6674 6.95312C21.418 6.95312 23.249 7.26562 23.249 7.26562V11.2031H21.2315C19.2438 11.2031 18.624 12.4365 18.624 13.7019V16.7031H23.0615L22.3521 21.3281H18.624V32.5088C26.273 31.3085 32.124 24.6893 32.124 16.7031Z" fill="#1877F2"/>
      <path d="M22.3521 21.3281L23.0615 16.7031H18.624V13.7019C18.624 12.4364 19.2439 11.2031 21.2315 11.2031H23.249V7.26562C23.249 7.26562 21.418 6.95312 19.6674 6.95312C16.0128 6.95312 13.624 9.16813 13.624 13.1781V16.7031H9.56152V21.3281H13.624V32.5088C14.451 32.6384 15.2869 32.7033 16.124 32.7031C16.9611 32.7034 17.797 32.6384 18.624 32.5088V21.3281H22.3521Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_5942_38392">
        <rect width="32" height="32" fill="white" transform="translate(0.124023 0.703125)"/>
      </clipPath>
    </defs>
  </svg>
}

export {Facebook}