import React, { useState } from "react";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextLabels from "../textLabels";
import { PropType } from "./types/propType";
import CloseImg from "./images/close.svg";
import TabBar from "../tabs";
import UserImg from "./images/user.png";
import EventImg from "./images/event.png";
import ClockImg from "./images/clock.svg";
import CDImg from "./images/cd.svg";
import StarImg from "./images/star.svg";
import "./style.css";
import UserEvents from "./userEvents";

const tabs = [
  {
    value: "profile",
    label: "Profile & Membership",
  },
  {
    value: "events",
    label: "Event Booking History",
  },
  // {
  //   value: "orders",
  //   label: "Order History",
  // },
];

const eventsData = [
  {
    image: EventImg,
    title: "Tabla Jugalbandi",
    admitCount: 2,
    passCount: 1,
    ticketCount: 1,
    artists: ["Javed Jaferi", "Ghoomran Ghobi"],
    location: "Recital Hall St. Catherine University,St. Paul",
    time: "Sunday April 23 2023, 5:00 PM",
  },
  {
    image: EventImg,
    title: "Tabla Jugalbandi",
    admitCount: 2,
    passCount: 1,
    ticketCount: 1,
    artists: ["Javed Jaferi", "Ghoomran Ghobi"],
    location: "Recital Hall St. Catherine University,St. Paul",
    time: "Sunday April 23 2023, 5:00 PM",
  },
];

const orderDetails = [
  {
    status: "processing",
    orderId: "#04-6370480-0180302",
    amount: "$ 40",
    quantity: 1,
    cds: [
      {
        title: "CD 1",
        subTitle: "Hindustani Vocals",
      },
      {
        title: "CD 2",
        subTitle: "Hindustani Vocals",
      },
      {
        title: "CD 3",
        subTitle: "Hindustani Vocals",
      },
      {
        title: "CD 4",
        subTitle: "Hindustani Vocals",
      },
    ],
  },
  {
    status: "processing",
    orderId: "#04-6370480-0180302",
    amount: "$ 40",
    quantity: 1,
    cds: [
      {
        title: "CD 1",
        subTitle: "Hindustani Vocals",
      },
      {
        title: "CD 2",
        subTitle: "Hindustani Vocals",
      },
      {
        title: "CD 3",
        subTitle: "Hindustani Vocals",
      },
      {
        title: "CD 4",
        subTitle: "Hindustani Vocals",
      },
    ],
  },
];

const ModalProfilePopup = ({
  open,
  handleClose,
  heading,
  closeBtn,
  profileData,
}: PropType) => {
  const userId = profileData?.id
  const [activeTab, setActiveTab] = useState("profile");

  const handleTabClick = (val: any) => {
    setActiveTab(val);
  };

  const handleCloseModal = ()=>{handleClose(); setActiveTab("profile") }

  return (
    <>
      <div>
        <Dialog
          open={open}
          onClose={handleCloseModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="modal_popup_profile"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              display: "flex",
              justifyContent: "space-between"
            }}
            className="modal_title"
          >
            <TextLabels variant="h400" weight={500}>
              {heading}
            </TextLabels>
            {closeBtn && (
              <img
                src={CloseImg}
                onClick={handleCloseModal}
                style={{ cursor: "pointer" }}
              />
            )}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <TabBar
                handleClick={handleTabClick}
                tabs={tabs}
                defaultValue="profile"
                fullWidth={true}
              />
              {activeTab === "profile" && profileData && (
                <Box className="modal_profile_popup_tab_profile_box">
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between"
                    }}
                  >
                    {profileData?.membership_name && <Box style={{ display: "flex" }}>
                      <img src={StarImg} />
                      <p style={{ marginLeft: 4 }}>
                        {profileData?.membership_name}
                      </p>
                    </Box>}

                    {/* Commenting the free passes detail as per requirment  */}

                    {/* {profileData?.membership_name && <Box className="buy_cd_step1_membership_link">
                      {profileData?.no_of_free_passes?<>
                      <p>{profileData?.no_of_free_passes
                      } Passes Left</p>
                      </>:
                      <>
                      <p>No Passes Left</p>
                      </>
                      }
                    </Box>} */}
                  </Box>
                  <Box className="tab_profile_box_top">
                    <Grid container>
                      <Grid item xs={2}>
                        <img src={UserImg} className="tab_profile_image" />
                      </Grid>
                      <Grid item xs={10}>
                        <TextLabels variant="h300" weight={500} color="#221714">
                          {profileData.username}
                        </TextLabels>
                        <Box sx={{ marginTop: "15px" }} />
                        <TextLabels variant="h200" weight={400} color="#503B35">
                          {profileData.email}
                        </TextLabels>
                      </Grid>
                    </Grid>
                  </Box>
                  {profileData.addresses.length ? (
                    profileData.addresses.map((address: any, i: number) => {
                      return (
                        <Box className="tab_profile_box_top">
                          <Box className="tab_profile_address_box">
                            <p className="tab_profile_address_box_heading">
                              Address {i + 1} (Default)
                            </p>
                            <Box
                              sx={{
                                marginTop: "30px"
                              }}
                            >
                              <p className="tab_profile_address_box_line1">
                                {address.address_line_first},{" "}
                                {address.address_line_second}
                              </p>
                              <p className="tab_profile_address_box_line1">
                                {address.city}, {address.state},{" "}
                                {address.country}
                              </p>
                            </Box>
                          </Box>
                        </Box>
                      );
                    })
                  ) : (
                    <>
                    <Box className="tab_profile_box_top">
                          <Box className="tab_profile_address_box">
                            <p className="tab_profile_address_box_heading">
                              No Address Added
                            </p>
                          </Box>
                        </Box>
                    </>
                  )}
                </Box>
              )}
              {activeTab === "events" && (
              <UserEvents userId={userId} />
              )}
              {activeTab === "orders" && (
                <Box className="modal_profile_popup_orders_box">
                  {orderDetails.map((order, i) => {
                    return (
                      <Box className="modal_profile_popup_order" key={i}>
                        <Grid container>
                          <Grid item xs={12}>
                            <span className="processing_status">
                              <img src={ClockImg} />
                              Processing
                            </span>
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            className="modal_popup_order_grid_margin"
                          >
                            <Box>
                              <TextLabels
                                variant="h100"
                                color="#7E8085"
                                weight={400}
                              >
                                Order ID
                              </TextLabels>
                            </Box>
                            <Box
                              sx={{
                                marginTop: "10px"
                              }}
                            >
                              <TextLabels
                                variant="h200"
                                weight={500}
                                color="#221714"
                              >
                                {order.orderId}
                              </TextLabels>
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            className="modal_popup_order_grid_margin"
                          >
                            <Box>
                              <TextLabels
                                variant="h100"
                                color="#7E8085"
                                weight={400}
                              >
                                Amount Paid
                              </TextLabels>
                            </Box>
                            <Box
                              sx={{
                                marginTop: "10px"
                              }}
                            >
                              <TextLabels
                                variant="h200"
                                weight={500}
                                color="#221714"
                              >
                                {order.amount}
                              </TextLabels>
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            className="modal_popup_order_grid_margin"
                          >
                            <Box>
                              <TextLabels
                                variant="h100"
                                color="#7E8085"
                                weight={400}
                              >
                                Order Quantity
                              </TextLabels>
                            </Box>
                            <Box
                              sx={{
                                marginTop: "10px"
                              }}
                            >
                              <TextLabels
                                variant="h200"
                                weight={500}
                                color="#221714"
                              >
                                {order.quantity}
                              </TextLabels>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box className="modal_popup_order_divider"></Box>
                          </Grid>
                          <Grid item xs={12 / 5}>
                            <img src={CDImg} />
                          </Grid>
                          {order.cds.map((cd: any, i: number) => {
                            return (
                              <Grid item xs={12 / 5}>
                                <Box>
                                  <p className="modal_popup_cd_title">
                                    {cd.title}
                                  </p>
                                </Box>
                                <Box>
                                  <p className="modal_popup_cd_subtitle">
                                    {cd.subTitle}
                                  </p>
                                </Box>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Box>
                    );
                  })}
                </Box>
              )}
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default ModalProfilePopup;
