import React, { useEffect, useMemo, useState } from "react";
import Topbar from "../../molecules/topbar";
import TabBar from "../../atoms/tabs";
import Grids from "../../atoms/grids";
import Event1 from "./images/event1.png";
import Event2 from "./images/event2.png";
import { EventType } from "./types/eventType";
import { ReqOptions } from "../../../utils/types/reqOptions";
import useHttp from "../../../utils/hooks/useHttp";
import moment from "moment";
import AlertCmp from "../../atoms/alert";
import Loader from "../../atoms/loader";
import Paginate from "../../atoms/pagination";
import CloseImg from './images/close.svg'
import { Button } from "../../atoms/buttons";
import { useDropzone } from "react-dropzone";
import Menu from './images/menu.svg'

const tabs = [
  {
    value: "all_events",
    label: "All Events",
  },
  // {
  //   value: "live",
  //   label: "Current Events",
  // },
  {
    value: "past",
    label: "Past Events",
  },
];

// const items: EventType[] = [
//   {
//     eventId: 1,
//     eventName: "Event 1",
//     eventImage: Event1,
//     eventDate: "June, 15 2022",
//     imageCount: 0,
//   },
//   {
//     eventId: 2,
//     eventName: "Event 2",
//     eventImage: Event1,
//     eventDate: "June, 15 2022",
//     imageCount: 14,
//   },
//   {
//     eventId: 3,
//     eventName: "Event 3",
//     eventImage: Event2,
//     eventDate: "April, 23 2023",
//     imageCount: 24,
//   },
// ];

// const eventItems: { gridType: "images"; items: EventType[] } = {
//   gridType: "images",
//   items,
// };

const sortOptions = [
  {
    key: "name",
    label: "Name",
  },
  {
    key: "location",
    label: "Location",
  },
  {
    key: "date",
    label: "Date",
  },
];

const EventGallery = () => {
  const { loading, error, sendRequest } = useHttp(transformData);
  const [search, setSearch] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [events, setEvents] = useState<any[]>([]);
  const [totalRecords, setTotalRecords] = useState(12);
  const [currentEventId, setCurrentEventId] = useState<any>();
  const [currentEvent, setCurrentEvent] = useState<any>();
  const [filterYearValue, setFilterYearValue] = useState<any>(null)
  const items:EventType[] =  useMemo(()=>{
    if (events?.length > 0) {
     return events.map((i: any) => ({
          eventId: i?.id,
          eventName: i?.title,
          eventImage: i?.event_galleries[0]?.event_images[0]?.[0],
          eventDate: moment(i?.date).format("MMMM DD, YYYY"),
          imageCount: i?.event_galleries[0]?.event_images?.length,
        })
      );
    }
    return []
  },[events]);

  const eventItems: { gridType: "images"; items: EventType[] } = {
    gridType: "images",
    items,
  };

  useEffect(() => {
    if (filterYearValue !== null) {
      const requestOptions: ReqOptions = {
        url: `/event_managments`,
        method: "GET",
        params: {
          year: filterYearValue,
          tab: activeTab,
          page: currentPage,
          per_page: 8,
          search: search,
        },
      };

      sendRequest(requestOptions);
    }
  }, [filterYearValue]);

  const handleYearFilter = (val: any) => {
    setFilterYearValue(val);
  };

  function transformData(data: any) {
    if (data && data?.events) {
      setEvents(data?.events);
      setTotalRecords(data?.total)
    }
  }

  const handleSearchInput = (e: any) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    if (search !== null) {
      const timer = setTimeout(() => {
        const reqOptions: ReqOptions = {
          url: '/event_managments',
          method: "GET",
          params: {
            tab: activeTab,
            search: search,
            page: 1,
            per_page: 8,
          },
        };

        sendRequest(reqOptions);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [search]);

  const [activeTab, setActiveTab] = useState("all_events");

  useEffect(() => {
    const requestOptions: ReqOptions = {
      url: `/event_managments?tab=${activeTab}`,
      method: "GET",
      params: {
        tab: activeTab,
        page: currentPage,
        per_page: 8,
      },
    };
    setCurrentEvent(null)
    sendRequest(requestOptions);
  }, [activeTab, currentPage]);

  const handleTabClick = (val: any) => {
    setActiveTab(val);
    setCurrentPage(1);
  };

  const handlePageChange = (e: any, page: number) => {
    setCurrentPage(page);
  };

  const handleImageCick = (e: any) => {
    events.forEach((i: any) => {
      if (i?.id == e) {
        setCurrentEvent(i)
      }
    });
    setCurrentEventId(e)
  }
  const handleUncheck = () => {
    setCurrentEvent(null)
    setCurrentEventId(null)
  }

  return (
    <>
      {loading &&
        <Loader />}
      <>
        {error && (
          <AlertCmp type="error" position="dashboard">
            Something went wrong! Try again..
          </AlertCmp>
        )}
        <TabBar
          defaultValue="all_events"
          handleClick={handleTabClick}
          tabs={tabs} />
        <Topbar
          totalRecordsCount={totalRecords}
          handleYearFilter={handleYearFilter}
          searchLabel="Search by event name, city..."
          showBtn={false}
          handleSearchInput={handleSearchInput}
          filterBtn={true}
          sortOptions={sortOptions}
          modal={{
            heading: "Create Membership",
            inputs: [],
          }}
        />
        <Grids
          items={eventItems}
          tab={activeTab}
          spacing={4}
          size={3}
          sizeMd={6}
          sizeSm={6}
          sizeM={12}
          imgClick={handleImageCick}
        />
        {currentEvent && <CustomImageGallery eventName={currentEvent.title} handleUnCheck={handleUncheck} images={currentEvent} eventId={currentEventId} />}
        <Paginate
          page={currentPage}
          totalRecords={totalRecords}
          recordsPerPage={12}
          handlePageChange={handlePageChange}
        />
      </>
    </>
  );
};

export default EventGallery;


// const CustomImageGallery = ({ images, eventId, handleUnCheck }: any) => {
//   console.log("here are images", images?.event_galleries[0]?.event_images);
//   return (
//     <div style={{ overflowY: 'scroll', maxHeight: '600px' }}>
//       <div style={{display: 'flex'}}>
//       <h4>Event {eventId} Photos {`(${images?.event_galleries[0]?.event_images.length})`}</h4>
//         <img
//           src={CloseImg}
//           onClick={handleUnCheck}
//           style={{ cursor: "pointer", marginLeft: '15px', marginTop: '5px' }}
//         />
//       </div>
//       <div style={galleryStyle}>
//         {images?.event_galleries[0]?.event_images.map((image: any, index: any) => (
//           <div key={index} style={imageContainerStyle}>
//             <img
//               src={image}
//               alt={`Image ${index + 1}`}
//               style={imageStyle}
//             />
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

const CustomImageGallery = ({ images, eventId, handleUnCheck,eventName }: any) => {
  const [selectedImages, setSelectedImages] = useState<any>([]);
  const [showDropdownIndex, setShowDropdownIndex] = useState<any>(null);
  const [tempIndex, setTempIndex] = useState<any>(null)

  const transformData = (data: any) => {
    window.location.reload()
  }
  const { loading, error, sendRequest } = useHttp(transformData);


  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/jpg": [],
      "image/png": [],
    },
    onDrop: (acceptedFiles: any) => {
      const newFile = acceptedFiles[0];
  
      setSelectedImages((prevSelectedImages: any) => {
        const updatedImages = [...prevSelectedImages];
        updatedImages[tempIndex] = Object.assign(newFile, {
          preview: URL.createObjectURL(newFile),
        });
        return updatedImages;
      });
  
      setTempIndex(null);
    },
    multiple: false,
  });

  // const handleReplaceImage = (index: number) => {
  //   const fileInput = document.createElement('input');
  //   fileInput.type = 'file';
  //   fileInput.accept = 'image/*';
  //   fileInput.onchange = (event: any) => {
  //     const file = event.target.files[0];
  //     console.log("name",file.type)
  //     console.log("name",file.name)
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       const newImage = e.target?.result;
  //       setSelectedImages((prevSelectedImages: any) => {
  //         const updatedImages = [...prevSelectedImages];
  //         updatedImages[index] = newImage;
  //         return updatedImages;
  //       });
  //     };
  //     reader.readAsDataURL(file);
  //   };
  //   fileInput.click();
  // };

  const handleDeleteImage = (index: number, url: any) => {
    setSelectedImages((prevSelectedImages: any) => {
      const updatedImages = [...prevSelectedImages];
      updatedImages[index] = null;
      return updatedImages;
    });
    const link = getImageName(url)
    const requestOptions: ReqOptions = {
      url: `/event_gallery/delete_single_image`,
      method: "DELETE",
      data: {
        old_images: link,
        event_id: eventId
      }
    };
    sendRequest(requestOptions);
  };

  const toggleDropdown = (index: number) => {
    if (showDropdownIndex === index) {
      setShowDropdownIndex(null);
    } else {
      setShowDropdownIndex(index);
    }
  };

  const getImageName = (url: string) => {
    if (!url) {
      return '';
    }
    const name = url[0]?.split('/').pop();
    return name || '';
  };

  const getCommaSeparatedString = () => {
    const names = images?.event_galleries[0]?.event_images.map((image: any, index: any) => {
      if (selectedImages[index]) {
        return getImageName(image);
      }
      return null;
    }).filter((name: string | null) => name !== null).join(',');
    return names || '';
  };

  const handleSaveChanges = () => {
    const selectedImageNames = getCommaSeparatedString();
    const formData = new FormData();
    selectedImages.forEach((file: any, index: any) => {
      if (file) {
        formData.append('event_images[]', file);
      }
    });

    formData.append('old_images', selectedImageNames);
    formData.append('event_id', images.id);
    const requestOptions: ReqOptions = {
      url: `/event_gallery/update_event_gallery`,
      method: "POST",
      contentType: "multipart/form-data",
      data: formData,
    };

    sendRequest(requestOptions);
  };

  return (
    <div style={{ overflowY: 'scroll', maxHeight: '600px' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h4>Event: {eventName} Photos ({images?.event_galleries[0]?.event_images.length})</h4>
        <img
          src={CloseImg}
          onClick={handleUnCheck}
          style={{ cursor: 'pointer', marginLeft: '15px', marginTop: '5px' }}
        />
      </div>
      <div style={galleryStyle}>
        {images?.event_galleries[0]?.event_images.map((image: any, index: number) => {
          return (
            <div key={index} style={imageContainerStyle}>
              {selectedImages[index]?.preview ? (
                <img src={selectedImages[index]?.preview} alt={`Selected ${index}`} style={imageStyle} />
              ) : (
                <div
                  style={{ position: 'relative', width: '100%', height: '100%' }}
                  
                >
                  <img 
                  onClick={()=> {toggleDropdown(index); setTempIndex(index)}}
                  src={Menu} 
                  style={{position: 'absolute', height: 4, cursor: 'pointer', bottom: 20, right: 10}} />
                  <img src={image} alt={`Image ${index}`} style={imageStyle} />
                  {showDropdownIndex === index && (
                    <div
                      style={{
                        position: 'absolute',
                        bottom: "31px",
                        right:"8px",
                        // top: '210px',
                        // left: '228px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        backgroundColor: '#fff',
                        padding: '10px',
                        borderRadius: '4px',
                        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.15)',
                        zIndex: 1,
                      }}
                    >
                      <div {...getRootProps({ className: "" })}>
                        <p style={{ margin: 0, fontSize: 16, cursor: "pointer", padding: 4, color: '#913713' }}>
                        <input {...getInputProps()} />
                          Replace
                        </p>
                      </div>
                      <p
                        style={{ margin: 0, fontSize: 16, cursor: "pointer", padding: 4, color: '#ACACAC' }}
                        onClick={() => handleDeleteImage(index, image)}
                      >
                        Delete
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
      {selectedImages.length > 0 && (
        <Button variant="outlined" size="s" handleClick={handleSaveChanges}>
          Save Changes
        </Button>
      )}
    </div>
  );
};


// // Styles
const galleryStyle: React.CSSProperties = {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
};

const imageContainerStyle: React.CSSProperties = {
  width: '30%',
  marginBottom: '20px',
  borderRadius: '10px',
  overflow: 'hidden',
  margin: '10px'
};

const imageStyle: React.CSSProperties = {
  width: '100%',
  borderRadius: '10px',
  height: '300px'
};

