import React from "react";
import Layout from "../../organism/layout";
import EventDetails from "../../templates/eventDetails";

const EventDetailsPage = () => {
  return (
    <Layout pageName="Event Details" menuActive="Event Management">
      <EventDetails />
    </Layout>
  );
};

export default EventDetailsPage;
