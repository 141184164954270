import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'
import TextLabels from '../textLabels'
import { Button } from '../buttons'
import CloseImg from './images/close.svg'
import NotFound from '../../molecules/notfound'

const AttendeeModal = ({
    heading,
    attendeeList,
    primaryButton,
    handleClose,
    open,
    closeBtn,
    primaryAction,
}: any) => {
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="modal_popup_simple"
            >
                <div style={{height: '50vh', overflowY: 'scroll'}}>
                <DialogTitle
                    id="alert-dialog-title"
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                    className="modal_title"
                >
                    <TextLabels variant="h400" weight={500}>
                        {heading}
                    </TextLabels>
                    {closeBtn && (
                        <img
                            src={CloseImg}
                            onClick={handleClose}
                            style={{ cursor: "pointer" }}
                        />
                    )}
                </DialogTitle>
                <DialogContent>
                    {/* <DialogContentText id="alert-dialog-description">
                        {imagePopup ? (
                            <></>
                        ) : (
                            <TextLabels variant="h200" weight={400} color="#606266">
                                {heading == "Delete all images?" ? (
                                    <span dangerouslySetInnerHTML={{ __html: content }}></span>
                                ) : (
                                    content
                                )}
                            </TextLabels>
                        )}
                    </DialogContentText> */}
                    <div style={{display: 'flex', flexDirection: 'column', overflowY: 'scroll'}}>
                        {attendeeList?.length > 0 ? attendeeList.map((item: any, idx: number)=> {
                            return(
                                <div>
                                    {`Attendee ${idx+1} - ${item?.name} (${item?.user_type})`}
                                </div>
                            )
                        }) : <NotFound />}
                    </div>
                </DialogContent>
                    </div>
                    {primaryButton && (
                        <>
                            <Button handleClick={primaryAction} variant="contained" size="s">
                                {primaryButton}
                            </Button>
                        </>
                    )}
            </Dialog>
        </div>
    )
}

export default AttendeeModal