import { useEffect, useState } from "react";
import CardSection from "../../molecules/cardSection";
import SectionHeading from "../../molecules/sectionHeading";
import WebCarousel from "../../atoms/webCarousel";
import { Box } from "@mui/material";
import "./style.css";
import useHttp from "../../../utils/hooks/useHttp";
import Loader from "../../atoms/loader";

const AnnouncementCardSection = () => {
  const { loading, sendRequestWithoutToken } = useHttp(transformData);
  const [announcements, setAnnouncements] = useState<any>([]);
  function transformData(data: any) {
    if (data.status) {
      setAnnouncements(data.announcements);
    }

  }

  useEffect(() => {
    const reqOptions = {
      url: `/get_announcements`,
      method: "GET",
    };
    sendRequestWithoutToken(reqOptions);
  }, []);
  return (
    <>
      {loading && <Loader />}
      <Box className="announcement_section">
        <CardSection>
          <Box className="announcement_holder">
            <SectionHeading
              title="Announcements"
              classNameBox="announcement_heading_img_box"
              classNameImg="announcement_heading_img"
              width="187"
              marginRight="-217"
            />
            
                       
          </Box>
          <WebCarousel type="announcement_card" carouselContent={announcements}  />
          
        </CardSection>
      </Box>
    </>
  );
};

export default AnnouncementCardSection;
