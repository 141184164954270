import React, { useEffect, useRef, useState } from "react";
import { PropType } from "./types/propType";
import { Box } from "@mui/material";
import TextLabels from "../textLabels";
import ActiveImg from "./images/active.svg";
import ScheduleImg from "./images/schedule.svg";
import PastImage from "./images/past.svg";
import MenuImg from "./images/menu.svg";
import ToggleMenu from "../toggleMenu";
import ModalPopup from "../modalPopup";
import useHttp from "../../../utils/hooks/useHttp";
import ModalFormPopup from "../modalFormPopup";
import { ModalInputs } from "./types/modalInputs";
import Loader from "../loader";
import moment from "moment";
import useOutsideAlerter from "../../../utils/hooks/useOutsideAlerter";
import "./style.css";

const inputs: ModalInputs[] = [
  {
    label: "Announcement Heading *",
    placeholder: "Enter heading here",
    type: "text",
    name: "title",
    id: "announcement_title",
    grid: 1,
    errorMsg: "Please enter heading",
  },
  {
    label: "Start Date *",
    placeholder: "MM/DD/YYYY",
    type: "date",
    name: "start_date",
    id: "start_date",
    grid: 2,
    errorMsg: "Please select start date",
  },
  {
    label: "End Date *",
    placeholder: "MM/DD/YYYY",
    type: "date",
    name: "end_date",
    id: "end_date",
    grid: 2,
    errorMsg: "Please enter end date",
  },
  {
    label: "Message *",
    placeholder: "Write your message here",
    type: "textarea",
    name: "message",
    id: "message",
    grid: 1,
    maxRows: 4,
    errorMsg: "Please enter message",
  },
  {
    label: "Send announcement to “mailing list”.",
    placeholder: "",
    type: "checkbox",
    name: "mailing_list",
    id: "mailing_list",
    grid: 1,
  },
];

const AnnouncementCard = ({
  cardDetails,
  toggleMenu,
  handleToggleMenu,
  handleApiFetchAgain,
  handleSuccessCall,
  handleCloseOptions,
}: PropType) => {
  
  const [openDeleteAll, setOpenDeleteAll] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [modalInputs, setModalInputs] = React.useState<ModalInputs[]>(inputs);
  const { loading, error, sendRequest } = useHttp(transformData);
  const wrapperRef = useRef(null);
  const clickRef = useRef(null);

  useOutsideAlerter(wrapperRef, clickRef, handleCloseOptions);



  function transformData(data: any) {
    if (data.status) {
      handleCloseDeleteAll();

      if (handleSuccessCall) {
        handleSuccessCall(data.message);
      }
      handleApiFetchAgain();
    }
  }

  const handleClickOpenDeleteAll = () => {
    setOpenDeleteAll(true);
  };

  const handleCloseDeleteAll = () => {
    setOpenDeleteAll(false);
  };

  const handleClickOpenEdit = () => {
    const inputsArr = [...modalInputs];

    inputsArr.forEach((input) => {
      if (cardDetails[input.name]) {
        if (input.type === "date") {
          input.value = moment(cardDetails[input.name], "MM/DD/YYYY").format(
            "YYYY-MM-DD"
          );
        } else {
          input.value = cardDetails[input.name];
        }
      }
    });
    setModalInputs(inputsArr);
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleDeleteAnnouncement = () => {
    const reqOptions = {
      url: `/announcements/${cardDetails.id}`,
      method: "DELETE",
    };

    sendRequest(reqOptions);
  };
  // var yourString= "<div><h1>Hello World</h1>\n<p>We are in SOF</p></div>";
  function getText(html:any){
    var divContainer= document.createElement("div");
    divContainer.innerHTML = html;
    return divContainer.textContent || divContainer.innerText || "";
}


  return (
    <>
      {loading && <Loader />}
      <Box className="announcement_box">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <TextLabels variant="h3" weight={700}>
            {cardDetails.title}
          </TextLabels>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            className="menu_box"
          >
            <TextLabels variant="h100" weight={300} color="">
              {cardDetails.publishDate}
            </TextLabels>
            &nbsp;&nbsp;
            <div
              onClick={(e) => handleToggleMenu(e, cardDetails.id)}
              ref={clickRef}
            >
              <img
                src={MenuImg}
                style={{
                  cursor: "pointer",
                }}
              />
            </div>
            {toggleMenu === cardDetails.id && (
              <Box className="toggle_pos" ref={wrapperRef}>
                <ToggleMenu
                  handleClickOpenDeleteAll={handleClickOpenDeleteAll}
                  handleClickOpenEdit={handleClickOpenEdit}
                  announcementScreen={true}
                  editable={cardDetails.status == 'past' ? false : true}
                />
              </Box>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            paddingBottom: "15px",
          }}
        ></Box>
        <TextLabels variant="h200" weight={400} color="#9FA1A6">
          {getText(cardDetails.message)}
        </TextLabels>
        <Box
          display="flex"
          alignItems="center"
          sx={{
            paddingTop: "15px",
          }}
        >
          {cardDetails.status === "active" && <img src={ActiveImg} />}
          {cardDetails.status === "past" && <img src={PastImage} />}
          {cardDetails.status === "schedule" && <img src={ScheduleImg} />}
          <Box
            display="flex"
            alignItems="center"
            sx={{
              paddingLeft: "10px",
            }}
          >
            <TextLabels variant="h100" weight={400} color="#404145">
              {cardDetails.status === "active" && "Active:"}
              {cardDetails.status === "schedule" && "Scheduled:"}
              {cardDetails.status === "past" && "Past:"}
            </TextLabels>
            &nbsp;
            <TextLabels variant="h100" weight={400} color="#7E8085">
              From {cardDetails.start_date} - {cardDetails.end_date}
            </TextLabels>
          </Box>
        </Box>
      </Box>
      <ModalPopup
        heading="Delete announcement?"
        content="Are you sure you want to delete this announcement? This is a non-reversible action and can not be undone."
        primaryButton="Yes, Delete"
        secondaryButton="Cancel"
        open={openDeleteAll}
        handleClickOpen={handleClickOpenDeleteAll}
        handleClose={handleCloseDeleteAll}
        closeBtn={true}
        primaryAction={handleDeleteAnnouncement}
      />
      <ModalFormPopup
        heading={"Edit Announcement"}
        inputs={modalInputs}
        closeBtn={true}
        confirmBtn="Save Changes"
        handleClose={handleCloseEdit}
        apiUrl={`/announcements/${cardDetails.id}`}
        method="PUT"
        open={openEdit}
        handleSuccessCall={handleSuccessCall}
        handleApiFetchAgain={handleApiFetchAgain}
        noDisable={true}
      />
    </>
  );
};

export default AnnouncementCard;
