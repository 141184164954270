import React,{useState,useEffect} from 'react'
import { PropType } from './types/propTypes'
import { Box, Grid } from "@mui/material";
import TextLabels from '../../atoms/textLabels';
import useHttp from "../../../utils/hooks/useHttp";
import { ReqOptions } from "../../../utils/types/reqOptions";
import NoMemberImg from "../../molecules/nomember/images/no_member.svg";
import moment from 'moment';

const RecentActivity = ({activities}:PropType) => {
    const userData = localStorage.getItem("user") as any;
    const user = userData ? JSON.parse(userData) : null;
  
  return (
    <>
    {
            (activities && activities.length>0) ? 
            <>
            <Box sx={{marginTop:"20px",paddingBottom:"155px"}}>
        
            {
                activities && activities.length>0 && activities.map((item:any,index:number)=>{
                    return <Grid key={item?.id} container 
                    style={{
                        background: "#F8F9FA",
                        boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.06), 0px 1px 2px rgba(0, 0, 0, 0.1)",
                        marginBottom:"20px"
                        
                    }}
                    >
                    <Grid item xs={4} sm={3} md={3} lg={2}
                    sx={{
                        minHeight:"104px",
                        display:"flex",
                        alignItems: "center",
                        justifyContent: "center",
                        
                    }} 
                    
                    >
                        <img src={user?.profile_image || "profiles/avatar.png"} className='recent-activity-avatar' style={{width: "80px",height:" 80px",borderRadius: "100%"}} />
                    </Grid>
                    
                    <Grid item xs={8} sm={9} md={9} lg={10} sx={{height:{xs:"0",sm:"0",md:"150px"},marginTop: "5px !important"}}
                    
                    >
                        
                        <Box className="profileData" key={index}>
                        <p><TextLabels variant="h300" color="#404145" weight={700}>{item?.parameters?.title}</TextLabels></p>
                        <p><TextLabels variant="h200" color="#606266" weight={500}> 
                        <div dangerouslySetInnerHTML={{ __html: (item?.parameters?.description || '').replace(/[\[\]"]/g, '') }}></div></TextLabels></p>
                        <p><TextLabels variant="h100" color="#7E8085" weight={400}> {moment(item?.created_at).fromNow()}</TextLabels></p>
                        
                        
                        </Box>
                            
                    </Grid>
                    </Grid> 
                })
            }
                
            
            
            </Box>
            </>
            :
            <>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
            >
            <img src={NoMemberImg} className="not_found_img activity_not_found" />
            <Box
                sx={{
                width: "50%",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                paddingBottom:"175px"
                }}
            >
                <Box>
                <TextLabels variant="h300" color="#404145" weight={500}>
                Nothing to show
                </TextLabels>
                </Box>
                <Box
                sx={{
                    width: "56%",
                    marginLeft: "25%",
                }}
                >
                <TextLabels variant="h200" weight={400} color="#7E8085">
                    Looks like there's nothing to see here,
                    let's try again later.
                </TextLabels>
                </Box>
            </Box>
            </Box>
            </>
        }
    </>
  )
}

export default RecentActivity