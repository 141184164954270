import React from "react";
import WebLayout from "../../organism/webLayout";
import AllEvents from "../../templates/allEvents";
import BuyCD from "../../templates/buyCD";

const BuyCDPage = () => {
  return <BuyCD />;
};

export default BuyCDPage;
