import React, { useEffect, useRef, useState } from "react";
import { Box, TextField, Switch } from "@mui/material";
import TextLabels from "../textLabels";
import ActiveImg from "./images/active.svg";
import ScheduleImg from "./images/schedule.svg";
import PastImage from "./images/past.svg";
import MenuImg from "./images/menu.svg";
import ToggleMenu from "../toggleMenu";
import useHttp from "../../../utils/hooks/useHttp";
import Loader from "../loader";
import moment from "moment";
import useOutsideAlerter from "../../../utils/hooks/useOutsideAlerter";
import "./styles.css";
import { ModalInputs } from "./types/modalInputs";
import { PropType } from "./types/propType";
import { Button } from "../buttons";
import { ReqOptions } from "../../../utils/types/reqOptions";
import AlertCmp from "../alert";

const PaymentUrlCard = ({
  cardDetails,
  handleApiFetchAgain,
  handleCloseOptions,
}: PropType) => {
  const wrapperRef = useRef(null);
  const clickRef = useRef(null);

  useOutsideAlerter(wrapperRef, clickRef, handleCloseOptions);
  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });

    return formattedDate;
  }
  const [urlError, setUrlError] = useState<string>("");
  const [value, setValue] = useState<string>(cardDetails?.payment_link)
  const [isActive, setIsActive] = useState<boolean>(cardDetails?.status);
  const { loading, error, sendRequest } = useHttp(transformData);
  const [showSuccessAlert, setShowSuccessAlert] = useState({
    show: false,
    message: "",
  });

  function transformData(data: any) {
    if(data?.status){
      setShowSuccessAlert({
        show: true,
        message: "Updated Successfully",
      });
      setTimeout(() => {
        setShowSuccessAlert({
          show: false,
          message: "",
        });
      }, 1500);
    }
    handleApiFetchAgain()
  }

  const isValidURL = (url: string) => {
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlPattern.test(url);
  };
  
  const UpdateLink = () => {
    if (!isValidURL(value)) {
      setUrlError("Please enter a valid URL");
    } else {
    const reqOptions: ReqOptions = {
      url: `/payment_links/${cardDetails?.id}`,
      method: "PUT",
      data: {
        payment_link: {
          payment_link: value
        }
      },
    };

    sendRequest(reqOptions);
  }
  }

  const UpdateStatus = () => {
    const reqOptions: ReqOptions = {
      url: `/payment_links/${cardDetails?.id}`,
      method: "PUT",
      data: {
        payment_link: {
          status: !isActive
        }
      },
    };

    sendRequest(reqOptions);
  }

  return (
    <>
    {showSuccessAlert.show && (
        <AlertCmp type="success" position="dashboard">
          {showSuccessAlert.message}
        </AlertCmp>
      )}
      <Box className="feedback_box">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <TextLabels variant="h300" weight={700}>
            Additional URL
          </TextLabels>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            className="menu_box"
          >
            <TextLabels variant="h100" weight={300} color="">
              {formatDate(cardDetails.updated_at)}
            </TextLabels>
            &nbsp;&nbsp;
          </Box>
        </Box>
        <Box sx={{
          display: 'flex',
          marginTop: '10px'
        }}>
          <TextField
            id={cardDetails?.id}
            name={"Additional URL"}
            type={'text'}
            placeholder={'Additional URL'}
            value={value}
            onChange={(e)=>{
              setValue(e.target.value);
              setUrlError("");
            }}
            sx={{ width: '400px' }}
            required={true}
            error={Boolean(urlError)}
            helperText={urlError}
          />
          <Button sx={{height : '45px', marginLeft: '15px'}} handleClick={ UpdateLink}>Update URL</Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: "15px",
          }}
        >
          <TextLabels variant="h200" weight={400} color="#9FA1A6">Status:</TextLabels>
          <Switch
            checked={isActive}
            onChange={()=>{setIsActive(!isActive); UpdateStatus()}}
            color="primary"
          />
          <TextLabels variant="h200" weight={400} color="#9FA1A6">
            {isActive ? "Activated" : "Deactivated"}
          </TextLabels>
        </Box>
        <Box
          sx={{
            paddingBottom: "15px",
          }}
        ></Box>
      </Box>
    </>
  );
};

export default PaymentUrlCard;