import React, { useState, useEffect } from "react";
import Topbar from "../../molecules/topbar";
import Tables from "../../atoms/tables";
import { ColumnTypes } from "./types/columnTypes";
import { DataTypes } from "./types/dataTypes";
import { Box } from "@mui/material";
import NoMember from "../../molecules/nomember";
import { ModalInputs } from "./types/modalInputs";
import useHttp from "../../../utils/hooks/useHttp";
import { ReqOptions } from "../../../utils/types/reqOptions";
import Paginate from "../../atoms/pagination";
import AlertCmp from "../../atoms/alert";
import Loader from "../../atoms/loader";
import {
  SEND_MESSAGE_MULTIPLE_ACTION,
  BLOCK_PROFILE_MULTIPLE_ACTION,
  DELETE_USER_MULTIPLE_ACTION,
  EXPORT_MULTIPLE_ACTION,
  DOTS_SEND_MESSAGE,
  DOTS_VIEW_PROFILE,
  DOTS_DELETE_USER,
  DOTS_BLOCK_PROFILE,
  MEMBERSHIP_RECORDS_PER_PAGE,
  MEMBERSHIP_GET_URL,
} from "../../../utils/configs";
import TabBar from "../../atoms/tabs";

const columns: ColumnTypes[] = [
  { key: "membership_type", label: "Membership Type" },
  { key: "admits", label: "Admits" },
  { key: "price", label: "Cost" },
  { key: "no_of_free_passes", label: "Free Passes" },
  { key: "save_upto", label: "Save Upto" },
  { key: "blockedText", label: 'Status' },
  { key: "action", label: "Actions" }
];

const tabs = [
  {
    value: "all",
    label: "All Users",
  },
  {
    value: "guest",
    label: "Guest User",
  },
  {
    value: "members",
    label: "Members",
  },
  {
    value: "registered",
    label: "Registered Users",
  },
  {
    value: "mailing",
    label: "Mailing List",
  },
  {
    value: "board",
    label: "Board Members",
  },
];

const inputs: ModalInputs[] = [
  {
    label: "Membership Type",
    placeholder: "Enter membership name",
    type: "text",
    name: "membership_type",
    id: "membership_type",
    grid: 1,
    errorMsg: "Please enter membership type",
  },
  {
    label: "Admits",
    placeholder: "For eg. Students, Senior Citizens, No Restrictions ",
    type: "select",
    name: "admits",
    id: "admits",
    grid: 1,
  },
  {
    label: "Price",
    placeholder: "Membership Price",
    type: "number",
    name: "price",
    id: "price",
    grid: 2,
    errorMsg: "Please enter membership price",
  },
  {
    label: "Save Upto",
    placeholder: "00",
    type: "number",
    name: "save_upto",
    id: "save_upto",
    grid: 2,
    errorMsg: "Please enter membership saving",
  },
  {
    label: "Number of free passes",
    placeholder: "00",
    type: "number",
    name: "no_of_free_passes",
    id: "no_of_free_passes",
    grid: 1,
  },
];

const Membership = () => {
  const { loading, error, sendRequest } = useHttp(transformData);
  const [currentPage, setCurrentPage] = useState(1);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [initialRender, setInitialRender] = useState(false);
  const [activeTab, setActiveTab] = useState("all");
  const [totalRecords, setTotalRecords] = useState(MEMBERSHIP_RECORDS_PER_PAGE);
  const [modalInputs, setModalInputs] = useState<ModalInputs[]>(inputs);
  const [memberships, setMemberships] = useState([]);
  const [search, setSearch] = useState(null);
  const [filterYearValue, setFilterYearValue] = useState(null);
  const [showMultipleActions, setShowMultipleActions] = useState(false);
  const [selectedMembership, setSelectedMembership] = useState([]);
  useEffect(() => {
    const requestOptions: ReqOptions = {
      url: MEMBERSHIP_GET_URL,
      method: "GET",
      params: {
        page: currentPage,
        per_page: MEMBERSHIP_RECORDS_PER_PAGE,
      },
    };

    setInitialRender(true);
    sendRequest(requestOptions);
  }, []);

  useEffect(() => {
    if (search !== null) {
      const timer = setTimeout(() => {
        const reqOptions: ReqOptions = {
          url: MEMBERSHIP_GET_URL,
          method: "GET",
          params: {
            page: currentPage,
            per_page: MEMBERSHIP_RECORDS_PER_PAGE,
            search: search,
          },
        };

        sendRequest(reqOptions);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [search]);

  useEffect(() => {
    if (initialRender) {
      const reqOptions: ReqOptions = {
        url: MEMBERSHIP_GET_URL,
        method: "GET",
        params: {
          page: currentPage,
          per_page: MEMBERSHIP_RECORDS_PER_PAGE,
          search: search,
        },
      };

      sendRequest(reqOptions);
    }
  }, [currentPage]);

  useEffect(() => {
    if (showSuccessAlert) {
      const timer = setTimeout(() => {
        setShowSuccessAlert(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [showSuccessAlert]);

  function transformData(data: any) {
    if (data.status) {
      let membershipArr: any = [];
      if (data.memberships) {
        membershipArr = data.memberships.map((membership: any) => {
          membership.id = membership.id;
          membership.action = ["dots"];
          
          membership.blockedText = membership.blocked ? 'Disabled' : 'Enabled';
          membership.admits = !membership?.admits?.length ? [] : membership.admits;
          
          membership.dotActions = membership.blocked ? ["delete_membership", "edit_membership", "enable_membership"] : ["delete_membership", "edit_membership", "disable_membership"];
          return membership;
        });
      }

      data.total && setTotalRecords(data.total);
      setMemberships(membershipArr);
    }
  }

  const handleApiFetchAgain = () => {
    const reqOptions: ReqOptions = {
      url: MEMBERSHIP_GET_URL,
      method: "GET",
      params: {
        page: currentPage,
        per_page: MEMBERSHIP_RECORDS_PER_PAGE,
      },
    };

    sendRequest(reqOptions);
  };

  const handleSelectedUsers = (records: any) => {
    setSelectedMembership(records);
  };

  const handleTabClick = (
    val: "all" | "gold" | "platinum" | "silver" | "bronze" | "block"
  ) => {
    setActiveTab(val);
    setCurrentPage(1);
  };

  const handleSuccessCall = (msg: string) => {
    setShowSuccessAlert(true);
    setSuccessMsg(msg);
  };

  useEffect(() => {
    if (filterYearValue !== null) {
      const requestOptions: ReqOptions = {
        url: MEMBERSHIP_GET_URL,
        method: "GET",
        params: {
          year: filterYearValue,
          page: 1,
          per_page: MEMBERSHIP_RECORDS_PER_PAGE,
          search: search,
        },
      };

      sendRequest(requestOptions);
    }
  }, [filterYearValue]);

  const handleYearFilter = (val: any) => {
    setFilterYearValue(val);
  };

  const handleSearchInput = (e: any) => {
    setSearch(e.target.value);
  };

  const handlePageChange = (e: any, page: number) => {
    setCurrentPage(page);
  };

  const handleCheckSelected = (selected: boolean) => {
    setShowMultipleActions(selected);
  };

  return (
    <>
      {loading && <Loader />}
      {showSuccessAlert && (
        <AlertCmp type="success" position="dashboard">
          {successMsg}
        </AlertCmp>
      )}
      {error && (
        <AlertCmp type="error" position="dashboard">
          Something went wrong! Try again..
        </AlertCmp>
      )}
      {/* <TabBar handleClick={handleTabClick} tabs={tabs} /> */}
      <Topbar
        searchLabel="Search by membership..."
        showBtn={true}
        filterBtn={false}
        totalRecords={true}
        apiUrl={MEMBERSHIP_GET_URL}
        selectedRecords={selectedMembership}
        method="POST"
        handleApiFetchAgain={handleApiFetchAgain}
        confirmBtn="create"
        handleSuccessCall={handleSuccessCall}
        handleSearchInput={handleSearchInput}
        showMultipleActions={showMultipleActions}
        mutipleOptions={[EXPORT_MULTIPLE_ACTION]}
        totalRecordsCount={memberships.length>0?totalRecords:0}
        handleYearFilter={handleYearFilter}
        modal={{
          heading: "Create Membership",
          inputs: modalInputs,
        }}
        showButton={true}
      />
      <Box
        sx={{
          marginTop: "30px",
        }}
      >
        {memberships.length ? (
          <>
            <Tables
              showCheckbox={true}
              tab={activeTab}
              columns={columns}
              data={memberships}
              handleApiFetchAgain={handleApiFetchAgain}
              apiUrl={MEMBERSHIP_GET_URL}
              handleSuccessCall={handleSuccessCall}
              handleMultipleSelection={handleSelectedUsers}
              handleCheckSelected={handleCheckSelected}
              deleteModal={{
                heading: "Delete Membership?",
                content:
                  "Are you sure you want to delete this membership type? This action is non-reversible and can not be undone.",
                primaryButton: "Yes, delete",
                secondaryButton: "Cancel",
              }}
              editModal={{
                heading: "Edit Membership",
                inputs: modalInputs,
              }}
            />
            <Paginate
              page={currentPage}
              totalRecords={totalRecords}
              recordsPerPage={MEMBERSHIP_RECORDS_PER_PAGE}
              handlePageChange={handlePageChange}
            />
          </>
        ) : (
          <>
            {!loading && (
              <NoMember
                heading="Create a membership"
                content={`You haven’t created any membership type.
                          Click "Create" to add a membership.`}
              />
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default Membership;
