import { useState } from "react";
import WebDialog from "../../molecules/webDialog";
import {
  DialogContent,
  DialogContentText,
  Box,
  DialogActions,
} from "@mui/material";
import InputsWithLabel from "../../molecules/inputsWithLabel";
import SectionHeading from "../../molecules/sectionHeading";
import { Button } from "../../atoms/buttons";
import { PropType } from "./types/propType";
import useHttp from "../../../utils/hooks/useHttp";
import Loader from "../../atoms/loader";
import AlertCmp from "../../atoms/alert";
import "./style.css";

const WebMailListingDialog = ({
  open,
  handleClose,
  handleOpenDialog,
}: PropType) => {
  const [email, setEmail] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState({
    show: false,
    message: "",
  });
  const [showErrorAlert, setShowErrorAlert] = useState({
    show: false,
    message: "",
  });
  const { loading, error, sendRequestWithoutToken } = useHttp(transformData);

  function transformData(data: any) {
    if (data.status) {
      setShowSuccessAlert({
        show: true,
        message: data.message,
      });

      handleCloseMailSuccess();

      setTimeout(() => {
        setShowSuccessAlert({
          show: false,
          message: "",
        });
      }, 5000);
    }else{
      setShowErrorAlert({
        show: true,
        message: data?.error || data?.message,
      });
      setTimeout(() => {
        setShowErrorAlert({
          show: false,
          message: "",
        });
        setEmail('')
        handleClose()
      }, 1500);
    }
  }

  const handleCloseMailSuccess = () => {
    handleClose();
    handleOpenDialog();
    setEmail("");
  };

  const handleMailSubmit = (e: any) => {
    e.preventDefault();

    const reqOptions = {
      url: `/mailing_lists/create_mailing_list`,
      method: "POST",
      data: {
        email,
      },
    };

    sendRequestWithoutToken(reqOptions);
  };

  return (
    <>
      {loading && <Loader />}
      {showSuccessAlert.show && (
        <AlertCmp type="success_web" position="user_side">
          {showSuccessAlert.message}
        </AlertCmp>
      )}

      {showErrorAlert.show && (
        <AlertCmp type="error" position="user_side">
          {showErrorAlert.message}
        </AlertCmp>
      )}
      <WebDialog
        title="Mailing List"
        open={open}
        handleClose={()=>{handleClose(); setEmail('')}}
        primaryButtonLabel="Mailing"
        subTitle="Mailing to continue"
      >
        <form onSubmit={handleMailSubmit}>
          <DialogContent>
            <DialogContentText>
              <SectionHeading title="Mailing List" />
              <p className="login_dialog_subtitle">
                Join our mailing list to receive latest updates, promotions
              </p>
            </DialogContentText>
            <Box style={{ marginTop: "56px" }}>
              <InputsWithLabel
                label="Email address"
                type="text"
                name="email"
                id="email"
                labelColor="#FDFCFC"
                placeholder="Enter email here"
                value={email}
                handleInputChange={(e: any) => setEmail(e.target.value)}
              />
            </Box>
          </DialogContent>
          <DialogActions className="login_dialog_actions web_dialog_mail_join_btn">
            <Button
              variant="outlined"
              color="info"
              size="s"
              disable={email ? false : true}
              type="submit"
            >
              Join
            </Button>
          </DialogActions>
        </form>
        <Box className="login_dialog_signup">
          <p>
            <span
              className="login_dialog_signup_link"
              role="button"
              onClick={handleClose}
            >
              No, thanks
            </span>
          </p>
        </Box>
      </WebDialog>
    </>
  );
};

export default WebMailListingDialog;
