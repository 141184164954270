const useWebUser = () => {
  const webToken = localStorage.getItem("web_token");
  let webUserData = null;
  const webUserJson = localStorage.getItem("web_user");
  if (webUserJson) {
    webUserData = JSON.parse(webUserJson);
  }

  return { user: webUserData, token: webToken };
};

const useNaiveUser = () => {
  const token = localStorage.getItem("token");
  let user = null;
  const userJsonString = localStorage.getItem("user");
  if (userJsonString) {
    user = JSON.parse(userJsonString);
  }
  return { user, token };
};

const useUser = () => {
  const { user: webUser } = useWebUser();
  const { user: naiveUser } = useNaiveUser();

  const userData = naiveUser || webUser;
  return { user: userData, isAdmin: userData?.role === "Admin" };
};

export { useNaiveUser, useWebUser };
export default useUser;
