import axios from 'axios';
import { ChangeEvent, useState } from 'react';
const useGetApi = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<any[]>([]);
    const callGetApi = async (path: any) => {
        setLoading(true);
        const apiKey = localStorage.getItem("web_token"); // Replace with your actual API key
        const apiUrl = `${process.env.REACT_APP_API_URL_DEV}/${path}`; // Replace with the API endpoint URL
        const headers = {
            'Authorization': `${apiKey}`
        };
        try {
            const resp=await fetch(apiUrl,{method:"GET",headers:headers});
            const res=resp.json();
            return res;
        } catch (error) {
        }
        // fetch(apiUrl, {
        //     method: 'GET',
        //     headers: headers
        // }).then(response => {
        //     if (!response.ok) {
        //         throw new Error(`HTTP Error! Status: ${response.status}`);
        //     }
        //     console.log()
        //     return response.json(); // Assuming the API returns JSON data
        // }).then(data => {
        //     console.log('data',data)
        //     // setData(data);
        //     // return data
        // })
        //     .catch(error => {
        //         console.error('Error:', error);
        //     });
        setLoading(false);
    };
    return { callGetApi, loading, data };
};
export default useGetApi;






