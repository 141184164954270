import React, { useEffect, useState } from "react";
import WebDialog from "../../molecules/webDialog";
import {
  DialogContent,
  DialogContentText,
  Box,
  DialogActions,
  Grid,
  colors,
} from "@mui/material";
import InputsWithLabel from "../../molecules/inputsWithLabel";
import { Input } from "../../atoms/inputs";
import SectionHeading from "../../molecules/sectionHeading";
import { Button } from "../../atoms/buttons";
import FileButton from "@mui/material/Button";
import { Link } from "react-router-dom";
import { PropType } from "./types/propType";
import TextLabels from "../../atoms/textLabels";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import useHttp from "../../../utils/hooks/useHttp";
import ErrorImg from "./images/error.svg";
import Loader from "../../atoms/loader";
import "./style.css";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const selectInputs: any = {
  student: "Student",
  "senior citizen": "Senior Citizen",
  general: "General",
};

const WebRegisterDialog = ({
  open,
  handleClose,
  handleOpenDialog,
  handleOtpOpen,
  handleAlertBox,
  handleAlertErrorBox,
  page,
}: PropType) => {
  const [userTypeSelect, setUserTypeSelect] = React.useState("");
  const { loading, error, sendRequestWithoutToken } = useHttp(transformData);
  const [disableButton, setDisableButton] = useState(true);
  const [formData, setFormData] = useState<any>({
    username: {
      value: "",
      validate: true,
      errorMsg: "* Please enter full name",
    },
    email: {
      value: "",
      validate: true,
      errorMsg: "* Please enter email",
    },
    user_type: {
      value: "",
      validate: true,
      errorMsg: "* Please select user type",
    },
    address: {
      value: "",
    },
    signup_for_news_latter: {
      value: false,
    },
  });

  useEffect(() => {
    let disableFlag = false;
    Object.keys(formData).forEach((key) => {
      if (formData[key].value === "" && formData[key].validate !== undefined) {
        disableFlag = true;
      }
    });

    setDisableButton(disableFlag);
  }, [formData]);

  useEffect(() => {
    if (!open) {
      const loginDataObj = JSON.parse(JSON.stringify(formData));
      Object.keys(loginDataObj).forEach((key) => {
        loginDataObj[key].value = "";
      });
      setFormData(loginDataObj);
    }
  }, [open]);

  function transformData(data: any) {
    if (data.status) {
      localStorage.setItem("user_register", JSON.stringify(data.user));
      handleAlertBox(data.message);
      handleOtpOpen();
      handleOpenDialog();
    } else {
      handleAlertErrorBox(data.message);
    }
  }

  const handleChange = (event: SelectChangeEvent) => {
    // console.log(event.target.value);

    const formDataObj = JSON.parse(JSON.stringify(formData));
    formDataObj["user_type"].value = event.target.value;
    formDataObj["user_type"].validate = true;
    setFormData(formDataObj);
  };

  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    let errorFlag = false;
    const formDataObj = JSON.parse(JSON.stringify(formData));
    Object.keys(formDataObj).forEach((key) => {
      if (key === "signup_for_news_latter") return;
      // check if user has entered first name or full name
      if (key === "username") {
        // const fullNameRegex = /^[A-Za-z]+\s[A-Za-z]+$/;
        const fullNameRegex = /^[A-Za-z]+(?: [A-Za-z]+)*$/; // updated the regex to accept more than 2 words 

        if (!fullNameRegex.test(formDataObj[key].value)) {
          formDataObj[key].validate = false;
          errorFlag = true;
        }

        if (
          formDataObj[key]?.value?.trim() === "" &&
          formData[key].validate !== undefined
        ) {
          formDataObj[key].validate = false;
          errorFlag = true;
        }
      }
      if (key === "email") {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailRegex.test(formDataObj[key].value)) {
          formDataObj[key].validate = false;
          formDataObj[key].errorMsg = "* Please enter a valid email id";
          errorFlag = true;
        }

        if (
          formDataObj[key]?.value?.trim() === "" &&
          formData[key].validate !== undefined
        ) {
          formDataObj[key].validate = false;
          formDataObj[key].errorMsg = "* Please enter email";
          errorFlag = true;
        }
      } else {
        if (
          formDataObj[key]?.value?.trim() === "" &&
          formData[key].validate !== undefined
        ) {
          formDataObj[key].validate = false;
          errorFlag = true;
        }
      }
    });
    setFormData(formDataObj);

    if (!errorFlag) {
      const dataObj: any = {};
      Object.keys(formData).forEach((key) => {
        dataObj[key] = formData[key].value;
      });

      const reqOptions = {
        url: `/create_user`,
        method: "POST",
        data: dataObj,
      };

      sendRequestWithoutToken(reqOptions);
    }
  };

  const handleInputChange = (e: any) => {
    const formObj = JSON.parse(JSON.stringify(formData));
    formObj[e.target.name].value = e.target.value;
    if (e.target.value !== "") {
      formObj[e.target.name].validate = true;
    }
    setFormData(formObj);
  };

  const handleNewletterCheck = (e: any) => {
    const formDataObj = JSON.parse(JSON.stringify(formData));
    formDataObj["signup_for_news_latter"].value = e.target.checked;
    setFormData(formDataObj);
  };

  const handleNameKey = (event: any) => {
    const isNumericKey = event.keyCode >= 48 && event.keyCode <= 57;
    if (isNumericKey) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (error) {
      handleAlertErrorBox(error);
    }
  }, [error]);

  return (
    <>
      {loading && <Loader />}
      <WebDialog
        title="Welcome !"
        open={open}
        handleClose={handleClose}
        primaryButtonLabel="Sign In"
        subTitle="Sign in to continue"
      >
        <form onSubmit={handleFormSubmit}>
          <DialogContent>
            <DialogContentText>
              {page !== "membership" && <SectionHeading title="Welcome !" />}
              {page == "membership" && (
                <Box className="login_dialog_signup">
                  <p style={{ fontSize: "20px" }}>
                    Already have an account?{" "}
                    <span
                      onClick={handleOpenDialog}
                      className="login_dialog_signup_link"
                      style={{ cursor: "pointer", fontSize: "20px" }}
                    >
                      Sign In
                    </span>
                  </p>
                </Box>
              )}
              {page !== "membership" && (
                <p className="login_dialog_subtitle">
                  Register yourself by entering the following information.
                </p>
              )}
              {/* <Box className="login_dialog_signup">
                <p
                  style={{ fontSize: "20px" }}
                >
                  Already have an account?{" "}
                  <span
                    onClick={handleOpenDialog}
                    className="login_dialog_signup_link"
                    style={{ cursor: "pointer", fontSize: "20px" }}
                  >
                    Sign In
                  </span>
                </p>
              </Box> */}
            </DialogContentText>
            <InputsWithLabel
              label="Name *"
              type="text"
              name="username"
              id="username"
              labelColor="#FDFCFC"
              placeholder="Enter full name"
              handleInputChange={handleInputChange}
              handleKeyDown={handleNameKey}
            />
            {!formData["username"].validate && (
              <p className="web_register_error_label">
                <img src={ErrorImg} />
                <span>{formData["username"].errorMsg}</span>
              </p>
            )}
            <Box
              sx={{
                marginTop: "45px",
              }}
            />
            <InputsWithLabel
              label="Email *"
              type="text"
              name="email"
              id="email"
              labelColor="#FDFCFC"
              placeholder="Enter email here"
              handleInputChange={handleInputChange}
            />
            {!formData["email"].validate && (
              <p className="web_register_error_label">
                <img src={ErrorImg} />
                <span>{formData["email"].errorMsg}</span>
              </p>
            )}
            {/* <Box
              sx={{
                marginTop: "45px",
              }}
            />
            <Box>
              <TextLabels variant="h5" color="#FDFCFC" weight={700}>
                Mobile Number (optional)
              </TextLabels>
              <Grid container spacing={4}>
                <Grid item xs={3} className="register_dialog_web_padding">
                  <Input
                    type="text"
                    name="country_code"
                    id="country_code"
                    placeholder="+1"
                    sx={{
                      marginTop: "10px",
                      width: "100%",
                      backgroundColor: "#ffffff",
                      borderRadius: "4px",
                      fontWeight: "400",
                      fontSize: "14px",
                      padding: "0px",
                    }}
                    handleChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={9} className="register_dialog_web_padding">
                  <Input
                    type="text"
                    name="contact_number"
                    id="contact_number"
                    placeholder="Enter number"
                    sx={{
                      marginTop: "10px",
                      width: "100%",
                      backgroundColor: "#ffffff",
                      borderRadius: "4px",
                      fontWeight: "400",
                      fontSize: "14px",
                      padding: "0px",
                    }}
                    handleChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </Box> */}
            <Box
              sx={{
                marginTop: "45px",
              }}
            />
            <Box>
              <TextLabels variant="h5" color="#FDFCFC" weight={700}>
                User Type *
              </TextLabels>
              <Box
                className="web_dialog_select"
                sx={{
                  marginTop: "10px",
                }}
              >
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <Select
                    value={userTypeSelect}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    renderValue={
                      formData["user_type"].value !== ""
                        ? () => selectInputs[formData["user_type"].value]
                        : () => (
                            <span
                              style={{
                                color: "#BABDC2",
                              }}
                            >
                              Select User Type
                            </span>
                          )
                    }
                  >
                    <MenuItem value={"general"}>General</MenuItem>
                    <hr className="divider" />
                    <MenuItem value={"student"}>Student</MenuItem>
                    <hr className="divider" />
                    <MenuItem value={"senior citizen"}>Senior</MenuItem>
                  </Select>
                  {formData["user_type"].value === "student" && (
                    <p className="web_register_select_extra_label">
                      * Present your student ID at the time of the concert.
                    </p>
                  )}
                </FormControl>
                <Box
                  sx={{
                    marginTop: "20px",
                  }}
                >
                  {!formData["user_type"].validate && (
                    <p className="web_register_error_label">
                      <img src={ErrorImg} />
                      <span>{formData["user_type"].errorMsg}</span>
                    </p>
                  )}
                </Box>
              </Box>
            </Box>
            {userTypeSelect === "student" && (
              <>
                <Box
                  sx={{
                    marginTop: "45px",
                  }}
                />
                <Box>
                  <TextLabels variant="h5" color="#FDFCFC" weight={700}>
                    Student ID
                  </TextLabels>
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="raised-button-file"
                    multiple
                    type="file"
                  />
                  <label htmlFor="raised-button-file">
                    <Box className="web_dialog_student_upload">
                      <button>Browse</button>
                    </Box>
                  </label>
                </Box>
              </>
            )}
            {userTypeSelect === "senior" && (
              <>
                <Box
                  sx={{
                    marginTop: "45px",
                  }}
                />
                <Box>
                  <TextLabels variant="h5" color="#FDFCFC" weight={700}>
                    Age Proof
                  </TextLabels>
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="raised-button-file"
                    multiple
                    type="file"
                  />
                  <label htmlFor="raised-button-file">
                    <Box className="web_dialog_student_upload">
                      <button>Upload</button>
                    </Box>
                  </label>
                </Box>
              </>
            )}
            {/* <Box
              sx={{
                marginTop: "45px",
              }}
            />
            <InputsWithLabel
              label="Address (optional)"
              type="text"
              name="address"
              id="address"
              placeholder="Enter adress here"
              labelColor="#FDFCFC"
            />
            <Box
              sx={{
                marginTop: "45px",
              }}
            /> */}
            <Box className="web_dialog_checkbox">
              <Checkbox {...label} onChange={handleNewletterCheck} />
              <span>Signup for Newsletter</span>
            </Box>
            {page !== "membership" && (
              <Box className="login_dialog_signup">
                <p
                // style={{ fontSize: "20px" }}
                >
                  Already have an account?{" "}
                  <span
                    onClick={handleOpenDialog}
                    className="login_dialog_signup_link"
                    style={{
                      cursor: "pointer",
                      // , fontSize: "20px"
                    }}
                  >
                    Sign In
                  </span>
                </p>
              </Box>
            )}
          </DialogContent>
          <DialogActions className="login_dialog_actions">
            <Button
              variant="outlined"
              color="info"
              size="s"
              type="submit"
              sx={{
                fontFamily: "Raleway",
                fontWeight: "700",
                fontSize: "14px",
              }}
              disable={disableButton}
            >
              Continue
            </Button>
          </DialogActions>
        </form>
      </WebDialog>
    </>
  );
};

export default WebRegisterDialog;
