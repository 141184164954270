/* eslint-disable react-hooks/rules-of-hooks */
import axios from "axios";
import { useState } from "react";

const profileManagementApi = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const createProfileApi = async (data: any, path: any,method:string) => {
    setLoading(true);
    try {
      const url = process.env.REACT_APP_API_URL_DEV;
      const response = await axios({
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
        url: `${url}/${path}`,
        data: data,
      });
      if (!response.data) throw new Error("Something Went Wrong");
      return response?.data;
    } catch (e: any) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const getAllMembershipData = async (path: any) => {
    setLoading(true);
    try {
      const url = process.env.REACT_APP_API_URL_DEV;
      const token = localStorage.getItem("token");

      if (!url) throw new Error("API URL is not defined.");
      if (!token) throw new Error("Authorization token is missing.");

      const response = await axios.get(`${url}/${path}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      if (response.status < 200 || response.status >= 300) {
        throw new Error("Request failed with status: " + response.status);
      }

      return response?.data;
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };



  return { createProfileApi, loading, error, getAllMembershipData };
};
export default profileManagementApi;
