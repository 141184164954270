import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { Box, Grid, TextareaAutosize } from "@mui/material";
import SectionHeading from "../../molecules/sectionHeading";
import { Input } from "../../atoms/inputs";
import { Button } from "../../atoms/buttons";
import "./style.css";
import PaymentPopup from "../../organism/paymentComponent";
import AlertCmp from "../../atoms/alert";
import SaveIcon from "@mui/icons-material/Save";
import useGetApi from "../../../utils/hooks/usegetApi";
import useEditAboutTitle from "../../../utils/hooks/useEditableTitle";
import Loader from "../../atoms/loader";
// import EditAboutTitle from "../../../utils/hooks/EditableTitle";

const priceData = [25, 50, 75, 100];

const Donate = () => {
  const [amount, setAmount] = useState<any>(0);
  const [openPayment, setOpenPayment] = useState<any>(false);
  const editableRef = React.useRef<HTMLInputElement>(
    null
  ) as MutableRefObject<HTMLDivElement>;
  const [showText, setShowText] = useState<any>("");
  const [savebtnShow, setSavebtnShow] = useState<boolean>(false);

  // const {error,loading,aboutUsTitle}=EditAboutTitle()

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleClickOutside = async (event: any) => {
      if (editableRef.current && !editableRef.current.contains(event.target)) {
        // console.log('Clicked outside the contentEditable element');
        const data = {
          about: {
            image_text: editableRef.current.innerText,
          },
        };
        // await aboutUsTitle(data)
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const [showSuccessAlert, setShowSuccessAlert] = useState({
    show: false,
    message: "",
  });

  // const [data, setData] = useState([]); // State to store the API response data

  const { callGetApi, data } = useGetApi();

  const apiCall = async () => {
    // let arr=[];
    const result1 = await callGetApi("donations");
    setShowText(result1?.Donation?.[0]);
  };

  useEffect(() => {
    // Inside the useEffect hook, make the GET request
    apiCall();
  }, []);

  const webToken = localStorage.getItem("web_token");
  const userInfo: any = localStorage.getItem("web_user") ?? null;
  const userRole = userInfo && JSON.parse(userInfo);

  const [isEditing, setIsEditing] = useState(true);
  const [text, setText] = useState("Hello, World!");

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e: any) => {
    setShowText({ donation_text: e.target.value });
    setSavebtnShow(true);
  };

  const { aboutUsTitle } = useEditAboutTitle();

  const handleSaveClick = async () => {
    setIsEditing(false);
    await aboutUsTitle(showText, 1, "donations");
    apiCall();
    setSavebtnShow(false);
  };

  // loading screen
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <Box>
      {loading && <Loader />}
      {savebtnShow && (
        <SaveIcon className="save_icon" onClick={handleSaveClick} />
      )}
      {showSuccessAlert.show && (
        <AlertCmp type="success_web" position="user_side">
          {showSuccessAlert.message}
        </AlertCmp>
      )}
      <Box className="donate_top">
        <SectionHeading title="Donation" marginRight="-250" />

        {isEditing && userRole?.role === "Admin" ? (
          <div className="input_tag_wrapper">
            <TextareaAutosize
              id="donation_text"
              value={showText?.donation_text}
              onChange={handleInputChange}
              className="donate_content"
              style={{ color: "#babdc2", paddingInline: 0 }}
            />
          </div>
        ) : (
          <p onClick={() => setIsEditing(true)} className="donate_top_subtitle">

            {/* Commenting the api data as per requirment  */}
            {/* {showText?.donation_text} */}

            IMSOM is a non-profit 501(c)3 organization and contributions made to
            it via this page will be fully tax-deductible. These donations do
            not count towards the annual membership of IMSOM. Please click the
            link below to donate any amount to help IMSOM continue its mission
            of presenting the best in Indian Music. <br /> <br />
            Thanks, IMSOM Executive
            Committee
          </p>
        )}
      </Box>
      <Box>
        <Box className="donate_inputs">
          {/* <input
            type="number"
            placeholder="Enter Custom Amount"
            id="amount"
            name="amount"
            value={amount}
            style={{color: '#FFF'}}
            onChange={(e)=>{setAmount(e.target.value)}}
          /> */}
          <input
            type="text"
            placeholder="Enter Custom Amount"
            id="amount"
            name="amount"
            value={amount}
            style={{ color: "#FFF" }}
            onChange={(e) => {
              const numericValue = e.target.value.replace(/[^0-9]/g, "");
              setAmount(numericValue);
            }}
            inputMode="numeric"
            pattern="[0-9]*"
          />
          <Button
            handleClick={() => {
              if (amount && amount != 0) {
                setOpenPayment(true);
              } else {
                setShowSuccessAlert({
                  show: true,
                  message: "Please enter amount",
                });
                setTimeout(() => {
                  setShowSuccessAlert({
                    show: false,
                    message: "",
                  });
                }, 2500);
              }
            }}
            variant="outlined"
            size="s"
            color="info"
          >
            Pay via Paypal
          </Button>
        </Box>
        <Box className="donate_prices_box">
          {priceData.map((price: number, i) => {
            return (
              <div
                className="donate_price_box"
                onClick={() => {
                  if (amount == null || amount == 0) {
                    setAmount(price);
                  } else {
                    let amt = parseInt(amount) + price;
                    setAmount(amt);
                  }
                }}
              >
                +${price}
              </div>
            );
          })}
        </Box>
        <Box className="donate_mobile_button">
          <Button
            handleClick={() => {
              if (amount && amount != 0) {
                setOpenPayment(true);
              } else {
                setShowSuccessAlert({
                  show: true,
                  message: "Please enter amount",
                });
                setTimeout(() => {
                  setShowSuccessAlert({
                    show: false,
                    message: "",
                  });
                }, 2500);
              }
            }}
            variant="outlined"
            size="s"
            color="info"
          >
            Pay via Paypal
          </Button>
        </Box>
      </Box>
      {amount != null && openPayment && (
        <PaymentPopup
          open={openPayment}
          handleClose={() => {
            setOpenPayment(false);
          }}
          amount={amount}
          type={"donation"}
        />
      )}
    </Box>
  );
};

export default Donate;
