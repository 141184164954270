import React from "react";
import { Box } from "@mui/material";
import BrandImg1 from "./images/brand1.png";
import BrandImg2 from "./images/brand2.png";
import BrandImg3 from "./images/brand3.png";
import "./style.css";

const brandLogos = [BrandImg1, BrandImg2, BrandImg3];

const AcknowledgementSection = ({ ackData }: { ackData: any }) => {
  return (
    <Box className="ack_section">
      <h4>Acknowledgment</h4>
      <p dangerouslySetInnerHTML={{ __html: ackData.ack }} />
      <Box className="ack_section_brands">
        {ackData.event_images.length &&
          ackData.event_images.map((image: any, i: number) => {
            return <img src={image[0]} key={i} />;
          })}
      </Box>
    </Box>
  );
};

export default AcknowledgementSection;
