import React from "react";
import { PropType } from "./types/propType";
import { Box, Grid } from "@mui/material";
import HeroImg from "./images/hero.png";
import TicketImg from "./images/ticket.svg";
import LocationImg from "./images/location.svg";
import TimeImg from "./images/time.svg";
import TickImg from "./images/tick.svg";
import DisabledLocationImg from "./images/location_disabled.svg";
import DisabledTimeImg from "./images/time_disabled.svg";
import CDImg from "./images/cdimg.svg";
import TextLabels from "../../atoms/textLabels";
import ClockImg from "./images/clock.svg";
import "./style.css";

const orderData = [
  {
    status: "processing",
    orderId: "#04-6370480-0180302",
    amount: "$ 40",
    quantity: 1,
    cds: [
      {
        title: "CD 1",
        type: "Hindustani Vocals",
      },
      {
        title: "CD 1",
        type: "Hindustani Vocals",
      },
      {
        title: "CD 1",
        type: "Hindustani Vocals",
      },
      {
        title: "CD 1",
        type: "Hindustani Vocals",
      },
    ],
  },
  {
    status: "delivered",
    orderId: "#04-6370480-0180302",
    amount: "$ 40",
    quantity: 1,
    cds: [
      {
        title: "CD 1",
        type: "Hindustani Vocals",
      },
      {
        title: "CD 1",
        type: "Hindustani Vocals",
      },
      {
        title: "CD 1",
        type: "Hindustani Vocals",
      },
      {
        title: "CD 1",
        type: "Hindustani Vocals",
      },
    ],
  },
  {
    status: "cancelled",
    orderId: "#04-6370480-0180302",
    amount: "$ 40",
    quantity: 1,
    cds: [
      {
        title: "CD 1",
        type: "Hindustani Vocals",
      },
      {
        title: "CD 1",
        type: "Hindustani Vocals",
      },
      {
        title: "CD 1",
        type: "Hindustani Vocals",
      },
      {
        title: "CD 1",
        type: "Hindustani Vocals",
      },
    ],
  },
];

const BuyCDStep3 = ({ handleStep }: PropType) => {
  return (
    <Box className="order_history_box">
      <Box className="modal_profile_popup_orders_box">
        {orderData.map((order, i) => {
          return (
            <Box
              className="modal_profile_popup_order user_profile_order_box"
              key={i}
            >
              <Grid container>
                <Grid item xs={12}>
                  <span className="processing_status user_processing_status">
                    <img src={ClockImg} />
                    Processing
                  </span>
                </Grid>
                <Grid item xs={4} className="modal_popup_order_grid_margin">
                  <Box>
                    <TextLabels variant="h100" color="#606266" weight={400}>
                      Order ID
                    </TextLabels>
                  </Box>
                  <Box
                    sx={{
                      marginTop: "10px",
                    }}
                  >
                    <TextLabels variant="h200" weight={500} color="#D5D7DB">
                      {order.orderId}
                    </TextLabels>
                  </Box>
                </Grid>
                <Grid item xs={4} className="modal_popup_order_grid_margin">
                  <Box>
                    <TextLabels variant="h100" color="#606266" weight={400}>
                      Amount Paid
                    </TextLabels>
                  </Box>
                  <Box
                    sx={{
                      marginTop: "10px",
                    }}
                  >
                    <TextLabels variant="h200" weight={500} color="#D5D7DB">
                      {order.amount}
                    </TextLabels>
                  </Box>
                </Grid>
                <Grid item xs={4} className="modal_popup_order_grid_margin">
                  <Box>
                    <TextLabels variant="h100" color="#606266" weight={400}>
                      Order Quantity
                    </TextLabels>
                  </Box>
                  <Box
                    sx={{
                      marginTop: "10px",
                    }}
                  >
                    <TextLabels variant="h200" weight={500} color="#D5D7DB">
                      {order.quantity}
                    </TextLabels>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className="modal_popup_order_divider"></Box>
                </Grid>
                <Grid item xs={12 / 5}>
                  <img src={CDImg} />
                </Grid>
                {order.cds.map((cd: any, i: number) => {
                  return (
                    <Grid item xs={12 / 5} className="user_profile_cd_grid">
                      <Box>
                        <p className="modal_popup_cd_title user_profile_order_title">
                          {cd.title}
                        </p>
                      </Box>
                      <Box>
                        <p className="modal_popup_cd_subtitle user_profile_order_subtitle">
                          {cd.type}
                        </p>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default BuyCDStep3;
