import { Box, Typography, Button } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useParams } from "react-router-dom";
import moment from "moment";
import CardSection from "../../molecules/cardSection";
import "./style.css";
import useHttp from "../../../utils/hooks/useHttp";
import { useEffect, useState } from "react";
import Loader from "../../atoms/loader";

const AnnouncementDetail = () => {
  const { id } = useParams();
  const { loading, sendRequestWithoutToken } = useHttp(transformData);
  const [membership, setMembership] = useState<any>([]);
  function transformData(data: any) {
    if (data.status) {
      setMembership(data.announcement);
    }
  }

  useEffect(() => {
    const reqOptions = {
      url: `/get_announcement?id=${id}`,
      method: "GET",
    };
    sendRequestWithoutToken(reqOptions);
  }, [id]);

  return (
    <>
      {loading && <Loader />}
      <Box className="announcement_top_website">
        <Box>
          <Box className="announcement_header">
            <Button
              className="back_button"
              variant="outlined"
              onClick={() => window.history.back()}
            >
              <ArrowBackIosNewIcon />
            </Button>
            <Typography
              variant="h5"
              component="h5"
              className="announcement_heading"
            >
              {membership.title}
            </Typography>
          </Box>
          <Typography variant="h5" component="h5" className="announcement_date">
            {moment(membership.start_date).format("MMMM DD, YYYY")}
          </Typography>
        </Box>
        <CardSection>
          <Box className="announcement_wrapper_message">
            <Typography
              variant="h5"
              component="h5"
              className="announcement_message"
            >
              <p dangerouslySetInnerHTML={{ __html: membership.message }} />
            </Typography>
          </Box>
        </CardSection>
      </Box>
    </>
  );
};

export default AnnouncementDetail;
