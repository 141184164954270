import CDOrdersAndBanner from "../../templates/cdOrdersAndBanner";
import Layout from "../../organism/layout";

const CDOrdersAndBannerPage = () => {
  return (
    <Layout pageName="Banners" menuActive="Banners">
      <CDOrdersAndBanner />
    </Layout>
  );
};

export default CDOrdersAndBannerPage;
