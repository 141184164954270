import { useEffect, useState } from "react";
import BackImg from "./images/back.svg";
import { Box } from "@mui/material";
import GalleryImg1 from "./images/gallery1.png";
import GalleryImg2 from "./images/gallery2.png";
import GalleryImg3 from "./images/gallery3.png";
import GalleryImg4 from "./images/gallery4.png";
import GalleryImg5 from "./images/gallery5.png";
import SectionHeading from "../../molecules/sectionHeading";

import "./style.css";
import { useNavigate } from "react-router-dom";
import useHttp from "../../../utils/hooks/useHttp";
import { MyWrapper } from "../../atoms/masonaryImageGllery";
// import { MyWrapperResponsive } from "../../atoms/masonaryImageGllery/index";

const GalleryWebsite = () => {
  const navigate = useNavigate();
  const { loading, sendRequestWithoutToken } = useHttp(transformData);
  const [galleryData, setGalleryData] = useState<any>([]);
  function transformData(data: any) {
    if (data.status) {
      setGalleryData(data.event_gallery);
    }
  }

  useEffect(() => {
    const reqOptions = {
      url: `/event_gallery/get_event_gallery`,
      method: "GET",
    };

    sendRequestWithoutToken(reqOptions);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);


  return (
    <Box className="gallery_page_website">
      <Box className="gallery_page_top_website">
        <Box className="gallery_page_top_navigation_website">
          <img
            onClick={() => navigate(-1)}
            src={BackImg}
            className="back_img"
            style={{cursor:"pointer",position:"relative",zIndex:1000}}
          />
          <SectionHeading title="Gallery" />
        </Box>
      </Box>
      <Box className="gallery_images_website image_gallery_container">
        {galleryData.map((gallery: any) => (
          <div className="image_container">
            <h2 className="image_container_heading">{gallery.event_title}</h2>
            <MyWrapper galleryData={gallery.event_images} />
          </div>
        ))}
      </Box>
    </Box>
  );
};

export default GalleryWebsite;
