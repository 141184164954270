const Whatsapp = ()=>{
    return <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
    <g clip-path="url(#clip0_5942_40591)">
      <path d="M0.80158 16.5112C0.800836 19.1998 1.50335 21.8251 2.83916 24.139L0.673828 32.045L8.76462 29.9235C11.0024 31.1418 13.5097 31.7801 16.0576 31.7803H16.0643C24.4755 31.7803 31.3224 24.9359 31.326 16.5232C31.3276 12.4467 29.7415 8.61339 26.8598 5.72943C23.9785 2.8457 20.1466 1.25674 16.0637 1.25488C7.65157 1.25488 0.805177 8.09891 0.801704 16.5112" fill="url(#paint0_linear_5942_40591)"/>
      <path d="M0.256737 16.5067C0.255868 19.292 0.983558 22.0112 2.367 24.4079L0.124023 32.5973L8.50492 30.3999C10.8141 31.6589 13.4141 32.3227 16.0597 32.3237H16.0665C24.7794 32.3237 31.8722 25.2331 31.876 16.5193C31.8774 12.2963 30.2343 8.3252 27.2496 5.33792C24.2645 2.351 20.2956 0.704861 16.0665 0.703125C7.35205 0.703125 0.260209 7.79274 0.256737 16.5067ZM5.24774 23.9952L4.93481 23.4984C3.61934 21.4068 2.92502 18.9896 2.92601 16.5077C2.92886 9.26486 8.82331 3.37227 16.0714 3.37227C19.5815 3.37376 22.8802 4.74207 25.3614 7.22468C27.8423 9.70753 29.2076 13.008 29.2067 16.5183C29.2035 23.7611 23.3089 29.6544 16.0665 29.6544H16.0613C13.7031 29.6532 11.3903 29.0199 9.37326 27.8231L8.89326 27.5385L3.91987 28.8424L5.24774 23.995V23.9952Z" fill="url(#paint1_linear_5942_40591)"/>
      <path d="M12.1151 9.89974C11.8191 9.24201 11.5077 9.22873 11.2263 9.2172C10.9958 9.20728 10.7324 9.20802 10.4692 9.20802C10.2057 9.20802 9.7777 9.30712 9.4159 9.70216C9.05373 10.0976 8.0332 11.0531 8.0332 12.9965C8.0332 14.9401 9.44877 16.8183 9.6461 17.0821C9.84368 17.3454 12.3789 21.4613 16.394 23.0447C19.7309 24.3605 20.41 24.0988 21.1342 24.0328C21.8586 23.9671 23.4715 23.0775 23.8005 22.1551C24.1298 21.2328 24.1298 20.4423 24.0311 20.277C23.9324 20.1125 23.6689 20.0136 23.2739 19.8161C22.8787 19.6186 20.9366 18.6629 20.5746 18.5311C20.2124 18.3993 19.9491 18.3336 19.6857 18.7291C19.4222 19.1241 18.6658 20.0136 18.4352 20.277C18.2049 20.5411 17.9743 20.574 17.5794 20.3764C17.1841 20.1782 15.9119 19.7616 14.4026 18.416C13.2282 17.3689 12.4354 16.0759 12.205 15.6803C11.9745 15.2854 12.1803 15.0713 12.3784 14.8745C12.5559 14.6975 12.7735 14.4132 12.9712 14.1827C13.1682 13.952 13.2339 13.7874 13.3657 13.5239C13.4975 13.2602 13.4315 13.0295 13.3329 12.832C13.2339 12.6344 12.4663 10.6808 12.1151 9.89974Z" fill="white"/>
    </g>
    <defs>
      <linearGradient id="paint0_linear_5942_40591" x1="1533.28" y1="3080.26" x2="1533.28" y2="1.25488" gradientUnits="userSpaceOnUse">
        <stop stop-color="#1FAF38"/>
        <stop offset="1" stop-color="#60D669"/>
      </linearGradient>
      <linearGradient id="paint1_linear_5942_40591" x1="1587.72" y1="3190.12" x2="1587.72" y2="0.703125" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F9F9F9"/>
        <stop offset="1" stop-color="white"/>
      </linearGradient>
      <clipPath id="clip0_5942_40591">
        <rect width="31.7519" height="32" fill="white" transform="translate(0.124023 0.703125)"/>
      </clipPath>
    </defs>
  </svg>
}

export {Whatsapp}