import React from "react";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import "./style.css";
import Box from "@mui/material/Box";
import LoaderImg from "./images/final_imsom.gif";

const Loader = () => {
  return (
    // <Stack
    //   sx={{ color: "grey.500" }}
    //   spacing={2}
    //   direction="row"
    //   className="loader_box"
    // >
    //   <CircularProgress color="primary" />
    // </Stack>
    <Box className="loader-container">
      <Box className="loader">
        <img src={LoaderImg} className="custom-icon" alt="loader..." />
      </Box>
    </Box>
  );
};

export default Loader;
