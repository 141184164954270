import React from "react";
import { Box, Grid } from "@mui/material";
import LongDiyaImg from "./images/Asset 2.svg";
import ShortDiyaImg from "./images/Asset 1.svg";
import "./style.css";

const DiyaLayout = ({ children }: { children: any }) => {
  return (
    <Box className="membership_section">
      <Box>
        <Grid container spacing={{ sm: 2, xs: 1 }}>
          <Grid item xs={1}>
            <img src={LongDiyaImg} className="diya_image" />
          </Grid>
          <Grid item xs={1}>
            <img src={ShortDiyaImg} className="diya_image" id="short_image1" />
          </Grid>
          <Grid item xs={8} className="membership_content">
            {children}
          </Grid>
          <Grid item xs={1}>
            <img src={ShortDiyaImg} className="diya_image rotate_diya" id="short_image" />
          </Grid>
          <Grid item xs={1}>
            <img src={LongDiyaImg} className="diya_image rotate_diya" />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default DiyaLayout;
