import { RoutesType } from "./types/RouteType";
import DashboardPage from "../../components/pages/dashboard";
import UserManagementPage from "../../components/pages/userManagement";
// import CDOrdersAndBannerPage from "../../components/pages/cdOrdersAndBanner";
import ChangePassword from "../../components/pages/changePassword";
import LoginPage from "../../components/pages/login";
import MembershipPage from "../../components/pages/membership";
import EventGalleryPage from "../../components/pages/eventGallery";
import EventGalleryImagesPage from "../../components/pages/eventGalleryImages";
import AnnouncementPage from "../../components/pages/announcement";
import ProfilePage from "../../components/pages/profile";
import SettingPage from "../../components/pages/setting";
import EventManagementPage from "../../components/pages/eventManagement";
import HomePage from "../../components/pages/home";
import EventBasicDetails from "../../components/templates/eventBasicDetails";
import CreateEventPage from "../../components/pages/createEvent";
import EventDetailsPage from "../../components/pages/eventDetails";
import AllEventsPage from "../../components/pages/allEvents";
import UpcomingEventsPage from "../../components/pages/upcomingEvents";
import PastEventsPage from "../../components/pages/pastEvents";
import SinglePastEventPage from "../../components/pages/singlePastEvent";
import GalleryWebsitePage from "../../components/pages/galleryWebsite";
import AboutUsPage from "../../components/pages/aboutUs";
import MembershipsPage from "../../components/pages/customerMemberships";
import MembershipsWelcomePage from "../../components/pages/customerMembershipsWelcome";
import AllArtistsPage from "../../components/pages/allArtists";
import BuyCDPage from "../../components/pages/buyCD";
import BuyCDDetailPage from "../../components/pages/buyCDDetail";
import BuyCDWizardStepsPage from "../../components/pages/buyCDWizardSteps";
import TicketBookingPage from "../../components/pages/ticketBooking";
import MusicMasalaPage from "../../components/pages/musicMasala";
import FeedbackPage from "../../components/pages/feedback";
import FeedbackWelcomePage from "../../components/pages/feedbackWelcome";
import DonatePage from "../../components/pages/donate";
import ThankYouPage from "../../components/pages/thankYou";
import AttendeeDetailsPage from "../../components/pages/attendeeDetails";
import MailListingPage from "../../components/pages/mailListing";
import AnnouncementDetailPage from "../../components/pages/announcementDetail";
import ParticipantListPage from "../../components/pages/participantList";
import CDOrdersAndBannerPage from "../../components/pages/cdOrdersAndBanner";
import FeedbackManagementPage from "../../components/pages/feedbackManagement";
import PaymentManagementPage from "../../components/pages/paymentManagement";
import EditProfileManagement from "../../components/pages/editProfileManagement/EditProfileManagement";

export const routes: RoutesType[] = [
  {
    path: "/",
    exact: true,
    component: <HomePage />,
    protected: false,
    userSide: true,
  },
  {
    path: "/login",
    exact: true,
    component: <LoginPage />,
    protected: false,
  },
  {
    path: "/forgot-password",
    exact: true,
    component: <ChangePassword />,
    protected: false,
  },
  {
    path: "/change-password",
    exact: true,
    component: <ChangePassword />,
    protected: false,
  },
  {
    path: "/admin",
    exact: true,
    component: <div>Hello Admin</div>,
    protected: true,
  },
  {
    path: "/dashboard",
    exact: true,
    component: <DashboardPage />,
    protected: true,
  },
  {
    path: "/user-management",
    exact: true,
    component: <UserManagementPage />,
    protected: true,
  },
  {
    path: "/user-management/edit-profile",
    exact: true,
    component: <EditProfileManagement />,
    protected: true,
  },
  {
    path: "/cd-orders-banners",
    exact: true,
    component: <CDOrdersAndBannerPage />,
    protected: true,
  },

  {
    path: "/membership",
    exact: true,
    component: <MembershipPage />,
    protected: true,
  },
  {
    path: "/event-gallery",
    exact: true,
    component: <EventGalleryPage />,
    protected: true,
  },
  {
    path: "/event-gallery/:id",
    exact: true,
    component: <EventGalleryImagesPage />,
    protected: true,
  },
  {
    path: "/announcement",
    exact: true,
    component: <AnnouncementPage />,
    protected: true,
  },
  {
    path: "/profile",
    exact: true,
    component: <ProfilePage />,
    protected: true,
  },
  {
    path: "/settings",
    exact: true,
    component: <SettingPage />,
  },
  {
    path: "/event-management",
    exact: true,
    component: <EventManagementPage />,
  },
  {
    path: "/create-event",
    exact: true,
    component: <CreateEventPage />,
  },
  {
    path: "/event-details/:id",
    exact: true,
    component: <EventDetailsPage />,
  },
  {
    path: "/event_participants/:id",
    exact: true,
    component: <ParticipantListPage />,
  },
  {
    path: "/all-events",
    exact: true,
    component: <AllEventsPage />,
    userSide: true,
  },
  {
    path: "/upcoming-event/:id",
    exact: true,
    component: <UpcomingEventsPage />,
    userSide: true,
  },
  {
    path: "/past-events",
    exact: true,
    component: <PastEventsPage />,
    userSide: true,
  },
  {
    path: "/event/:id",
    exact: true,
    component: <SinglePastEventPage />,
    userSide: true,
  },
  {
    path: "/event/gallery/:id",
    exact: true,
    component: <GalleryWebsitePage />,
    userSide: true,
  },
  {
    path: "/about-us",
    exact: true,
    component: <AboutUsPage />,
    userSide: true,
  },
  {
    path: "/memberships",
    exact: true,
    component: <MembershipsPage />,
    userSide: true,
  },
  {
    path: "/memberships-welcome",
    exact: true,
    component: <MembershipsWelcomePage />,
    userSide: true,
  },
  {
    path: "/all-artists",
    exact: true,
    component: <AllArtistsPage />,
    userSide: true,
  },
  {
    path: "/buy-cd",
    exact: true,
    component: <BuyCDPage />,
    userSide: true,
  },
  {
    path: "/buy-cd/:id",
    exact: true,
    component: <BuyCDDetailPage />,
    userSide: true,
  },
  {
    path: "/user-profile",
    exact: true,
    component: <BuyCDWizardStepsPage />,
    userSide: true,
    protectUserSide: true,
  },
  {
    path: "/music-masala",
    exact: true,
    component: <MusicMasalaPage />,
    userSide: true,
  },
  {
    path: "/feedback",
    exact: true,
    component: <FeedbackPage />,
    userSide: true,
  },
  {
    path: "/feedback-welcome",
    exact: true,
    component: <FeedbackWelcomePage />,
    userSide: true,
  },
  {
    path: "/donate",
    exact: true,
    component: <DonatePage />,
    userSide: true,
  },
  {
    path: "/ticket-booking/:id",
    exact: true,
    component: <TicketBookingPage />,
    userSide: true,
  },
  {
    path: "/thank-you",
    exact: true,
    component: <ThankYouPage />,
    userSide: true,
  },
  {
    path: "/attendee-details/:id",
    exact: true,
    component: <AttendeeDetailsPage />,
    userSide: true,
  },
  {
    path: "/mail-list",
    exact: true,
    component: <MailListingPage />,
    userSide: true,
  },
  {
    path: "/password/forgot-password",
    exact: true,
    component: <HomePage />,
    userSide: true,
  },
  {
    protected: false,
    path: "/announcement-detail/:id",
    exact: true,
    component: <AnnouncementDetailPage />,
    userSide: true,
  },
  {
    path: "/feedback-management",
    exact: true,
    component: <FeedbackManagementPage />,
    protected: true
  },
  {
    path: "/payment-management",
    exact: true,
    component: <PaymentManagementPage />,
    protected: true
  }
];
