import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { PropType } from "./types/propType";
import CloseImg from "./images/close.svg";
import { Box } from "@mui/material";
import UserImg from "./images/user.png";
import { Button } from "../../atoms/buttons";
import InputsWithLabel from "../../molecules/inputsWithLabel";
import TickImg from "./images/tick.svg";
import "./style.css";
import { ReqOptions } from "../../../utils/types/reqOptions";
import useHttp from "../../../utils/hooks/useHttp";
import { useDispatch } from "react-redux";
import { logout, webLogout } from "../../../utils/store/slices/authSlice";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Form from "../../atoms/form";
import ErrorMessage from "../../atoms/errorMessage";
import AlertCmp from "../../atoms/alert";

const WebUpdatePassword = ({ open, handleClose, }: PropType) => {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [correctPwd, setCorrectPwd] = useState("");
  const [error, setError] = useState("");
  const [newPwd, setNewPwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState({
    show: false,
    message: ""
  });
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const transformData = (data:any) => {
    if(data.status){
      dispatch(webLogout());
      navigate("/", { replace: true });
    }else{
      setShowErrorAlert({show:true,message:data.message})
      setTimeout(()=>{
        setShowErrorAlert({show:false,message:""})
      },3000 )
    }
  }
  const { loading, sendRequestWithoutToken } = useHttp(transformData);
  const handleChangePassword = async() => {
    if(correctPwd == newPwd) {
      setError("Old password and new password should not be same")
      return 
    }
   
    const requestOptions: ReqOptions = {
      url: `/users/update_password`,
      method: "POST",
      data:{
        old_password: correctPwd,
        new_password: newPwd,
        confirm_password: confirmPwd,
      }
    };

    sendRequestWithoutToken(requestOptions);
    // let token =localStorage.getItem('web_token')
    // console.log('token', token)
    // const body = {
    //   old_password: correctPwd,
    //   new_password: newPwd,
    //   confirm_password: confirmPwd,
    // }
    // const headers = {
    //   "Access-Control-Allow-Origin": "*",
    //   Authorization: token
    // }
    // const { data } =await axios.post(` http://3.110.92.230:3000/api/users/update_password`, body, {headers})
    // dispatch(webLogout());
    // navigate("/", { replace: true });
  };

  useEffect(()=>{
    error && setError("")
  },[correctPwd, newPwd])

  return (
    <>
      {showErrorAlert.show && (
        <AlertCmp type="error_web" position="user_side">
          {showErrorAlert.message}
        </AlertCmp>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="user_update_password_dialog"
      >
        <DialogTitle id="alert-dialog-title">
          <Box className="user_info_edit_dialog_heading">
            <h6>Change Password</h6>
            <img
              src={CloseImg}
              onClick={handleClose}
              style={{ cursor: "pointer" }}
            />
          </Box>
        </DialogTitle>
        <DialogContent>
          <Form onSubmit={handleChangePassword}>
            <DialogContentText id="alert-dialog-description">
              <Box
                sx={{
                  position: "relative",
                  marginBottom: "30px"
                }}
              >
                <InputsWithLabel
                  type={showOldPassword ? "text" : "password"}
                  name="old_password"
                  id="old_password"
                  placeholder="Enter current password here"
                  label="Current Password *"
                  labelColor="#FDFCFC"
                  handleInputChange={(e: any) => {
                    setCorrectPwd(e.target.value);
                  }}
                  required
                />
                <img
                  style={{
                    position: "absolute",
                    right: "9px",
                    top: "40px",
                    cursor: "pointer"
                  }}
                  src="/icons/eye_icon.svg"
                  onClick={() => {
                    setShowOldPassword(!showOldPassword);
                  }}
                />
              </Box>
              <Box sx={{ marginBottom: "30px" }}>
                <Box sx={{ position: "relative" }}>
                  <InputsWithLabel
                    type={showNewPassword ? "text" : "password"}
                    name="new_password"
                    id="new_password"
                    placeholder="Enter new password here"
                    label="New Password *"
                    labelColor="#FDFCFC"
                    handleInputChange={(e: any) => {
                      setNewPwd(e.target.value);
                    }}
                    required
                  />
                  <img
                    style={{
                      position: "absolute",
                      right: "9px",
                      top: "40px",
                      cursor: "pointer"
                    }}
                    src="/icons/eye_icon.svg"
                    onClick={() => {
                      setShowNewPassword(!showNewPassword);
                    }}
                  />
                </Box>
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </Box>
              <Box
                sx={{
                  position: "relative",
                  marginBottom: "30px"
                }}
              >
                <InputsWithLabel
                  type={showConfirmNewPassword ? "text" : "password"}
                  name="confirm_new_password"
                  id="confirm_new_password"
                  placeholder="Confirm new password"
                  label="Confirm Password *"
                  labelColor="#FDFCFC"
                  handleInputChange={(e: any) => {
                    setConfirmPwd(e.target.value);
                  }}
                  required
                />
                <img
                  style={{
                    position: "absolute",
                    right: "9px",
                    top: "40px",
                    cursor: "pointer"
                  }}
                  src="/icons/eye_icon.svg"
                  onClick={() => {
                    setShowConfirmNewPassword(!showConfirmNewPassword);
                  }}
                />
              </Box>
            </DialogContentText>
            <Box
              sx={{
                textAlign: "center"
              }}
            >
              <Button
                disable={!(confirmPwd == newPwd)}
                type="submit"
                variant="outlined"
                color="info"
                icon={TickImg}
                size="s"
              >
                Save Changes
              </Button>
            </Box>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default WebUpdatePassword;
