import React, { useState, useEffect } from "react";
import NoMember from "../../molecules/nomember";
import useHttp from "../../../utils/hooks/useHttp";
import { ReqOptions } from "../../../utils/types/reqOptions";
import AlertCmp from "../../atoms/alert";
import { Box } from "@mui/material";
import Loader from "../../atoms/loader";
import {
  ANNOUNCEMENT_RECORDS_PER_PAGE,
} from "../../../utils/configs";
import "./style.css";
import PaymentUrlCard from "../../atoms/PaymentUrlCard";

const PaymentManagement = () => {
  const [toggleMenu, setToggleMenu] = useState<number | null>(null);
  const { loading, error, sendRequest } = useHttp(transformData);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [initialRender, setInitialRender] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [paymentUrl, setPaymentUrl] = useState<any>([]);

  function handleCloseOptions() {
    setToggleMenu(null);
  }

  function transformData(data: any) {
    if (data.status) {
        setPaymentUrl(data?.payments)
    }
  }

  useEffect(() => {
    const requestOptions: ReqOptions = {
      url: '/payment_links',
      method: "GET",
    };

    setInitialRender(true);
    sendRequest(requestOptions);
  }, []);

  useEffect(() => {
    if (showSuccessAlert) {
      const timer = setTimeout(() => {
        setShowSuccessAlert(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [showSuccessAlert]);

  useEffect(() => {
    if (initialRender) {
      const reqOptions: ReqOptions = {
        url: '/payment_links',
        method: "GET",
      };

      sendRequest(reqOptions);
    }
  }, [currentPage]);

  const handleSuccessCall = (msg: string) => {
    setShowSuccessAlert(true);
    setSuccessMsg(msg);
  };

  const handleToggleMenu = (e: React.MouseEvent, eventId: number) => {
    e.stopPropagation();
    if (eventId === toggleMenu) {
      setToggleMenu(null);
    } else {
      setToggleMenu(eventId);
    }
  };

  const handleApiFetchAgain = () => {
    const reqOptions: ReqOptions = {
      url: '/payment_links',
      method: "GET",
    };

    sendRequest(reqOptions);
  };

  const handlePageChange = (e: any, page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      {loading && <Loader />}
      {showSuccessAlert && (
        <AlertCmp type="success" position="dashboard">
          {successMsg}
        </AlertCmp>
      )}
      {error && (
        <AlertCmp type="error" position="dashboard">
          Something went wrong! Try again..
        </AlertCmp>
      )}
      {paymentUrl?.length ? (
        <Box
          sx={{
            marginTop: "15px",
          }}
        >
          <Box
          sx={{
            marginBottom: '15px'
          }}
          ></Box>
          {paymentUrl.map((feedback:any, i:any) => {
            return (
              <PaymentUrlCard
                cardDetails={feedback}
                toggleMenu={toggleMenu}
                handleApiFetchAgain={handleApiFetchAgain}
                handleToggleMenu={handleToggleMenu}
                handleSuccessCall={handleSuccessCall}
                handleCloseOptions={handleCloseOptions}
                key={i}
              />
            );
          })}
        </Box>
      ) : (
        <>
          {!loading && (
            <NoMember
              heading="No Feedbacks"
              content={`There are no feedbacks from user yet.
      Comeback again to this section later.`}
            />
          )}
        </>
      )}
    </>
  );
};

export default PaymentManagement;
