import React from "react";
import { Box, Grid } from "@mui/material";
import UserImg from "./images/user.svg";
import { PropType } from "./types/propType";
import "./style.css";
import { Link } from "react-router-dom";

const ContactUsSection = ({ contactDetails }: PropType) => {
  return (
    <Box className="contact_us_section_website">
      <h1 className="contact_us_title_website">Contact Us</h1>
      <p className="contact_us_para_website">
        If you have any questions, please
        don't hesitate to contact one of our representatives for assistance.
        They will be happy to help you with any inquiries you may have.
      </p>
      <Box>
        <Grid container spacing={4} className="contact_info_grid">
          <Grid item md={6} sm={12} xs={12} className="contact_border">
            <Grid container>
              <Grid item xs={2}>
                {" "}
                <img src={UserImg} />
              </Grid>
              <Grid item xs={10}>
                <h4 className="contact_name_website">
                  {contactDetails.contact_person_first_name}
                </h4>
                <p className="contact_email_website">
                <a style={{color:"#fff", textDecoration:"none"}} href={`mailto:${contactDetails.contact_person_first_email}`}>{contactDetails.contact_person_first_email}</a>
                  {/* {contactDetails.contact_person_first_email} */}
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            className="contact_no_border_website"
          >
            <Grid container>
              <Grid item xs={2}>
                {" "}
                <img src={UserImg} />
              </Grid>
              <Grid item xs={10}>
                <h4 className="contact_name_website">
                  {contactDetails.contact_person_second_name}
                </h4>
                
                <p className="contact_email_website">
                <a style={{color:"#fff", textDecoration:"none"}} href={`mailto:${contactDetails.contact_person_second_email}`}>{contactDetails.contact_person_second_email}</a>
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ContactUsSection;
