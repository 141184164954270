import React from 'react'
import './styles.css'

const Faq = () => {
    return (
        <div  id="FAQs" className='main-block'>
            <h1  style={{paddingLeft: '35px', paddingRight: '35px', paddingTop: '20px'}} className='price_heading'>Frequently Asked Questions</h1>
            <div className='qna-block'>
                <div>
                    <p className='question-text'>Coming Soon!</p>
                    {/* <p className='answer-text'>The e-ticket can be scanned from your phone, but make sure you download it upfront, have your battery charged and turn your screen brightness fully up at the entrance to ensure a steady flow going in. Then, it’s time to put your phone away and start dancing.</p> */}
                </div>
                {/* <div>
                    <p className='question-text'>IS THE FESTIVAL INDOOR OR OUTDOOR?</p>
                    <p className='answer-text'>Music On Festival is an outdoor event but all the stages are in tents.</p>
                </div>
                <div>
                    <p className='question-text'>HOW DOES THE TICKET PERSONALIZATION WORK?</p>
                    <p className='answer-text'>During the ticket purchase process, the ticket owner’s full name, email address and other information needs to be filled for every individual ticket. Later on, it is possible to can change this data with the help of this website.</p>
                </div> */}
            </div>
        </div>
    )
}

export default Faq