import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import  "./style.css";
import { PropType } from "./types/propType";
import {Box, Grid } from "@mui/material";
import ArrowImg from "./images/arrow.svg";
import fakeImage from "./images/Frame 52.svg"


const SimpleSlider =({ carouselContent }: PropType)=> {


  const [sliderData,setSliderData]=useState<string[]>([])
   useEffect(() => {
        const arr=[...carouselContent]
        const clonedArr = arr.map((data:any)=>{
          const clonedObj = { ...data };
          const eventArray = clonedObj.event_images.slice(0, 4); 
          while (eventArray.length <4) {
            eventArray.push(["images/event-default.png"]);
          }
          clonedObj.event_images = eventArray;
          return clonedObj;
      });
      setSliderData(clonedArr);
 
  }, [carouselContent])


  


  function SampleNextArrow(props: any) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img className="nextArrow" src={ArrowImg} />
      </div>
    );
  }
  function SamplePrevArrow(props: any) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img className="prevArrow" src={ArrowImg} />
      </div>
    );
  }

   {
    const settings = {
          dots: true,
          // autoplay: true,
          // autoplaySpeed: 3000,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          nextArrow: <SampleNextArrow />,
          prevArrow: <SamplePrevArrow />,
          responsive: [
            {
              breakpoint: 821,
              settings: {
                dots: true,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 600,
              settings: {
                dots: true,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 480,
              settings: {
                dots: true,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        };
    return (
      <div className="card_section">
      
        <Slider {...settings}>
        {sliderData.map((data: any,i:number) => (  
          <>
          <div className="card">
            <div className="gallery_container">
             <div className="first shadow" >
              <img src={data.event_images[0]} alt="" />
              <div className="title">{data.title}</div>
             </div>
             <div className="second">
              <div className="second1 shadow"><img src={data.event_images[1]} alt="" />
              <div className="title">{data.title}</div></div>
              <div className="second2 shadow"><img src={data.event_images[2]} alt="" />
              <div className="title">{data.title}</div></div>
             </div>
             <div className="third shadow"><img src={data.event_images[3]} alt="" />
             <div className="title">{data.title}</div>
             </div>
            </div>
          </div>
          </>
        ))}
        </Slider>
      </div>
    );
  }
}
export default SimpleSlider ;