import React from "react";
import { Box, Divider, List, ListItem, ListItemButton } from "@mui/material";
import MenuItem from "../menuItem";
import { PropType } from "./types/propTypes";
import GalleryImg from "./images/gallery.svg";
import GalleryWhiteImg from "./images/gallery_white.svg";
import UserImg from "./images/user.svg";
import UserWhiteImg from "./images/user_white.svg";
import EventImg from "./images/event.svg";
import EventWhiteImg from "./images/event_white.svg";
import AnnouncementImg from "./images/announcment.svg";
import AnnouncementImgWhite from "./images/announcement_white.svg";
import EventGalleryImg from "./images/event_gallery.svg";
import EventGalleryWhiteImg from "./images/event_gallery_white.svg";
import GroupsImg from "./images/group.svg";
import GroupWhiteImg from "./images/group_white.svg";
import CDBannersImg from "./images/cdbanners.svg";
import CDBannersWhiteImg from "./images/cdbanners_white.svg";
import SettingsImg from "./images/settings.svg";
import FeedbackImg from "./images/feedback.png"
import ActiveFeedbackImg from "./images/activefeedback.png"
import PaymentImg from "./images/Payment Link NS.png"
import ActivePaymentImg from "./images/Payment Link Selected.png"
import SettingWhiteImg from "./images/setting_white.svg";
import Logo from "./images/logo.png";
import LogoutImg from "./images/logout.svg";
import { Drawer } from "@mui/material";
import { Link } from "react-router-dom";
import { logout } from "../../../utils/store/slices/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./style.css";

const sideBarOptions = [
  {
    image: GalleryImg,
    imageActive: GalleryWhiteImg,
    label: "Dashboard",
    link: "/dashboard",
  },
  {
    image: UserImg,
    imageActive: UserWhiteImg,
    label: "User Management",
    link: "/user-management",
  },
  {
    image: EventImg,
    imageActive: EventWhiteImg,
    label: "Event Management",
    link: "/event-management",
  },
  {
    image: AnnouncementImg,
    imageActive: AnnouncementImgWhite,
    label: "Announcement",
    link: "/announcement",
  },
  {
    image: EventGalleryImg,
    imageActive: EventGalleryWhiteImg,
    label: "Event Gallery",
    link: "/event-gallery",
  },
  {
    image: GroupsImg,
    imageActive: GroupWhiteImg,
    label: "Membership",
    link: "/membership",
  },
  {
    image: CDBannersImg,
    imageActive: CDBannersWhiteImg,
    label: "Banners",
    link: "/cd-orders-banners",
  },
  {
    image: FeedbackImg,
    imageActive: ActiveFeedbackImg,
    label: "Feedback",
    link: "/feedback-management",
  },
  // {
  //   image: PaymentImg,
  //   imageActive: ActivePaymentImg,
  //   label: "Additional URL",
  //   link: "/payment-management",
  // },
  {
    image: SettingsImg,
    imageActive: SettingWhiteImg,
    label: "Settings",
    link: "/settings",
  },
];

const SideBar = ({
  drawerWidth,
  menuActive,
  handleDrawerToggle,
  mobileOpen,
  window,
}: PropType) => {
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogoutClick = () => {
    dispatch(logout());
    setTimeout(() => {
      navigate("/login", { replace: true });
    }, 200);
  };

  const drawer = (
    <div className="gradient_bg">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className="logo_container"
      >
        <img src={Logo} />
      </Box>
      <Box className="sidebar-divider">
        <Divider style={{ width: "94%" }} />
      </Box>

      <Box className="menu_container">
        <List>
          {sideBarOptions.map((option, i) => {
            return (
              <Link to={option.link} className="menu_link">
                <ListItem key={option.label}>
                  <ListItemButton
                    className={`${
                      option.label === menuActive ? "list_item_active" : ""
                    }`}
                    sx={
                      option.label === menuActive
                        ? {
                            backgroundColor: "#913713",
                            borderRadius: "4px",
                            paddingTop: "15px",
                            paddingBottom: "15px",
                          }
                        : {
                            borderRadius: "4px",
                            paddingTop: "15px",
                            paddingBottom: "15px",
                          }
                    }
                  >
                    <MenuItem
                      image={
                        option.label === menuActive
                          ? option.imageActive
                          : option.image
                      }
                      label={option.label}
                      key={i}
                      active={option.label === menuActive ? true : false}
                    />
                  </ListItemButton>
                </ListItem>
              </Link>
            );
          })}
        </List>
        <List>
          <ListItem
            sx={{
              paddingBottom: "15px",
            }}
          >
            <ListItemButton>
              <MenuItem
                image={LogoutImg}
                label="Logout"
                active={false}
                handleClick={handleLogoutClick}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </div>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
      className="sidebar"
    >
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
        className="gradient_bg"
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
        className="gradient_bg"
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default SideBar;
