/* eslint-disable react-hooks/rules-of-hooks */
import axios from "axios";
import { useState } from "react";

const getUserDataApi = () => {
  const [userDataloading, setUserDataLoading] = useState<boolean>(false);
  const [userDataerror, setUserDataError] = useState<string | null>(null);

  const getUserDeatils = async (path: any,id:number) => {
    setUserDataLoading(true);
    try {
      const url = process.env.REACT_APP_API_URL_DEV;
      const token = localStorage.getItem("token");

      if (!url) throw new Error("API URL is not defined.");
      if (!token) throw new Error("Authorization token is missing.");

      const response = await axios.get(`${url}/${path}?user_id=${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      if (response.status < 200 || response.status >= 300) {
        throw new Error("Request failed with status: " + response.status);
      }

      return response?.data;
    } catch (error: any) {
        setUserDataError(error.message);
    } finally {
        setUserDataLoading(false);
    }
  };


  return { userDataloading, userDataerror,getUserDeatils };
};
export default getUserDataApi;
