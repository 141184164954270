import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import TextLabels from "../../atoms/textLabels";
import NoMemberImg from "../../molecules/nomember/images/no_member.svg";
import { title } from "process";
import { Input } from "../../atoms/inputs";
import { Button } from "../../atoms/buttons";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../utils/store";
import moment from "moment";
import useHttp from "../../../utils/hooks/useHttp";
import { ReqOptions } from "../../../utils/types/reqOptions";
import AlertCmp from "../../atoms/alert";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorMessage from "../../atoms/errorMessage";
import RecentActivity from "../../organism/recentActivity";
import Loader from "../../atoms/loader";
import CameraImg from "./images/ph_camera.svg";
import WebEditImageDialog from "../../organism/webEditImageDialog";
import "./style.css";
import { useNavigate } from "react-router-dom";

const ProfileTemplate = () => {
  const { loading, error, sendRequest } = useHttp(transformData);
  const [successMsg, setSuccessMsg] = useState(false);
  const [activities, setActivities] = useState([]);
  const [dataSuccessMsg, setDataSuccessMsg] = useState("");
  const navigate = useNavigate();
  function transformData(data: any) {
    if(data?.message){
      setDataSuccessMsg(data?.message)
      if(data?.message==="Please check new email to update email into your profile."){
        localStorage.clear();
        setTimeout(()=>{
          navigate(`/login`)
        },1000)
      }
    }
    if (data?.user) {
      const existedUserJson = localStorage.getItem("user");
      let exitedUser = {}
      if(existedUserJson) {
        exitedUser = JSON.parse(existedUserJson);
      }
      localStorage.setItem("user", JSON.stringify({...exitedUser,...data.user}));
      setLoader(false);
     //setSuccessMsg(true);
      setIsEdit(false);
    }
    if (data?.user_activities && data?.status) {
      setActivities(data?.user_activities);
    }
  }

  const userData = localStorage.getItem("user") as any;
  const user = userData ? JSON.parse(userData) : null;
  const [random, setRandom] = useState<number | undefined>();
  const [imageModal, setImageModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const [fname, setFname] = useState(user?.first_name ? user?.first_name : "");
  const [lname, setLname] = useState(user?.last_name ? user?.last_name : "");
  const [email, setEmail] = useState(user?.email ? user?.email : "");
  const dispatch: AppDispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [loader, setLoader] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [fnameError, setFnameError] = useState("");
  const [lnameError, setLnameError] = useState("");
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const handleCloseImageModal = () => {
    setImageModal(false);
  };

  const handleOpenImageModal = () => {
    setImageModal(true);
  };

  const handleSelectedFile = (file: any) => {
    setSelectedFile(file);
  };

  const handlePreviewUrl = (file: any) => {
    setPreviewUrl(file);
  };

  const handleSubmit = (event: any) => {
    setDataSuccessMsg("")
    setSuccessMsg(false);
    event.preventDefault();
    const data = new FormData(event.currentTarget) as any;
    const first_name = data.get("first_name")?.trim()
    const last_name = data.get("last_name")?.trim();
    const email = data.get("email")?.trim();
    if (!first_name) {
      setFnameError("Please enter first name.");
      return;
    }
    const cleanedInputValue:any = first_name.replace(/[^a-zA-Z]/g, '');
    const regex:any = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9])[\w\W]*$/;
    if(cleanedInputValue!=""){
      if (regex.test(cleanedInputValue)) {
        setFnameError("Please enter valid first name.");
        return;
      } else {
        setFnameError("");
      }
    }else{
      setFnameError("Please enter valid first name.");
      return;
    }
   
    if (!last_name) {
      setLnameError("Please enter last name.");
      return;
    }
    const cleanedInputValue2:any = last_name.replace(/[^a-zA-Z]/g, '');
    const regex2:any = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9])[\w\W]*$/;
    if(cleanedInputValue2!=""){
      if (regex2.test(cleanedInputValue2)) {
        setLnameError("Please enter valid last name.");
        return;
      } else {
        setLnameError("");
      }
    }else{
      setLnameError("Please enter valid last name.");
      return;
    }
    // if(!(/^[A-Za-z ]/.test(last_name))){
    //   setLnameError("Please enter valid last name.");
    //   return;
    // }

    if (!email) {
      setEmailError("Please enter email.");
      return;
    } else if (!emailRegex.test(email)) {
      setEmailError("Invalid email format");
      return;
    } else {
      setEmailError("");
    }

    const requestOptions: ReqOptions = {
      url: `/users/${user?.id}/update_profile`,
      method: "POST",
      data: {
        first_name: first_name,
        last_name: last_name,
        email: email,
      },
    };
    sendRequest(requestOptions);
    setSuccessMsg(true)
    setLoader(true);
  };
  useEffect(() => {
    if (error) {
      setLoader(false);
    }
  }, [error]);
  useEffect(() => {
    const requestOptions: ReqOptions = {
      url: `/users/${user?.id}/get_user_activity`, 
      method: "GET",
    };

    sendRequest(requestOptions);
  }, [loader]);

  const forceRender = ()=>{
    setRandom(Math.random())
  }
  return (
    <>
      {loading && <Loader />}
       {
        successMsg && dataSuccessMsg && (
          <AlertCmp type="success" position="dashboard">
          <div dangerouslySetInnerHTML={{ __html: (dataSuccessMsg || '').replace(/[\[\]"]/g, '') }}></div>
        </AlertCmp>
        )
       }
      
      {/* {
        successMsg && localStorage.getItem("emailMsg") && 
        <AlertCmp type="success" position="dashboard">
        Please check new email to update email into your profile.
        </AlertCmp>
      } */}
      <Box sx={{ position: "relative", width: "100%" }}>
        <img
          src={
            activities && activities.length
              ? "images/profileBanner2.png"
              : "images/profileBanner.png"
          }
          width="100%"
        />
        <Box
          className="profile-detail-mobileBox"
          sx={{
            position: "absolute",
            width: "90%",
            height: isEdit ? "180px" : "168px",
            backgroundColor: "#FFFFFF",
            top: "60%",
            left: "5%",
            boxShadow:
              "0px 0px 2px rgba(0, 0, 0, 0.06), 0px 3px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "4px",
          }}
        >
          <Grid container>
            <Grid
              className="profile-grid-1"
              item
              xs={12}
              sm={4}
              md={3}
              lg={2}
              sx={{
                minHeight: "168px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {isEdit ? (
                <Box
                  sx={{
                    position: "relative",
                  }}
                >
                  <img src={user?.profile_image || "profiles/images.jpg"} className="imgProfileRes" />
                  <img
                    src={CameraImg}
                    className="profile_camera_image"
                    onClick={handleOpenImageModal}
                  />
                </Box>
              ) : (
                <img src={user?.profile_image || "profiles/images.jpg"} className="imgProfileRes" />
              )}
            </Grid>

            <Grid
              item
              xs={12}
              sm={8}
              md={9}
              lg={10}
              sx={{
                height: "72px",
                marginTop: isEdit ? "8px !important" : "24px !important",
              }}
            >
              <Box
                className="profileData"
                sx={{ lineHeight: isEdit ? "1.3" : "1.5" }}
              >
                {isEdit ? (
                  <>
                    <Box component="form" noValidate onSubmit={handleSubmit}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Box sx={{ width: "80%" }}>
                            <TextLabels
                              variant="h100"
                              color="#2C2D30"
                              weight={700}
                            >
                              First Name
                            </TextLabels>
                            <Input
                              id="profile-text-input"
                              type="text"
                              name="first_name"
                              value={fname}
                              variant="outlined"
                              handleChange={(e) => {
                                const value = e.target.value;
                                setFname(e.target.value);
                                value && setFnameError("");
                              }}
                              sx={{
                                width: "100%",
                                backgroundColor: "#ffffff",
                                borderRadius: "4px",
                                fontWeight: "400",
                                fontSize: "14px",
                              }}
                            />
                            {fnameError && (
                              <ErrorMessage>{fnameError}</ErrorMessage>
                            )}
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box sx={{ width: "80%" }}>
                            <TextLabels
                              variant="h100"
                              color="#2C2D30"
                              weight={700}
                            >
                              Last Name
                            </TextLabels>
                            <Input
                              id="profile-text-input"
                              type="text"
                              name="last_name"
                              value={lname}
                              variant="outlined"
                              handleChange={(e) => {
                                const value = e.target.value
                                setLname(value);
                                value && setLnameError("");
                              }}
                              sx={{
                                width: "100%",
                                backgroundColor: "#ffffff",
                                borderRadius: "4px",
                                fontWeight: "400",
                                fontSize: "14px",
                              }}
                            />
                             {lnameError && (
                              <ErrorMessage>{lnameError}</ErrorMessage>
                            )}
                          </Box>

                        </Grid>
                      </Grid>

                      <Box sx={{ width: "90%" }}>
                        <TextLabels variant="h100" color="#2C2D30" weight={700}>
                          Email
                        </TextLabels>
                        <Input
                          id="profile-email-input"
                          type="email"
                          name="email"
                          value={email}
                          variant="outlined"
                          handleChange={(e) => {
                            setEmail(e.target.value);
                            email &&
                              emailRegex.test(e.target.value) &&
                              setEmailError("");
                          }}
                          sx={{
                            width: "100%",
                            backgroundColor: "#ffffff",
                            borderRadius: "4px",
                            fontWeight: "400",
                            fontSize: "14px",
                          }}
                        />
                        {emailError && (
                          <ErrorMessage>{emailError}</ErrorMessage>
                        )}
                      </Box>
                      <Box>
                        <Button
                          //disable={loader?true:false}
                          sx={{
                            backgroundColor: "#FFFFFF",
                            color: "#913713",
                            padding: "0px !important",
                            fontSize: "14px",
                            "&:hover": {
                              backgroundColor: "#FFFFFF",
                              color: "#913713",
                            },
                          }}
                          type="submit"
                          btnClass="loginSubmitBtn"
                          color="error"
                          variant="contained"
                          icon="icons/tick.png"
                        >
                          Save
                        </Button>
                        {/* {
                        loader && <CircularProgress className='edit-profile-loader' color="success" />
                    } */}
                      </Box>

                      <img
                        className="profile-close-icon"
                        style={{
                          position: "absolute",
                          top: "13px",
                          right: "29px",
                          cursor: "pointer",
                        }}
                        onClick={() => setIsEdit(false)}
                        src="icons/close.png"
                      />
                    </Box>
                  </>
                ) : (
                  <>
                    <p>
                      <TextLabels variant="h400" color="#404145" weight={700}>
                        {user?.first_name} {user?.last_name}
                        {/* Alesia Kane */}
                      </TextLabels>
                    </p>
                    <p>
                      <TextLabels variant="h200" color="#BD4819" weight={500}>
                        Admin
                      </TextLabels>
                    </p>
                    <p>
                      <TextLabels variant="h200" color="#2C2D30" weight={400}>
                        {user?.email}
                        {/* admin@imsom.com */}
                      </TextLabels>
                    </p>
                    <p>
                      <TextLabels variant="h200" color="#7E8085" weight={400}>
                        Admin since:{" "}
                        {user?.created_at &&
                          moment(user?.created_at).format("DD/MM/YYYY")}
                        {/* 12/12/2017 */}
                      </TextLabels>
                    </p>
                    <img
                      style={{
                        position: "absolute",
                        top: 0,
                        right: "29px",
                        cursor: "pointer",
                      }}
                      onClick={() => setIsEdit(true)}
                      src="icons/profile-edit.png"
                    />
                    <Box
                      className="profile-details-logoBox"
                      style={{
                        position: "absolute",
                        bottom: "-10px",
                        right: "29px",
                      }}
                    >
                      <img
                        className="profile-details-logo"
                        src="images/profile-imsom.png"
                      />
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
          {!isEdit && <Box className={`profileSection ${isEdit && "profileSectionMobile"}`}>
            <TextLabels variant="h200" color="#2C2D30" weight={700}>
              Recent Activity
            </TextLabels>
            <RecentActivity activities={activities} />
          </Box>}
          <Box style={{ marginTop: "20px", height: "20px" }}></Box>
        </Box>
        <Box></Box>
      </Box>
      <WebEditImageDialog
        open={imageModal}
        handleClose={handleCloseImageModal}
        handleSelectedFile={handleSelectedFile}
        selectedFile={selectedFile}
        previewUrl={previewUrl}
        handlePreviewUrl={handlePreviewUrl}
        adminSide={true}
        onSuccess={(userInfo:any)=>{
          forceRender()
          setSelectedFile(null);
          handleCloseImageModal();
        }}
      />
    </>
  );
};

export default ProfileTemplate;
