import React from "react";
import Layout from "../../organism/layout";
import PaymentManagement from "../../templates/paymentManagement";


const PaymentManagementPage = () => {
  return (
    <Layout pageName="Additional URL" menuActive="Additional URL">
        <PaymentManagement />
    </Layout>
  );
};

export default PaymentManagementPage;
