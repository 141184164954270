import React, { useEffect, useState } from "react";
import AlertCmp from "../../atoms/alert";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import TextLabels from "../../atoms/textLabels";
import { Input } from "../../atoms/inputs";
import ErrorMessage from "../../atoms/errorMessage";
import { Link } from "react-router-dom";
import { Button } from "../../atoms/buttons";
import { useDispatch } from "react-redux";
import type { AppDispatch } from "../../../utils/store";
import { login } from "../../../utils/store/slices/authSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../utils/store";
import Loader from "../../atoms/loader";
import { useSearchParams } from "react-router-dom";
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';

const Item = styled(Paper)(({ theme }) => ({
  //backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  //textAlign: 'center',
  //color: theme.palette.text.secondary,
  border: "none",
  boxShadow: "none",
  backgroundColor: "#FAE4DB",
}));

export default function LoginOrganism() {
  const [search, setSearch] = useSearchParams();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [pwdError, setPwdError] = useState("");
  const [successMsg, setSuccessMsg] = useState(false);
  const [invalidToken, setInvalidToken] = useState(false);

  const { isAuthenticated, isLoading, error } = useSelector(
    (state: RootState) => state.auth
  );
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  useEffect(() => {
    // if isUserLoggedIn turned to true redirect to /home
    if (isAuthenticated) {
      setSuccessMsg(true);

      const timer = setTimeout(() => {
        navigate("/dashboard");
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isAuthenticated]);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget) as any;
    if (!data.get("email") && !data.get("password")) {
      setEmailError("Please enter email.");
      setPwdError("Please enter password.");
      return;
    }
    if (!data.get("email")) {
      setEmailError("Please enter email.");
      return;
    } else if (!emailRegex.test(data.get("email"))) {
      setEmailError("Invalid email format");
      return;
    } else {
      setEmailError("");
    }
    if (!data.get("password")) {
      setPwdError("Please enter password.");
      return;
    } else {
      setPwdError("");
    }
   

    dispatch(
      login({
        email: data.get("email"),
        password: data.get("password"),
        webSide: false,
      })
    );
  };

  useEffect(() => {
    if (search.get("token") == "invalid") {
      setInvalidToken(true);
    }
  }, [search]);

  const isEmailValid = () => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const isSubmitDisabled = () => {
    return (
      email.trim() === "" ||
      !isEmailValid() ||
      password.trim() === "" ||
      isLoading
    );
  };

  return (
    <>
      {isLoading && (
        <Box className="toastBox">
          <Loader />
        </Box>
      )}
      {invalidToken && (
        <Box className="toastBox">
          <AlertCmp type="error">
            Invalid token error. Please Login again.
          </AlertCmp>
        </Box>
      )}
      {error && (
        <Box className="toastBox">
          <AlertCmp type="error">{error}</AlertCmp>
        </Box>
      )}
      {successMsg && (
        <Box className="toastBox">
          <AlertCmp type="success">Success! You are being logged in.</AlertCmp>
        </Box>
      )}
      <Box
        sx={{
          padding: 0,
          width: "70%",
        }}
      >
        <Item>
          <>
            <Box
              sx={{ textAlign: "center", display: "none" }}
              className="mobile-login-logo"
            >
              <img src="/images/profile-imsom.png" />
            </Box>
            <TextLabels
              className="login-heading"
              fontFamily="Poppins"
              variant="subtitle2"
              color="#3C3E42"
              weight={600}
            >
              Admin Login
            </TextLabels>
            <TextLabels
              fontFamily="Poppins"
              variant="h5"
              color="#7E8085"
              weight={400}
            >
              Hello, welcome back to our account!
            </TextLabels>

            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              {/* <InputLabel sx={{color:theme=>theme.label.main}}>Email</InputLabel> */}
              <Box sx={{ margin: "20px 0" }}>
                <TextLabels variant="h5" color="#2C2D30" weight={700}>
                  Email
                </TextLabels>
                <Input
                  id="standard-number"
                  label="Email"
                  type="email"
                  name="email"
                  placeholder="Enter email here"
                  variant="outlined"
                  handleChange={(e) => {
                    setEmail(e.target.value);
                    e.target.value &&
                      emailRegex.test(e.target.value) &&
                      setEmailError("");
                  }}
                  sx={{
                    marginTop: "10px",
                    width: "100%",
                    backgroundColor: "#ffffff",
                    borderRadius: "4px",
                    fontWeight: "400",
                    fontSize: "14px",
                  }}
                />
                {emailError && <ErrorMessage>{emailError}</ErrorMessage>}
              </Box>
              <Box sx={{ margin: "20px 0 0 0" }}>
                <Box
                  sx={{
                    position: "relative",
                    margin: "10px 0px",
                  }}
                >
                  <TextLabels variant="h5" color="#2C2D30" weight={700}>
                    Password
                  </TextLabels>

                  {/* <Link
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "12px",
                      lineHeight: "14px",
                      color: "#913713",
                      textDecoration: "none",
                    }}
                    to="/change-password"
                  >
                    Forgot Password?
                  </Link> */}
                </Box>

                <Box sx={{ position: "relative" }}>
                  <Input
                    id="standard-number"
                    name="password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter password here"
                    handleChange={(e) => {
                      setPassword(e.target.value);
                      e.target.value && setPwdError("");
                    }}
                    sx={{
                      width: "100%",
                      backgroundColor: "#ffffff",
                      borderRadius: "4px",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "16px",
                    }}
                  />
                  {showPassword ? <Box
                  style={{ position: "absolute", right: "9px", top: "13px", cursor: 'pointer' }}
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}><VisibilityOff /></Box> : 
                  <Box
                  style={{ position: "absolute", right: "9px", top: "13px", cursor: 'pointer' }}
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}><Visibility /></Box>}
                  {/* <img
                    style={{ position: "absolute", right: "9px", top: "13px" }}
                    src="icons/password.png"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  /> */}
                </Box>
                {pwdError && <ErrorMessage>{pwdError}</ErrorMessage>}
              </Box>
              <Box sx={{ textAlign: "right" }}>
                <Link
                  style={{
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "12px",
                    lineHeight: "14px",
                    color: "#913713",
                    textDecoration: "none",
                  }}
                  to="/forgot-password"
                >
                  Forgot Password?
                </Link>
              </Box>
              <Box sx={{ margin: "20px 0" }}>
                <Button
                  disable={isSubmitDisabled()}
                  sx={{
                    backgroundColor: "#913713",
                    "&:hover": {
                      backgroundColor: "#913713",
                    },
                  }}
                  type="submit"
                  btnClass="loginSubmitBtn"
                  color="error"
                  fullWidth={true}
                  variant="contained"
                >
                  Sign In
                </Button>
              </Box>
            </Box>
          </>
        </Item>
      </Box>
    </>
  );
}
