import React from "react";
import { PropType } from "./types/propType";
import LineImg from "./images/line.svg";
import { Box } from "@mui/material";
import "./style.css";

const SectionHeading = ({
  title,
  subTitle,
  marginTop,
  marginBottom,
  pos,
  marginRight,
  classNameSubtitle,
  classNameBox,
  classNameImg,
  width,
  marginTopMobile,
  marginLeftMobile,
  marginLeft,
}: PropType) => {
  
  return (
    <Box className="parent_section_heading" style={{zIndex:"999", position:"relative"}}>
      <h4 className="section_heading">{title}</h4>
      {subTitle && (
        <h4 className={`section_subtitle ${classNameSubtitle}`}>{subTitle}</h4>
      )}
      <Box
        className={`${
          classNameBox || pos ? " _margin" : "section_img_box"
        }`}
      >
        <img style={{
            marginTop: `${marginTop}px`,
            marginBottom: `${marginBottom}px`,
            marginRight: `${marginRight}px`,
            width: `${width}px`,
            marginLeft:`${marginLeft}px`,
          }}
          // src={LineImg}
          className={`${marginTopMobile ? "margin-mobile-heading " : ""} && ${
            marginLeftMobile ? "margin_left_mobile" : ""}`}
        />
      </Box>
    </Box>
  );
};

export default SectionHeading;
