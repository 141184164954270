import { configureStore, ThunkMiddleware } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';

const middleware = [thunk];

const store = configureStore({
    reducer: rootReducer,
    middleware: middleware as ThunkMiddleware[],
    devTools: process.env.NODE_ENV !== 'production',
  });

  export type RootState = ReturnType<typeof store.getState>;
  export type AppDispatch = typeof store.dispatch;

  export default store;