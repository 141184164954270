import { Box } from '@mui/material'
import React from 'react'
import { Button } from '../../atoms/buttons'
import { useNavigate } from 'react-router-dom';

const NoEvents = () => {
  const navigate = useNavigate();

  return (
    <Box width="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="300px">
      <Box className="no-events-text"> You haven't booked any event yet.</Box>
      <Button size="s"
          variant="outlined"
          color="info" handleClick={()=>{
        navigate("/all-events")
      }}>Go to Upcoming Events</Button>
    </Box>
  )
}

export {NoEvents}