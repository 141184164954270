
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import "./style.css";

import React from "react";

const MyWrapper = (galleryData: any) => {
  return (
    <ResponsiveMasonry  columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 4 }}>
      <Masonry >
        { galleryData.galleryData.map((gallery: any, i: number) => {
            return  <img key={i} src={gallery[0]} alt="IMSOM" />;
          })
        }
      </Masonry>
    </ResponsiveMasonry>
  );
};

export { MyWrapper };
