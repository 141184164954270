import React,{useState} from 'react';
import AlertCmp from "../../atoms/alert";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import TextLabels from '../../atoms/textLabels';
import { Input } from '../../atoms/inputs';
import ErrorMessage from '../../atoms/errorMessage';
import { Link } from 'react-router-dom';
import { Button } from '../../atoms/buttons';
import Login from '../../templates/login';
import LoginOrganism from '../../organism/login';


export default function LoginPage() {
    

    return <><Login children={<LoginOrganism/>} /></>

}