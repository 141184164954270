import React, { useEffect, useState, useRef } from "react";
import { AppBar, Toolbar, Box } from "@mui/material";
import TextLabels from "../../atoms/textLabels";
import { PropType } from "./types/propTypes";
import BellImg from "./images/bell.svg";
import ProfileImg from "./images/profile.png";
import DownArrowImg from "./images/downarrow.svg";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Notifications from "../../organism/notifications";
import { Link } from "react-router-dom";
import useOutsideAlerter from "../../../utils/hooks/useOutsideAlerter";
import "./style.css";

const Header = ({ drawerWidth, pageName, handleDrawerToggle }: PropType) => {
  const userData = localStorage.getItem("user") as any;
  // const user = typeof userData !== "undefined" ? JSON.parse(userData) : "";
  const [user, setUserData] = React.useState<any>(null);
  const [scrollMenuBg, setSrcollMenuBg] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);
  const wrapperRef = useRef(null);
  const clickRef = useRef(null);
  useOutsideAlerter(wrapperRef, clickRef, handleCloseOptions);

  function handleCloseOptions() {
    setToggleMenu(false);
  }

  const handleToggle = () => {
    setToggleMenu(!toggleMenu);
  };

  const checkScroll = () => {
    if (window.scrollY == 0) {
      setSrcollMenuBg(false);
    } else {
      setSrcollMenuBg(true);
    }
  };

  // useEffect(() => {
  //   setToggleNotifications(closeBox);
  // }, [closeBox]);

  useEffect(() => {
    const updateUserState = () => {
      const webToken = localStorage.getItem("token");
      let webUserData = null;
      if (localStorage.getItem("user")) {
        webUserData = JSON.parse(localStorage.getItem("user") as any);
      }
      if (webToken && webUserData) {
        setUserData(webUserData);
      }
    };
    updateUserState();
      window?.addEventListener("storage", updateUserState);
    window.addEventListener("scroll", checkScroll);

    return () => {
      window.removeEventListener("scroll", checkScroll)
      window?.removeEventListener("storage", updateUserState);
    };
  }, []);

  



  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
        backgroundColor: "#fff",
      }}
      className={`header_app_bar ${scrollMenuBg ? "header_animation" : ""}`}
    > 
      <Toolbar>
        <IconButton
          className="header-mobile-menu-btn"
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon
            sx={{
              color: "#000",
            }}
          />
        </IconButton>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
          alignItems="center"
        >
          <TextLabels
            className="pageName"
            variant="h600"
            weight={600}
            color="#1E100B"
            fontFamily="Poppins"
            size="24px"
          >
            {pageName}
          </TextLabels>
          <Box display="flex" alignItems="center" className="header_right">
            {/* <div className="icon_round_bg notification_holder">
              <div onClick={handleToggle} ref={clickRef}>
                <img src={BellImg} />
              </div>
              {toggleMenu && (
                <Box className="notification_box" ref={wrapperRef}>
                  <Notifications />
                </Box>
              )}
            </div> */}
            <Link to="/profile" className="profile_link">
              <div className="profile_icon">
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  className="profile_detail"
                >
                  <span className="admin_profile_image">
                    <img src={ user?.profile_image || ProfileImg} className="profile_image admin_profile_image" style={{height:"22px",width:"22px",objectFit:"cover"}} />
                  </span>
                  <Box
                    sx={{
                      textTransform: "capitalize",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <TextLabels variant="h100" weight={600}>
                      {user?.first_name} {user?.last_name?.substring(0, 1)}.
                    </TextLabels>
                  </Box>
                </Box>
                <img src={DownArrowImg} />
              </div>
            </Link>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
