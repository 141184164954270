import {createSlice, PayloadAction, createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios'

interface AuthState {
    isAuthenticated: boolean;
    isWebAuthenticated: boolean;
    user: any;
    webUser: any;
    error: string | null;
    isLoading: boolean;
  }
  
  const initialAuthState: AuthState = {
    isAuthenticated: false,
    isWebAuthenticated: false,
    user: null,
    webUser: null,
    error: null,
    isLoading: false,
  };

  interface LoginPayload {
    email: string;
    password: string;
  }

export const login = createAsyncThunk<any, { email: string, password: string, webSide?: boolean }>(
    'auth/login',
    async ({ email, password, webSide }, {dispatch}) => {

      try {
        dispatch(setInitialStates())

        const body = {
            user: {email, password}
        }

        const config = {
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*'
            }
          };

        const response: any = await axios.post(`${process.env.REACT_APP_API_URL_DEV}/sessions/login`, {...body, webside: webSide}, config);

        if (response.data.status) {
          if (webSide) {
            // Store user and token in local storage
          localStorage.setItem('web_token', response.data.token);
          localStorage.setItem('web_user', JSON.stringify(response.data.user));
          } else {
            // Store user and token in local storage
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('user', JSON.stringify(response.data.user));
          }
          if (webSide) {
            dispatch(setWebUser(response.data))
          } else {
            dispatch(setUser(response.data))
          }
        } else {
          throw new Error(response.data.message)
        }
      } catch (error: any) {
        dispatch(setError(error.message));
        // return rejectWithValue(error.response.data);
      }
    }
) as any;

export const setError = (errorMessage: string) => ({
  type: 'auth/setError',
  payload: errorMessage,
});

export const setUser = (user: string) => ({
  type: 'auth/setUser',
  payload: user,
});

export const setWebUser = (user: string) => ({
  type: 'auth/setWebUser',
  payload: user,
});

export const setInitialStates = () => {
  return ({
    type: 'auth/setInitialStates',
  })
};  

const authSlice = createSlice({
    name: 'auth',
    initialState: initialAuthState,
    reducers: {
        logout(state) {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          localStorage.clear();

          state.isAuthenticated = false;
          state.user = null;
          state.error = null;
          state.isLoading = false
        },
        webLogout(state) {
          localStorage.removeItem("web_token");
          localStorage.removeItem("web_user");
          localStorage.clear();

          state.isWebAuthenticated = false;
          state.webUser = null;
          state.error = null;
          state.isLoading = false
        },
        setError(state, action) {
          state.error = action.payload;
          state.isAuthenticated = false
          state.isWebAuthenticated = false
          state.webUser = null
          state.user = null
          state.isLoading = false
        },
        setWebUser(state, action) {
          state.error = null;
          state.isWebAuthenticated = true
          state.webUser = action.payload.user
          state.isLoading = false
        },
        setUser(state, action) {
          state.error = null;
          state.isAuthenticated = true
          state.user = action.payload.user
          state.isLoading = false
        },
        setInitialStates(state){
          state.error = null;
          state.isAuthenticated = false
          state.isWebAuthenticated = false
          state.webUser= null
          state.user = null
          state.isLoading = true
        }
    }
})

export const { logout, webLogout } = authSlice.actions;

export default authSlice.reducer;