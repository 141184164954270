import React, { useState, useEffect } from "react";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextLabels from "../textLabels";
import { Box } from "@mui/material";
import CloseImg from "./images/close.svg";
import { PropType } from "./types/propType";
import DropZone from "../dropZone";
import { Button } from "../buttons";
import ModalPopup from "../modalPopup";
import "./style.css";
import { ReqOptions } from "../../../utils/types/reqOptions";
import useHttp from "../../../utils/hooks/useHttp";
import AlertCmp from "../alert";

const ModalImageUploadPopup = ({
  heading,
  subHeading,
  submitBtnText,
  open,
  handleClose,
  eventId,
  cb,
  resetModal,
}: PropType) => {
  const { loading, error, sendRequest } = useHttp(transformData);
  const [files, setFiles] = useState<any>([]);
  const [openDiscard, setOpenDiscard] = useState(false);
  const [responseMsg, setResponseMsg] = useState("");
  const [responseType, setResponseType] = useState<boolean>(false);

  function transformData(data: any) {
    if (data?.status && data?.events?.id) {
      handleClose();
      setResponseMsg("Event images uploaded successfully.");
      setResponseType(true);
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    }
  }

  const handleFiles = (files: any) => {
    cb(files);
    setFiles(files);
  };

  const handleCheck = () => {
    if (files.length) {
      handleClickDiscard();
    } else {
      handleClose();
    }
  };

  const handleClickDiscard = () => {
    setFiles([]);
    setOpenDiscard(true);
  };

  const handleCloseDiscard = () => {
    setOpenDiscard(false);
    handleClose();
  };

  const uploadEventImages = () => {
    setResponseMsg("");
    setResponseType(false);
    if (eventId) {
      const formData1: any = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData1.append(`event_managment[event_images][]`, files[i]);
      }
      const requestOptions: ReqOptions = {
        url: `/event_managments/${eventId}`,
        method: "PUT",
        contentType: "multipart/form-data",
        data: formData1,
      };

      sendRequest(requestOptions);
    }
  };

  useEffect(() => {
    if (error) {
      setResponseMsg("Something went wrong! Try again..");
      setResponseType(false);
    }
    setFiles([]);
  }, [error, resetModal]);

  return (
    <>
      {responseMsg && (
        <AlertCmp
          type={responseType ? "success" : "error"}
          position="dashboard"
        >
          {responseMsg}
        </AlertCmp>
      )}
      <div>
        <Dialog
          open={open}
          onClose={handleCheck}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="modal_image_popup"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
            className="modal_title"
          >
            <TextLabels variant="h400" weight={500}>
              {heading}
            </TextLabels>
            <img
              src={CloseImg}
              onClick={handleCheck}
              style={{ cursor: "pointer" }}
            />
          </DialogTitle>
          <DialogContent>
            <TextLabels variant="h100" weight={700}>
              {subHeading}
            </TextLabels>
            <Box
              sx={{
                paddingTop: "10px",
              }}
            ></Box>
            <DropZone
              files={files}
              handleFiles={handleFiles}
              multiple={heading === "Upload Banner" ? false : true}
            />
            <Box
              display="flex"
              justifyContent="center"
              sx={{
                paddingTop: "30px",
              }}
            >
              <Button
                handleClick={uploadEventImages}
                variant="contained"
                disable={!files.length || loading}
                size="s"
              >
                {submitBtnText as string}
              </Button>
            </Box>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      </div>
      <ModalPopup
        heading="Close Window?"
        closeBtn={true}
        content="Closing the window will result in discarding the added images."
        primaryButton="Discard"
        secondaryButton="Do not discard"
        open={openDiscard}
        handleClickOpen={handleClickDiscard}
        handleClose={handleCloseDiscard}
        primaryAction={() => {}}
      />
    </>
  );
};

export default ModalImageUploadPopup;
