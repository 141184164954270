import React, { useEffect, useState } from "react";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextLabels from "../textLabels";
import Box from "@mui/material/Box";
import { Button } from "../buttons";
import CloseImg from "./images/close.svg";
import { PropType } from "./types/propType";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { convertToHTML, convertFromHTML } from "draft-convert";
import "./style.css";

const ModalMessagePopup = ({
  open,
  handleClose,
  heading,
  closeBtn,
  handleEmailMessage,
  primaryButton,
  secondaryButton,
  primaryAction,
  secondaryAction,
  messageEmail,
}: PropType) => {
  const [editorState, setEditorState] = useState<any>("");

  const onEditorStateChange = (e: any) => {
    setEditorState(e);
  };

  useEffect(() => {
    if (editorState) {
      handleEmailMessage(convertToHTML(editorState.getCurrentContent()));
    }
  }, [editorState]);

  useEffect(() => {
    if (!open) {
      setEditorState("");
    }
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="modal_popup_message"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
          className="modal_title"
        >
          <TextLabels variant="h400" weight={500}>
            {heading}
          </TextLabels>
          {closeBtn && (
            <img
              src={CloseImg}
              onClick={handleClose}
              style={{ cursor: "pointer" }}
            />
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box
              sx={{
                marginTop: "15px",
              }}
            >
              <Editor
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                placeholder="Enter bio here (max 250 words)"
              />
            </Box>
            <Box
              sx={{
                marginTop: "15px",
                marginBottom: "15px",
              }}
            >
              {messageEmail && <TextLabels variant="h200" weight={400} color="#7E8085">
                Selected User {messageEmail.length ? messageEmail.length : 1}
              </TextLabels>}
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="modal_popup_message_buttons">
          {primaryButton && (
            <>
              <Button handleClick={primaryAction} variant="contained" size="s">
                {primaryButton}
              </Button>
            </>
          )}
          {secondaryButton && (
            <Button
              btnClass={secondaryButton && "do-not-disturb"}
              handleClick={secondaryAction ? secondaryAction : handleClose}
              variant="outlined"
              size="s"
            >
              {secondaryButton}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalMessagePopup;
