import React from "react";
import Layout from "../../organism/layout";
import FeedbackManagement from "../../templates/feedbackManagement";

const FeedbackManagementPage = () => {
  return (
    <Layout pageName="Feedback" menuActive="Feedback">
        <FeedbackManagement />
    </Layout>
  );
};

export default FeedbackManagementPage;
