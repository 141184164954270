import React from "react";
import { PropType } from "./types/propTypes";
import { Typography } from "@mui/material";

const TextLabels = ({
  className,
  fontFamily,
  children,
  variant,
  color,
  weight,
  size,
  title
}: PropType) => {
  return (
    <Typography
      variant={variant}
      sx={{
        color,
        fontWeight: weight,
        fontFamily: fontFamily,
        fontSize: size,
      }}
      className={className}
      title={title}
    >
      {children}
    </Typography>
  );
};

export default TextLabels;
