import React from "react";

const Form = (props: React.FormHTMLAttributes<Element>) => {
  const { onSubmit, ...restProps } = props;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit && onSubmit(e);
      }}
      {...restProps}
    >
      {props.children}
    </form>
  );
};

export default Form;
