import React, { useEffect, useState, useRef } from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import { PropType } from "./types/propTypes";
import LogoImg from "./images/logo3.svg";
import WebMenuItem from "../../atoms/webMenuItem";
import FooterSection from "../footerSection";
import WebLoginDialog from "../webLoginDialog";
import WebRegisterDialog from "../webRegisterDialog";
import WebOtpDialog from "../webOtpDialog";
import WebPasswordDialog from "../webPasswordDialog";
import MenuImg from "./images/menu.svg";
import { Link, useLocation } from "react-router-dom";
import CloseImg from "./images/close.svg";
import AlertCmp from "../../atoms/alert";
import WebForgotPassword from "../webForgotPassword";
import WebChangePassword from "../webChangePassword";
import "./style.css";
import useUser from "../../../utils/hooks/useUser";
import { useDispatch } from "react-redux";
import { logout, webLogout } from "../../../utils/store/slices/authSlice";
import SaveIcon from '@mui/icons-material/Save';
import useHttp from "../../../utils/hooks/useHttp";
import WebMailListingDialog from "../webMailListingDialog";
import WebMailSuccessDialog from "../webMailSuccessDialog";
import MainLogo from './images/mainLogo.svg'

import { ReactComponent as HomeIcon } from './images/HomeIcon.svg';
import { ReactComponent as AboutIcon } from './images/AboutIcon.svg';
import { ReactComponent as EventsIcon } from './images/EventsIcon.svg';
import { ReactComponent as MembershipIcon } from './images/MembershipIcon.svg';
import { ReactComponent as DonateIcon } from './images/DonateIcon.svg';
import GoToTop from "../../atoms/GoToTop";

interface MenuOptionModel {
  label: string,
  link: string,
  type: string,
  icon?: any,
  onClick?: () => void;
}




const WebLayout = ({ children }: PropType) => {

  const [scrollTop, setScrollTop] = useState(false);
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [openRegisterDialog, setOpenRegisterDialog] = useState(false);
  const [openOtpDialog, setOpenOtpDialog] = useState(false);
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const [openForgotPasswordDialog, setOpenForgotPasswordDialog] =
    useState(false);
  const [openChangePasswordDialog, setOpenChangePasswordDialog] = useState(false);
  const [toggleMobileMenu, setToggleMobileMenu] = useState(false);
  const [showAlert, setShowAlert] = useState({
    show: false,
    message: "",
  });
  const [showErrorAlert, setShowErrorAlert] = useState({
    show: false,
    message: "",
  });
  const [resetToken, setResetToken] = useState<any>(null);
  const { user } = useUser();

  const dispatch = useDispatch();
  const location = useLocation();
  const prevPathnameRef = useRef("");


  const menuOptions: MenuOptionModel[] = [
    {
      label: "Home",
      link: "/",
      type: "text",
      icon: <HomeIcon fill={(location.pathname === '/')? "#D0931B": '#BABDC2'} />,
    },
    {
      label: "About Us",
      link: "/about-us",
      type: "text",
      icon: <AboutIcon fill={(location.pathname === '/about-us')? "#D0931B": '#BABDC2'}/>,
    },
    {
      label: "Events",
      link: "/all-events",
      type: "text",
      icon: <EventsIcon fill={(location.pathname === '/all-events')? "#D0931B": '#BABDC2'} />,
    },
    {
      label: "Membership",
      link: "/memberships",
      type: "text",
      icon: <MembershipIcon fill={(location.pathname === '/memberships')? "#D0931B": '#BABDC2'} />,
    },
    {
      label: "Donation",
      link: "/donate",
      type: "text",
      icon: <DonateIcon fill={(location.pathname === '/donate')? "#D0931B": '#BABDC2'} />,
    },
    {
      label: "Join Our Mailing List",
      link: "/mailing",
      type: "button",
      icon: "",
  
    },
    {
      label: "Login",
      link: "/login",
      type: "button",
      icon: "",
    },
  ];
  

  useEffect(() => {
    prevPathnameRef.current = location.pathname;
    localStorage.setItem("previousPage", prevPathnameRef.current)
  }, [location.pathname]);

  const handleLogout = () => {
    dispatch(webLogout());
    dispatch(logout());
    setTimeout(() => {
      window.location.href = location.pathname;
    }, 200);
  };

  const loggedUserOptions = [
    {
      label: "Profile",
      link: "/user-profile",
      type: "text",
    },
    {
      label: "Logout",
      link: "/",
      type: "button",
      onClick: handleLogout
    },
  ]

  const mobileOptions = [...menuOptions];
  if (user) {
    mobileOptions.pop();
    mobileOptions.push(...loggedUserOptions);
  }


  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const foo = params.get("reset_token");
    setResetToken(foo);
  }, []);

  useEffect(() => {
    if (resetToken) {
      setOpenChangePasswordDialog(true);
    }
  }, [resetToken]);

  const handleCloseLoginDialog = (event: any, reason: any) => {
    if (reason && reason == "backdropClick") return;
    setOpenLoginDialog(false);
  };

  const handleCloseRegisterDialog = (event: any, reason: any) => {
    if (reason && reason == "backdropClick") return;
    setOpenRegisterDialog(false);
  };

  const handleCloseOtpDialog = (event: any, reason: any) => {
    if (reason && reason == "backdropClick") return;
    setOpenOtpDialog(false);
  };

  const handleClosePasswordDialog = (event: any, reason: any) => {
    if (reason && reason == "backdropClick") return;
    setOpenPasswordDialog(false);
  };

  const handleCloseForgotPasswordDialog = (event: any, reason: any) => {
    if (reason && reason == "backdropClick") return;
    setOpenForgotPasswordDialog(false);
  };

  const handleCloseChangePasswordDialog = (event: any, reason: any) => {
    if (reason && reason == "backdropClick") return;
    setOpenChangePasswordDialog(false);
  };

  const handleOpenLoginDialog = () => {
    setOpenLoginDialog(true);
    setOpenRegisterDialog(false);
    // setOpenOtpDialog(false);
    setToggleMobileMenu(false);
    setOpenPasswordDialog(false);
    setOpenForgotPasswordDialog(false);
    setOpenChangePasswordDialog(false);
  };

  const handleOpenRegisterDialog = () => {
    setOpenRegisterDialog(true);
    setOpenLoginDialog(false);
    // setOpenOtpDialog(false);
    setOpenPasswordDialog(false);
    setOpenForgotPasswordDialog(false);
    setOpenChangePasswordDialog(false);
  };

  const handleOpenOtpDialog = () => {
    setOpenOtpDialog(true);
    setOpenLoginDialog(false);
    setOpenRegisterDialog(false);
    setOpenPasswordDialog(false);
    setOpenForgotPasswordDialog(false);
    setOpenChangePasswordDialog(false);
  };

  const handleOpenPasswordDialog = () => {
    setOpenPasswordDialog(true);
    setOpenOtpDialog(false);
    setOpenLoginDialog(false);
    setOpenRegisterDialog(false);
    setOpenForgotPasswordDialog(false);
    setOpenChangePasswordDialog(false);
  };

  const handleOpenForgotPasswordDialog = () => {
    setOpenPasswordDialog(false);
    // setOpenOtpDialog(false);
    setOpenLoginDialog(false);
    setOpenRegisterDialog(false);
    setOpenChangePasswordDialog(false);
    setOpenForgotPasswordDialog(true);
  };

  const handleOpenMobileMenu = () => {
    setToggleMobileMenu(true);
  };

  const handleCloseMobileMenu = () => {
    setToggleMobileMenu(false);
  };

  const handleAlertBox = (message: any) => {
    setShowAlert({
      show: true,
      message,
    });

    setTimeout(() => {
      setShowAlert({
        show: false,
        message: "",
      });
    }, 5000);
  };

  const handleAlertErrorBox = (message: any) => {
    setShowErrorAlert({
      show: true,
      message,
    });

    setTimeout(() => {
      setShowErrorAlert({
        show: false,
        message: "",
      });
    }, 5000);
  };

  const handleScroll = () => {
    if (window.scrollY == 0) {
      setScrollTop(false);
    } else {
      setScrollTop(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  function transformData(data: any) {
    if (data.status) {
      setShowSuccessAlert({
        show: true,
        message: data.message,
      });

      setOpenMailSuccessDialog(true)

      setTimeout(() => {
        setShowSuccessAlert({
          show: false,
          message: "",
        });
      }, 5000);
    } else {
      setShowErrorAlert({
        show: true,
        message: data?.error || data?.message,
      });
      setTimeout(() => {
        setShowErrorAlert({
          show: false,
          message: "",
        });
      }, 1500);
    }
  }
  const [showSuccessAlert, setShowSuccessAlert] = useState({
    show: false,
    message: "",
  });

  const { loading, error, sendRequestWithoutToken } = useHttp(transformData);
  const [openMailDialog, setOpenMailDialog] = useState(false);
  const [openMailSuccessDialog, setOpenMailSuccessDialog] = useState(false);

  const handleOpenMailDialog = () => {
    setToggleMobileMenu(false);
    let user = localStorage.getItem('web_user')
    if (user != null) {
      let web_user: any = JSON.parse(user)
      const email = web_user?.email
      const reqOptions = {
        url: `/mailing_lists/create_mailing_list`,
        method: "POST",
        data: {
          email
        },
      };
      sendRequestWithoutToken(reqOptions);
    } else {
      setOpenMailDialog(true);
    }
  };

  const handleCloseMailDialog = () => {
    setOpenMailDialog(false);
  };
  const handleOpenMailSuccessDialog = () => {
    setOpenMailSuccessDialog(true);
  };
  const handleCloseMailSuccessDialog = () => {
    setOpenMailSuccessDialog(false);
    setOpenMailDialog(false);
  };

  return (
    <>
      {showAlert.show && (
        <AlertCmp type="success_web" position="user_side">
          {showAlert.message}
        </AlertCmp>
      )}
      {showErrorAlert.show && (
        <AlertCmp type="error_web" position="user_side">
          {showErrorAlert.message}
        </AlertCmp>
      )}

      {/* bottom to the top button  */}
      <GoToTop />

      <Box className="home_container">
        <Box
          sx={{ flexGrow: 1 }}
          className={
            scrollTop
              ? "web_header_grid"
              : "web_header_grid web_header_grid_no_bg"
          }
        >
          <Grid
            container
            spacing={2}
            sx={{
              margin: "0px",
              width: "100%",
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              className="web_grid_prop logo_grid"
            >
              <Link to="/" className="main_logo_link">
                {/* <img src={LogoImg} /> */}
                {/* <img src={"/icons/logo.svg"} alt="logo" /> */}
                <img src={MainLogo} alt="logo" />
              </Link>
            </Grid>
            <Grid
              item
              sm={1}
              xs={1}
              md={8}
              display={{ xs: "none", sm: "none", md: "flex" }}
              className="web_grid_prop options_grid"
            >
              {menuOptions.map((option, i) => {
                return (
                  <WebMenuItem
                    {...option}
                    key={i}
                    handleOpen={option?.link == '/mailing' ? handleOpenMailDialog : handleOpenLoginDialog}
                  />
                );
              })}
            </Grid>
          </Grid>
          <Box className="web_header_mobile_option">
            <img src={MenuImg} onClick={handleOpenMobileMenu} />
          </Box>
        </Box>
        <Box className="web_layout_grid">{children}</Box>
        <FooterSection />
      </Box>

      {/* login dialog box */}
      <WebLoginDialog
        open={openLoginDialog}
        handleClose={handleCloseLoginDialog}
        handleOpenDialog={handleOpenRegisterDialog}
        handleOpenOtpDialog={handleOpenOtpDialog}
        handleAlertBox={handleAlertBox}
        handleAlertErrorBox={handleAlertErrorBox}
        handleOpenForgotPasswordDialog={handleOpenForgotPasswordDialog}
      />

      {/* register dialog box */}
      <WebRegisterDialog
        open={openRegisterDialog}
        handleClose={handleCloseRegisterDialog}
        handleOpenDialog={handleOpenLoginDialog}
        handleOtpOpen={() => { setOpenRegisterDialog(false); setOpenOtpDialog(true) }}
        handleAlertBox={handleAlertBox}
        handleAlertErrorBox={handleAlertErrorBox}
        page=""
      />

      {/* otp dialog box */}
      <WebOtpDialog
        open={openOtpDialog}
        handleClose={handleCloseOtpDialog}
        handleAlertBox={handleAlertBox}
        handlePasswordOpen={handleOpenPasswordDialog}
        handleAlertErrorBox={handleAlertErrorBox}
      />

      <WebPasswordDialog
        open={openPasswordDialog}
        handleClose={handleClosePasswordDialog}
        handleOpenDialog={handleOpenPasswordDialog}
        handleAlertBox={handleAlertBox}
        handleAlertErrorBox={handleAlertErrorBox}
      />

      <WebForgotPassword
        open={openForgotPasswordDialog}
        handleClose={handleCloseForgotPasswordDialog}
        handleAlertBox={handleAlertBox}
        handleAlertErrorBox={handleAlertErrorBox}
      />

      <WebChangePassword
        open={openChangePasswordDialog}
        handleClose={handleCloseChangePasswordDialog}
        handleAlertBox={handleAlertBox}
        handleAlertErrorBox={handleAlertErrorBox}
        handleOpenLoginDialog={handleOpenLoginDialog}
        resetToken={resetToken}
      />

      {toggleMobileMenu && (
        <Box className="web_header_mobile_menu">
          <Box className="web_header_mobile_close">
            <img src={CloseImg} onClick={handleCloseMobileMenu} />
          </Box>
          {mobileOptions.map((option, i) => {
            return (
              <>

                {option.type === "button" ? (
                  <span
                    className="web_header_mobile_option_titles"
                    key={i}
                    onClick={option.onClick ? option.onClick : option?.link == '/mailing' ? handleOpenMailDialog : handleOpenLoginDialog}
                  >
                    <h6>{option.label}</h6>
                  </span>
                ) : (
                  <Link
                    className="web_header_mobile_option_titles"
                    key={i}
                    to={option.link}
                    onClick={handleCloseMobileMenu}
                  >
                    <h6>{option.label}</h6>
                  </Link>
                )}
              </>
            );
          })}
        </Box>
      )}
      {/* Mail dialog box */}
      <WebMailListingDialog
        open={openMailDialog}
        handleClose={handleCloseMailDialog}
        handleOpenDialog={handleOpenMailSuccessDialog}
      />
      {/* Mail Success dialog box */}
      <WebMailSuccessDialog
        open={openMailSuccessDialog}
        handleClose={handleCloseMailSuccessDialog}
      />
    </>
  );
};

export default React.memo(WebLayout);
