import { Button as MuiButton, Typography } from "@mui/material";
import { PropType } from "./types/propTypes";
import "./style.css";

export function Button({
  sx,
  disable = false,
  color = "primary",
  variant = "contained",
  type = "button",
  size,
  fullWidth,
  border,
  children,
  btnClass,
  icon,
  handleClick,
}: PropType) {
  const styles = {
    button: {
      padding: `${
        size === "xs"
          ? "8px"
          : size === "s"
          ? "10px"
          : size === "m"
          ? "16px"
          : "20px"
      } ${
        size === "xs"
          ? "20px"
          : size === "s"
          ? "30px"
          : size === "m"
          ? "50px"
          : "50px"
      }`,
      borderRadius:
        border === "square" ? "0px" : border === "login" ? "4px" : "8px",
    },
  };

  return (
    <MuiButton
      sx={sx}
      type={type}
      className={btnClass}
      color={color}
      style={styles.button}
      disableElevation
      variant={variant}
      fullWidth={fullWidth}
      disabled={disable}
      onClick={handleClick}
    >
      {icon && <img src={icon} className="button_icon" />}
      <Typography
        variant="button"
        sx={{
          fontWeight: border === "login" ? 500 : "",
        }}
      >
        {children}
      </Typography>
    </MuiButton>
  );
}
