import React, { useEffect, useState } from "react";
import { Paper, Box, Grid } from "@mui/material";
import HeroText from "../../molecules/heroText";
import HeroImages from "../../molecules/heroImages";
import useHttp from "../../../utils/hooks/useHttp";
import { PropType } from "./types/propType";
import moment from "moment";
import "./style.css";

const PreviewBanner = ({ bannerData }: PropType) => {
  return (
    <Paper
      className="carousel_slide_preview_banner"
      sx={{
        height: "100%",
        background: "#140A0A",
      }}
    >
      <Box sx={{ flexGrow: 1 }} className="carousel_slide_box">
        <Grid container spacing={2}>
          <Grid item sm={12} md={4} xs={12}>
            <HeroText
              title={bannerData.event_managment.title}
              location={bannerData.event_managment.venue}
              time={`${moment(bannerData.event_managment.date).format(
                "MMMM Do YYYY"
              )} ${bannerData.event_managment.start_date}`}
              previewBanner={true}
              price={
                bannerData?.event_managment?.ticket_details
                  ? bannerData?.event_managment?.ticket_details[1]
                      ?.advance_value
                  : "--"
              }
            />
          </Grid>
          <Grid item sm={12} md={8} xs={12} className="carousel_image_grid">
            {bannerData.event_managment.artists && (
              <HeroImages
                images={bannerData.event_managment.artists}
                previewBanner={true}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default PreviewBanner;
