import React, { useEffect, useState } from "react";
import EventCardWebsite from "../../organism/eventCardWebsite";
import { Box, Grid } from "@mui/material";
import ArtistImg1 from "./images/artist1.png";
import ArtistImg2 from "./images/artist2.png";
import ArtistImg3 from "./images/artist3.png";
import ArtistImg4 from "./images/artist4.png";
import ArtistImg5 from "./images/artist5.png";
import ArtistImg6 from "./images/artist6.png";
import ArtistDetailSection from "../../organism/artistDetailSection";
import GallerySection from "../../organism/gallerySection/eventGallery";
import useHttp from "../../../utils/hooks/useHttp";
import moment from "moment";
import Loader from "../../atoms/loader";
import { useParams } from "react-router-dom";
import "./style.css";
import SimpleSlider from "../../atoms/webCarousel/newCarousel";

const eventData1 = {
  title: "Tabla jugalbandi",
  location: "Recital Hall St. Catherine University, St. Paul",
  time: "Sunday April 23 2023, 5:00 PM",
  artists: [
    {
      image: ArtistImg1,
      name: "Apoorva Gokhale",
      link: true,
    },
    {
      image: ArtistImg2,
      name: "Apoorva Gokhale",
      link: true,
    },
    {
      image: ArtistImg3,
      name: "Apoorva Gokhale",
      link: true,
    },
    {
      image: ArtistImg4,
      name: "Apoorva Gokhale",
      link: true,
    },
    {
      image: ArtistImg5,
      name: "Apoorva Gokhale",
      link: true,
    },
    {
      image: ArtistImg6,
      name: "Apoorva Gokhale",
      link: true,
    },
  ],
};

const ticketsData = [
  {
    title: "For Members, Students, and Seniors (65+)",
    online: "$ 15",
    gate: "$ 20",
  },
  {
    title: "General Admission",
    online: "$ 20",
    gate: "$ 25",
  },
];

const artistDetailsData = [
  {
    artist_image: ArtistImg1,
    name: "Apoorva Gokhale",
    performance_type: "vocal",
    desc: "Born in the family of traditional legendary musicians, Apoorva Gokhale has carved a niche for herself as one of the sought after well-known vocalists of the younger generation with a firm background of Gwalior Gharana. ",
    bio: "She has an impressive musical lineage and has proudly and responsibly inherited the musical qualities from her grandfather, the late Gayanacharya Pandit Gajananrao Joshi and her great- grandfather Pandit Antubua Joshi, an eminent court musician in the erstwhile state of Aundh, district Satara. At a very tender age of five, she initially received a sound grooming from her grandfather Pandit Gajananrao Joshi, who insisted to see in her tonal perfection with just intonation and infused a keen sense of rhythm. Later on she received rigorous training in the form of guru-shishya parampara under the guidance and supervision of her uncle Pandit Madhukarrao Joshi, an eminent vocalist and violinist.",
  },
  {
    artist_image: ArtistImg2,
    name: "Apoorva Gokhale",
    performance_type: "vocal",
    desc: "Born in the family of traditional legendary musicians, Apoorva Gokhale has carved a niche for herself as one of the sought after well-known vocalists of the younger generation with a firm background of Gwalior Gharana. ",
    bio: "She has an impressive musical lineage and has proudly and responsibly inherited the musical qualities from her grandfather, the late Gayanacharya Pandit Gajananrao Joshi and her great- grandfather Pandit Antubua Joshi, an eminent court musician in the erstwhile state of Aundh, district Satara. At a very tender age of five, she initially received a sound grooming from her grandfather Pandit Gajananrao Joshi, who insisted to see in her tonal perfection with just intonation and infused a keen sense of rhythm. Later on she received rigorous training in the form of guru-shishya parampara under the guidance and supervision of her uncle Pandit Madhukarrao Joshi, an eminent vocalist and violinist.",
  },
  {
    artist_image: ArtistImg3,
    name: "Apoorva Gokhale",
    performance_type: "vocal",
    desc: "Born in the family of traditional legendary musicians, Apoorva Gokhale has carved a niche for herself as one of the sought after well-known vocalists of the younger generation with a firm background of Gwalior Gharana. ",
    bio: "She has an impressive musical lineage and has proudly and responsibly inherited the musical qualities from her grandfather, the late Gayanacharya Pandit Gajananrao Joshi and her great- grandfather Pandit Antubua Joshi, an eminent court musician in the erstwhile state of Aundh, district Satara. At a very tender age of five, she initially received a sound grooming from her grandfather Pandit Gajananrao Joshi, who insisted to see in her tonal perfection with just intonation and infused a keen sense of rhythm. Later on she received rigorous training in the form of guru-shishya parampara under the guidance and supervision of her uncle Pandit Madhukarrao Joshi, an eminent vocalist and violinist.",
  },
  {
    artist_image: ArtistImg4,
    name: "Apoorva Gokhale",
    performance_type: "vocal",
    desc: "Born in the family of traditional legendary musicians, Apoorva Gokhale has carved a niche for herself as one of the sought after well-known vocalists of the younger generation with a firm background of Gwalior Gharana. ",
    bio: "She has an impressive musical lineage and has proudly and responsibly inherited the musical qualities from her grandfather, the late Gayanacharya Pandit Gajananrao Joshi and her great- grandfather Pandit Antubua Joshi, an eminent court musician in the erstwhile state of Aundh, district Satara. At a very tender age of five, she initially received a sound grooming from her grandfather Pandit Gajananrao Joshi, who insisted to see in her tonal perfection with just intonation and infused a keen sense of rhythm. Later on she received rigorous training in the form of guru-shishya parampara under the guidance and supervision of her uncle Pandit Madhukarrao Joshi, an eminent vocalist and violinist.",
  },
  {
    artist_image: ArtistImg5,
    name: "Apoorva Gokhale",
    performance_type: "vocal",
    desc: "Born in the family of traditional legendary musicians, Apoorva Gokhale has carved a niche for herself as one of the sought after well-known vocalists of the younger generation with a firm background of Gwalior Gharana. ",
    bio: "She has an impressive musical lineage and has proudly and responsibly inherited the musical qualities from her grandfather, the late Gayanacharya Pandit Gajananrao Joshi and her great- grandfather Pandit Antubua Joshi, an eminent court musician in the erstwhile state of Aundh, district Satara. At a very tender age of five, she initially received a sound grooming from her grandfather Pandit Gajananrao Joshi, who insisted to see in her tonal perfection with just intonation and infused a keen sense of rhythm. Later on she received rigorous training in the form of guru-shishya parampara under the guidance and supervision of her uncle Pandit Madhukarrao Joshi, an eminent vocalist and violinist.",
  },
  {
    artist_image: ArtistImg6,
    name: "Apoorva Gokhale",
    performance_type: "vocal",
    desc: "Born in the family of traditional legendary musicians, Apoorva Gokhale has carved a niche for herself as one of the sought after well-known vocalists of the younger generation with a firm background of Gwalior Gharana. ",
    bio: "She has an impressive musical lineage and has proudly and responsibly inherited the musical qualities from her grandfather, the late Gayanacharya Pandit Gajananrao Joshi and her great- grandfather Pandit Antubua Joshi, an eminent court musician in the erstwhile state of Aundh, district Satara. At a very tender age of five, she initially received a sound grooming from her grandfather Pandit Gajananrao Joshi, who insisted to see in her tonal perfection with just intonation and infused a keen sense of rhythm. Later on she received rigorous training in the form of guru-shishya parampara under the guidance and supervision of her uncle Pandit Madhukarrao Joshi, an eminent vocalist and violinist.",
  },
];

const SinglePastEvent = () => {
  const { loading, error, sendRequestWithoutToken } = useHttp(transformData);
  const { id } = useParams();
  const [eventData, setEventData] = useState<any>(null);
  const [artistDetailsData, setArtistDetailsData] = useState<any>([]);

  function transformData(data: any) {
    if (data.status) {
      const obj = {
        id: data.event.id,
        title: data.event.title,
        date: data?.event?.date,
        location: `${data.event.venue}`,
        locationLink: data?.event?.location,
        time: `${moment.utc(data.event.date).format("MMMM DD YYYY")}, 
        ${data.event.start_date}`,
        artists: data.event.artists,
        eventGallery: data.event.event_galleries,
        event_type: data?.event?.event_type,
        sub_type: data?.event?.sub_type,
        url: data?.event?.url
      };

      setEventData(obj);
      setArtistDetailsData(data.event.artists);
    }
  }

  useEffect(() => {
    const reqOptions = {
      url: `/get_event`,
      method: "GET",
      params: {
        id,
      },
    };

    sendRequestWithoutToken(reqOptions);
  }, []);

  return (
    <>
      {loading && <Loader />}
      <Box className="single_event_home_section">
        {eventData && <EventCardWebsite data={eventData} />}
        {artistDetailsData.length &&
          artistDetailsData.map((details: any, i: number) => {
            return <ArtistDetailSection key={i} {...details} />;
          })}
        {eventData?.eventGallery &&  eventData.eventGallery[0] && eventData.eventGallery[0].event_images?.length > 0 && (
          <GallerySection galleryData={eventData.eventGallery} />
        )}
      </Box>
    </>
  );
};

export default SinglePastEvent;
