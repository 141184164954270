import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteImg from "./images/delete.svg";
import DeleteColoredImg from "./images/delete-colored.svg";
import BlockImg from "./images/block.svg";
import UserImg from "./images/user.svg";
import EnableImg from "./images/Enable.png";
import NotifyImg from "./images/notify.svg";
import EditImg from "./images/edit.svg";
import FulfilledOrderImg from "./images/fulfilled_order.svg";
import DotsImg from "./images/dots.svg";
import { PropType } from "./types/propTypes";
import Box from "@mui/material/Box";
import TextLabels from "../textLabels";
import ModalPopup from "../modalPopup";
import ModalFormPopup from "../modalFormPopup";
import ModalMessagePopup from "../modalMessagePopup";
import ModalProfilePopup from "../modalProfilePopup";
import useHttp from "../../../utils/hooks/useHttp";
import { ReqOptions } from "../../../utils/types/reqOptions";
import { USERS_GET_URL } from "../../../utils/configs";
import Loader from "../loader";
import "./style.css";
import { Checkbox, Typography } from "@mui/material";
import useOutsideAlerter from "../../../utils/hooks/useOutsideAlerter";
import AttendeeModal from "../attendeeModal";
import { useNavigate } from "react-router-dom";

const dotsOptionsHelper: any = {
  send_message_user: {
    label: "Send Message",
    color: "primary",
    icon: NotifyImg,
  },
  view_profile_user: {
    label: "View Profile",
    color: "secondary",
    icon: UserImg,
  },
  block_profile_user: {
    label: "Block Profile",
    color: "secondary",
    icon: BlockImg,
  },
  edit_user: {
    label: "Edit User",
    color: "secondary",
    icon: "/icons/ticket-edit.png",
  },
  unblock_profile_user: {
    label: "Unblock Profile",
    color: "secondary",
    icon: BlockImg,
  },
  edit_user_user: {
    label: "Edit Member",
    color: "primary",
    icon: "/icons/ticket-edit.png",
  },
  edit_membership: {
    label: "Edit",
    color: "primary",
    icon: "/icons/ticket-edit.png",
  },
  delete_user_user: {
    label: "Delete User",
    color: "secondary",
    icon: DeleteImg,
  },
  delete_membership: {
    label: "Delete",
    color: "primary",
    icon: DeleteColoredImg,
  },
  disable_membership: {
    label: "Disable",
    color: "secondary",
    icon: BlockImg,
  },
  enable_membership: {
    label: "Enable",
    color: "secondary",
    icon: EnableImg,
  },
  fulfilled_order: {
    label: "Fulfilled Order",
    color: "primary",
    icon: FulfilledOrderImg,
  },
  cancel_order: {
    label: "Cancel Order",
    color: "secondary",
    icon: BlockImg,
  },
  view_attendees: {
    label: "View Attendees",
    color: "secondary",
    icon: UserImg,
  },
};

const Tables = ({
  showCheckbox,
  tab,
  columns,
  data,
  deleteModal,
  editModal,
  apiUrl,
  blockUrl,
  handleSuccessCall,
  handleApiFetchAgain,
  handleCheckSelected,
  handleMultipleSelection,
  memberModal,
}: PropType) => {
  const [openBlock, setOpenBlock] = React.useState(false);
  const [selectedMember, setSelectedMember] = React.useState<any>(null);
  const [openDisable, setOpenDisable] = React.useState(false);
  const [openUnblock, setOpenUnblock] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [messageEmail, setMessageEmail] = React.useState("");
  const [modalMessage, setModalMessage] = React.useState("");
  const [openMessage, setOpenMessage] = React.useState(false);
  const [openProfile, setOpenProfile] = React.useState(false);
  const [profileData, setProfileData] = React.useState(null);
  const [memberData, setMemberData] = React.useState<any>(null);
  const [openMember, setOpenMember] = React.useState(false);
  const [attendees, setAttendees] = React.useState([]);
  const [openViewAttendees, setOpenViewAttendees] = React.useState(false);
  const { loading, error, sendRequest } = useHttp(transformData);
  const [ifEnable, setIfEnable] = React.useState<any>();
  const {
    loading: loadingMessage,
    error: errorMessage,
    sendRequest: sendRequestMessage,
  } = useHttp(transformDataMessage);
  const [modalInputs, setModalInputs] = React.useState<any>(editModal?.inputs);
  const [memberInputs, setMemberInputs] = React.useState<any>(
    memberModal?.inputs
  );
  const [selected, setSelected] = React.useState<any>([]);
  const [showDotsDropdown, setShowDotsDropdown] = React.useState<any>("");

  const [modalOpenId, setModalOpenId] = React.useState(0);

  const wrapperRef = React.useRef(null);
  const clickRef = React.useRef(null);

  useOutsideAlerter(wrapperRef, clickRef, handleCloseOptions);

  function handleCloseOptions() {
    setShowDotsDropdown("");
  }

  useEffect(() => {
    if (handleMultipleSelection) {
      handleMultipleSelection(selected);
    }
  }, [selected]);

  function transformData(data: any) {
    if (data.status) {
      setOpenDelete(false);
      setOpenEdit(false);
      setSelectedMember(null);
      setOpenBlock(false);
      setOpenUnblock(false);

      if (handleSuccessCall) {
        handleSuccessCall(data.message);
      }
      handleApiFetchAgain();
    }
  }

  function transformDataMessage(data: any) {
    if (data.status) {
      if (handleSuccessCall) {
        handleSuccessCall(data.message);
      }
    }
    setOpenMessage(false);
  }

  const handleClickOpenMessage = (row: any) => {
    setMessageEmail(row);
    setOpenMessage(true);
  };

  const handleClickOpenBlock = (record: any) => {
    setModalOpenId(record.id);
    setSelectedMember(record);
    setOpenBlock(true);
  };

  const handleClickOpenDisable = (row: any, record: any) => {
    setModalOpenId(row);
    setOpenDisable(true);
    if (!record.blocked) {
      setIfEnable(true);
    } else {
      setIfEnable(false);
    }
  };

  const handleClickOpenProfile = (id: any, record: any) => {
    setModalOpenId(id);
    setProfileData(record);
    setOpenProfile(true);
  };

  const handleClickOpenUnblock = (row: any) => {
    setModalOpenId(row.id);
    setOpenUnblock(true);
  };

  const handleCloseBlock = () => {
    setOpenBlock(false);
    setSelectedMember(null);
  };

  const handleCloseDisable = () => {
    setOpenDisable(false);
  };

  const handleCloseUnblock = () => {
    setOpenUnblock(false);
  };

  const handleCloseProfile = () => {
    setOpenProfile(false);
  };

  const handleCloseMessage = () => {
    setOpenMessage(false);
  };

  const handleClickOpenDelete = (id: number) => {
    setModalOpenId(id);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleClickOpenEdit = (row: any) => {
    debugger
    const inputsArr = [...modalInputs];
    inputsArr?.forEach((input) => {
      if (row[input.name]) {
        input.value = row[input.name];
      }
    });
    setModalInputs(inputsArr);
    setModalOpenId(row.id);
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleBlock = () => {
    if (blockUrl) {
      const reqOptions: ReqOptions = {
        url: blockUrl,
        method: "post",
        data: {
          user_id: [modalOpenId],
          type: selectedMember.blocked ? "unblock" : "block",
        },
      };

      sendRequest(reqOptions);
    }
  };

  const handleDisable = () => {
    const reqOptions: ReqOptions = {
      url: `/memberships/${modalOpenId}/bolck_unblock`,
      method: "post",
    };

    sendRequest(reqOptions);
    setOpenDisable(false);
  };

  const handleUnblock = () => {
    if (blockUrl) {
      const reqOptions: ReqOptions = {
        url: blockUrl,
        method: "post",
        data: {
          user_id: [modalOpenId],
          type: "unblock",
        },
      };

      sendRequest(reqOptions);
    }
  };

  const handleEmailMessage = (val: any) => {
    setModalMessage(val);
  };

  const handleSendMessage = () => {
    let dataObj: any = {};

    if (tab === "mailing") {
      dataObj = {
        id: [messageEmail],
        message: modalMessage,
      };
    } else {
      dataObj = {
        user_id: [messageEmail],
        message: modalMessage,
      };
    }

    const reqOptions: ReqOptions = {
      url:
        tab === "mailing"
          ? "/mailing_lists/send_message"
          : "/users/send_message",
      method: "POST",
      data: dataObj,
    };

    sendRequestMessage(reqOptions);
  };

  const handleDelete = () => {
    if (tab === "mailing") {
      const reqOptions: ReqOptions = {
        url: "/mailing_lists/delete_mailing_list",
        method: "delete",
        data: {
          id: [modalOpenId],
        },
      };

      sendRequest(reqOptions);
    } else if (tab === "board") {
      const reqOptions: ReqOptions = {
        url: "/board_memebers/delete_board_memeber",
        method: "delete",
        data: {
          id: [modalOpenId],
        },
      };

      sendRequest(reqOptions);
    } else {
      if (apiUrl === USERS_GET_URL) {
        const reqOptions: ReqOptions = {
          url: "/users/delete_user",
          method: "delete",
          data: {
            user_id: [modalOpenId],
          },
        };
        sendRequest(reqOptions);
      } else {
        const reqOptions: ReqOptions = {
          url: `${apiUrl}/${modalOpenId}`,
          method: "delete",
        };
        sendRequest(reqOptions);
      }
    }
  };

  const onSelectAllClick = (event: any) => {
    if (event.target.checked) {
      setSelected(data?.map((n: any) => n.id));
      return;
    }
    setSelected([]);
  };

  const handleCheckboxClick = (event: any, id: any) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(id);
    let newSelected: any = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id: any) => selected.indexOf(id) !== -1;

  useEffect(() => {
    handleCheckSelected(selected.length ? true : false);
  }, [selected]);

  useEffect(() => {
    setSelected([]);
  }, [data]);

  const handleDotsDropdown = (e: any, index: number) => {
    e.stopPropagation();
    if (index === showDotsDropdown) {
      setShowDotsDropdown("");
    } else {
      setShowDotsDropdown(index);
    }
  };

  const handleEditMember = (record: any) => {
    setModalOpenId(record.id);
    setMemberData(record);
    const inputsArr = [...memberInputs];
    inputsArr.forEach((input) => {
      if (input.name === "member_email" && record.email) {
        input.value = record.email;
      } else if (record[input.name]) {
        input.value = record[input.name];
      }
    });
    setMemberInputs(inputsArr);
    setOpenMember(true);
  };
  const navigate = useNavigate();
  const handleEditUser = (record: any) => {
    navigate("/user-management/edit-profile", {
      state: {
        record,
      },
    });
  };

  const handleCloseMemberModal = () => {
    setOpenMember(false);
    setMemberData(null);
  };

  const handleViewAttendees = (row: any) => {
    setAttendees(row?.attendees);
    setOpenViewAttendees(true);
  };

  console.log("columns", columns, data);

  const handleDotAction = (label: any, id: any, email: any, record: any) => {
    // updated the data for the edit user by removing the mailing list value 
    const editDataForEmail = {...record, mailing_list: '-'}
    switch (label) {
      case "Delete User":
        handleClickOpenDelete(id);
        break;
      case "Block Profile":
        handleClickOpenBlock(record);
        break;
      case "Unblock Profile":
        handleClickOpenUnblock(record);
        break;
      case "Edit Member":
        handleEditMember(record);
        break;
      case "Send Message":
        handleClickOpenMessage(id);
        break;
      case "View Profile":
        handleClickOpenProfile(id, record);
        break;
      case "Delete":
        handleClickOpenDelete(id);
        break;
      case "Disable":
        handleClickOpenDisable(id, record);
        break;
      case "Enable":
        handleClickOpenDisable(id, record);
        break;
      case "Edit":
        handleClickOpenEdit(record);
        break;
      case "View Attendees":
        handleViewAttendees(record);
        break;
      case "Edit User":
        handleEditUser(editDataForEmail);
        break;
      default:
        handleClickOpenBlock(record);
        break;
    }
  };

  // console.log("testing: column--", columns);

  return (
    <>
      {loading && <Loader />}
      {loadingMessage && <Loader />}
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "unset",
          overflow: "inherit",
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow
              sx={{
                borderBottom: "unset",
                border: "1px solid #FFDAD9",
                boxShadow: "unset",
              }}
            >
              {showCheckbox && (
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={
                      selected.length > 0 && selected.length < data.length
                    }
                    checked={selected.length === data.length}
                    className="selectCheckbox"
                    onChange={onSelectAllClick}
                  />
                </TableCell>
              )}
              {columns?.map((column, i) => {
                return (
                  <>
                    {tab == "all" ? (
                      <TableCell
                        key={i}
                        sx={{
                          padding: "10px 16px",
                        }}
                      >
                        <TextLabels variant="h200" weight={500} color="#0F1419">
                          {column.label}
                        </TextLabels>
                      </TableCell>
                    ) : column.key == "membership" ? (
                      <></>
                    ) : (
                      <TableCell
                        key={i}
                        sx={{
                          padding: "10px 16px",
                        }}
                      >
                        <TextLabels variant="h200" weight={500} color="#0F1419">
                          {column.label}
                        </TextLabels>
                      </TableCell>
                    )}
                  </>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row: any, i: number) => {
              const isSelecteds = isSelected(row.id);
              return (
                <TableRow
                  key={i}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    borderBottom: "unset",
                    border: "1px solid #FFDAD9",
                    boxShadow: "unset",
                  }}
                  style={{ background: row.blocked ? "#FFDAD9" : "" }}
                >
                  {showCheckbox && (
                    <TableCell className="selectCheckbox" padding="checkbox">
                      <Checkbox
                        onClick={(event) => handleCheckboxClick(event, row.id)}
                        className="selectCheckbox"
                        checked={isSelecteds}
                      />
                    </TableCell>
                  )}
                  {columns?.map((col, j) => {
                    return (
                      <>
                        {col?.key === "action" ? (
                          <TableCell
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {row[col?.key]?.map((action: string, k: number) => {
                              return (
                                <Box className="dots_dropdown_box">
                                  {action === "dots" && (
                                    <span
                                      key={k}
                                      onClick={(e) =>
                                        handleDotsDropdown(e, row?.id)
                                      }
                                      ref={clickRef}
                                    >
                                      <img
                                        src={DotsImg}
                                        className="dots_img"
                                        alt="not-found"
                                        // onClick={() => handleClickOpenEdit(row)}
                                      />
                                    </span>
                                  )}
                                  {showDotsDropdown === row?.id && (
                                    <Box
                                      className="dots_dropdown_holder_box"
                                      ref={wrapperRef}
                                    >
                                      <ul>
                                        {row["dotActions"]?.map(
                                          (option: any, i: any) => {
                                            return (
                                              <li
                                                key={i}
                                                className={
                                                  dotsOptionsHelper[option]
                                                    .color === "primary"
                                                    ? "dots_option_li dots_option_li_primary"
                                                    : "dots_option_li"
                                                }
                                                onClick={() =>
                                                  handleDotAction(
                                                    dotsOptionsHelper[option]
                                                      .label,
                                                    row.id,
                                                    row.email,
                                                    row
                                                  )
                                                }
                                              >
                                                <img
                                                  src={
                                                    dotsOptionsHelper[option]
                                                      .icon
                                                  }
                                                  alt="not-found"
                                                />

                                                <span>
                                                  {
                                                    dotsOptionsHelper[option]
                                                      .label
                                                  }
                                                </span>
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    </Box>
                                  )}
                                </Box>
                              );
                            })}
                          </TableCell>
                        ) : tab == "all" ? (
                          <TableCell key={j}>
                            <TextLabels variant="h200" weight={400}>
                              {/* {typeof row[col?.key] === 'object' ? 
                                    <span>{row[col.key] ?  row[col.key][0] || 'No restrictions': "--" }{row[col.key] && row[col.key][1] ? `, ${row[col?.key][1]}` : ''} </span>
                                  : row[col?.key]
                              } */}
                              {typeof row[col?.key] === "object" ? (
                                <>
                                  {col?.key === "mailing_list" &&
                                  row[col.key] !== "-" ? (
                                    row[col.key] // Assuming obj.mailing_list contains the JSX element <img src={CheckImg} />
                                    // "x"
                                  ) : (
                                    <span>
                                      {row[col.key]
                                        ? row[col.key][0] || "No restrictions"
                                        : "--"}
                                      {row[col.key] && row[col.key][1]
                                        ? `, ${row[col?.key][1]}`
                                        : ""}
                                    </span>
                               
                                  )}
                                </>
                              ) : (
                                row[col?.key]
                              )}
                            </TextLabels>
                          </TableCell>
                        ) : 
                        // row[col?.key] == "--" ? (
                        //   <>
                            
                        //   </>
                        // ) : 
                        (
                          <TableCell key={j}>
                            <TextLabels variant="h200" weight={400}>
                              {row[col?.key]}
                            </TextLabels>
                          </TableCell>
                        )}
                      </>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {openEdit ? (
        <ModalFormPopup
          heading={"Update Membership"}
          inputs={modalInputs}
          closeBtn={true}
          confirmBtn={"Update"}
          handleClose={handleCloseEdit}
          // boardMemberApi={boardMemberApi}
          apiUrl={apiUrl + `/${modalOpenId}`}
          method={"PUT"}
          open={openEdit}
          handleApiFetchAgain={handleApiFetchAgain}
          handleSuccessCall={handleSuccessCall}
        />
      ) : null}
      <ModalPopup
        open={openBlock}
        handleClickOpen={handleClickOpenBlock}
        handleClose={handleCloseBlock}
        heading="Block User?"
        content="Are you sure you want to block this user?"
        primaryButton="Block"
        secondaryButton="Cancel"
        primaryAction={handleBlock}
      />
      <ModalPopup
        open={openDisable}
        handleClickOpen={handleClickOpenDisable}
        handleClose={handleCloseDisable}
        heading={ifEnable ? "Disable Membership?" : "Enable Membership"}
        content={
          ifEnable
            ? "Are you sure you want to disable this membership?"
            : "Are you sure you want to enable this membership?"
        }
        primaryButton={ifEnable ? "Disable" : "Enable"}
        secondaryButton="Cancel"
        primaryAction={handleDisable}
      />
      <ModalPopup
        open={openUnblock}
        handleClickOpen={handleClickOpenUnblock}
        handleClose={handleCloseUnblock}
        heading="Unblock User?"
        content="Are you sure you want to unblock this user?"
        primaryButton="Unblock"
        secondaryButton="Cancel"
        primaryAction={handleUnblock}
      />
      {deleteModal && (
        <ModalPopup
          open={openDelete}
          handleClickOpen={handleClickOpenDelete}
          handleClose={handleCloseDelete}
          heading={deleteModal.heading}
          content={deleteModal.content}
          primaryButton={deleteModal.primaryButton}
          secondaryButton={deleteModal.secondaryButton}
          primaryAction={handleDelete}
        />
      )}
      {memberData && (
        <ModalFormPopup
          heading={"Edit Board Member"}
          inputs={memberInputs}
          closeBtn={true}
          confirmBtn="Save Changes"
          handleClose={handleCloseMemberModal}
          apiUrl={`/board_memebers/${memberData.id}/update_board_member`}
          method="PUT"
          open={openMember}
          handleSuccessCall={handleSuccessCall}
          handleApiFetchAgain={handleApiFetchAgain}
          noDisable={true}
          profileImage={
            memberData.board_memeber_image
              ? memberData.board_memeber_image[0]
              : null
          }
        />
      )}
      {/* {memberInputs && (
        <ModalFormPopup
          heading={memberInputs.heading}
          inputs={memberInputs}
          closeBtn={true}
          confirmBtn={"Save Changes"}
          handleClose={handleCloseMemberModal}
          // boardMemberApi={boardMemberApi}
          apiUrl={apiUrl}
          boardMemberData={memberData}
          // method={method}
          open={openMember}
          handleApiFetchAgain={handleApiFetchAgain}
          handleSuccessCall={handleSuccessCall}
        />
      )} */}
      <ModalMessagePopup
        open={openMessage}
        handleClose={handleCloseMessage}
        heading="Send Message"
        closeBtn={true}
        primaryButton="Confirm"
        secondaryButton="Cancel"
        handleEmailMessage={handleEmailMessage}
        primaryAction={handleSendMessage}
        secondaryAction={handleCloseMessage}
        messageEmail={messageEmail}
      />
      <ModalProfilePopup
        open={openProfile}
        handleClose={handleCloseProfile}
        profileData={profileData}
        heading="Profile Details"
        closeBtn={true}
      />
      <AttendeeModal
        open={openViewAttendees}
        handleClose={() => {
          setOpenViewAttendees(false);
        }}
        heading={"Attendees List"}
        primaryButton={"Close"}
        closeBtn={false}
        attendeeList={attendees}
        primaryAction={() => {
          setOpenViewAttendees(false);
        }}
      />
    </>
  );
};

export default Tables;
