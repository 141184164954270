import React, { useState, useEffect } from "react";
import Topbar from "../../molecules/topbar";
import TabBar from "../../atoms/tabs";
import { ModalInputs } from "./types/modalInputs";
import AnnouncementCard from "../../atoms/announcementCard";
import { AnnouncementType } from "./types/announcementTypes";
import NoMember from "../../molecules/nomember";
import useHttp from "../../../utils/hooks/useHttp";
import moment from "moment";
import { ReqOptions } from "../../../utils/types/reqOptions";
import AlertCmp from "../../atoms/alert";
import { Box } from "@mui/material";
import Paginate from "../../atoms/pagination";
import Loader from "../../atoms/loader";
import {
  ANNOUNCEMENT_RECORDS_PER_PAGE,
  ANNOUNCEMENT_GET_URL,
} from "../../../utils/configs";
import "./style.css";

const tabs = [
  {
    value: "all_announcements",
    label: "All",
  },
  {
    value: "active",
    label: "Active",
  },
  {
    value: "schedule",
    label: "Scheduled",
  },
  {
    value: "past",
    label: "Past",
  },
];

const inputs: ModalInputs[] = [
  {
    label: "Announcement Heading *",
    placeholder: "Enter heading here",
    type: "text",
    name: "title",
    id: "announcement_title",
    grid: 1,
    errorMsg: "Please enter heading",
  },
  {
    label: "Start Date *",
    placeholder: "MM/DD/YYYY",
    type: "date",
    name: "start_date",
    id: "start_date",
    grid: 2,
    errorMsg: "Please select start date",
  },
  {
    label: "End Date *",
    placeholder: "MM/DD/YYYY",
    type: "date",
    name: "end_date",
    id: "end_date",
    grid: 2,
    errorMsg: "Please enter end date",
  },
  {
    label: "Message *",
    placeholder: "Write your message here",
    type: "textarea",
    name: "message",
    id: "message",
    grid: 1,
    maxRows: 4,
    errorMsg: "Please enter message",
  },
  {
    label: "Send announcement to “mailing list”.",
    placeholder: "",
    type: "checkbox",
    name: "mailing_list",
    id: "mailing_list",
    grid: 1,
  },
];

const Announcement = () => {
  const [toggleMenu, setToggleMenu] = useState<number | null>(null);
  const { loading, error, sendRequest } = useHttp(transformData);
  const [activeTab, setActiveTab] = useState("all_announcements");
  const [modalInputs, setModalInputs] = useState<ModalInputs[]>(inputs);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [initialRender, setInitialRender] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState(null);
  const [filterYearValue, setFilterYearValue] = useState(null);
  const [totalRecords, setTotalRecords] = useState(
    ANNOUNCEMENT_RECORDS_PER_PAGE
  );

  const [announcements, setAnnouncements] = useState<AnnouncementType[]>([]);

  function handleCloseOptions() {
    setToggleMenu(null);
  }

  function transformData(data: any) {
    if (data.status) {
      let announcementArr: any = [];
      if (data.announcements) {
        announcementArr = data.announcements.map((announcement: any) => {
          const obj: any = {};
          obj.id = announcement.id;
          obj.title = announcement.title;
          obj.message = announcement.message;
          obj.start_date = moment(announcement.start_date).format("MM/DD/YYYY");
          obj.end_date = moment(announcement.end_date).format("MM/DD/YYYY");
          obj.publishDate = moment(announcement.created_at).format(
            "MMM D, YYYY"
          );
          obj.status = "past";

          if (announcement.end_date < moment(new Date()).format("MM/DD/YYYY")) {
            obj.status = "past";
          } else if (
            announcement.start_date <=
              moment(new Date()).format("MM/DD/YYYY") &&
            announcement.end_date >= moment(new Date()).format("MM/DD/YYYY")
          ) {
            obj.status = "active";
          } else if (
            announcement.start_date > moment(new Date()).format("MM/DD/YYYY")
          ) {
            obj.status = "schedule";
          }

          return obj;
        });
      }

      // if (data.total) {
        setTotalRecords(data?.total);
      // }
      setAnnouncements(announcementArr);
    }
  }

  useEffect(() => {
    const requestOptions: ReqOptions = {
      url: ANNOUNCEMENT_GET_URL,
      method: "GET",
      params: {
        tab: activeTab,
        page: currentPage,
        per_page: ANNOUNCEMENT_RECORDS_PER_PAGE,
      },
    };

    setInitialRender(true);
    sendRequest(requestOptions);
  }, []);

  useEffect(() => {
    if (showSuccessAlert) {
      const timer = setTimeout(() => {
        setShowSuccessAlert(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [showSuccessAlert]);

  useEffect(() => {
    if (initialRender) {
      const reqOptions: ReqOptions = {
        url: ANNOUNCEMENT_GET_URL,
        method: "GET",
        params: {
          tab: activeTab,
          page: currentPage,
          per_page: ANNOUNCEMENT_RECORDS_PER_PAGE,
          search: search,
        },
      };

      sendRequest(reqOptions);
    }
  }, [currentPage, activeTab]);

  useEffect(() => {
    if (search !== null) {
      const timer = setTimeout(() => {
        const reqOptions: ReqOptions = {
          url: ANNOUNCEMENT_GET_URL,
          method: "GET",
          params: {
            tab: activeTab,
            page: currentPage,
            per_page: ANNOUNCEMENT_RECORDS_PER_PAGE,
            search: search,
          },
        };

        sendRequest(reqOptions);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [search]);

  const handleSuccessCall = (msg: string) => {
    setShowSuccessAlert(true);
    setSuccessMsg(msg);
  };

  const handleToggleMenu = (e: React.MouseEvent, eventId: number) => {
    e.stopPropagation();
    if (eventId === toggleMenu) {
      setToggleMenu(null);
    } else {
      setToggleMenu(eventId);
    }
  };

  const handleApiFetchAgain = () => {
    const reqOptions: ReqOptions = {
      url: ANNOUNCEMENT_GET_URL,
      method: "GET",
      params: {
        tab: activeTab,
        page: currentPage,
        per_page: ANNOUNCEMENT_RECORDS_PER_PAGE,
      },
    };

    sendRequest(reqOptions);
  };

  const handleTabClick = (
    val: "all_announcements" | "active" | "schedule" | "past"
  ) => {
    setActiveTab(val);
    setCurrentPage(1);
  };

  const handlePageChange = (e: any, page: number) => {
    setCurrentPage(page);
  };

  const handleSearchInput = (e: any) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    if (filterYearValue !== null) {
      const requestOptions: ReqOptions = {
        url: ANNOUNCEMENT_GET_URL,
        method: "GET",
        params: {
          year: filterYearValue,
          tab: activeTab,
          page: 1,
          per_page: ANNOUNCEMENT_RECORDS_PER_PAGE,
          search: search,
        },
      };

      sendRequest(requestOptions);
    }
  }, [filterYearValue]);

  const handleYearFilter = (val: any) => {
    setFilterYearValue(val);
  };


  return (
    <>
      {loading && <Loader />}
      {showSuccessAlert && (
        <AlertCmp type="success" position="dashboard">
          {successMsg}
        </AlertCmp>
      )}
      {error && (
        <AlertCmp type="error" position="dashboard">
          Something went wrong! Try again..
        </AlertCmp>
      )}
      <TabBar
        handleClick={handleTabClick}
        tabs={tabs}
        defaultValue="all_announcements"
      />
      <Topbar
        searchLabel="Search by keyword..."
        showBtn={true}
        apiUrl={ANNOUNCEMENT_GET_URL}
        method="POST"
        handleApiFetchAgain={handleApiFetchAgain}
        handleSuccessCall={handleSuccessCall}
        handleSearchInput={handleSearchInput}
        filterBtn={true}
        totalRecords={true}
        totalRecordsCount={totalRecords}
        handleYearFilter={handleYearFilter}
        modal={{
          heading: "Create Announcement",
          inputs,
          
        }}
      />
      {announcements.length ? (
        <Box
        sx={{
          marginTop: "15px",
        }}
        >
          {announcements.map((Item, i) => {
            return (
              <AnnouncementCard
                cardDetails={Item}
                inputs={modalInputs}
                toggleMenu={toggleMenu}
                handleApiFetchAgain={handleApiFetchAgain}
                handleToggleMenu={handleToggleMenu}
                handleSuccessCall={handleSuccessCall}
                handleCloseOptions={handleCloseOptions}
                key={i}
              />
            );
          })}
          <Paginate
            page={currentPage}
            totalRecords={totalRecords}
            recordsPerPage={ANNOUNCEMENT_RECORDS_PER_PAGE}
            handlePageChange={handlePageChange}
          />
        </Box>
      ) : (
        <>
          {!loading && (
            <NoMember
              heading="No Announcement"
              content={`You haven’t created any announcement yet.
      Click "Create" to add an announcement.`}
            />
          )}
        </>
      )}
    </>
  );
};

export default Announcement;
