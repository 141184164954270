import moment from "moment";

export const transformEventWithStatus = (event:any)=>{
    let status = "";
    const todayDate = moment(new Date()).format("YYYY-MM-DD");
    if(event.cancel) {
      status = "cancelled";
    } else if (todayDate > event.date) {
      status = "past";
    } else if (todayDate === event.date) {
      if (event.schedule_flag) {
        status = "scheduled";
      } else {
        const start_time = event.start_date;
        const end_time = event.end_date;

        const startTime24h = start_time.substr(-2);
        const endTime24h = end_time.substr(-2);

        const startTimeString = start_time;
        const currentDate = new Date();
        const [hours, minutes] = startTimeString.split(":");

        let startTimeHour: any = "";
        if (startTime24h === "AM") {
          startTimeHour = parseInt(hours);
        } else {
          startTimeHour = parseInt(hours) + 12;
        }

        currentDate.setHours(startTimeHour, parseInt(minutes), 0);

        const endTimeString = end_time;
        const currentEndDate = new Date();
        const [hoursEnd, minutesEnd] = endTimeString.split(":");

        let endTimeHour: any = "";
        if (endTime24h === "AM") {
          endTimeHour = parseInt(hoursEnd);
        } else {
          endTimeHour = parseInt(hoursEnd) + 12;
        }

        currentEndDate.setHours(endTimeHour, parseInt(minutesEnd), 0);

        const momentNow = new Date();
        if (momentNow < currentDate) {
          status = "upcoming";
        } else {
          if (momentNow < currentEndDate) {
            status = "live";
          } else {
            status = "past";
          }
        }
      }
    } else {
      if (event.schedule_flag) {
        status = "scheduled";
      } else {
        status = "upcoming";
      }
    }
    event.status = status;
    return event;
}