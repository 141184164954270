import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  List,
  ListItem,
  ListItemIcon,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CardSection from "../../molecules/cardSection";
import SectionHeading from "../../molecules/sectionHeading";
import "./style.css";
import useHttp from "../../../utils/hooks/useHttp";
import { useEffect, useRef, useState } from "react";
import Loader from "../../atoms/loader";
import WebRegisterDialog from "../../organism/webRegisterDialog";
import WebPasswordDialog from "../../organism/webPasswordDialog";
import { useNavigate } from "react-router-dom";
import WebLoginDialog from "../../organism/webLoginDialog";
import WebOtpDialog from "../../organism/webOtpDialog";
import WebForgotPassword from "../../organism/webForgotPassword";
import WebChangePassword from "../../organism/webChangePassword";
import PaymentPopup from "../../organism/paymentComponent";
import AlertCmp from "../../atoms/alert";

const CustomerMemberships = () => {
  const { loading, sendRequestWithoutToken } = useHttp(transformData);
  const [memberships, setMemberships] = useState<any>([]);
  const [openRegisterDialog, setOpenRegisterDialog] = useState(false);
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const [openPayment, setOpenPayment] = useState<any>(false)
  const [amount, setAmount] = useState<any>(null)
  const [showAlert, setShowAlert] = useState({
    show: false,
    message: "",
  });
  const [showErrorAlert, setShowErrorAlert] = useState({
    show: false,
    message: "",
  });

  function transformData(data: any) {
    if (data.status) {
      setMemberships(data.memberships);
      // const sortedMemberships = data.memberships.sort((a: any, b: any) => a.price - b.price);
      // setMemberships(sortedMemberships);
    }
  }

  useEffect(() => {
    const reqOptions = {
      url: `/get_memberships`,
      method: "GET",
      params: {
        block: false
      }
    };
    sendRequestWithoutToken(reqOptions);
  }, []);

  const handleAlertBox = (message: any) => {
    setShowAlert({
      show: true,
      message,
    });

    setTimeout(() => {
      setShowAlert({
        show: false,
        message: "",
      });
    }, 5000);
  };

  const handleAlertErrorBox = (message: any) => {
    setShowErrorAlert({
      show: true,
      message,
    });

    setTimeout(() => {
      setShowErrorAlert({
        show: false,
        message: "",
      });
    }, 5000);
  };

  const subscribeMembership = (e: any) => {
    e.preventDefault();
    navigate("/memberships-welcome");
  };

  const navigate = useNavigate();

  const [paid, setPaid] = useState(false);
  const [payerror, setError] = useState(null);
  const [price, setPrice] = useState()
  const paypalRef: any = useRef();
  const [user, setUser] = useState<any>(null)

  useEffect(() => {
    const webuser = localStorage.getItem("web_user");
    if (webuser) {
      const user = JSON.parse(webuser)
      setUser(user)
    }
  }, [])

  // useEffect(() => {
  //   // if(price){
  //   // @ts-ignore
  //   window.paypal
  //     .Buttons({
  //       createOrder: (data: any, actions: any) => {
  //         return actions.order.create({
  //           intent: "CAPTURE",
  //           purchase_units: [
  //             {
  //               description: "Your description",
  //               amount: {
  //                 currency_code: "USD",
  //                 value: '100',
  //               },
  //             },
  //           ],
  //         });
  //       },
  //       onApprove: async (data: any, actions: any) => {
  //         const order = await actions.order.capture();
  //         setPaid(true);
  //         console.log("order", order);
  //       },
  //       onError: (err: any) => {
  //         console.error("hello err ", err);
  //       },
  //     })
  //     .render(paypalRef.current);
  //   // }
  // }, []);

  const [openOtpDialog, setOpenOtpDialog] = useState(false);
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [openForgotPasswordDialog, setOpenForgotPasswordDialog] = useState(false);
  const [openChangePasswordDialog, setOpenChangePasswordDialog] = useState(false);
  const [toggleMobileMenu, setToggleMobileMenu] = useState(false);
  const [resetToken, setResetToken] = useState<any>(null);
  const [membershipDetails, setMembershipDetails] = useState<any>(null)
  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const foo = params.get("reset_token");
    setResetToken(foo);
  }, []);

  const handleCloseLoginDialog = (event: any, reason: any) => {
    if (reason && reason == "backdropClick") return;
    setOpenLoginDialog(false);
  };

  const handleCloseRegisterDialog = (event: any, reason: any) => {
    if (reason && reason == "backdropClick") return;
    setOpenRegisterDialog(false);
  };

  const handleCloseOtpDialog = (event: any, reason: any) => {
    if (reason && reason == "backdropClick") return;
    setOpenOtpDialog(false);
  };

  const handleClosePasswordDialog = (event: any, reason: any) => {
    if (reason && reason == "backdropClick") return;
    setOpenPasswordDialog(false);
  };

  const handleCloseForgotPasswordDialog = (event: any, reason: any) => {
    if (reason && reason == "backdropClick") return;
    setOpenForgotPasswordDialog(false);
  };

  const handleCloseChangePasswordDialog = (event: any, reason: any) => {
    if (reason && reason == "backdropClick") return;
    setOpenChangePasswordDialog(false);
  };

  const handleOpenLoginDialog = () => {
    setOpenLoginDialog(true);
    setOpenRegisterDialog(false);
    // setOpenOtpDialog(false);
    setToggleMobileMenu(false);
    setOpenPasswordDialog(false);
    setOpenForgotPasswordDialog(false);
    setOpenChangePasswordDialog(false);
  };

  const handleOpenRegisterDialog = () => {
    setOpenRegisterDialog(true);
    setOpenLoginDialog(false);
    // setOpenOtpDialog(false);
    setOpenPasswordDialog(false);
    setOpenForgotPasswordDialog(false);
    setOpenChangePasswordDialog(false);
  };

  const handleOpenOtpDialog = () => {
    setOpenOtpDialog(true);
    setOpenLoginDialog(false);
    setOpenRegisterDialog(false);
    setOpenPasswordDialog(false);
    setOpenForgotPasswordDialog(false);
    setOpenChangePasswordDialog(false);
  };

  const handleOpenPasswordDialog = () => {
    setOpenPasswordDialog(true);
    setOpenOtpDialog(false);
    setOpenLoginDialog(false);
    setOpenRegisterDialog(false);
    setOpenForgotPasswordDialog(false);
    setOpenChangePasswordDialog(false);
  };

  const handleOpenForgotPasswordDialog = () => {
    setOpenPasswordDialog(false);
    // setOpenOtpDialog(false);
    setOpenLoginDialog(false);
    setOpenRegisterDialog(false);
    setOpenChangePasswordDialog(false);
    setOpenForgotPasswordDialog(true);
  };

  const [showSuccessAlert, setShowSuccessAlert] = useState({
    show: false,
    message: "",
  });

  return (
    <>
      {showSuccessAlert.show && (
        <AlertCmp type="success_web" position="user_side">
          {showSuccessAlert.message}
        </AlertCmp>
      )}
      {loading && <Loader />}
      <Box className="memberships_section">
        <CardSection>
          <SectionHeading title="Membership" marginRight="-200" />
          <Box className="membership_card_wrapper">
            <Grid container>
              <Grid item xs={12}>
                <Grid container spacing={10} justifyContent="center">
                  {memberships.map((plan: any) => (
                    <Grid key={plan.id} item xs={12} sm={6} md={3}>
                      <Card className="membership_card">
                        <CardContent className="membership_card_content">
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="div"
                            className="membership_plan"
                          >
                            {plan.membership_type}
                          </Typography>
                          <Typography className="membership_prices">
                            ${plan.price}
                            <Typography
                              className="membership_duration"
                              component="span"
                            >
                              /yr
                            </Typography>
                          </Typography>
                          <Typography className="membership_description">
                            {
                              plan?.admits?.length && plan?.admits[0] != 'No restrictions' ?
                                <span> {plan.admits[0] == 'student' ? 'Full-time Students' : plan.admits[0] == 'senior_citizen' ? 'Seniors (over 65 years) only' : ''} {plan.admits[1] ? '&' : ''} {plan.admits[1] == 'senior_citizen' ? 'Seniors (over 65 years) only' : plan.admits[1] == 'student' ? 'Full-time Students' : ''}.</span>
                                : 'No Restrictions'
                            }
                          </Typography>
                          <Typography>
                            <List>
                              <ListItem className="membership_list">
                                <ListItemIcon style={{ minWidth: "30px" }}>
                                  <CheckIcon className="membership_list_icon" />
                                </ListItemIcon>
                                <Typography className="membership_list_feature">
                                  {plan.no_of_free_passes} Passes
                                </Typography>
                              </ListItem>
                              <ListItem className="membership_list">
                                <ListItemIcon style={{ minWidth: "30px" }}>
                                  <CheckIcon className="membership_list_icon" />
                                </ListItemIcon>
                                <Typography className="membership_list_feature">
                                  You can save ${plan.save_upto}
                                </Typography>
                              </ListItem>
                              <ListItem className="membership_list">
                                <ListItemIcon style={{ minWidth: "30px" }}>
                                  <CheckIcon className="membership_list_icon" />
                                </ListItemIcon>
                                <Typography className="membership_list_feature">
                                  Discounted tickets
                                </Typography>
                              </ListItem>
                            </List>
                          </Typography>
                          {localStorage.getItem("web_token") ? (
                            <Button
                              variant="outlined"
                              className="subscription_button"
                              onClick={() => {
                                if (user?.membership_type) {
                                  setShowSuccessAlert({
                                    show: true,
                                    message: "You are already a member",
                                  });
                                  setTimeout(() => {
                                    setShowSuccessAlert({
                                      show: false,
                                      message: "",
                                    });
                                  }, 2500);
                                } else {
                                  setAmount(plan.price)
                                  setMembershipDetails(plan)
                                  setOpenPayment(true)
                                }
                              }}
                            >
                              Pay Via Paypal
                            </Button>
                            // <Box ref={paypalRef}></Box >
                          ) : (
                            <Button
                              variant="outlined"
                              className="subscription_button"
                              onClick={() => setOpenRegisterDialog(true)}
                            >
                              Register to Buy
                            </Button>
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </CardSection>
      </Box>

      {((amount != null) && openPayment) && <PaymentPopup
        open={openPayment}
        handleClose={() => {
          setOpenPayment(false)
        }}
        amount={amount}
        type={"membership"}
        membershipDetails={membershipDetails}
      />}

      {/* login dialog box */}
      <WebLoginDialog
        open={openLoginDialog}
        handleClose={handleCloseLoginDialog}
        handleOpenDialog={handleOpenRegisterDialog}
        handleOpenOtpDialog={handleOpenOtpDialog}
        handleAlertBox={handleAlertBox}
        handleAlertErrorBox={handleAlertErrorBox}
        handleOpenForgotPasswordDialog={handleOpenForgotPasswordDialog}
      />

      {/* register dialog box */}
      <WebRegisterDialog
        open={openRegisterDialog}
        handleClose={handleCloseRegisterDialog}
        handleOpenDialog={handleOpenLoginDialog}
        handleOtpOpen={() => { setOpenRegisterDialog(false); setOpenOtpDialog(true) }}
        handleAlertBox={handleAlertBox}
        handleAlertErrorBox={handleAlertErrorBox}
        page="membership"
      />

      {/* otp dialog box */}
      <WebOtpDialog
        open={openOtpDialog}
        handleClose={handleCloseOtpDialog}
        handleAlertBox={handleAlertBox}
        handlePasswordOpen={handleOpenPasswordDialog}
        handleAlertErrorBox={handleAlertErrorBox}
      />

      <WebPasswordDialog
        open={openPasswordDialog}
        handleClose={handleClosePasswordDialog}
        handleOpenDialog={handleOpenPasswordDialog}
        handleAlertBox={handleAlertBox}
        handleAlertErrorBox={handleAlertErrorBox}
      />

      <WebForgotPassword
        open={false}
        handleClose={handleCloseForgotPasswordDialog}
        handleAlertBox={handleAlertBox}
        handleAlertErrorBox={handleAlertErrorBox}
      />

      <WebChangePassword
        open={false}
        handleClose={handleCloseChangePasswordDialog}
        handleAlertBox={handleAlertBox}
        handleAlertErrorBox={handleAlertErrorBox}
        handleOpenLoginDialog={handleOpenLoginDialog}
        resetToken={resetToken}
      />
    </>
  );
};

export default CustomerMemberships;
