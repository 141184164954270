import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import BackImg from "./images/back.svg";
import TabBar from "../../atoms/tabs";
import Topbar from "../../molecules/topbar";
import "./style.css";
import useHttp from "../../../utils/hooks/useHttp";
import { ReqOptions } from "../../../utils/types/reqOptions";
import Tables from "../../atoms/tables";
import Paginate from "../../atoms/pagination";
import NotFound from "../../molecules/notfound";

const tabs = [
  {
    value: "all_users",
    label: "All Users",
  },
  {
    value: "guest",
    label: "Guest User",
  },
  {
    value: "members",
    label: "Members",
  },
  {
    value: "registered",
    label: "Registered Users",
  },
];

const columns: any = {
  all_users: [
    { key: "username", label: "Full Name" },
    { key: "email", label: "Email" },
    { key: "user_type", label: "User Type" },
    { key: "role_name", label: "User Role" },
    { key: "no_of_purchase_tickets", label: "Tickets Purchased" },
    { key: "action", label: "Actions" },
  ],
  guest: [
    { key: "username", label: "Full Name" },
    { key: "email", label: "Email" },
    { key: "user_type", label: "User Type" },
    { key: "role_name", label: "User Role" },
    { key: "no_of_purchase_tickets", label: "Tickets Purchased" },
    { key: "action", label: "Actions" },
  ],
  members: [
    { key: "username", label: "Full Name" },
    { key: "email", label: "Email" },
    { key: "user_type", label: "User Type" },
    { key: "role_name", label: "User Role" },
    { key: "no_of_purchase_tickets", label: "Tickets Purchased" },
    { key: "action", label: "Actions" },
  ],
  registered: [
    { key: "username", label: "Full Name" },
    { key: "email", label: "Email" },
    { key: "user_type", label: "User Type" },
    { key: "role_name", label: "User Role" },
    { key: "no_of_purchase_tickets", label: "Tickets Purchased" },
    { key: "action", label: "Actions" },
  ],
};

const ParticipantList = () => {
  const [tab, setTab] = useState("all_users");
  const [search, setSearch] = useState(null);
  const [filterYearValue, setFilterYearValue] = useState(null);
  const [users, setUsers] = useState<any>([]);
  const [event, setEvent] = useState<any>();
  const { id } = useParams();

  const handleTabChange = (tabNo: any) => {
    setTab(tabNo == "all" ? "all_users" : tabNo);
  };

  const handleSearchInput = (e: any) => {
    setSearch(e.target.value);
  };

  const handleYearFilter = (val: any) => {
    setFilterYearValue(val);
  };
  const navigate = useNavigate();
  const getAllUsers = async () => {
    if (search == "") {
      if (tab == "all_users") {
        const reqOptions: ReqOptions = {
          url: `/list_of_users`,
          method: "GET",
          params: {
            event_id: id,
          },
        };
        sendRequest(reqOptions);
      } else if (tab == "guest") {
        const reqOptions: ReqOptions = {
          url: `/list_of_users`,
          method: "GET",
          params: {
            event_id: id,
            tab: "guest",
          },
        };
        sendRequest(reqOptions);
      } else if (tab == "members") {
        const reqOptions: ReqOptions = {
          url: `/list_of_users`,
          method: "GET",
          params: {
            event_id: id,
            tab: "member",
          },
        };
        sendRequest(reqOptions);
      } else if (tab == "registered") {
        const reqOptions: ReqOptions = {
          url: `/list_of_users`,
          method: "GET",
          params: {
            event_id: id,
            tab: "registered_user",
          },
        };
        sendRequest(reqOptions);
      }
    } else {
      if (tab == "all_users") {
        const reqOptions: ReqOptions = {
          url: `/list_of_users`,
          method: "GET",
          params: {
            event_id: id,
            search: search,
          },
        };
        sendRequest(reqOptions);
      } else if (tab == "guest") {
        const reqOptions: ReqOptions = {
          url: `/list_of_users`,
          method: "GET",
          params: {
            event_id: id,
            tab: "guest",
            search: search,
          },
        };
        sendRequest(reqOptions);
      } else if (tab == "members") {
        const reqOptions: ReqOptions = {
          url: `/list_of_users`,
          method: "GET",
          params: {
            event_id: id,
            tab: "member",
            search: search,
          },
        };
        sendRequest(reqOptions);
      } else if (tab == "registered") {
        const reqOptions: ReqOptions = {
          url: `/list_of_users`,
          method: "GET",
          params: {
            event_id: id,
            tab: "registered_user",
            search: search,
          },
        };
        sendRequest(reqOptions);
      }
    }
  };
  const transformData = (data: any) => {
    if (data?.status) {
      setTotalRecords(data?.total);
      const updatedUsers = data?.users.map((user: any) => ({
        ...user, // Spread the properties of the original user object
        action: ["dots"], // Set the action property to ["dots"]
        dotActions: ["view_attendees"],
      }));

      setUsers(updatedUsers);
    }
  };

  // console.log('users:' , users);

  const getEventById = () => {
    const reqOptions: ReqOptions = {
      url: `/event_managments/${id}`,
      method: "GET",
    };
    eventRequest(reqOptions);
  };

  useEffect(() => {
    getEventById();
  }, []);

  const transformEvent = (data: any) => {
    setEvent(data?.event);
  };
  const { loading, error, sendRequest } = useHttp(transformData);
  const {
    loading: load,
    error: err,
    sendRequest: eventRequest,
  } = useHttp(transformEvent);

  useEffect(() => {
    getAllUsers();
  }, [tab, search]);

  const [showMultipleActions, setShowMultipleActions] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const handleCheckSelected = (selected: boolean) => {
    // setShowMultipleActions(selected);
  };
  const handlePageChange = (e: any, page: number) => {
    setCurrentPage(page);
  };

  return (
    <Box className="participant_list_box">
      <Box className="participant_list_back_btn">
        <img
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/event-management");
          }}
          src={BackImg}
        />
        {event?.title}
      </Box>
      <TabBar
        handleClick={handleTabChange}
        tabs={tabs}
        defaultValue="all_users"
      />
      <Topbar
        searchLabel="Search by name, email ID, membership..."
        filterBtn={false}
        handleSearchInput={handleSearchInput}
        handleYearFilter={handleYearFilter}
        pageName="/create-event"
        totalRecordsCount={totalRecords}
        showMultipleActions={showMultipleActions}
        modal={{
          heading: "Create Membership",
          inputs: [],
        }}
      />
      {users?.length != 0 ? (
        <>
          <Tables
            showCheckbox={false}
            tab={tab}
            columns={columns[tab]}
            data={users}
            handleApiFetchAgain={() => {}}
            handleCheckSelected={handleCheckSelected}
          />
          <Paginate
            page={currentPage}
            totalRecords={totalRecords}
            recordsPerPage={9}
            handlePageChange={handlePageChange}
          />
        </>
      ) : (
        <>{!loading && <NotFound />}</>
      )}
    </Box>
  );
};

export default ParticipantList;
