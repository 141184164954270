import React from "react";
import Layout from "../../organism/layout";
import Announcement from "../../templates/announcement";

const AnnouncementPage = () => {
  return (
    <Layout pageName="Announcement" menuActive="Announcement">
      <Announcement />
    </Layout>
  );
};

export default AnnouncementPage;
