import React, { useRef, useEffect, useState } from "react";

function useOutsideAlerter(
  ref: any,
  clickRef: any,
  handleCloseOptions: () => void
) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        clickRef.current &&
        !clickRef.current.contains(event.target)
      ) {
        // alert("You clicked outside of me!");
        handleCloseOptions();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export default useOutsideAlerter;
