import React, { useState, useEffect } from "react";
import { PropType } from "./types/propType";
import { Box } from "@mui/material";
import { Button } from "../buttons";
import { Link, useLocation } from "react-router-dom";
import UserImg from "./images/user.png";
import ArrowImg from "./images/arrow.svg";
import ProfileImg from "./images/profile.svg";
import LogoutImg from "./images/logout.svg";
import { webLogout } from "../../../utils/store/slices/authSlice";
import { useDispatch } from "react-redux";
import "./style.css";

const WebMenuItem = ({ link, label, type, handleOpen, checkType, icon }: PropType) => {
  const location = useLocation();
  const [storeToken, setStoreToken] = useState(null);
  const [userData, setUserData] = useState<any>(null);
  const [toggleProfileMenu, setToggleProfileMenu] = useState(false);
  const dispatch = useDispatch();

  const handleProfileToggle = () => {
    setToggleProfileMenu((prevState) => !prevState);
  };

  const handleLogout = () => {
    dispatch(webLogout());
    setTimeout(() => {
      window.location.href = location.pathname;
    }, 200);
  };
  
  // console.log(location);
  

  
  useEffect(() => {
    const updateUserState = () => {
      // const webToken = localStorage.getItem("web_token");
      // const token = localStorage.getItem("token");
      const webUser = localStorage.getItem("web_user");
      // const user = localStorage.getItem("user")
      const userJson = webUser || null
      let webUserData = null;
      if (userJson) {
        webUserData = JSON.parse(userJson);
      }
      if (webUserData) {
        // console.log("usertoken",webUserData)
        setUserData(webUserData);
      }
    };
    updateUserState();
    if(type === "button"){
      window.addEventListener("storage", updateUserState);
    }

    return () => {
      if(type === "button"){
        window.removeEventListener("storage", updateUserState);
      }
    };
  }, []);

  return (
    <Box className="web_option" style={{zIndex: "5"}}>
      {type === "button" ? (
        <>
          {link == '/login' ? (
            <Box
              className="web_header_user_dropdown"
              onClick={userData ? handleProfileToggle : handleOpen}
            >
              <img src={userData?.profile_image || UserImg} className="web_header_user_img web_user_image" />
              { userData && <p className="username_elipsis" style={{ textTransform: "capitalize" }}>
                {userData ? userData?.username || "--" : ''}
              </p>}
              { userData && <img src={ArrowImg} className="web_header_user_arrow" />}
              {toggleProfileMenu && (
                <Box className="web_header_dropdown">
                  <ul>
                    <Link
                      to="/user-profile"
                      className="web_header_dropdown_link"
                    >
                      <li>
                        <img src={ProfileImg} />
                        <span>Profile</span>
                      </li>
                    </Link>
                    <li onClick={handleLogout}>
                      <img src={LogoutImg} />
                      <span>Logout</span>
                    </li>
                  </ul>
                </Box>
              )}
            </Box>
          ) : (
            <Button
              size="s"
              color="info"
              sx={{
                height: "38px",
                fontFamily: "Inter",
                borderRadius: "4px",
                fontWeight: "500 !important",
                fontSize: "14px",
                color: "#140A0A",
                padding: "10px",
              }}
              handleClick={handleOpen}
              // border="login"
            >
              {label}
            </Button>
          )}
        </>
      ) : (
        <Link to={link} className="nav_link" style={{
          display:"flex",
          flexDirection:"column",
          alignItems:"center",
          justifyContent:"space-between",
          gap:"6px",
        }}>
          
          {icon}
          <h4
            className={
              location.pathname === link ? "nav_text active" : "nav_text"
            }
            style={{margin:'0px'}}
          >
            {label}
          </h4>
        </Link>
      )}
    </Box>
  );
};

export default WebMenuItem;
