import React, { useEffect, useState } from "react";
import { PropType } from "./types/propType";
import { Box, Grid } from "@mui/material";
import StarImg from "./images/star.svg";
import { Link } from "react-router-dom";
import UserImg from "./images/user_profile.png";
import PencilImg from "./images/pencil.svg";
import InputsWithLabel from "../../molecules/inputsWithLabel";
import CloseImg from "./images/close.svg";
import { Button } from "../../atoms/buttons";
import TickImg from "./images/tick.svg";
import WebEditImageDialog from "../../organism/webEditImageDialog";
import WebUpdatePassword from "../../organism/webUpdatePassword";
import CameraImg from "./images/camera.svg";
import DeleteImg from "./images/delete.svg";
import useHttp from "../../../utils/hooks/useHttp";
import Loader from "../../atoms/loader";
import AlertCmp from "../../atoms/alert";
import ProfileAddresses from "./userAddresses";
import "./style.css";
import { updateStorageWebUser } from "../../../utils/storage";

const BuyCDStep1 = ({ handleStep }: PropType) => {
  const [editSection, setEditSection] = useState(false);
  const [openImageDialog, setOpenImageDialog] = React.useState(false);
  const [openUpdatePasswordDialog, setOpenUpdatePasswordDialog] =
    useState(false);
  const [userInfo, setUserInfo] = useState<any>(null);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const [addressesData, setAddressesData] = useState([]);
  const [showSuccessMsg, setShowSuccessMsg] = useState({
    show: false,
    message: "",
  });
  const [showErrorMsg, setShowErrorMsg] = useState({
    show: false,
    message: "",
  });
  const [errors,setErrors] = useState({username:'',email:''});
  const { loading, error, sendRequestWeb } = useHttp(transformData);

  function transformData(data: any) {
    if (data.status) {
     
      setUserInfo({
        ...userInfo,
        username: data.user.username,
        email: data.user.email,
      });
      setShowSuccessMsg({
        show: true,
        message: data.message,
      });

      setTimeout(() => {
        setShowSuccessMsg({
          show: false,
          message: "",
        });
      }, 5000);
      updateStorageWebUser(data.user);
      setEditSection(false);
    } else {
      let webUserData = null;
      if (localStorage.getItem("web_user")) {
        // webUserData = JSON.parse(localStorage.getItem("web_user") as any);
        const user = localStorage.getItem("web_user")
        const obj = JSON.parse(user || '')
      const newUser = {
        ...obj,
        email: data?.user?.email,
        username: data?.user?.username,
      }
      // localStorage.setItem("web_user",JSON.stringify(newUser))
      webUserData = newUser
      }

      if (webUserData) {
        setUserInfo({
          ...userInfo,
          username: webUserData.username,
          email: webUserData.email,
        });
      }

      setShowErrorMsg({
        show: true,
        message: data.message,
      });

      setTimeout(() => {
        setShowErrorMsg({
          show: false,
          message: "",
        });
      }, 5000);
    }
  }

  const handleClickOpen = () => {
    setOpenImageDialog(true);
  };

  const handleClose = () => {
    setOpenImageDialog(false);
  };

  const handleClickOpenPassword = () => {
    setOpenUpdatePasswordDialog(true);
  };

  const handleClosePassword = () => {
    setOpenUpdatePasswordDialog(false);
  };

  const handleEditProfile = () => {
    setEditSection(true);
  };

  const handleCloseEditProfile = () => {
    setEditSection(false);
  };

  const handleSelectedFile = (file: any) => {
    setSelectedFile(file);
  };

  const handlePreviewUrl = (file: any) => {
    setPreviewUrl(file);
  };

  const handleInputChange = (e: any) => {
    setUserInfo((prevState: any) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const handleProfileEdit = (e: any) => {
    e.preventDefault();
    let error = false;
    const username = userInfo?.username?.trim();
    if (!username) {
      error = true;
      setErrors((prev) => ({ ...prev, username: "*Required field" }));
    } else {
      setErrors((prev) => ({ ...prev, username: "" }));
    }
    const email = userInfo?.email?.trim();
    if (!email) {
      error = true;
      setErrors((prev) => ({ ...prev, email: "*Required field" }));
    } else {
      const emailRegex = /[a-z0-9._%+-]+@[a-z0-9]+\.[a-z]{2,3}$/;
      if (!emailRegex.test(email)) {
        error = true;
        setErrors((prev) => ({ ...prev, email: "Enter vaild email" }));
      } else {
        setErrors((prev) => ({ ...prev, email: "" }));
      }
    }
    if (error) return;
    const formData = new FormData();

    Object.keys(userInfo).forEach((key) => {
      if (key !== "profile_image") {
        formData.append(`guest[${key}]`, userInfo[key]);
      }
    });
    const web_user = localStorage.getItem("web_user")
    const user:any = JSON.parse(web_user || "")

    const reqOptions = {
      url: `/users/${user?.id}/update_profile`,
      method: "POST",
      data: {
        'username': username,
        'email': email,
      }
    };
    sendRequestWeb(reqOptions);
  };

  useEffect(() => {
    let webUserData = null;
    if (localStorage.getItem("web_user")) {
      webUserData = JSON.parse(localStorage.getItem("web_user") as any);
    }


    if (webUserData) {
      setAddressesData(webUserData.addresses);

      setUserInfo({
        username: webUserData.username,
        email: webUserData.email,
        profile_image: webUserData.profile_image,
        no_of_free_passes: webUserData?.no_of_free_passes,
        membership_type: webUserData?.membership_type
      });
    }
  }, []);

  return (
    <>
      {loading && <Loader />}
      
      {showSuccessMsg.show && (
        <AlertCmp type="success_web" position="user_side">
          {showSuccessMsg.message}
        </AlertCmp>
      )}
      {showErrorMsg.show && (
        <AlertCmp type="error" position="user_side">
          {showErrorMsg.message}
        </AlertCmp>
      )}
      {error && (
        <AlertCmp type="error" position="user_side">
          {showErrorMsg.message}
        </AlertCmp>
      )}
      <Box className="buy_cd_step1_box">
      {userInfo?.membership_type &&
        <Box className="buy_cd_step1_membership">
          <Box className="buy_cd_step1_membership_label">
            <img src={StarImg} />
            <p>{userInfo?.membership_type}</p>
          </Box>
          {/* <Box className="buy_cd_step1_membership_link">
            {userInfo?.no_of_free_passes > 0 && <Link to="/memberships">{userInfo?.no_of_free_passes} Passes Left</Link>}
          </Box> */}
        </Box>
      }
        <Box className="buy_cd_step1_user_details" style={{marginTop: userInfo?.membership_type  ? "45px":"0px" }}>
          {userInfo && (
            <>
              {editSection ? (
                <Grid
                  container
                  spacing={{ sm: 4, xs: 2 }}
                  className="user_info_edit_grid_container"
                >
                  <Grid item xs={12} sm={8} className="user_info_edit_grid">
                    <h4 className="user_info_edit_heading">
                      Edit Profile Details
                    </h4>
                    <Grid container>
                    <Grid item xs={12} sm={4}>
                    <Box className="user_info_edit_input_box">
                      <Box
                        className="user_info_edit_image_box"
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={handleClickOpen}
                      >
                        <img
                          src={
                            previewUrl
                              ? previewUrl
                              : userInfo.profile_image
                              ? userInfo.profile_image
                              : UserImg
                          }
                          className="user_info_edit_img"
                        />
                        <img
                          src={CameraImg}
                          className="user_info_edit_camera"
                        />
                      </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                    <Box className="">
                        <InputsWithLabel
                          label="Name *"
                          placeholder="Enter your name"
                          type="text"
                          id="username"
                          name="username"
                          labelColor="#FDFCFC"
                          value={userInfo.username}
                          handleInputChange={handleInputChange}
                          helperText={errors.username}
                          msg={errors.username}
                        />
                        <Box sx={{ marginTop: "30px" }} />
                        <InputsWithLabel
                          label="Email *"
                          placeholder="Enter your email"
                          type="text"
                          id="email"
                          name="email"
                          labelColor="#FDFCFC"
                          value={userInfo.email}
                          handleInputChange={handleInputChange}
                          helperText={errors.email}
                          msg={errors.email}
                        />
                      </Box>
                    </Grid>
                    </Grid>
                    {/* <Box className="user_info_edit_input_box">
                      <Box
                        className="user_info_edit_image_box"
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={handleClickOpen}
                      >
                        <img
                          src={
                            previewUrl
                              ? previewUrl
                              : userInfo.profile_image
                              ? userInfo.profile_image
                              : UserImg
                          }
                          className="user_info_edit_img"
                        />
                        <img
                          src={CameraImg}
                          className="user_info_edit_camera"
                        />
                      </Box>
                      <Box className="user_info_edit_inputs_box">
                        <InputsWithLabel
                          label="Name *"
                          placeholder="Enter your name"
                          type="text"
                          id="username"
                          name="username"
                          labelColor="#FDFCFC"
                          value={userInfo.username}
                          handleInputChange={handleInputChange}
                          helperText={errors.username}
                          msg={errors.username}
                        />
                        <Box sx={{ marginTop: "30px" }} />
                        <InputsWithLabel
                          label="Email *"
                          placeholder="Enter your email"
                          type="text"
                          id="email"
                          name="email"
                          labelColor="#FDFCFC"
                          value={userInfo.email}
                          handleInputChange={handleInputChange}
                          helperText={errors.email}
                          msg={errors.email}
                        />
                      </Box>
                    </Box> */}
                    <Box className="user_info_change_password">
                      <p onClick={handleClickOpenPassword}>Change Password</p>
                    </Box>
                    <Box className="user_info_save_changes">
                      <Button
                        variant="outlined"
                        color="info"
                        icon={TickImg}
                        size="s"
                        handleClick={handleProfileEdit}
                      >
                        Save Changes
                      </Button>
                    </Box>
                  </Grid>
                  <Box className="user_info_edit_close">
                    <img src={CloseImg} onClick={handleCloseEditProfile} />
                  </Box>
                </Grid>
              ) : (
                <Grid container spacing={{ sm: 4, xs: 2 }}>
                  <Grid
                    item
                    sm={1}
                    xs={1}
                    display={{ sm: "grid" }}
                    className="buy_cd_step1_grid buy_cd_step1_user_img_grid"
                  >
                    <img
                      className="buy_cd_step1_user_img"
                      src={userInfo.profile_image || UserImg}
                      onClick={handleClickOpen}
                      style={{ cursor: "pointer" }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={11}
                    sm={11}
                    className="buy_cd_step1_grid buy_cd_step1_user_grid"
                  >
                    <Box className="buy_cd_step1_user_info">
                      <h4 style={{ textTransform: "capitalize" }}>
                        {userInfo.username || "--"}
                      </h4>
                      <p className="buy_cd_step1_user_email">
                        {userInfo.email}
                      </p>
                    </Box>
                    <img
                      src={PencilImg}
                      className="buy_cd_step1_pencil_icon"
                      onClick={handleEditProfile}
                    />
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Box>
        <ProfileAddresses addressData={addressesData} />
      </Box>
      <WebEditImageDialog
        open={openImageDialog}
        handleClose={handleClose}
        handleSelectedFile={handleSelectedFile}
        selectedFile={selectedFile}
        previewUrl={previewUrl}
        handlePreviewUrl={handlePreviewUrl}
        setUserInfo={setUserInfo}
        userInfo={userInfo}
        uploadButtonLabel="Save"
      />
      <WebUpdatePassword
        open={openUpdatePasswordDialog}
        handleClose={handleClosePassword}
      />
    </>
  );
};

export default BuyCDStep1;
