import React, { useEffect, useState } from "react";
import WebDialog from "../../molecules/webDialog";
import {
  DialogContent,
  DialogContentText,
  Box,
  DialogActions,
  Checkbox,
} from "@mui/material";
import InputsWithLabel from "../../molecules/inputsWithLabel";
import SectionHeading from "../../molecules/sectionHeading";
import { Button } from "../../atoms/buttons";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { PropType } from "./types/propType";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../utils/store";
import { login } from "../../../utils/store/slices/authSlice";
import Loader from "../../atoms/loader";
import ErrorImg from "./images/error.svg";
import "./style.css";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const WebLoginDialog = ({
  open,
  handleClose,
  handleOpenDialog,
  handleOpenOtpDialog,
  handleAlertBox,
  handleAlertErrorBox,
  handleOpenForgotPasswordDialog,
  eventId
}: PropType) => {
  const [loginData, setLoginData] = useState<any>({
    email: {
      value: "",
      validate: true,
      errorMsg: "* Please enter your email",
    },
    password: {
      value: "",
      validate: true,
      errorMsg: "* Please enter your password",
    },
  });
  const [disableButton, setDisableButton] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [checked, setChecked] = useState(false)
  const location = useLocation();

  const navigate = useNavigate()

  const { isWebAuthenticated, isLoading, error } = useSelector(
    (state: RootState) => state.auth
  );
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    // if isUserLoggedIn turned to true redirect to /home
    if (isWebAuthenticated) {
      handleAlertBox("Logged in successfully!");
      const timer = setTimeout(() => {
        window.location.reload();
        if(eventId) {
          navigate(`/ticket-booking/${eventId}`)
        }
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isWebAuthenticated]);

  useEffect(() => {
    if(checked == true){
      setDisableButton(false)
    } else {
    let disableFlag = false;
    Object.keys(loginData).forEach((key) => {
      if (loginData[key].value === "") {
        disableFlag = true;
      }
    });
    setDisableButton(disableFlag);
  }
  }, [loginData, checked]);

  useEffect(() => {
    if (error) {
      handleAlertErrorBox(error);
    }
  }, [error]);

  useEffect(() => {
    if (!open) {
      const loginDataObj = JSON.parse(JSON.stringify(loginData));
      Object.keys(loginDataObj).forEach((key) => {
        loginDataObj[key].value = "";
      });
      setLoginData(loginDataObj);
    }
  }, [open]);

  const handleSubmit = (event: any) => {
    if (checked == false) {
      event.preventDefault();
      let errorFlag = false;

      const loginObj = JSON.parse(JSON.stringify(loginData));
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      Object.keys(loginObj).forEach((input) => {
        if (input === "email") {
          if (loginObj[input].value.trim() === "") {
            loginObj[input].validate = false;
            loginObj[input].errorMsg = "* Please enter email id";
            errorFlag = true;
          } else if (!emailRegex.test(loginObj[input].value)) {
            loginObj[input].validate = false;
            loginObj[input].errorMsg = "* Please enter valid email id";
            errorFlag = true;
          }
        } else {
          if (loginObj[input].value.trim() === "") {
            loginObj[input].validate = false;
            errorFlag = true;
          }
        }
      });

      setLoginData(loginObj);

      if (!errorFlag) {
        dispatch(
          login({
            email: loginData.email.value,
            password: loginData.password.value,
            webSide: true,
          })
        );
      }
    } else {
      localStorage.setItem("guest_user", 'true')
      if(eventId) {
        navigate(`/ticket-booking/${eventId}`)
      }
    }
  };

  const handleInputChange = (e: any) => {
    const loginObj = JSON.parse(JSON.stringify(loginData));
    loginObj[e.target.name].value = e.target.value;
    if (e.target.value !== "") {
      loginObj[e.target.name].validate = true;
    }
    setLoginData(loginObj);
  };

  const handleCheckboxChange = (event: any) => {
    setChecked(event.target.checked);
  };

  return (
    <>
      {isLoading && <Loader />}
      <WebDialog
        title="Welcome !"
        open={open}
        handleClose={handleClose}
        primaryButtonLabel="Sign In"
        subTitle="Sign in to continue"
      >
        <form onSubmit={handleSubmit}>
          <DialogContent className="dialogue_Content">
            <DialogContentText >
              <SectionHeading title="Welcome !" />
              <p className="login_dialog_subtitle">Sign in to continue</p>
              <p style={{marginBottom: '45px'}} className="login_dialog_subtitle">and avail membership</p>
            </DialogContentText>
            <InputsWithLabel
              label="Email"
              type="text"
              name="email"
              id="email"
              placeholder="Enter email here"
              labelColor="#FDFCFC"
              handleInputChange={handleInputChange}
            />
            {!loginData["email"].validate && (
              <p className="web_register_error_label">
                <img src={ErrorImg} />
                <span>{loginData["email"].errorMsg}</span>
              </p>
            )}
            <Box
              sx={{
                marginTop: "45px",
              }}
            />
            <Box className="web_login_forgot_password_box">
              <InputsWithLabel
                label="Password"
                type={showPassword ? "text" : "password"}
                name="password"
                id="password"
                placeholder="Enter password here"
                labelColor="#FDFCFC"
                handleInputChange={handleInputChange}
              />
              <p
                className="web_login_forgot_password"
                onClick={handleOpenForgotPasswordDialog}
              >
                Forgot Password?
              </p>
              <img
                style={{
                  position: "absolute",
                  right: "9px",
                  top: "40px",
                  cursor: "pointer",
                }}
                src="/icons/eye_icon.svg"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              />
            </Box>
            {!loginData["password"].validate && (
              <p className="web_register_error_label">
                <img src={ErrorImg} />
                <span>{loginData["password"].errorMsg}</span>
              </p>
            )}
            <Box className="web_dialog_checkbox">
              <Checkbox {...label} onChange={handleCheckboxChange} />
              <span>Continue as Guest</span>
            </Box>
          </DialogContent>
          <DialogActions className="login_dialog_actions">
            <Button
              variant="outlined"
              color="info"
              size="s"
              type="submit"
              sx={{
                fontFamily: "Raleway",
                fontWeight: "700",
                fontSize: "14px",
                color: "#D5D7DB",
                border: "1px solid #D5D7DB",
                padding: "12.5px 30px",
                width: "100%"
              }}
              disable={disableButton}
            >
              Sign In
            </Button>
          </DialogActions>
          <Box className="login_dialog_signup">
            <p>
              Don’t have an account?{" "}
              <span
                onClick={handleOpenDialog}
                className="login_dialog_signup_link"
                style={{ cursor: "pointer" }}
              >
                Sign Up
              </span>
            </p>
          </Box>
        </form>
      </WebDialog>
    </>
  );
};

export default WebLoginDialog;
