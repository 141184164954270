import React, { useEffect, useState } from "react";
import EventCardWebsite from "../../organism/eventCardWebsite";
import { EventCard } from "./types/eventCard";
import PastEventSection from "../../organism/pastEventSection";
import AcknowledgementSection from "../../organism/acknowledgementSection";
import useHttp from "../../../utils/hooks/useHttp";
import Loader from "../../atoms/loader";
import moment from "moment";
import { Box } from "@mui/material";
import "./style.css";

const AllEvents = () => {
  const [eventsData, setEventsData] = useState<any>([]);
  const { loading, error, sendRequestWithoutToken } = useHttp(transformData);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const reqOptions = {
      url: `/event_managments`,
      method: "GET",
      params: {
        tab: "both_events",
        // page: 1,
        // per_page: 6,
      },
    };

    sendRequestWithoutToken(reqOptions);
  }, []);

  function transformData(data: any) {
    if (data.status) {
      const eventData = data.events;
      eventData.sort((a: any, b: any) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
  
        // Compare dates first
        if (dateA < dateB) return -1;
        if (dateA > dateB) return 1;
  
        // If dates are equal, compare times
        const timeA = a.start_date.split(':').map(Number);
        const timeB = b.start_date.split(':').map(Number);
  
        if (timeA[0] < timeB[0]) return -1;
        if (timeA[0] > timeB[0]) return 1;
        if (timeA[1] < timeB[1]) return -1;
        if (timeA[1] > timeB[1]) return 1;
  
        // Dates and times are equal
        return 0;
      });
      const eventsArr = [];
      const today = new Date();
      const upcomingDate = new Date();
      upcomingDate.setDate(today.getDate() + 15);
      for (const event of eventData) {
        const obj = {
          id: event.id,
          title: event.title,
          locationLink: `${event.location}`,
          location: `${event.venue}`,
          time: `${moment.utc(event.date).format("MMMM D YYYY")}, 
          ${event.start_date}`,
          artists: event.artists,
          date: event?.date,
          schedule: event?.schedule_flag,
          url: event?.url,
          event_type: event?.event_type,
          sub_type: event?.sub_type,
          status:
            event.start_date > today && event.start_date < upcomingDate
              ? "upcoming"
              : "",
        };
        eventsArr.push(obj);
      }
      setEventsData(eventsArr);
    }
  }

  return (
    <>
      {loading && <Loader />}
      <Box className="all_events_page_top">
        {eventsData.map((event: EventCard, i: number) => {
          return <EventCardWebsite data={event} key={i} />;
        })}
      </Box>
      <PastEventSection />
      {/* <AcknowledgementSection /> */}
    </>
  );
};

export default AllEvents;
