import React from "react";
import UserManagement from "../../templates/userManagement";
import Layout from "../../organism/layout";

const UserManagementPage = () => {
  return (
    <Layout pageName="User Management" menuActive="User Management">
      <UserManagement />
    </Layout>
  );
};

export default UserManagementPage;
