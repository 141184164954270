import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ProtectedRouteUser = (props: { children: any }) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const checkUserToken = () => {
    const userToken = localStorage.getItem("web_token");
    if (!userToken || userToken === "undefined") {
      setIsLoggedIn(false);
      return navigate("/");
    }
    setIsLoggedIn(true);
  };

  useEffect(() => {
    checkUserToken();
  });

  return isLoggedIn && props.children;
};
export default ProtectedRouteUser;
