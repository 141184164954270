import React from "react";
import { Box } from "@mui/material";
import EditImg from "./images/edit.svg";
import DeleteImg from "./images/delete.svg";
import { PropType } from "./types/propType";
import TextLabels from "../textLabels";

const ToggleMenu = ({
  handleClickOpenDeleteAll,
  handleClickOpenEdit,
  announcementScreen,
  gallery,
  editable
}: PropType) => {
  return (
    <Box
      className={
        announcementScreen ? "toggle_menu toggle_menu_right_pos" : "toggle_menu"
      }
    >
      <ul>
        {
          announcementScreen ?
            editable ?
              <li onClick={handleClickOpenEdit}>
                <img src={EditImg} />{" "}
                <TextLabels variant="h200" weight={500} color="#913713">
                  {gallery == true ? `Add` : `Edit`}
                </TextLabels>
              </li>
            : null
          : <li onClick={handleClickOpenEdit}>
              <img src={EditImg} />{" "}
              <TextLabels variant="h200" weight={500} color="#913713">
                {gallery == true ? `Add` : `Edit`}
              </TextLabels>
            </li>
        }
        <li onClick={handleClickOpenDeleteAll}>
          <img src={DeleteImg} />{" "}
          <TextLabels variant="h200" weight={500} color="#7E8085">
            Delete
          </TextLabels>
        </li>
      </ul>
    </Box>
  );
};

export default ToggleMenu;
