import React from "react";
import DividerImg from "./images/divider.svg";
import { Box } from "@mui/material";
import "./style.css";

const TitleDivider = ({ children }: { children: any }) => {
  return (
    <Box>
      {children}
    </Box>
  );
};

export default TitleDivider;
