import React,{useEffect, useRef, useState} from "react";
import TextLabels from "../../atoms/textLabels";
import Grids from "../../atoms/grids";
import { CardTypes } from "./types/cardTypes";
import { Box, Button } from "@mui/material";
import GraphImg from "./images/graph.png";
import "./style.css";
import useHttp from "../../../utils/hooks/useHttp";
import { ReqOptions } from "../../../utils/types/reqOptions";
// @ts-ignore
import Chart from 'chart.js/auto';

const items: CardTypes[] = [
  {
    heading: "Total Tickets Sold",
    numbers: 560,
    stats: 13,
    statType: "+",
    timeline: "month to date",
  },
  {
    heading: "Average Ticket Sold",
    numbers: 120,
    stats: 1.1,
    statType: "+",
    timeline: "month to date",
  },
  {
    heading: "Upcoming Events",
    numbers: 8,
    stats: 5,
    statType: "-",
    timeline: "month to date",
  },
  {
    heading: "New Members",
    numbers: 524,
    stats: 31,
    statType: "-",
    timeline: "month to date",
  },
];



const Dashboard = () => {
  const [overViewData, setOverViewData] = useState<any>()
  const [graphLength, setGraphLength] = useState<any>('days')
  const [dayData, setDayData] = useState<any>()
  const userData = localStorage.getItem('user') as any;
  const user = typeof userData!=='undefined' ? JSON.parse(userData) : ""
  function transformData(data: any) {
    localStorage.setItem('settings', JSON.stringify(data));
  }
  const transformDataOverview = (data:any) => {
    if(data) {
    const Arr = [{
      heading: "Total Tickets Sold",
      numbers: data?.TotalTicket?.total_ticket,
      stats: data?.TotalTicket?.total_ticket_percentage[0] == '-' ? data?.TotalTicket?.total_ticket_percentage.substr(1) : data?.TotalTicket?.total_ticket_percentage,
      statType: data?.TotalTicket?.total_ticket_percentage[0] == '-' ? '-' : '+',
      timeline: "month to date",
    },
    {
      heading: "Average Ticket Sold",
      numbers: data?.AverageTicket?.average_ticket,
      stats: data?.TotalTicket?.total_ticket_percentage[0] == '-' ? data?.AverageTicket?.average_ticket_percentage.substr(1) : data?.TotalTicket?.total_ticket_percentage,
      statType: data?.TotalTicket?.total_ticket_percentage[0] == '-' ? '-' : '+',
      timeline: "month to date",
    },
    {
      heading: "Upcoming Events",
      numbers: data?.UpcomingEvents?.upcoming_events,
      stats: data?.UpcomingEvents?.upcoming_events_percentage[0] == '-' ? data?.UpcomingEvents?.upcoming_events_percentage.substr(1) : data?.UpcomingEvents?.upcoming_events_percentage,
      statType: data?.UpcomingEvents?.upcoming_events_percentage[0] == '-' ? '-' : '+',
      timeline: "month to date",
    },
    {
      heading: "New Members",
      numbers: data?.Membership?.membership,
      stats: data?.Membership?.membership_events_percentage[0] == '-' ? data?.Membership?.membership_events_percentage.substr(1) : data?.Membership?.membership_events_percentage,
      statType: data?.Membership?.membership_events_percentage[0] == '-' ? '-' : '+',
      timeline: "month to date",
    }]
    setOverViewData(Arr)
  }
  }
  const transformGraphData = (data:any) => {
    if(graphLength == 'days'){
    setDayData(data.DAYS)
    }else if(graphLength == 'months') {
      const arr:any = []
      Object.keys(data?.Monthly_Ticket_Count).forEach((key,i)=>{
        const obj = {
          DAY: key,
          ticketsSold: data?.Monthly_Ticket_Count[key][0]
        }
        arr.push(obj)
      })
      setDayData(arr)
    } else {
      const arr:any = []
      Object.keys(data?.Year_Ticket_Count).forEach((key,i)=>{
        const obj = {
          DAY: key,
          ticketsSold: data?.Year_Ticket_Count[key]
        }
        arr.push(obj)
      })
      setDayData(arr)
    }
  }
  const { loading, error, sendRequest: sendRequest } = useHttp(transformDataOverview);
  const { loading: load, error: err, sendRequest: sendRequestData } = useHttp(transformData);
  const { loading: loadDays, error: errDays, sendRequest: sendRequestGraph } = useHttp(transformGraphData);

  const [dashboardItems, setDashboardItems] = useState<any>(null)

  useEffect(()=>{
    if(overViewData){
    setDashboardItems({
      gridType: "cards",
      items: overViewData,
    })
  }
  }, [overViewData])

  useEffect(() => {
    const requestOptions: ReqOptions = {
      url: `/getSettings`,
      method: "GET",
      params: {
        user_id:user?.id
      }
    };

    sendRequestData(requestOptions);
  }, [])

  useEffect(() => {
    const requestOptions: ReqOptions = {
      url: `/dashboard/get_overview`,
      method: "GET",
    };

    sendRequest(requestOptions);
  }, [])

  useEffect(() => {
    if(graphLength == 'days') { 
    const requestOptions: ReqOptions = {
      url: `/dashboard/get_days_graph_data`,
      method: "GET",
    };

    sendRequestGraph(requestOptions);
  }else if (graphLength == 'months') {
    const requestOptions: ReqOptions = {
      url: `/dashboard/get_month_graph_data`,
      method: "GET",
    };

    sendRequestGraph(requestOptions);
  } else {
    const requestOptions: ReqOptions = {
      url: `/dashboard/get_year_graph_data`,
      method: "GET",
    };

    sendRequestGraph(requestOptions);
  }
  }, [graphLength])

  const chartRef:any = useRef(null);

  const ticketData:any = [];

  for (let day = 1; day <= 30; day++) {
    const ticketsSold = Math.floor(Math.random() * 100); // Random number of tickets sold for each day
    ticketData.push({ day, ticketsSold });
  }


  useEffect(() => {
    let chartInstance:any = null;

    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');

      if (chartInstance) {
        chartInstance.destroy();
      }

      chartInstance = new Chart(ctx, {
        type: 'line',
        data: {
          labels: dayData?.map((day:any) => day.DAY),
          datasets: [
            {
              label: 'Tickets Sold',
              data: dayData?.map((day:any) => day.ticketsSold),
              borderColor: '#17a34a',
              fill: true,
              backgroundColor: '#a7e1bf',
            },
          ],
        },
        options: {
          scales: {
            x: {
              title: {
                display: true,
                text: graphLength == 'days' ? 'Days of the Month' : graphLength == 'months' ? 'Months of the Year' : "Years",
              },
            },
            y: {
              title: {
                display: true,
                text: 'Number of Tickets Sold',
              },
            },
          },
        },
      });
    }

    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [ticketData]);
  return (
    <>
      <Box
        sx={{
          paddingBottom: "30px",
        }}
      >
        <TextLabels variant="h400" weight={500} fontFamily="Poppins">
          Overview
        </TextLabels>
      </Box>
      {dashboardItems && <Grids
        items={dashboardItems}
        spacing={4}
        size={3}
        sizeMd={6}
        sizeSm={6}
        sizeM={12}
      />}
      <Box style={{
        display: 'flex',
        marginTop: "10px",
      }}>
        <Button onClick={()=>{setGraphLength('days')}} style={{color: "#000", fontWeight: '400'}}>Day</Button>
        <Button onClick={()=>{setGraphLength('months')}} style={{color: "#000", fontWeight: '400'}}>Month</Button>
        <Button onClick={()=>{setGraphLength('years')}} style={{color: "#000", fontWeight: '400'}}>Year</Button>
      </Box>
      <Box
        sx={{
          paddingTop: "15px",
        }}
      >
        {/* <img src={GraphImg} className="graph_img" /> */}
        <canvas ref={chartRef} />
      </Box>
    </>
  );
};

export default Dashboard;
