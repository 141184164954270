import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import SectionHeading from "../../molecules/sectionHeading";
import WebsiteArtistImage from "../../atoms/websiteArtistImage";
import Painting1 from "./images/painting1.png";
import Painting2 from "./images/painting2.png";
import { Button } from "../../atoms/buttons";
import CardSection from "../../molecules/cardSection";
import { Link } from "react-router-dom";
import ArrowImg from "./images/arrow.svg";
import ArtImg1 from "./images/art1.png";
import ArtImg2 from "./images/art2.png";
import ArtImg3 from "./images/art3.png";
import CDImg from "./images/cd.svg";
import "./style.css";
import Loader from "../../atoms/loader";

const eventData = [
  {
    image: ArtImg1,
    title: "Ragamala Music and Dance Theater",
  },
  {
    image: ArtImg2,
    title: "Pangea World Theater",
  },
  {
    image: ArtImg3,
    title: "Katha Dance Theater",
  },
];



const MusicMasala = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // loading screen 
  const  [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000)
  },[])

  return (
    <Box>
      {loading && <Loader />}
      <Box className="music_masala_top">
        <SectionHeading title="Music Masala" />
        <p className="music_masala_subtitle">
          All exciting things related to Indian music here. Watch this space for
          more.
        </p>
      </Box>
      <Box className="music_masala_artist_images">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="music_masala_artist_image">
              <WebsiteArtistImage image={Painting1} />
              <p className="music_masala_box">Terms used in Indian music</p>
              <Button variant="outlined" size="s" color="info" btnClass="know_more_button">
                Learn more
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="music_masala_artist_image">
              <WebsiteArtistImage image={Painting2} />
              <p>Notes from Tabla Classes</p>
              <Button
                variant="outlined"
                size="s"
                color="info"
                btnClass="know_more_button"
              >
                Learn more
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="music_masala_article_section">
        <CardSection>
          <Box className="music_masala_article_section_content">
            <Box className="heading">Articles on Indian classical music</Box>
            <h6>Essays on Ragas</h6>
            <p>
              Learn more about the alphabets of Indian music to reach
              enlightenment
            </p>
            <Link to="/" className="music_masala_article_link">
              Let’s Go
              <img src={ArrowImg} />
            </Link>
          </Box>
          if user only enter space on input fields then validation message
          should display
        </CardSection>
      </Box>
      <Box className="music_masala_web_section">
        <SectionHeading title="From the Web" />
        <p>Curated section based on Indian music from the web</p>
      </Box>
      <Box className="music_masala_indian_art">
        <Box className="heading">Indian Art Organisations</Box>
        <Box className="past_event_box">
          <Grid container spacing={2} className="event_grid_container">
            {eventData.map((event, i) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  className="event_grid"
                  key={i}
                >
                  <Link to="/event/1">
                    <Box className="event_content">
                      <Box className="event_image">
                        <img src={event.image} />
                      </Box>
                      <Box className="event_text">
                        <h6 className="event_title">{event.title}</h6>
                      </Box>
                    </Box>
                  </Link>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
      {/* <Box className="music_masala_buy_cd">
        <h4>Buy Indian classical music online</h4>
        <Grid container className="music_masala_buy_cd_grid">
          <Grid item xs={12} sm={12} md={4}>
            <Box className="music_masala_buy_cd_box">
              <img src={CDImg} />
              <p>Shrimati's</p>
              <Link to="/" className="music_masala_cd_link">
                Let’s Go
                <img src={ArrowImg} />
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Box className="music_masala_buy_cd_box">
              <img src={CDImg} />
              <p>Neelam Audio and Video Inc</p>
              <Link to="/" className="music_masala_cd_link">
                Let’s Go
                <img src={ArrowImg} />
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Box className="music_masala_buy_cd_box">
              <img src={CDImg} />
              <p>Music Archive by Rajan Parrikar</p>
              <Link to="/" className="music_masala_cd_link">
                Let’s Go
                <img src={ArrowImg} />
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Box> */}
      <Box className="music_masala_other_sites">
        <h4>Explore other Sites</h4>
        <Box className="music_masala_other_site_grid">
          <Grid container>
            <Grid item xs={12} md={6}>
              <Box className="music_masala_other_sites_box music_masala_flex_end music_masala_border_right">
                <h6>Shrishti’s Carnatica</h6>
                <p>Home of Carnatic Music</p>
                <Link to="/" className="music_masala_other_site_link">
                  Let’s Go
                  <img src={ArrowImg} />
                </Link>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className="music_masala_other_sites_box music_masala_flex_start music_masala_box_two">
                <h6>ITC Sangeet</h6>
                <p>Research Academy</p>
                <Link to="/" className="music_masala_other_site_link">
                  Let’s Go
                  <img src={ArrowImg} />
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default MusicMasala;
