import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/system";
import TabBar from "../../atoms/tabs";
import TextLabels from "../../atoms/textLabels";
import { Grid } from "@mui/material";
import Switch from "@mui/material/Switch";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../atoms/buttons";
import ModalPopup from "../../atoms/modalPopup";
import { Input } from "../../atoms/inputs";
import ErrorMessage from "../../atoms/errorMessage";
import useHttp from "../../../utils/hooks/useHttp";
import { ReqOptions } from "../../../utils/types/reqOptions";
import AlertCmp from "../../atoms/alert";
import CircularProgress from '@mui/material/CircularProgress';
import Loader from "../../atoms/loader";
import { useDispatch } from "react-redux";
import { logout } from "../../../utils/store/slices/authSlice";
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';

const label = { inputProps: { "aria-label": "Switch demo" } };

const SettingTemplate = () => {
  const { loading, error, sendRequest } = useHttp(transformData);
  const settingData = localStorage.getItem('settings') as any;
  const setting = typeof settingData!=='undefined' ? JSON.parse(settingData) : ""
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [successMsg, setSuccessMsg] = useState('');
  const [successMsg1, setSuccessMsg1] = useState('');
  const [msgType, setMsgType] = useState(false);
  const [loader, setLoader] = useState(false)
  const ref = React.createRef() as any;
  const [activeTab, setActiveTab] = useState<any>();
  const [passwordChange, setPasswordChange] = useState(false);
  const [openBlock, setOpenBlock] = useState(false);
  const [showCorrectPassword, setShowCorrectPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [correctPwd, setCorrectPwd] = useState("");
  const [newPwd, setNewPwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [correctPwdError, setCorrectPwdError] = useState("");
  const [newPwdError, setNewPwdError] = useState("");
  const [confirmPwdError, setConfirmPwdError] = useState("");
  const userData = localStorage.getItem('user') as any;
  const user = typeof userData!=='undefined' ? JSON.parse(userData) : ""
  const pwdRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
  const [count,setCount] = useState(1)
  const [settingsObj,setSettingsObj] = useState({
    comment_push: setting?.comment_push ? setting?.comment_push : false,
    comment_email: setting?.comment_email ? setting?.comment_email : false,
    event_push: setting?.event_push ? setting?.event_push : false,
    event_email: setting?.event_email ? setting?.event_email : false,
    ticket_push: setting?.ticket_push ? setting?.ticket_push : false,
    ticket_email: setting?.ticket_email ? setting?.ticket_email : false
  })
  function transformData(data: any) {
    setCount(count+1);
    //if(activeTab == "security"){
      if(data?.status){
        activeTab == "security" ? setSuccessMsg(data?.message) : setSuccessMsg1(data?.message)
        setLoader(false)
        setMsgType(true)
        if(activeTab == "security"){
          setTimeout(()=>{
            handleCloseBlock()
            dispatch(logout());
            navigate("/login", { replace: true });
          },2000)
        }
        if(activeTab == 'notifications'){
           localStorage.setItem('settings',JSON.stringify({
            comment_push: settingsObj.comment_push,
            comment_email: settingsObj.comment_email,
            event_push: settingsObj.event_push,
            event_email: settingsObj.event_email,
            ticket_push: settingsObj.ticket_push,
            ticket_email: settingsObj.ticket_email
           }))
        }
        
      }else{
       
       if(data?.message){
        activeTab == "security" ? setSuccessMsg(data?.message) : setSuccessMsg1(data?.message)
         setLoader(false)
         setMsgType(false)
       }
       
      }
    //}
    
    
    
  }
  const handleClickOpenBlock = () => {
    setSuccessMsg('')
    setOpenBlock(true);
    setCorrectPwdError('')
    setNewPwdError('')
    setConfirmPwdError('')
  };

  const handleCloseBlock = () => {
    setOpenBlock(false);
  };
  const tabs: any[] = [
    // {
    //   value: "notifications",
    //   label: "Notifications",
    // },
    {
      value: "security",
      label: "Security",
    },
  ];
  useEffect(()=> {
    setActiveTab("security")
  }, [])
  const getTabState = () => {
    //alert(ref?.current?.getMyState())
  };
  useEffect(() => {
  }, [activeTab]);

  const handleTabChange = (tabNo: any) => {
    setSuccessMsg('')
    setSuccessMsg1('')
    setActiveTab(tabNo);
  };

  const handleChangePassword = () => {
    setSuccessMsg('')
    setLoader(false)
    setMsgType(false)
    if (!correctPwd) {
      //setCorrectPwdError("The password you entered is incorrect.");
      setCorrectPwdError("Enter your current password.");
      return;
    } else {
      setCorrectPwdError("");
    }
    if (!newPwd) {
      setNewPwdError("Enter your new password.");
      return;
    }else if(!(pwdRegex.test(newPwd))){
      setNewPwdError('Minimum 8 characters are allowed and use atleast one uppercase letter, one lowercase letter, one number and one special character.');
      return;
    }
    else if(newPwd == correctPwd){
      setNewPwdError("Old password and new password can't be same");
      return;
    } 
    else {
      setNewPwdError("");
    }
    if (!confirmPwd) {
      setConfirmPwdError("Enter your confirm password.");
      return;
    } else {
      setConfirmPwdError("");
    }
    if (confirmPwd != newPwd) {
      setConfirmPwdError("This password doesn’t match the new password. ");
      return;
    } else {
      setConfirmPwdError("");
    }
    const requestOptions: ReqOptions = {
      url: `/users/update_password`,
      method: "POST",
      data:{
        old_password: correctPwd,
        new_password: newPwd,
        confirm_password: confirmPwd,
      }
    };

    sendRequest(requestOptions);
    setLoader(true)
  };

  useEffect(() => {
    setSuccessMsg1('')
    setLoader(false)
    setMsgType(false)
    const requestOptions: ReqOptions = {
      url: `/updateSettings`,
      method: "POST",
      params: {
        user_id:user?.id,
        event_id:2,
        comment_push: settingsObj.comment_push,
        comment_email: settingsObj.comment_email,
        event_push: settingsObj.event_push,
        event_email: settingsObj.event_email,
        ticket_push: settingsObj.ticket_push,
        ticket_email: settingsObj.ticket_email
      }
    };

    sendRequest(requestOptions);
    setLoader(true)
  }, [settingsObj])

  useEffect(() => {
    if(error){
      setLoader(false)
    }
    
  }, [error])
  

  return (
    <>
    {
      (activeTab=='notifications' && loader) && <Loader/>
    }
    
    {(successMsg1 && activeTab=='notifications' && count>2) && (
        <AlertCmp type={msgType?'success':'error'} position="dashboard">
          {successMsg1}
        </AlertCmp>
      )}
      <TabBar
        tabs={tabs}
        defaultValue="notifications"
        handleClick={handleTabChange}
      />
      {activeTab == "security" ? (
        <Box
          sx={{
            background: "#FFFFFF",
            boxShadow:
              "0px 0px 2px rgba(0, 0, 0, 0.06), 0px 3px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "4px",
            padding: "24px 24px 0px 24px",
          }}
        >
          <Box
            className="profileData allSettings"
            sx={{
              borderBottom: "1px solid #D5D7DB",
              paddingBottom: "10px",
              marginBottom: "10px",
            }}
          >
            <p>
              <TextLabels variant="h300" color="#2C2D30" weight={500}>
                Password
              </TextLabels>
            </p>
            <p>
              <TextLabels variant="h200" color="#7E8085" weight={400}>
                These settings relate to password changes
              </TextLabels>
            </p>
            {/* <p style={{ paddingLeft: "10px" }}>
              <TextLabels variant="h200" color="#404145" weight={500}>
                Prompt Password change{" "}
                <Switch
                  {...label}
                  onChange={() => setPasswordChange(!passwordChange)}
                />
              </TextLabels>
              {passwordChange && (
                <>
                  <p>
                    <TextLabels variant="h200" color="#7E8085" weight={400}>
                      Ask for admin to change password every
                    </TextLabels>
                    <select value="3 months">
                      <option value="1 month">1 month</option>
                      <option value="3 months">3 months</option>
                      <option value="6 months">6 months</option>
                      <option value="1 year">1 year</option>
                    </select>
                  </p>
                  <p>
                    <TextLabels variant="h200" color="#7E8085" weight={400}>
                      Ask for users to change password every
                    </TextLabels>
                    <select value="3 months">
                      <option value="1 month">1 month</option>
                      <option value="3 months">3 months</option>
                      <option value="6 months">6 months</option>
                      <option value="1 year">1 year</option>
                    </select>
                  </p>
                </>
              )}
            </p> */}
          </Box>
          <Box
            className="profileData allSettings"
            sx={{ paddingBottom: "10px", marginBottom: "10px" }}
          >
            <p>
              <TextLabels variant="h200" color="#7E8085" weight={400}>
                Want to update your password for enhanced security and account
                protection?
              </TextLabels>
            </p>
            <p>
              <TextLabels variant="h200" color="#913713" weight={400}>
                <Button
                  type="button"
                  color="error"
                  variant="contained"
                  sx={{
                    color: "#913713",
                    backgroundColor: "#FFFFFF",
                    fontSize: "14px",
                    borderBottom: "1px solid #913713",
                    padding: "0px !important",
                    borderRadius: "0px !important",
                    "&:hover": {
                      color: "#913713",
                      backgroundColor: "#FFFFFF",
                    },
                  }}
                  handleClick={handleClickOpenBlock}
                >
                  Change password
                </Button>
              </TextLabels>
            </p>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            background: "#FFFFFF",
            boxShadow:
              "0px 0px 2px rgba(0, 0, 0, 0.06), 0px 3px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "4px",
            padding: "24px",
          }}
        >
          <TextLabels variant="h200" color="#7E8085" weight={400}>
            Get alerts for information you deem important directly to your
            dashboard.
          </TextLabels>
          <Grid
            container
            className="notifications-grid"
            style={{
              borderBottom: "1px solid #D5D7DB",
              padding: "16px",
            }}
          >
            <Grid
              item
              xs={8}
              sm={9}
              md={9}
              sx={{ height: "72px", marginTop: "5px !important" }}
            >
              <Box 
              className="profileData allSettings"
              >
                <p>
                  <TextLabels variant="h200" color="#404145" weight={500}>
                    Comments
                  </TextLabels>
                </p>
                <p>
                  <TextLabels variant="h200" color="#7E8085" weight={400}>
                    These are notifications related to comments on your{" "}
                    <p>events.</p>
                  </TextLabels>
                </p>
              </Box>
            </Grid>
            <Grid
              item
              xs={4}
              sm={3}
              md={3}
              sx={{
                minHeight: "104px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <Box sx={{ float: "right" }} className="setting-on-off">
                <Switch checked={settingsObj?.comment_push} onChange={(e)=>setSettingsObj({...settingsObj,comment_push:!settingsObj?.comment_push})} {...label}  />{" "}
                <TextLabels variant="h200" color="#7E8085" weight={400}>
                  Push
                </TextLabels>
              </Box>

              <Box sx={{ float: "right" }} className="setting-on-off">
                <Switch checked={settingsObj?.comment_email} onChange={(e)=>setSettingsObj({...settingsObj,comment_email:!settingsObj?.comment_email})} {...label} />{" "}
                <TextLabels variant="h200" color="#7E8085" weight={400}>
                  Email
                </TextLabels>
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            className="notifications-grid"
            style={{
              borderBottom: "1px solid #D5D7DB",
              padding: "16px",
            }}
          >
            <Grid
              item
              xs={8}
              sm={9}
              md={9}
              sx={{ height: "72px", marginTop: "5px !important" }}
            >
              <Box className="profileData allSettings">
                <p>
                  <TextLabels variant="h200" color="#404145" weight={500}>
                    Events
                  </TextLabels>
                </p>
                <p>
                  <TextLabels variant="h200" color="#7E8085" weight={400}>
                    These are notificatios for when an event goes live{" "}
                    <p>on the website.</p>
                  </TextLabels>
                </p>
              </Box>
            </Grid>
            <Grid
              item
              xs={4}
              sm={3}
              md={3}
              sx={{
                minHeight: "104px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <Box className="setting-on-off">
                <Switch checked={settingsObj?.event_push} onChange={(e)=>setSettingsObj({...settingsObj,event_push:!settingsObj?.event_push})} {...label}  />{" "}
                <TextLabels variant="h200" color="#7E8085" weight={400}>
                  Push
                </TextLabels>
              </Box>

              <Box className="setting-on-off">
                <Switch checked={settingsObj?.event_email} onChange={(e)=>setSettingsObj({...settingsObj,event_email:!settingsObj?.event_email})} {...label} />{" "}
                <TextLabels variant="h200" color="#7E8085" weight={400}>
                  Email
                </TextLabels>
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            className="notifications-grid"
            style={{
              padding: "16px 16px 0px 16px",
            }}
          >
            <Grid
              item
              xs={8}
              sm={9}
              md={9}
              sx={{ height: "72px", marginTop: "5px !important" }}
            >
              <Box className="profileData allSettings">
                <p>
                  <TextLabels variant="h200" color="#404145" weight={500}>
                    Ticket
                  </TextLabels>
                </p>
                <p>
                  <TextLabels variant="h200" color="#7E8085" weight={400}>
                    These are notifications related to ticket sale{" "}
                    <p>pertaining to individual events.</p>
                  </TextLabels>
                </p>
              </Box>
            </Grid>
            <Grid
              item
              xs={4}
              sm={3}
              md={3}
              sx={{
                minHeight: "104px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <Box sx={{ float: "right" }} className="setting-on-off">
                <Switch checked={settingsObj?.ticket_push} onChange={(e)=>setSettingsObj({...settingsObj,ticket_push:!settingsObj?.ticket_push})} {...label} />{" "}
                <TextLabels variant="h200" color="#7E8085" weight={400}>
                  Push
                </TextLabels>
              </Box>

              <Box sx={{ float: "right" }} className="setting-on-off">
                <Switch checked={settingsObj?.ticket_email} onChange={(e)=>setSettingsObj({...settingsObj,ticket_email:!settingsObj?.ticket_email})} {...label} />{" "}
                <TextLabels variant="h200" color="#7E8085" weight={400}>
                  Email
                </TextLabels>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
      <Box style={{ marginTop: "20px", height: "200px" }}></Box>
      <ModalPopup
        open={openBlock}
        handleClickOpen={handleClickOpenBlock}
        handleClose={handleCloseBlock}
        heading="Change Password"
        closeBtn={true}
        primaryAction={() => {}}
        content={
          <>
            <Box component="form" noValidate sx={{ mt: 1 }}>
              <Box sx={{ margin: "25px 0" }}>
                <Box
                  sx={{
                    position: "relative",
                    margin: "10px 0px",
                  }}
                >
                  <TextLabels variant="h5" color="#2C2D30" weight={700}>
                    Current password
                  </TextLabels>
                </Box>

                <Box sx={{ position: "relative" }}>
                  <Input
                    id="standard-number"
                    name="password"
                    label="Password"
                    type={showCorrectPassword ? "text" : "password"}
                    placeholder="Enter current password here"
                    characterLimit={25}
                    handleChange={(e: any) => {
                      setCorrectPwd(e.target.value);
                    }}
                    sx={{
                      width: "100%",
                      backgroundColor: "#ffffff",
                      borderRadius: "4px",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "16px",
                    }}
                  />
                  {showCorrectPassword ? <Box
                  style={{ position: "absolute", right: "9px", top: "13px", cursor: 'pointer' }}
                  onClick={() => {
                    setShowCorrectPassword(!showCorrectPassword);
                  }}><VisibilityOff /></Box> : 
                  <Box
                  style={{ position: "absolute", right: "9px", top: "13px", cursor: 'pointer' }}
                  onClick={() => {
                    setShowCorrectPassword(!showCorrectPassword);
                  }}><Visibility /></Box>}
                  {/* <img
                    style={{ position: "absolute", right: "9px", top: "13px" }}
                    src="icons/password.png"
                    onClick={() => {
                      setShowCorrectPassword(!showCorrectPassword);
                    }}
                  /> */}
                </Box>
                {correctPwdError && (
                  <ErrorMessage>{correctPwdError}</ErrorMessage>
                )}
              </Box>
              <Box sx={{ margin: "20px 0" }}>
                <Box
                  sx={{
                    position: "relative",
                    margin: "10px 0px",
                  }}
                >
                  <TextLabels variant="h5" color="#2C2D30" weight={700}>
                    New password
                  </TextLabels>
                </Box>

                <Box sx={{ position: "relative" }}>
                  <Input
                    id="standard-number"
                    name="password"
                    label="Password"
                    type={showNewPassword ? "text" : "password"}
                    placeholder="Enter new password here"
                    characterLimit={25}
                    handleChange={(e: any) => {
                      setNewPwd(e.target.value);
                      pwdRegex.test(e.target.value) && setNewPwdError('')
                    }}
                    sx={{
                      width: "100%",
                      backgroundColor: "#ffffff",
                      borderRadius: "4px",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "16px",
                    }}
                  />
                  {showNewPassword ? <Box
                  style={{ position: "absolute", right: "9px", top: "13px", cursor: 'pointer' }}
                  onClick={() => {
                    setShowNewPassword(!showNewPassword);
                  }}><VisibilityOff /></Box> : 
                  <Box
                  style={{ position: "absolute", right: "9px", top: "13px", cursor: 'pointer' }}
                  onClick={() => {
                    setShowNewPassword(!showNewPassword);
                  }}><Visibility /></Box>}
                  {/* <img
                    style={{ position: "absolute", right: "9px", top: "13px" }}
                    src="icons/password.png"
                    onClick={() => {
                      setShowNewPassword(!showNewPassword);
                    }}
                  /> */}
                </Box>
                {newPwdError && <ErrorMessage>{newPwdError}</ErrorMessage>}
              </Box>

              <Box sx={{ margin: "20px 0" }}>
                <Box
                  sx={{
                    position: "relative",
                    margin: "10px 0px",
                  }}
                >
                  <TextLabels variant="h5" color="#2C2D30" weight={700}>
                    Confirm new password
                  </TextLabels>
                </Box>

                <Box sx={{ position: "relative" }}>
                  <Input
                    id="standard-number"
                    name="password"
                    label="Password"
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Enter here"
                    characterLimit={25}
                    handleChange={(e: any) => {
                      setConfirmPwd(e.target.value);
                    }}
                    sx={{
                      width: "100%",
                      backgroundColor: "#ffffff",
                      borderRadius: "4px",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "16px",
                    }}
                  />
                  {showConfirmPassword ? <Box
                  style={{ position: "absolute", right: "9px", top: "13px", cursor: 'pointer' }}
                  onClick={() => {
                    setShowConfirmPassword(!showConfirmPassword);
                  }}><VisibilityOff /></Box> : 
                  <Box
                  style={{ position: "absolute", right: "9px", top: "13px", cursor: 'pointer' }}
                  onClick={() => {
                    setShowConfirmPassword(!showConfirmPassword);
                  }}><Visibility /></Box>}
                  {/* <img
                    style={{ position: "absolute", right: "9px", top: "13px" }}
                    src="icons/password.png"
                    onClick={() => {
                      setShowConfirmPassword(!showConfirmPassword);
                    }}
                  /> */}
                </Box>
                {confirmPwdError && (
                  <ErrorMessage>{confirmPwdError}</ErrorMessage>
                )}
              </Box>

              <Box
                sx={{
                  margin: "25px 0",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  disable={loader||(!correctPwd||!newPwd||!confirmPwd)?true:false}
                  sx={{
                    backgroundColor: "#913713;",
                    borderRadius: "8px",
                    fontSize: "14px",
                    padding: "20px 14px !important",
                    "&:hover": {
                      backgroundColor: "#913713;",
                    },
                  }}
                  type="button"
                  handleClick={handleChangePassword}
                  btnClass="loginSubmitBtn changePwdBtn"
                  color="error"
                  variant="contained"
                >
                  Save Changes
                </Button>
                {
                    loader && <CircularProgress className='button-loader' color="success" />
                }
              </Box>
              {(successMsg && count>2) && (
                <AlertCmp type={msgType?'success':'error'} >
                  {successMsg}
                </AlertCmp>
              )}
            </Box>
          </>
        }
      />
    </>
  );
};

export default SettingTemplate;
