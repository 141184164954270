import React from "react";
import Home from "../../templates/home";
import WebLayout from "../../organism/webLayout";
import { Box } from "@mui/material";
import "./style.css";

const HomePage = () => {
  return <Home/>;
};

export default HomePage;
