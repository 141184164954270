import React, { useState, useEffect, useContext, useRef } from "react";
import Layout from "../../organism/layout";
import InputsWithLabel from "../../molecules/inputsWithLabel";
import { Box, Grid } from "@mui/material";
import { Button } from "../../atoms/buttons";
import TextLabels from "../../atoms/textLabels";
import { Input } from "../../atoms/inputs";
import { EventContext } from "../../organism/wizardSteps";
import useHttp from "../../../utils/hooks/useHttp";
import { ReqOptions } from "../../../utils/types/reqOptions";
import Loader from "../../atoms/loader";
import AlertCmp from "../../atoms/alert";
import moment from "moment";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { PropType } from "./types/propTypes";
import ErrorMessage from "../../atoms/errorMessage";
import ModalBannerPopup from "../../atoms/modalBannerPopup";
import "./style.css";
import close from "./images/Close.svg";
import { setDate } from "date-fns";
import DropZone from "../../atoms/dropZone";

const Step1 = ({ handleStep, handleOpenPreviewPopup }: PropType) => {
  const [startTimeError, setStartTimeError] = useState<any>("");
  const { loading, error, sendRequest } = useHttp(transformData);
  const [responseMsg, setResponseMsg] = useState("");
  const [responseType, setResponseType] = useState<boolean>(false);
  const [validUrl, setValidUrl] = useState<boolean>(true);
  const [validVenue, setValidVenue] = useState<boolean>(true);
  const [eventDay, setEventDay] = useState<any>(null);
  const navigate = useNavigate();
  const { formData, handleFormDataInput } = useContext(EventContext);
  var stime: any[] = [];
  var etime: any[] = [];
  stime = formData?.event_managment?.start_date?.split(":");
  etime = formData?.event_managment?.end_date?.split(":");
  const [title, setTitle] = useState<string>(
    formData?.event_managment?.title ? formData?.event_managment?.title : ""
  );
  const [url, setUrl] = useState<string>(
    formData?.event_management?.link ? formData?.event_management?.link : ""
  );
  const routerLocation = useLocation();
  const [search, setSearch] = useSearchParams();
  const [errorMessage, setErrorMessage] = useState("");
  const [eventDate, setEventDate] = useState<any>(
    formData?.event_managment?.date
      ? new Date(formData?.event_managment?.date)
      : new Date()
  );
  const [dateStr, setDateStr] = useState<any>(formData?.event_managment?.date);
  const [duration, setDuration] = useState(
    formData?.event_managment?.duration
      ? formData?.event_managment?.duration
      : ""
  );
  const [venue, setVenue] = useState<string>(
    formData?.event_managment?.venue ? formData?.event_managment?.venue : ""
  );
  const [venueLink, setVenueLink] = useState<string>(
    formData?.event_managment?.location
      ? formData?.event_managment?.location
      : ""
  );
  const [eventId, setEventId] = useState(
    formData?.event_managment?.id ? formData?.event_managment?.id : null
  );
  const [startTimeBox, setStartTimeBox] = useState(false);
  const [startTimeHour, setStartTimeHour] = useState(
    formData?.event_managment?.start_date && stime?.length > 0
      ? stime[0] > 12
        ? stime[0] - 12
        : stime[0]
      : 0
  );
  const [startTimeMin, setStartTimeMin] = useState<any>(
    formData?.event_managment?.start_date && stime?.length > 0
      ? String(parseInt(stime[1]))
      : 0
  );
  const [endTimeBox, setEndTimeBox] = useState(false);
  const [endTimeHour, setEndTimeHour] = useState(
    formData?.event_managment?.end_date && etime?.length > 0
      ? etime[0] > 12
        ? etime[0] - 12
        : etime[0]
      : 0
  );
  const [endTimeMin, setEndTimeMin] = useState<any>(
    formData?.event_managment?.end_date && etime?.length > 0
      ? String(parseInt(etime[1]))
      : 0
  );
  const [startTimeFormat, setStartTimeFormat] = useState(
    stime && stime.length ? stime[1].substr(-2) : "AM"
  );
  const [endTimeFormat, setEndTimeFormat] = useState(
    etime && etime.length ? etime[1].substr(-2) : "AM"
  );
  const [startDate, setStartDate] = useState(new Date());
  const [endTimeError, setEndTimeError] = useState(false);
  const [currentTimeBolean, setCurrentTimeBolean] = useState(false);
  const [currentValidText, setValidText] = useState(false);
  const draftRef = useRef(false);
  const ref1 = useRef(null) as any;
  const ref2 = useRef(null) as any;

  useEffect(() => {
    if (dateStr != undefined) {
      const dateComponents = dateStr?.split("-");
      // Create a Date object using the components
      const date = new Date(
        parseInt(dateComponents[0], 10), // Year
        parseInt(dateComponents[1], 10) - 1, // Month (0-indexed)
        parseInt(dateComponents[2], 10) // Day
      );
      setEventDate(date);
    }
  }, [dateStr]);

  useEffect(() => {
    if (eventDate) {
      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const d = new Date(eventDate);
      const dayName = days[d.getDay()];
      setEventDay(dayName);
    }
  }, [eventDate]);

  function transformData(data: any) {
    if (data?.events?.id) {
      !eventId && setEventId(data?.events?.id);
      handleFormDataInput({ event_managment: data?.events });
    }
    if (data?.status) {
      setResponseMsg("Data saved successfully.");
      setResponseType(true);

      if (draftRef.current) {
        setTimeout(() => {
          navigate("/event-management");
        }, 1500);
      } else {
        setTimeout(() => {
          handleStep && handleStep("step2");
          if (!search.get("eid")) {
            navigate(`${routerLocation.pathname}?eid=${data?.events?.id}`, {
              replace: true,
            });
          }
        }, 1500);
      }
    }
  }

  const handleEventTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    // const regex = /[!@#$%^&*_+\-=\[\]{};':"\\|.<>\/?]+/;

    // Remove all non-word characters using regex
    const cleanedInputValue = inputValue.replace(/[^a-zA-Z]/g, "");
    const regex = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9])[\w\W]*$/;
    if (cleanedInputValue != "") {
      if (regex.test(cleanedInputValue)) {
        setErrorMessage("Please enter valid input");
      } else {
        setErrorMessage("");
      }
    } else {
      setErrorMessage("Please enter valid input");
    }
    if (inputValue.length > 100) {
      setErrorMessage("Title limit 100 characters");
    }

    setTitle(inputValue);
  };

  const isValidURL = (url: any) => {
    const pattern = /^(?:https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    return pattern.test(url || "");
  };

  const isNumericWithSpace = (str: any) => {
    // const pattern = /^[\d\s]+$/;
    // return pattern.test(str);
    // // // // return /^[0-9\s]+$/.test(str);
    const specialCharacterPattern1 =
      /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9])[\w\W]*$/;
    return specialCharacterPattern1.test(str);
  };

  const handleStep1Submit = (e: any, draft: boolean) => {
    setResponseMsg("");
    setResponseType(false);
    e.preventDefault();

    let errorFlag = false;
    if (errorMessage) return;
    if (!isValidURL(venueLink)) {
      errorFlag = true;
      setValidUrl(false);
      return;
    } else {
      errorFlag = false;
      setValidUrl(true);
    }
    const cleanedInputaValuea = venue.replace(/[^a-zA-Z]/g, "");
    const regexa = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9])[\w\W]*$/;
    if (cleanedInputaValuea != "") {
      if (regexa.test(cleanedInputaValuea)) {
        if (isNumericWithSpace(venue)) {
          errorFlag = true;
          setValidVenue(false);
        }
      } else {
        errorFlag = false;
        setValidVenue(true);
      }
    } else {
      errorFlag = true;
      setValidVenue(false);
    }

    if (!errorFlag) {
      const data: any = {
        event_managment: {
          draft:
            formData?.event_managment?.draft != null
              ? formData?.event_managment?.draft
              : true,
          title: title?.trim(),
          date: moment(eventDate).format("YYYY-MM-DD"),
          start_date: `${startTimeHour > 0 ? startTimeHour : "00"} : ${
            startTimeMin > 0 ? startTimeMin : "00"
          } ${startTimeFormat}`,
          end_date: `${endTimeHour > 0 ? endTimeHour : "00"} : ${
            endTimeMin > 0 ? endTimeMin : "00"
          } ${endTimeFormat}`,
          duration: duration,
          //duration:60,
          venue: venue?.trim(),
          location: venueLink,
          event_type: eventType,
          ...(eventType === "third_party" ? { url: eventPageLink } : {}),
        },
      };
      if (eventId) {
        data.event_managment.id = eventId;
      }
      const requestOptions: ReqOptions = {
        url: eventId ? `/event_managments/${eventId}` : `/event_managments`,
        method: eventId ? "PUT" : "POST",
        data: data,
      };
      draftRef.current = draft;
      sendRequest(requestOptions);
    }
  };

  useEffect(() => {
    const startTime = `${startTimeHour}:${startTimeMin} ${startTimeFormat}`;
    const endTime = `${endTimeHour}:${endTimeMin} ${endTimeFormat}`;
    if (startTime && endTime) {
      const startMoment = moment(startTime, "h:mm A");
      const endMoment = moment(endTime, "h:mm A");
      if (!startMoment.isBefore(endMoment)) {
        setEndTimeError(true);
      } else {
        setEndTimeError(false);
      }
      if (currentValidText) {
        const currentTimeInMinutes = convertToMinutes(currentTime);
        const updateTimeInMinutes = convertToMinutes(updateTime);

        // Check if the event date is today or in the future
        const eventMoment = moment(eventDate);
        const today = moment();
        const isEventDateTodayOrFuture = eventMoment.isSameOrAfter(
          today,
          "day"
        );

        if (
          isEventDateTodayOrFuture ||
          currentTimeInMinutes <= updateTimeInMinutes
        ) {
          setCurrentTimeBolean(false);
        } else {
          // setCurrentTimeBolean(true);
          setStartTimeError(
            "Start time cannot be earlier than the current time."
          );
          return;
        }
      }

      const duration = moment.duration(endMoment.diff(startMoment));
      const hours = duration.hours();
      const minutes = duration.minutes();
      setDuration(`${hours} hrs ${minutes} mins`);
    }
  }, [
    startTimeHour,
    startTimeMin,
    endTimeHour,
    endTimeMin,
    startTimeFormat,
    endTimeFormat,
    eventDate, // Add eventDate as a dependency
  ]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref1.current && !ref1.current.contains(event.target)) {
        setStartTimeBox(false);
      }
      if (ref2.current && !ref2.current.contains(event.target)) {
        setEndTimeBox(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (error) {
      setResponseMsg("Something went wrong! Try again..");
      setResponseType(false);
    }
  }, [error]);

  const handleTimeFormat = (format: string) => {
    setStartTimeFormat(format);
    setStartTimeBox(false);
  };

  const isValid =
    title &&
    eventDate &&
    startTimeHour &&
    endTimeHour &&
    venue?.trim() &&
    isValidURL(venueLink) &&
    !endTimeError &&
    !currentTimeBolean;
  const timeWithAMPM = eventDate.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  const parts = timeWithAMPM.split(":");
  // Remove leading zero from the hour part and reassemble the string
  const currentTime = `${parseInt(parts[0], 10)}:${parts[1]}`;
  // console.log("current now is : " +currentTime);
  let updateTime = startTimeHour + ":" + startTimeMin + " " + startTimeFormat;
  // console.log("update now is : " +updateTime)
  const convertToMinutes = (timeStr: any) => {
    const [timePart, amPm] = timeStr.split(" ");
    const [hours, minutes] = timePart
      .split(":")
      .map((part: any) => parseInt(part, 10));
    // Check if parsing was successful and variables are defined
    if (!isNaN(hours) && !isNaN(minutes) && (amPm === "AM" || amPm === "PM")) {
      let totalMinutes = hours * 60 + minutes;

      if (amPm === "PM" && hours !== 12) {
        totalMinutes += 12 * 60; // Add 12 hours for PM
      } else if (amPm === "AM" && hours === 12) {
        totalMinutes -= 12 * 60; // Subtract 12 hours for midnight
      }

      return totalMinutes;
    } else {
      // Handle invalid time format here, e.g., return an error message or handle it gracefully
      // console.error("Invalid time format:", timeStr);
      return 0; // Default value or error handling
    }
  };

  useEffect(() => {
    const currentDate = new Date();
    const currentHours = currentDate.getHours();
    const currentMinutes = currentDate.getMinutes();
    // Assuming eventDate is a Date object representing the event date
    const isSameDate = eventDate.toDateString() === currentDate.toDateString();

    if (isSameDate) {
      // Convert the selected start time to 24-hour format for comparison
      let eventHours = parseInt(startTimeHour, 10);
      const eventMinutes = parseInt(startTimeMin, 10);

      // Adjust eventHours based on AM/PM
      if (startTimeFormat === "PM" && eventHours !== 12) {
        eventHours += 12;
      } else if (startTimeFormat === "AM" && eventHours === 12) {
        eventHours = 0; // Midnight
      }

      if (
        eventHours < currentHours ||
        (eventHours === currentHours && eventMinutes < currentMinutes)
      ) {
        // Set an error message
        setStartTimeError(
          "Start time cannot be earlier than the current time."
        );
        return;
      }
    }

    // Clear the error message if the start time is valid
    setStartTimeError(null);
  }, [startTimeHour, startTimeMin, startTimeFormat, eventDate]);

  useEffect(() => {
    if (duration == "0 hrs 0 mins") {
      setStartTimeError(null);
    }
  }, [duration]);

  const [eventPageLink, setEventPageLink] = useState(
    formData?.event_managment?.url ? formData?.event_managment?.url : ""
  );
  const [eventType, setEventType] = useState<string>(
    formData?.event_managment?.event_type
      ? formData?.event_managment?.event_type
      : "imsom"
  );

  const handleEventTypeChange = (event: any) => {
    setEventType(event.target.value);
  };
  const labelColor: any = "2C2D30";

  const handleLinkInput = (event: any) => {
    setEventPageLink(event?.target?.value);
  };

  return (
    <>
      {loading && <Loader />}
      {responseMsg && (
        <AlertCmp
          type={responseType ? "success" : "error"}
          position="dashboard"
        >
          {responseMsg}
        </AlertCmp>
      )}

      <div style={{ marginTop: "20px" }}>
        <TextLabels
          variant="h5"
          color={`${labelColor || "#2C2D30"}`}
          weight={700}
        >
          {`Select type of Event *`}
        </TextLabels>
        <div style={{ marginTop: "10px" }}>
          <label
            style={{
              fontSize: "14px",
              fontWeight: "400",
            }}
          >
            <input
              style={{ borderColor: "#913713" }}
              type="radio"
              value="imsom"
              checked={eventType === "imsom"}
              onChange={handleEventTypeChange}
            />
            IMSOM Event
          </label>
          <label
            style={{
              fontSize: "14px",
              fontWeight: "400",
              marginLeft: "30px",
            }}
          >
            <input
              type="radio"
              value="third_party"
              checked={eventType === "third_party"}
              onChange={handleEventTypeChange}
            />
            3rd Party Event
          </label>
        </div>
      </div>

      {eventType === "third_party" && (
        <Box>
          <InputsWithLabel
            label="Link of event booking  *"
            placeholder="https://booking.page.com"
            type="text"
            name="event_title"
            id="event_title"
            value={eventPageLink}
            // error={!!errorMessage}
            // helperText={errorMessage}
            msg={errorMessage}
            handleInputChange={handleLinkInput}
          />
        </Box>
      )}

      <Box>
        <InputsWithLabel
          label="Title *"
          placeholder="Event title goes here"
          type="text"
          name="event_title"
          id="event_title"
          value={title}
          error={!!errorMessage}
          helperText={errorMessage}
          msg={errorMessage}
          handleInputChange={handleEventTitleChange}
        />
      </Box>

      {/* <Box>
        <h5 style={{fontSize:"14px"}}>Upload Big Banner Image *</h5>
        <div className="Dropdown_conatiner">
          <div className="Dropdown_innerDiv">
            <h3>Drag and drop your file</h3>
            <h3>Or</h3>
            <button>Browse</button>

            <div>
              <p>Max file size: <span>24 mb</span>, Dimension: <span>996px/560px</span></p>
              <p>Supported file type: <span>JPG, JPEG, PNG</span></p>
            </div>
          </div>
        </div>
      </Box> */}

      <Box>
        <Grid container className="event_date_day_grid">
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            className="event_date_grid_item"
          >
            <TextLabels variant="h5" color="#2C2D30" weight={700}>
              Event Date *
            </TextLabels>
            <Box sx={{ position: "relative" }}>
              <ReactDatePicker
                className="custom-datepicker"
                dateFormat="MM/dd/yyyy"
                minDate={new Date()}
                selected={eventDate}
                onChange={(date) => {
                  setEventDate(date);
                }}
              />
              <Box
                className="event-date-icon"
                sx={{ position: "absolute", top: "17px", left: "10px" }}
              >
                <img src="icons/calendar.png" />
              </Box>
            </Box>

            {/* <InputsWithLabel 
            label="Event Date *"
            // placeholder="Event title goes here"
            type="date"
            name="event_date"
            id='event_date'
            handleInputChange={(e)=>setEventDate(e.target.value)}
            /> */}
          </Grid>

          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            className="event_date_grid_item"
          >
            <Box sx={{ paddingLeft: "10px", paddingRight: "10px" }}>
              <TextLabels variant="h5" color="#2C2D30" weight={700}>
                Event Day *
              </TextLabels>
              <Input
                id="event_day"
                type="text"
                placeholder="Auto fill"
                name="event_day"
                disable={true}
                value={eventDay}
                sx={{
                  marginTop: "10px",
                  width: "100%",
                  background: "#F8F9FA",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container>
          <Grid
            sx={{ padding: "0px 10px 0px 0px" }}
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
          >
            <Box sx={{ position: "relative" }}>
              <InputsWithLabel
                label="Start Time *"
                type="text"
                name="start_time"
                id="start_time"
                placeholder="00 AM"
                handleClick={() => setStartTimeBox(!startTimeBox)}
                inputProps={{ readonly: true }}
                // {
                //   ...(startTimeHour>0)&&{'value':`${startTimeHour>0?startTimeHour:"00"} : ${startTimeMin>0?startTimeMin:"00"}`}
                // }
                value={`${startTimeHour > 0 ? startTimeHour : "00"} : ${
                  startTimeMin > 0 ? startTimeMin : "00"
                } ${startTimeFormat} `}
              />
              {startTimeError && <ErrorMessage>{startTimeError}</ErrorMessage>}
              <Box
                className="event-clock-icon"
                sx={{ position: "absolute", top: "37px", left: "10px" }}
              >
                {/* <img src="icons/clock.png" /> */}
              </Box>
              {startTimeBox && (
                <Box id="timeBox1" ref={ref1} sx={{}}>
                  <Box sx={{ textAlign: "center", marginBottom: "20px" }}>
                    <TextLabels variant="h200" weight={500}>
                      Enter Time
                    </TextLabels>
                  </Box>

                  <Box sx={{ display: "inline-flex" }}>
                    <Box sx={{ position: "relative" }}>
                      <Input
                        id="start_time_hour"
                        sx={{ width: "80px", height: "48px" }}
                        placeholder="00"
                        type="text"
                        handleChange={(e) => {
                          setValidText(true);
                          setStartTimeHour(
                            Math.min(
                              e.target.value.replace(/\D/g, "").substring(0, 2),
                              12
                            )
                          );
                        }}
                        value={startTimeHour}
                        inputProps={{
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          maxLength: 2,
                        }}
                      />
                      <Box sx={{ position: "absolute", top: "40px" }}>
                        <TextLabels variant="h200" weight={500}>
                          Hour
                        </TextLabels>
                      </Box>
                    </Box>

                    <Box sx={{ padding: "10px 10px 0px 10px" }}>
                      <img src="icons/colon.png" />
                    </Box>
                    <Box sx={{ position: "relative" }}>
                      <Input
                        id="start_time_min"
                        sx={{ width: "80px", height: "48px" }}
                        type="text"
                        placeholder="00"
                        handleChange={(e) => {
                          setStartTimeMin(
                            Math.min(
                              e.target.value.replace(/\D/g, "").substring(0, 2),
                              59
                            )
                          );
                        }}
                        value={startTimeMin}
                      />
                      <Box sx={{ position: "absolute", top: "40px" }}>
                        <TextLabels variant="h200" weight={500}>
                          Minute
                        </TextLabels>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        border: "0.75px solid #D5D7DB",
                        height: "45px",
                        marginLeft: "10px",
                      }}
                    >
                      <Button
                        btnClass={`timeBtn am-pm-btn active-btn-${
                          startTimeFormat == "AM"
                        }`}
                        sx={{}}
                        handleClick={() => {
                          handleTimeFormat("AM");
                        }}
                      >
                        AM
                      </Button>
                      <Button
                        btnClass={`timeBtn am-pm-btn active-btn-${
                          startTimeFormat == "PM"
                        }`}
                        // handleClick={() =>handleTimeFormat("AM")}
                        handleClick={() => {
                          setStartTimeFormat("PM");
                        }}
                      >
                        PM
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
            {/* {currentTimeBolean && (
              <ErrorMessage>
                Start time should be greater than Current time.
              </ErrorMessage>
            )} */}
          </Grid>
          <Grid sx={{ padding: "0px 10px" }} item xs={12} sm={4} md={4} lg={4}>
            <Box sx={{ position: "relative" }}>
              <InputsWithLabel
                label="End Time *"
                type="text"
                name="end_time"
                id="end_time"
                placeholder="00 AM"
                handleClick={() => setEndTimeBox(!endTimeBox)}
                inputProps={{ readonly: true }}
                value={`${endTimeHour > 0 ? endTimeHour : "00"} : ${
                  endTimeMin > 0 ? endTimeMin : "00"
                } ${endTimeFormat}`}
              />
              {endTimeError && startTimeHour > 0 && endTimeHour > 0 && (
                <ErrorMessage>
                  End time should be greater than Start time.
                </ErrorMessage>
              )}
              <Box
                className="event-clock-icon"
                sx={{ position: "absolute", top: "37px", left: "10px" }}
              >
                <img src="icons/clock.png" />
              </Box>
              {endTimeBox && (
                <Box id="timeBox2" ref={ref2} sx={{}}>
                  <Box sx={{ textAlign: "center", marginBottom: "20px" }}>
                    <TextLabels variant="h200" weight={500}>
                      Enter Time
                    </TextLabels>
                  </Box>

                  <Box sx={{ display: "inline-flex" }}>
                    <Box sx={{ position: "relative" }}>
                      <Input
                        id="end_time_hour"
                        sx={{ width: "80px", height: "48px" }}
                        placeholder="00"
                        type="text"
                        handleChange={(e) =>
                          setEndTimeHour(
                            Math.min(
                              e.target.value.replace(/\D/g, "").substring(0, 2),
                              12
                            )
                          )
                        }
                        value={endTimeHour}
                      />
                      <Box sx={{ position: "absolute", top: "40px" }}>
                        <TextLabels variant="h200" weight={500}>
                          Hour
                        </TextLabels>
                      </Box>
                    </Box>

                    <Box sx={{ padding: "10px 10px 0px 10px" }}>
                      <img src="icons/colon.png" />
                    </Box>
                    <Box sx={{ position: "relative" }}>
                      <Input
                        id="end_time_min"
                        sx={{ width: "80px", height: "48px" }}
                        type="text"
                        placeholder="00"
                        handleChange={(e) =>
                          setEndTimeMin(
                            Math.min(
                              e.target.value.replace(/\D/g, "").substring(0, 2),
                              59
                            )
                          )
                        }
                        value={endTimeMin}
                      />
                      <Box sx={{ position: "absolute", top: "40px" }}>
                        <TextLabels variant="h200" weight={500}>
                          Minute
                        </TextLabels>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        border: "0.75px solid #D5D7DB",
                        height: "45px",
                        marginLeft: "10px",
                      }}
                    >
                      <Button
                        btnClass={`timeBtn am-pm-btn active-btn-${
                          endTimeFormat == "AM"
                        }`}
                        sx={{}}
                        handleClick={() => setEndTimeFormat("AM")}
                      >
                        AM
                      </Button>
                      <Button
                        btnClass={`timeBtn am-pm-btn active-btn-${
                          endTimeFormat == "PM"
                        }`}
                        sx={{}}
                        handleClick={() => setEndTimeFormat("PM")}
                      >
                        PM
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid
            sx={{ padding: "0px 0px 0px 10px" }}
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
          >
            <Box>
              <InputsWithLabel
                label="Total Duration"
                type="text"
                inputProps={{ readonly: true }}
                placeholder="Auto calculate"
                name="total_duration"
                id="total_duration"
                value={duration}
              />
            </Box>
          </Grid>
        </Grid>

        <InputsWithLabel
          label="Event Venue *"
          type="text"
          name="event_venue"
          id="event_venue"
          placeholder="Enter event venue here"
          value={venue}
          handleInputChange={(e) => {
            setValidVenue(true);
            setVenue(e.target.value);
          }}
        />
        {!validVenue && (
          <ErrorMessage>Please enter valid venue location</ErrorMessage>
        )}
      </Box>
      <Box>
        <InputsWithLabel
          label="Venue Link *"
          type="text"
          name="venue_link"
          id="venue_link"
          placeholder="Enter venue’s Google Map link"
          value={venueLink}
          handleInputChange={(e) => {
            setValidUrl(true);
            setVenueLink(e.target.value);
          }}
        />
        {!validUrl && <ErrorMessage>Please enter valid url</ErrorMessage>}
      </Box>

      <Box sx={{ paddingBottom: "30px" }}>
        <Box className="step_button_container">
          <Grid container>
            <Grid item xs={6}>
              <Button
                btnClass={!isValid && "previewBanner"}
                disable={eventId || !isValid}
                sx={{
                  border: "1px solid #913713",
                  color: "#913713",
                  height: "44px",
                  padding: "0px",
                  "&:hover": {
                    border: "1px solid #913713",
                    backgroundColor: "#FFFFFF",
                    color: "#913713",
                  },
                }}
                variant="outlined"
                color="primary"
                size="s"
                handleClick={() => {
                  handleFormDataInput({
                    event_managment: {
                      title,
                      venue,
                      date: moment(eventDate).format("YYYY-MM-DD"),
                      start_date: `${
                        startTimeHour > 0 ? startTimeHour : "00"
                      } : ${
                        startTimeMin > 0 ? startTimeMin : "00"
                      } ${startTimeFormat}`,
                    },
                  });
                  handleOpenPreviewPopup();
                }}
              >
                Preview Banner
              </Button>
            </Grid>
            <Grid item xs={6} className="step_confirm_button">
              <Button
                btnClass="draft_btn"
                variant="outlined"
                color="primary"
                size="s"
                sx={{
                  marginRight: "30px",
                  border: "1px solid #913713",
                  color: "#913713",
                  height: "44px",
                  padding: "0px",
                  "&:hover": {
                    border: "1px solid #913713",
                    backgroundColor: "#FFFFFF",
                    color: "##913713",
                  },
                }}
                handleClick={(e) => {
                  handleStep1Submit(e, true);
                }}
              >
                Save as Draft
              </Button>
              <Button
                disable={
                  title.trim() &&
                  eventDate &&
                  startTimeHour &&
                  endTimeHour &&
                  venue.trim() &&
                  venueLink &&
                  !currentTimeBolean &&
                  !endTimeError &&
                  !startTimeError
                    ? // && (eventType === 'third_party' && url.trim() ? false : false)
                      false
                    : true
                }
                sx={{
                  backgroundColor: "#913713",
                  height: "44px",
                  padding: "0px",
                  "&:hover": {
                    backgroundColor: "#913713",
                    color: "#FFFFFF",
                  },
                }}
                handleClick={(e) => handleStep1Submit(e, false)}
                color="primary"
                size="s"
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </Box>
        {/* <Button 
    sx={{
        
        border:'1px solid #9FA1A6',
        color:'#9FA1A6',
        height:"44px",
        padding:"0px",
        '&:hover': {
            border:'1px solid #9FA1A6',
            backgroundColor: '#FFFFFF',
            color:'#9FA1A6'
        }
    }} 
    type="button"   variant="outlined" >Preview Banner</Button>
    <Button 
    sx={{
        float:"right",
        backgroundColor:"#913713",
        marginLeft:"20px",
        height:"44px",
        //width:"185px",
        padding:"0px",
        '&:hover': {
            border:'1px solid #9FA1A6',
            backgroundColor: '#913713',
            color:'#FFFFFF'
        }
    }} 
    type="button"   variant="contained" >Next</Button>
    <Button 
    sx={{
        float:"right",
        border:'1px solid #913713',
        color:'#913713',
        height:"44px",
        //width:"185px",
        padding:"0px",
        '&:hover': {
            border:'1px solid #913713',
            backgroundColor: '#FFFFFF',
            color:'##913713'
        }
    }} 
    type="button"   variant="outlined" >Save as Draft</Button> */}
      </Box>
    </>
  );
};

export default Step1;
