import React, { useEffect, useState } from "react";
import EventCardWebsite from "../../organism/eventCardWebsite";
import { Box, Grid } from "@mui/material";
import ArtistImg1 from "./images/artist1.png";
import ArtistImg2 from "./images/artist2.png";
import ArtistImg3 from "./images/artist3.png";
import ArtistImg4 from "./images/artist4.png";
import ArtistImg5 from "./images/artist5.png";
import ArtistImg6 from "./images/bg.png";
import DiyaLayout from "../../atoms/diyaLayout";
import SectionHeading from "../../molecules/sectionHeading";
import ArtistDetailSection from "../../organism/artistDetailSection";
import { Button } from "../../atoms/buttons";
import ContactUsSection from "../../organism/contactUsSection";
import AcknowledgementSection from "../../organism/acknowledgementSection";
import useHttp from "../../../utils/hooks/useHttp";
import { useParams, useNavigate } from "react-router-dom";
import Loader from "../../atoms/loader";
import "./style.css";
import moment from "moment";

const UpcomingEvents = () => {
  const navigate = useNavigate();
  const { loading, error, sendRequestWithoutToken } = useHttp(transformData);
  const [eventData, setEventData] = useState<any>(null);
  const [artistDetailsData, setArtistDetailsData] = useState<any>([]);
  const [ticketDetails, setTicketDetails] = useState<any>([]);
  const [contactDetails, setContactDetails] = useState<any>(null);
  const [ackData, setAckData] = useState<any>(null);
  const { id } = useParams();
  const [today, setToday] = useState<any>();
  const [eventDate, setEventDate] = useState<any>();
  const [acknowledgement, setAcknowledgement] = useState<any>("");


  useEffect(() => {
    const reqOptions = {
      url: `/get_event`,
      method: "GET",
      params: {
        id,
      },
    };

    sendRequestWithoutToken(reqOptions);
  }, []);

  function transformData(data: any) {
    if (data.status) {
      const eventObj = {
        title: data.event.title,
        date: data?.event?.date,
        location: `${data.event.venue}`,
        locationLink: data.event.location,
        time: `${moment.utc(data.event.date).format("MMMM D YYYY")}, 
        ${data.event.start_date}`,
        artists: data.event.artists,
        id: data.event.id,
        event_type: data?.event?.event_type,
        sub_type: data?.event?.sub_type,
        url: data?.event?.url,
      };
      const today = new Date();
      const date = new Date(today.toISOString().slice(0, 10));
      setToday(date);
      const eventdate = new Date(data?.event?.date);
      setEventDate(eventdate);

      setEventData(eventObj);
      setArtistDetailsData(data.event.artists);
      setAcknowledgement(data?.event?.acknowledgement);
      setTicketDetails(data.event.ticket_details || []);
      setAckData({
        ack: data.event.acknowledgement,
        event_images: data.event.event_images,
      });
      setContactDetails({
        contact_person_first_email: data.event.contact_person_first_email,
        contact_person_first_name: data.event.contact_person_first_name,
        contact_person_second_email: data.event.contact_person_second_email,
        contact_person_second_name: data.event.contact_person_second_name,
      });
    }
  }
  // {
  //   height: 100%;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: space-around;
  // }

  return (
    <>
      {loading && <Loader />}
      <Box>
        <Box>
          {eventData && (
            <EventCardWebsite data={eventData} hideViewMore="true" />
          )}
        </Box>
        {eventDate >= today && (
          <DiyaLayout>
            <Box
              className="upcoming_events_buy_ticket_box"
              style={{ zIndex: "999" }}
            >
              <div style={{marginLeft:"-20px", position: "relative"}}>
              <SectionHeading title="Ticket Prices" />
              </div>
              {eventData.sub_type === "free" ? (
                <Grid
                  container
                  spacing={4}
                  className="bgPng upcoming_events_buy_grid"
                >
                 
                  <>
                    <div style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "white",
                      marginTop: "150px"
                    }}>
                    <h2>Free Tickets</h2>
                    <p style={{color:"gray"}}>This concert is free.</p>
                    </div>
                  </>
                </Grid>
              ) : (
                <Grid
                  container
                  spacing={4}
                  className="bgPng upcoming_events_buy_grid"
                >
                  <>
                    <img className="childwww" src={ArtistImg6} />

                    {ticketDetails &&
                      ticketDetails.length &&
                      ticketDetails.map((ticket: any, i: number) => {
                        return (
                          <Grid
                            item
                            xs={12 / ticketDetails.length}
                            key={i}
                            className="ticket_grid_website"
                            style={{ zIndex: "999" }}
                          >
                            <Box>
                              <h6 className="ticket_title_website">
                                {ticket.title}
                              </h6>
                            </Box>
                            <Box className="ticket_title_online_website">
                              <p className="ticket_label_website">Online</p>
                              <p className="ticket_price_website">
                                $ {ticket.advance_value}
                              </p>
                            </Box>
                            <Box className="ticket_titl_gate_website">
                              <p className="ticket_label_website">Gate</p>
                              <p className="ticket_price_website">
                                $ {ticket.door_value}
                              </p>
                            </Box>
                          </Grid>
                        );
                      })}
                  </>
                </Grid>
              )}
              {/* <Box className="membership_button">
              <Button variant="outlined" color="info" size="s" handleClick={() => navigate(`/ticket-booking/${eventData.id}`)}>
               Purchase Tickets
              </Button>
            </Box> */}
            </Box>
          </DiyaLayout>
        )}
        {artistDetailsData.map((details: any, i: number) => {
          return (
            <ArtistDetailSection
              key={i}
              {...details}
              acknowledgement={acknowledgement}
            />
          );
        })}
        {contactDetails && <ContactUsSection contactDetails={contactDetails} />}
        {ackData && <AcknowledgementSection ackData={ackData} />}
      </Box>
    </>
  );
};

export default UpcomingEvents;
