import React, { useState, useEffect } from "react";
import { Button } from "../buttons";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextLabels from "../textLabels";
import InputsWithLabel from "../../molecules/inputsWithLabel";
import { Box } from "@mui/material";
import { PropType } from "./types/propType";
import { InputTypes } from "./types/inputsType";
import PlusImg from "./images/plus.svg";
import CloseImg from "./images/close.svg";
import ErrorMessage from "../errorMessage";
import useHttp from "../../../utils/hooks/useHttp";
import Loader from "../loader";
import Checkbox from "@mui/material/Checkbox";
// @ts-ignore
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import UserImg from './images/user.png'

import AlertCmp from "../alert";
import "./style.css";
import { useDropzone } from "react-dropzone";
import moment from "moment";
import { exit } from "process";
import { Editor } from "primereact/editor";


const label = { inputProps: { "aria-label": "Checkbox demo" } };

const selectLabels: any = {
  student: "student",
  senior: "senior_citizen",
  none: "none_of_above",
};
const selectDicision: any = {
  Executive_Committe: "Executive Committe",
  Board_of_Directors: "Board of Directors",
  Advisory_Board: "Advisory Board",
};

const ModalFormPopup = ({
  heading,
  content,
  closeBtn,
  confirmBtn,
  handleClose,
  inputs,
  open,
  apiUrl,
  method,
  handleApiFetchAgain,
  handleSuccessCall,
  editData,
  noDisable,
  secondaryButton,
  secondaryAction,
  scheduleBtn,
  primaryAction,
  boardMemberApi,
  profileImage
}: PropType) => {

  const [inputsValidation, setInputsValidation] = useState<any>(null);
  const [clearStates, setClearState] = useState<any>(null);
  const [inputEnter, setInputEnter] = useState(false);
  // @ts-ignore
  const [filterUser, setFilterUser] = useState(inputs[1]?.value ? inputs[1]?.value : []);
  const [division, setDivision] = useState<any>("")
  const { loading, error, sendRequest } = useHttp(transformData);
  const [text, setText] = useState('');

  function transformData(data: any) {
    if (data.status) {
      setProfilePic(null)
      handleClose();
      if (handleSuccessCall) {
        handleSuccessCall(data.message);

        let inputsObj = JSON.parse(JSON.stringify(inputsValidation));

        Object.keys(inputsObj).forEach((inputKey, i) => {
          if (inputsObj[inputKey].value) {
            inputsObj[inputKey].value = "";
          }
        });
        setInputsValidation(inputsObj);
      }
      if (handleApiFetchAgain) {
        handleApiFetchAgain();
      }
    }
  }
  // console.log("divisionnn",division)


  const handleSubmit = () => {
    let error = false;
    // const enddate = inputsObj?.["end_date"]?.value !== "" && new Date(inputsObj?.["end_date"]?.value);
    let inputsObj = JSON.parse(JSON.stringify(inputsValidation));
    const startdate = inputsObj?.["start_date"]?.value !== "" && new Date(inputsObj?.["start_date"]?.value);
    Object.keys(inputsObj).forEach((inputKey, i) => {
      if (inputsObj[inputKey].validate && !inputsObj[inputKey].value) {
        error = true;
        inputsObj[inputKey].validate = false;
      }
      if (inputKey === "end_date") {
        if (inputsObj[inputKey].value < startdate) {
          error = true;
          inputsObj[inputKey].validate = false;
          inputsObj[inputKey].errorMsg =
            "End Date should be greater than Start Date";
        }
      }
      if (inputKey === "member_name") {
        if (/[0-9]/.test(inputsObj[inputKey].value)) {
          error = true;
          inputsObj[inputKey].validate = false;
          inputsObj[inputKey].errorMsg =
            "Only alphabets are allowed";
        }
      }
    });

    if (!error) {
      handleApiCall();
    } else {
      setInputsValidation(inputsObj);
    }
  };

  const handleInputChange = (e: any, name: any = "") => {
    const inputsObj = JSON.parse(JSON.stringify(inputsValidation));
    if (e.target) {
      inputsObj[e.target.name].value = e.target.value.replace(/^\s+/g, "");
      if (inputsObj[e.target.name].validate !== undefined) {
        inputsObj[e.target.name].validate = true;
      }
    } else {
      let datea: any = new Date(e.$d)
      let day: any = datea.getDate()
      let month: any = (datea.getMonth() + 1).toString().padStart(2, '0')
      let year: any = datea.getFullYear()
      let ggg: any = year + '-' + month + '-' + day;

      inputsObj[name].value = ggg.replace(/^\s+/g, "");
      if (inputsObj[name].validate !== undefined) {
        inputsObj[name].validate = true;
      }
    }
    // const enddate = inputsObj?.["end_date"]?.value !== "" && new Date(inputsObj?.["end_date"]?.value);
    // const startdate = inputsObj?.["start_date"]?.value !== "" &&new Date(inputsObj?.["start_date"]?.value);
    // const enddate=inputsObj["end_date"]?.value?.toDateString();
    // const startdate=inputsObj["start_date"]?.value?.toDateString();

    //!inputEnter && setInputEnter(true);
    if (inputsObj?.["end_date"]?.value && inputsObj?.["start_date"]?.value) {
      if (new Date(inputsObj?.["end_date"]?.value) > new Date(inputsObj?.["start_date"]?.value)) {
        // inputsObj["end_date"].validate = false;
        // inputsObj["end_date"].errorMsg ="End Date should be greater than Start Date";
        inputsObj["end_date"].validate = true;
        inputsObj["end_date"].errorMsg = "";
      } else {
        // inputsObj["end_date"].validate = true;
        // inputsObj["end_date"].errorMsg = "";        
        inputsObj["end_date"].validate = false;
        inputsObj["end_date"].errorMsg = "End Date should be greater than Start Date";
      }
    }

    setInputsValidation(inputsObj);
  };

  const handleUserChange = (event: SelectChangeEvent) => {
    setInputsValidation({
      ...inputsValidation, admits: {
        value: event.target.value
      }
    })
    setFilterUser(event.target.value ? event.target.value : []);
  };

  const handleCheckbox = (e: any) => {
    const inputsObj = JSON.parse(JSON.stringify(inputsValidation));
    inputsObj["mailing_list"].value = e.target.checked;
    setInputsValidation(inputsObj);
  };

  useEffect(() => {
    let checkValidation = true;
    inputsValidation &&
      Object.keys(inputsValidation)?.length > 0 &&
      Object.keys(inputsValidation).forEach((input, i) => {
        if (input !== "mailing_list") {
          if (!inputsValidation[input].value) {
            checkValidation = false;
          }
          if (input == 'admits') {
            checkValidation = true;
          }
        }
      });
    setInputEnter(checkValidation);
  }, [inputsValidation]);


  useEffect(() => {
    if (noDisable === true) {
      setInputEnter(true);
    }
  }, [noDisable]);

  useEffect(() => {
    const inputsObj: any = {};
    inputs.forEach((input: any) => {
      if (input.errorMsg) {
        let inputValue = "";
        if (input.value) {
          inputValue = input.value;
        } else {
          inputValue = "";
        }
        inputsObj[input.name] = {
          value: inputValue,
          validate: true,
          errorMsg: input.errorMsg,
        };
      } else {
        let inputValue = "";
        if (input.value) {
          inputValue = input.value;
        } else {
          inputValue = "";
        }
        inputsObj[input.name] = {
          value: inputValue,
        };
      }
    });
    setClearState(inputsObj);
    setInputsValidation(inputsObj);
    setDivision(inputsObj.division?.value || "");
    if (profileImage) {
      setProfilePicPreview(profileImage);
    } else {
      setProfilePic(null);
    }
  }, [inputs]);

  const handleApiCall = () => {
    const body: any = {};
    let isValid = true;

    Object.keys(inputsValidation).forEach((input) => {
      body[input] = inputsValidation[input].value;
    });

    if (location == '/user-management') {
      const formData = new FormData();
      const emailRegex = /[a-z0-9._%+-]+@[a-z0-9]+\.[a-z]{2,3}$/;
      let alphabetRegex = /^[A-Za-z\s]+$/
      Object.keys(inputsValidation).forEach((input, index) => {
        if (input == 'member_email') {
          if (!emailRegex.test(inputsValidation[input].value)) {
            toast.error("Please enter a valid email")
            isValid = false;
          }
        }
        if (input == 'position') {
          if (!alphabetRegex.test(inputsValidation[input].value)) {
            toast.error("Position can only contain alphabets")
            isValid = false;
          }
        }
        formData.append(input == 'member_email' ? "email" : input, inputsValidation[input].value)
      });
      // if (!profilePicPreview && !profilePic) {
      //   toast.error("board member image is mandatory")
      //   return;
      // }
      if (!isValid) {
        return; // Return early from the function
      }

      if (profilePic != null) {
        formData.append("board_memeber_image", profilePic)
      }
      const requestOptions: any = {
        url: apiUrl,
        method: method == 'PUT' ? 'PUT' : 'POST',
        contentType: "multipart/form-data",
        data: formData,
      };

      sendRequest(requestOptions);



    } else {
      if (location == '/membership') {
        let alphabetRegex = /^[A-Za-z]+$/
        Object.keys(inputsValidation).forEach((input, index) => {
          if (input == 'no_of_free_passes') {
            if (inputsValidation[input].value == 0) {
              toast.error("Number of free passes cannot be zero")
              isValid = false;
            }
          }
          if (input == 'save_upto') {
            if (inputsValidation[input].value == 0) {
              toast.error("Save upto cannot be zero")
              isValid = false;
            }
          }
          if (input == 'price') {
            if (inputsValidation[input].value == 0) {
              toast.error("Price cannot be zero")
              isValid = false;
            }
          }
          if (input == 'membership_type') {
            if (!alphabetRegex.test(inputsValidation[input].value)) {
              toast.error("Enter valid name for membership name")
              isValid = false;
            }
          }
        })
      }
      if (location == '/announcement') {
        Object.keys(inputsValidation).forEach((input, index) => {
          if (input == 'title') {
            const cleanedInputValue = inputsValidation[input].value.replace(/[^a-zA-Z]/g, '');
            const regex = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9])[\w\W]*$/;
            if (cleanedInputValue != "") {
              if (!regex.test(cleanedInputValue)) {
                isValid = true;
              } else {
                toast.error("Enter valid name for announcement name")
                isValid = false;
              }
            } else {
              toast.error("Enter valid name for announcement name")
              isValid = false;
            }
            // if(!alphabetRegex.test(inputsValidation[input].value)) {
            //   toast.error("Enter valid name for announcement name")
            //   isValid = false; 
            // }
          }
        })
      }

      if (!isValid) {
        return; // Return early from the function
      }

      const requestOptions: any = {
        url: apiUrl,
        method,
        data: body,
      };

      sendRequest(requestOptions);
    }
  };

  const [location, setLocation] = useState<any>("")
  const [profilePic, setProfilePic] = useState<any>(null)
  const [profilePicPreview, setProfilePicPreview] = useState<any>(null)

  useEffect(() => {
    const currentLocation = window.location.pathname
    setLocation(currentLocation)
  }, [])

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: { "image/jpeg": [], "image/jpg": [], "image/png": [] },
    onDrop: (acceptedFiles) => {
      const newFile = acceptedFiles[0];
      setProfilePic(Object.assign(newFile, {
        preview: URL.createObjectURL(newFile),
      }));
    },
    multiple: false,
  });
  const clearState = () => {
    setInputsValidation(clearStates);
    setFilterUser([])
  }

  // useEffect(() => {
  //   setDivision('')
  // }, [open])
  return (
    <div>
      <ToastContainer />
      {loading && <Loader />}
      <Dialog
        open={open}
        onClose={() => {
          handleClose()
          clearState()
          setProfilePic(null)
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="modal_form_popup"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <TextLabels variant="h400" weight={500}>
            {heading}
          </TextLabels>
          <img
            src={CloseImg}
            onClick={handleClose}
            style={{ cursor: "pointer" }}
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              justifyContent="space-between"
            >
              {inputs.map((input: any, i) => {
                if (input.grid === 2) {
                  return (
                    <Box className="input_grid_display">
                      <InputsWithLabel
                        {...input}
                        inputProps={input.inputProps}
                        handleInputChange={handleInputChange}
                        value={
                          inputsValidation
                            ? inputsValidation[input.name].value
                              ? inputsValidation[input.name].value
                              : ""
                            : ""
                        }
                        key={i}
                      />
                      {inputsValidation &&
                        inputsValidation[input.name].validate === false && (
                          <ErrorMessage>
                            {inputsValidation[input.name].errorMsg}
                          </ErrorMessage>
                        )}
                    </Box>
                  );
                }
                if (input.type === "checkbox") {
                  return (
                    <Box
                      sx={{
                        width: "100%",
                      }}
                      className="modal_form_popup_checkbox"
                    >
                      {" "}
                      <Checkbox onChange={handleCheckbox} {...label} />
                      <span>{input.label}</span>
                    </Box>
                  );
                }
                if (input.type === "select") {
                  return (
                    <Box
                      sx={{
                        width: "100%",
                      }}
                    >
                      <TextLabels variant="h5" color={`"#2C2D30"`} weight={700}>
                        {input.label}
                      </TextLabels>
                      <Box
                        sx={{
                          marginBottom: "5px",
                        }}
                      ></Box>
                      <FormControl sx={{ width: "100%", margin: "0px" }}>
                        {location == '/membership' && <Select
                          multiple
                          value={filterUser}
                          onChange={handleUserChange}
                          displayEmpty
                          className="select_filter_years"
                          inputProps={{ "aria-label": "Without label" }}
                        // renderValue={
                        //   filterUser !== ""
                        //     ? () => selectLabels[filterUser]
                        //     : () => (
                        //       <span
                        //         style={{
                        //           color: "#BABDC2",
                        //         }}
                        //       >
                        //         All Type
                        //       </span>
                        //     )
                        // }
                        >
                          <MenuItem value={"student"}>Student</MenuItem>
                          <MenuItem value={"senior_citizen"}>Senior Citizen</MenuItem>
                          {/* <MenuItem value={"none_of_above"}>No Restrictions</MenuItem> */}
                        </Select>}

                        {location == '/user-management' &&
                          <Select
                            placeholder="Select Division"
                            value={division}
                            onChange={(e) => {
                              setDivision(e.target.value)
                              setInputsValidation({
                                ...inputsValidation,
                                division: {
                                  ...inputsValidation.division,
                                  value: e.target.value
                                }
                              })
                            }}
                            displayEmpty
                            className="select_filter_years"
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem value="" disabled>
                              Select Division
                            </MenuItem>
                            <MenuItem value={"Executive Committe"}>Executive Committe</MenuItem>
                            <MenuItem value={"Board of Directors"}>Board of Directors</MenuItem>
                            <MenuItem value={"Advisory Board"}>Advisory Board</MenuItem>
                          </Select>}
                      </FormControl>
                    </Box>
                  );
                }
                if (input.type === "file") return null
                if (input.type === "textarea") {
                  return (
                    <div className="card">
                      {/* <span style={{color:"black",fontSize:"15px",paddingBottom:"15px !importat"}}><b>messages</b></span> */}
                      <Editor value={inputsValidation && inputsValidation[input.name].value} onTextChange={(e: any) => {
                        setInputsValidation((prevObj: any) => ({
                          ...prevObj,
                          message: {
                            ...prevObj.message,
                            value: e.htmlValue
                          }
                        }))
                      }} />
                    </div>
                  )
                }

                return (
                  <>

                    <Box
                      sx={{
                        width: "100%",
                      }}
                    >
                      <InputsWithLabel
                        {...input}
                        handleInputChange={handleInputChange}
                        value={
                          inputsValidation
                            ? inputsValidation[input.name].value
                              ? inputsValidation[input.name].value
                              : ""
                            : ""
                        }
                        key={i}
                      />
                      {inputsValidation &&
                        inputsValidation[input.name].validate === false && (
                          <ErrorMessage>
                            {inputsValidation[input.name].errorMsg}
                          </ErrorMessage>
                        )}
                    </Box>
                  </>
                );
              })}
            </Box>
            {
              location == '/user-management' &&
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center'
                }}>
                <div {...getRootProps({ className: "" })}>
                  <input {...getInputProps()} />
                  <img
                    style={{ width: "100px", cursor: "pointer" }}
                    src={profilePic ? profilePic.preview : profilePicPreview || UserImg}
                    alt="Profile Picture"
                  />
                </div>
              </Box>
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {confirmBtn === "create" ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                icon={PlusImg}
                handleClick={handleSubmit}
                size="s"
                disable={!inputEnter}
              >
                Create
              </Button>
            </Box>
          ) : (
            <Box
              sx={
                secondaryButton
                  ? {
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }
                  : {
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }
              }
            >
              <Button
                btnClass={confirmBtn === "Publish Announcement" ? "btnPublishAnnoc" : ""}
                handleClick={
                  scheduleBtn
                    ? (e) => primaryAction(e, inputsValidation)
                    : handleSubmit
                }
                variant="contained"
                size="s"
                disable={!inputEnter}
              >
                {confirmBtn}
              </Button>
              {secondaryButton && (
                <Button
                  handleClick={secondaryAction}
                  variant="outlined"
                  size="s"
                >
                  {secondaryButton}
                </Button>
              )}
            </Box>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalFormPopup;
