import React,{ useEffect } from "react";
import SectionHeading from "../../molecules/sectionHeading";
import { Box, Grid } from "@mui/material";
import LongDiyaImg from "./images/long_diya.svg";
import ShortDiyaImg from "./images/short_diya.svg";
import ArtistImg6 from "./images/bg.png";
import { Button } from "../../atoms/buttons";
import DiyaLayout from "../../atoms/diyaLayout";
import { useNavigate } from "react-router-dom";
import "./style.css";
import useHttp from "../../../utils/hooks/useHttp";
import { useState } from "react";

const MembershipSection = () => {
  const navigate = useNavigate();
  const { loading, sendRequestWithoutToken } = useHttp(transformData);
  const [priceData, setPriceData] = useState();
  const [lPrice, setLPrice] = useState<any>();
  const [mPrice, setMaxPrice] = useState<any>();
  const [totalPasses, setPasses] = useState<any>();

  function transformData(data: any) {
    if (data.status) {
      setPriceData(data.memberships)
    }
  }

  useEffect(() => {
    const reqOptions = {
      url: `/get_memberships?block=false`,
      method: "GET",
    };

    sendRequestWithoutToken(reqOptions);
  }, []);
  
  useEffect(() => {
    setPriceRange() 
  }, [priceData])
  


  const setPriceRange = () => {
    if(priceData){
      // @ts-ignore
      const newArray = priceData?.map((item: any) => item.price);
      // @ts-ignore
      // console.log(9999,newArray)
      const noOfPasses = priceData?.map((item: any) => item.no_of_free_passes);
      const lowerPrice = Math.min(...newArray);
      const maxPrice = Math.max(...newArray);
      const passes = Math.max(...noOfPasses);
      setLPrice(lowerPrice);
      setMaxPrice(maxPrice);
      setPasses(passes);
    } 
    // else {
    //   setPriceRange();
    // }
  }
  return (
    <DiyaLayout>
      <SectionHeading
        title="Membership"
        marginBottom="8"
        width="195"
        marginRight="-224"
      />
      
      <Box className="widthclass100 bgPng">
      <img className="childwww" src={ArtistImg6} />
      <Box className="membership_details">
      
      <p className="membership_timeline">Yearly Membership </p>
      <p className="membership_price">
        <span>${mPrice} - ${lPrice}</span> only
      </p>
      {/* <p className="membership_passes">includes {totalPasses} passes</p> */}
    </Box>
    <Box className="membership_button">
      <Button
        variant="outlined"
        color="info"
        size="s"
        handleClick={() => navigate(`/memberships`)}
      >
        Learn More
      </Button>
    </Box>
      </Box>
    </DiyaLayout>
  );
};

export default MembershipSection;
