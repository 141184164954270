import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { PropType } from "./types/propType";
import CloseImg from "./images/close.svg";
import { Box } from "@mui/material";
import UserImg from "./images/user.png";
import { Button } from "../../atoms/buttons";
import "./style.css";
import useHttp from "../../../utils/hooks/useHttp";
import Loader from "../../atoms/loader";

const WebEditImageDialog = ({
  open,
  handleClose,
  handleSelectedFile,
  selectedFile,
  handlePreviewUrl,
  previewUrl,
  adminSide,
  setUserInfo,
  userInfo,
  uploadButtonLabel,
  onSuccess
  
}: PropType) => {
  const transformData = (data:any) => {
    if(adminSide){
      const existedUserJson = localStorage.getItem("user");
      let exitedUser = {}
      if(existedUserJson) {
        exitedUser = JSON.parse(existedUserJson);
      }
      localStorage.setItem("user", JSON.stringify({...exitedUser,...data.user}));
    }else{
      localStorage.setItem("web_user", JSON.stringify(data.user));
    }
    window.dispatchEvent( new Event('storage'))
    if(onSuccess ) onSuccess(data.user);
    setUserInfo({
      ...userInfo,
      profile_image: data?.user?.profile_image
    })
    handleClose()
  }
  
  const { loading, error, sendRequest ,sendRequestWeb} = useHttp(transformData);
  const [image, setImage] = useState<any>()
  const handleImageFile = (event: any) => {
    const file = event?.target?.files[0];
    setImage(file)

    if (file) {
      handleSelectedFile(file);
      const reader = new FileReader() as any;

      reader.onload = () => {
        handlePreviewUrl(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleDeleteImg = () => {
    handleSelectedFile(null);
    handlePreviewUrl("");
  };

  const handleUpload = async() => {
    const web_user = adminSide ? localStorage.getItem("user"):localStorage.getItem("web_user")
    const user:any = JSON.parse(web_user || "")
    const formData = new FormData()
    formData.append(`guest[profile_image]`, image)
    const reqOptions = {
      url: `/update_profile?id=${user?.id}`,
      method: "POST",
      contentType: "multipart/form-data",
      data: formData
    };
    if(adminSide){
      sendRequest(reqOptions);
    }else{
      sendRequestWeb(reqOptions);
    }
  }

  return (
    <>
      {loading && <Loader />}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={
          adminSide
            ? "user_info_edit_dialog user_info_edit_dialog_admin"
            : "user_info_edit_dialog"
        }
      >
        <DialogTitle id="alert-dialog-title">
          <Box
            className={
              adminSide
                ? "user_info_edit_dialog_heading user_info_edit_dialog_heading_admin"
                : "user_info_edit_dialog_heading"
            }
          >
            <h6>Profile Picture</h6>
            <img
              src={CloseImg}
              onClick={handleClose}
              style={{ cursor: "pointer" }}
            />
          </Box>
        </DialogTitle>
        <DialogContent>
          <p
            className={
              adminSide
                ? "user_info_edit_dialog_subtitle user_info_edit_dialog_subtitle_admin"
                : "user_info_edit_dialog_subtitle"
            }
          >
            Upload your picture to the profile section.
          </p>
          <DialogContentText id="alert-dialog-description">
            <Box className="user_info_edit_dialog_image_box">
              <img
                src={previewUrl || UserImg}
                className="user_info_edit_dialog_image"
                style={{cursor: 'pointer'}}
              />
            </Box>
            <Box className="user_info_edit_dialog_buttons">
              {previewUrl ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <label htmlFor="user_profile_image">
                    <Box
                      className={
                        adminSide
                          ? "upload_picture_label dddprofile upload_picture_label_admin"
                          : "upload_picture_label dddprofile"
                      }
                    >
                      <span>Reupload picture</span>
                    </Box>
                  </label>
                  <input
                    type="file"
                    id="user_profile_image"
                    onChange={handleImageFile}
                    style={{ display: "none" }}
                  />
                  <Box
                    className={
                      adminSide
                        ? "upload_picture_delete upload_picture_delete_admin"
                        : "upload_picture_delete"
                    }
                  >
                    <span onClick={handleDeleteImg}>Delete</span>
                  </Box>
                  <Box
                    className={
                      adminSide
                        ? "upload_picture_delete upload_picture_delete_admin"
                        : "upload_picture_delete"
                    }
                  >
                    <span onClick={handleUpload}>{uploadButtonLabel || 'Upload'}</span>
                  </Box>
                </Box>
              ) : (
                <>
                  <label htmlFor="user_profile_image">
                    <Box
                      className={
                        adminSide
                          ? "upload_picture_label upload_picture_label_admin"
                          : "upload_picture_label"
                      }
                    >
                      <span>
                        {adminSide ? "Browse Picture" : "Upload picture"}
                      </span>
                    </Box>
                  </label>
                  <input
                    type="file"
                    id="user_profile_image"
                    onChange={handleImageFile}
                    style={{ display: "none" }}
                  />
                </>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>

  );
};

export default WebEditImageDialog;
