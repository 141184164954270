import React from "react";
import Layout from "../../organism/layout";
import WizardSteps from "../../organism/wizardSteps";
import { useSearchParams } from "react-router-dom";

const CreateEventPage = () => {
  const [search, ] = useSearchParams();
  return (
    <Layout pageName={search.get("edit") ? "Edit Event":"Create Event"} menuActive="Event Management">
      <WizardSteps></WizardSteps>
    </Layout>
  );
};

export default CreateEventPage;
