import React, { useEffect } from "react";
import { PropType } from "./types/propType";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import WebsiteArtistImage from "../../atoms/websiteArtistImage";
import "./style.css";

const HeroImages = ({ images, previewBanner }: PropType) => {

 

  useEffect(()=>{
    localStorage.setItem("sliderLength", images?.length)
  },[images?.length])
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        // style={marginTop:{xs:"200px"}}
        spacing={images?.length > 2 ? { sm: 2, xs: 0 } : { sm: 6, xs: 0 }}
        className={images?.length > 2 ? "" : "hero_image_add_spacing"} 
        // style={{marginTop:"100px !important" ,backgroundColor:"red"}}
      >
        {images?.map((artist: any, i: number) => {
          return (
            <>
            {
              images?.length==1?
             <Grid 
              item
              xs ={12}
              sm ={3}
              md ={3}
              lg={3}
              >

             </Grid>
              :null
            }
            <Grid
              item
              xs={images?.length == 1 ? 12 : 6}
              sm={images?.length == 1 ? 6 : 12 / images?.length}
              md={images?.length == 1 ? 6 : 12 / images?.length}
              lg={images?.length == 1 ? 6 : 12 / images?.length}

              key={i}
              className={`${
                i % 2 === 0
                  ? "artist_img_box space_top_mobile"
                  : "artist_img_box space_top"
              } ${images?.length === 1 ? "artist_img_single" : ""}`}
            >
              <WebsiteArtistImage
              arrLength={images?.length}
                image={artist.artist_image[0]}
                name={artist.name}
                type={artist.performance_type}
                previewBanner={previewBanner}
                lengthCheck={images?.length||0}
                marginLeft={window.screen.width > 500 ? 'checkOk' :"nothing"}
              ></WebsiteArtistImage>
            </Grid>
            {
              images?.length==1?
             <Grid 
              item
              xs ={12}
              sm ={3}
              md ={3}
              lg={3}
              >

             </Grid>
              :null
            }
            </>
          );
        })}
      </Grid>
    </Box>
  );
};

export default HeroImages;
