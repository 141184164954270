import axios from 'axios';
import { ChangeEvent, useState } from 'react';
const useEditAboutTitle = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    // const [fileUrl, setabou] = useState(null);
    const aboutUsTitle = async (data:any,id:any,path:any) => {
        setLoading(true);
        try {
            const url = process.env.REACT_APP_API_URL_DEV;
            const response = await axios({
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: localStorage.getItem("web_token")
                },
                url: `${url}/${path}/${id}`,
                data: data
            });
            if (!response.data) throw new Error('Image not uploaded');
            // setFileUrl(response.data?.[0]?.url);
        } catch (e: any) {
            setError(e.message);
            // alert(e.message);
        }
        setLoading(false);
    };
    return { aboutUsTitle, loading, error };
};
export default useEditAboutTitle;






