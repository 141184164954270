import React, { useState, useEffect } from "react";
import WebDialog from "../../molecules/webDialog";
import {
  DialogContent,
  DialogContentText,
  Box,
  DialogActions,
} from "@mui/material";
import InputsWithLabel from "../../molecules/inputsWithLabel";
import SectionHeading from "../../molecules/sectionHeading";
import ErrorImg from "./images/error.svg";
import { Button } from "../../atoms/buttons";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { PropType } from "./types/propType";
import useHttp from "../../../utils/hooks/useHttp";
import { useDispatch } from "react-redux";
import { setWebUser } from "../../../utils/store/slices/authSlice";
import Loader from "../../atoms/loader";
import "./style.css";

const WebPasswordDialog = ({
  open,
  handleClose,
  handleOpenDialog,
  handleAlertBox,
  handleAlertErrorBox,
}: PropType) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { loading, error, sendRequestWithoutToken } = useHttp(transformData);
  const [disableButton, setDisableButton] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formData, setFormData] = useState<any>({
    password: {
      value: "",
      validate: true,
      errorMsg: "* Please enter password",
    },
    confirmPassword: {
      value: "",
      validate: true,
      errorMsg: "* Please enter confirm password",
    },
  });

  useEffect(() => {
    let disableFlag = false;
    Object.keys(formData).forEach((key) => {
      if (formData[key].value === "") {
        disableFlag = true;
      }
    });

    setDisableButton(disableFlag);
  }, [formData]);

  function transformData(data: any) {
    if (data.status) {
      handleAlertBox(data.message);

      localStorage.setItem("web_token", data.auth_token);
      localStorage.setItem("web_user", JSON.stringify(data.user));
      window.dispatchEvent(new Event('storage'))
      dispatch(setWebUser(data));
      setTimeout(() => {
        navigate(location);
        // window.location.href = "/";
      }, 3000);
    } else {
      handleAlertErrorBox(data.message);
    }
  }

  const handleRegistration = (e: any) => {
    e.preventDefault();


    let errorFlag = false;

    const dataObj = JSON.parse(JSON.stringify(formData));

    Object.keys(dataObj).forEach((input, i) => {
      if (dataObj[input].value === "") {
        dataObj[input].validate = false;
        errorFlag = true;
      }
    });

  // regex to satisfy conditions
  // const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=])(.{9,})$/;
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>]).{8,}$/


    if (dataObj.password.value !== dataObj.confirmPassword.value) {
      dataObj.confirmPassword.validate = false;
      dataObj.confirmPassword.errorMsg = "* Password not matching";
      errorFlag = true;
    }

    if (!regex.test(dataObj.password.value)) {
      dataObj.password.validate = false;
      dataObj.password.errorMsg =
        "Minimum 8 characters are allowed and use atleast one uppercase letter, one lowercase letter, one number and one special character.";
      errorFlag = true;
    }

    setFormData(dataObj); 

    // setFormData(dataObj);
    if (!errorFlag) {
      const user = JSON.parse(localStorage.getItem("user_register") as any);

      const obj = {
        guest: {
          password: dataObj.password.value,
          id: user.id,
        },
      };

      const reqOptions = {
        url: `/update_password`,
        method: "POST",
        data: obj,
      };

      sendRequestWithoutToken(reqOptions);
    }
  };

  const handleInputChange = (e: any) => {


    const dataObj = JSON.parse(JSON.stringify(formData));
    dataObj[e.target.name].value = e.target.value;
    if (e.target.value !== "") {
      dataObj[e.target.name].validate = true;
    }
    setFormData(dataObj);
  };

  useEffect(() => {
    if (error) {
      handleAlertErrorBox(error);
    }
  }, [error]);

  return (
    <>
      {loading && <Loader />}
      <WebDialog
        title="Welcome !"
        open={open}
        handleClose={handleClose}
        primaryButtonLabel="Sign In"
        subTitle="Sign in to continue"
      >
        <form onSubmit={handleRegistration}>
          <DialogContent>
            <DialogContentText>
              <SectionHeading title="Welcome !" />
              <p className="login_dialog_subtitle">
                Set a password to secure your account.
              </p>
            </DialogContentText>
            <Box
              sx={{
                position: "relative",
              }}
            >
              <InputsWithLabel
                label="Password"
                type={showPassword ? "text" : "password"}
                name="password"
                id="password"
                labelColor="#FDFCFC"
                placeholder="Enter password here"
                handleInputChange={handleInputChange}
              />
              <img
                style={{
                  position: "absolute",
                  right: "9px",
                  top: "40px",
                  cursor: "pointer",
                }}
                src="/icons/eye_icon.svg"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              />
              {!formData["password"].validate && (
                <p className="web_register_error_label">
                  <img src={ErrorImg} />
                  <span>{formData["password"].errorMsg}</span>
                </p>
              )}
            </Box>
            <Box
              sx={{
                marginTop: "45px",
              }}
            />
            <Box
              sx={{
                position: "relative",
              }}
            >
              <InputsWithLabel
                label="Confirm Password"
                type={showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                id="confirmPassword"
                labelColor="#FDFCFC"
                placeholder="Enter password here"
                handleInputChange={handleInputChange}
              />
              <img
                style={{
                  position: "absolute",
                  right: "9px",
                  top: "40px",
                  cursor: "pointer",
                }}
                src="/icons/eye_icon.svg"
                onClick={() => {
                  setShowConfirmPassword(!showConfirmPassword);
                }}
              />
              {!formData["confirmPassword"].validate && (
                <p className="web_register_error_label">
                  <img src={ErrorImg} />
                  <span>{formData["confirmPassword"].errorMsg}</span>
                </p>
              )}
              <p style={{color:"white", fontSize:"14px"}}>Minimum 8 characters are allowed and use atleast one uppercase letter, one lowercase letter, one number and one special character.</p>
            </Box>
          </DialogContent>
          <DialogActions className="login_dialog_actions">
            <Button
              variant="outlined"
              color="info"
              size="s"
              disable={disableButton}
              type="submit"
              handleClick={() => navigate(`/memberships`)}
            >
              Complete Registeration
            </Button>
          </DialogActions>
        </form>
      </WebDialog>
    </>
  );
};

export default WebPasswordDialog;
