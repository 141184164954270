import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import TextLabels from "../textLabels";
import { Box, Typography } from "@mui/material";
import { Button } from "../buttons";
import RemoveImg from "./images/remove.svg";
import { PropType } from "./types/propType";
import ErrorMessage from "../errorMessage";
import "./style.css";

const thumbsContainer: any = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb: any = {
  display: "inline-flex",
  borderRadius: 8,
  marginBottom: 8,
  marginRight: 20,
  width: 72,
  height: 72,
};

const thumbInner: any = {
  display: "flex",
  minWidth: 0,
  position: "relative",
  width: "100%",
  height: "100%",
  borderRadius: "8px",
};

const img: any = {
  display: "block",
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderRadius: "8px",
};
 
const DropZone = ({
  files,
  handleFiles,
  multiple = true,
  replace = false,
  browseAnother = false,
  browseAnotherText,
}: PropType) => {
  function nameLengthValidator(file: any) {
    // if (file.size > 5000) {
    if (file.size > 25165824) {
      return {
        code: "file-too-large",
        message: "Invalid image. Check for image format and size.",
      };
    }

    return null;
  }

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: {
      //"image/*": [],
      "image/jpeg": [],
      "image/jpg": [],
      "image/png": [],
    },
    onDrop: (acceptedFiles: any) => {
      //handleFiles(acceptedFiles)
      const newFiles: any = acceptedFiles.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      const filesArr = [...newFiles, ...files];
      handleFiles(filesArr);
    },
    validator: nameLengthValidator,
    multiple: multiple,
  });

  const handleRemove = (e: React.MouseEvent, index: number) => {
    const arr = [...files];
    arr.splice(index, 1);
    handleFiles(arr); 
  };

  const fileRejectionItems = fileRejections.map(({ file, errors }: any) => (
    <>
      {errors.map((e: any) => (
        <ErrorMessage key={e.code}>{e.message}</ErrorMessage>
      ))}
    </>
  ));

  const thumbs = files.map((file: any, i: number) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={RemoveImg}
          className="remove_icon"
          onClick={(e: React.MouseEvent) => handleRemove(e, i)}
        />
        <img
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      files.forEach((file: any) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <>
      {replace ? (
        <>
          <div className="dropzone_container">
            <div {...getRootProps({ className: "" })}>
            <input {...getInputProps()} />
              <Typography className="event-step2-toggle replace">
                <img src="icons/replace.png" style={{ paddingRight: "12px" }} />
                Replace
              </Typography>
            </div>
          </div>
        </>
      ) : browseAnother ? (
        <>
          <div className="dropzone_container">
            <div {...getRootProps({ className: "" })}>
            <input {...getInputProps()} />
              <Button
                sx={{
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #913713",
                  color: "#913713",
                  padding: "20px 20px !important",
                  "&:hover": {
                    backgroundColor: "#FFFFFF",
                    color: "#913713",
                  },
                }}
                type="button"
                btnClass="loginSubmitBtn changepwd-backBtn"
                color="error"
                variant="contained"
              >
                {browseAnotherText as string}
              </Button>
            </div>
          </div>
        </>
      ) : (
        <div className="dropzone_container">
          <div {...getRootProps({ className: "dropzone" })}>
            <TextLabels variant="h200" weight={400} color="#7E8085">
              Drag and drop your file
            </TextLabels>
            <Box
              sx={{
                paddingTop: "5px",
              }}
            ></Box>
            <TextLabels variant="h200" weight={400} color="#7E8085">
              Or
            </TextLabels>
            <Box
              sx={{
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            ></Box>
            <Button variant="outlined" size="s">
              Browse
            </Button>
            <Box
              sx={{
                paddingTop: "5px",
              }}
            ></Box>
            <Box>
              <TextLabels variant="h100" weight={400} color="#7E8085">
                Max file size:
              </TextLabels>{" "}
              <TextLabels variant="h100" weight={400} color="#140A0A">
                24 mb
              </TextLabels>
            </Box>
            <Box>
              <TextLabels variant="h100" weight={400} color="#7E8085">
                Supported file type:
              </TextLabels>{" "}
              <TextLabels variant="h100" weight={400} color="#140A0A">
                JPG, JPEG, PNG
              </TextLabels>
            </Box>
            <input {...getInputProps()} />
          </div>
          <aside style={thumbsContainer}>
            <Box display="flex" flexDirection="column">
              {fileRejectionItems && (
                <Box
                  sx={{
                    marginBottom: "30px",
                  }}
                >
                  {fileRejectionItems}
                </Box>
              )}
              <Box>{thumbs}</Box>
            </Box>
          </aside>
        </div>
      )}
    </>
  );
};

export default DropZone;
