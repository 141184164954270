import {combineReducers} from '@reduxjs/toolkit'
import authReducer from './slices/authSlice'
import sendEmailReducer from './slices/changePasswordSlice'
import resetPasswordReducer from './slices/resetPasswordSlice'

const rootReducer = combineReducers({
    auth: authReducer,
    sendEmail:sendEmailReducer,
    resetPassword:resetPasswordReducer
})

export default rootReducer