import React, { useEffect, useRef, useState } from "react";
import { Box, Grid } from "@mui/material";
import Tables from "../../atoms/tables";
import TabBar from "../../atoms/tabs";
import PlusImg from "./images/plus.svg";
import { ColumnTypes } from "./types/columnTypes";
import { DataTypes } from "./types/dataTypes";
import Topbar from "../../molecules/topbar";
import useHttp from "../../../utils/hooks/useHttp";
import { ReqOptions } from "../../../utils/types/reqOptions";
import moment from "moment";
import Paginate from "../../atoms/pagination";
import AlertCmp from "../../atoms/alert";
import Loader from "../../atoms/loader";
import "./style.css";
import {
  SEND_MESSAGE_MULTIPLE_ACTION,
  BLOCK_PROFILE_MULTIPLE_ACTION,
  DELETE_USER_MULTIPLE_ACTION,
  EXPORT_MULTIPLE_ACTION,
  DOTS_SEND_MESSAGE,
  DOTS_VIEW_PROFILE,
  DOTS_DELETE_USER,
  DOTS_BLOCK_PROFILE,
  USERS_RECORDS_PER_PAGE,
  USERS_GET_URL,
} from "../../../utils/configs";
import NoMember from "../../molecules/nomember";
import { Button } from "../../atoms/buttons";
import ModalImageUploadPopup from "../../atoms/modalImageUploadPopup";
import ModalPopup from "../../atoms/modalPopup";
import TextLabels from "../../atoms/textLabels";
import DropZone from "../../atoms/dropZone";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Input } from "../../atoms/inputs";
import InputsWithLabel from "../../molecules/inputsWithLabel";
import BannersCard from "../../molecules/bannersCard";
import { Editor } from "primereact/editor";

const columns: ColumnTypes[] = [
  { key: "fullName", label: "Full Name" },
  { key: "email", label: "Email" },
  { key: "cdSetQuantity", label: "CD Set Quantity" },
  { key: "address", label: "Address" },
  { key: "action", label: "Actions" },
];

const tabs = [
  {
    value: "banners",
    label: "Banners",
  },
  // {
  //   value: "all",
  //   label: "New CD Orders",
  // },
  // {
  //   value: "pastcdorders",
  //   label: "Past CD Orders",
  // },
];

const items: DataTypes[] = [
  {
    id: 1,
    fullName: "Vyarth Verma",
    email: "arths1234@xyz.com",
    cdSetQuantity: 1,
    address: "1756 Sugar Camp Road, Minnesota Lake, Zip- 56068",
    action: ["dots"],
    dotActions: ["fulfilled_order", "cancel_order"],
  },
  {
    id: 2,
    fullName: "Vyarth Verma",
    email: "arths1234@xyz.com",
    cdSetQuantity: 2,
    address: "3041 Clarksburg Park Road, Phoenix, Zip- 85075",
    action: ["dots"],
    dotActions: ["fulfilled_order", "cancel_order"],
  },
  {
    id: 3,
    fullName: "Vyarth Verma",
    email: "arths1234@xyz.com",
    cdSetQuantity: 1,
    address: "1756 Sugar Camp Road, Minnesota Lake, Zip- 56068",
    action: ["dots"],
    dotActions: ["fulfilled_order", "cancel_order"],
  },
  {
    id: 4,
    fullName: "Vyarth Verma",
    email: "arths1234@xyz.com",
    cdSetQuantity: 3,
    address: "1756 Sugar Camp Road, Minnesota Lake, Zip- 56068",
    action: ["dots"],
    dotActions: ["fulfilled_order", "cancel_order"],
  },
  {
    id: 5,
    fullName: "Vyarth Verma",
    email: "arths1234@xyz.com",
    cdSetQuantity: 2,
    address: "3041 Clarksburg Park Road, Phoenix, Zip- 85075",
    action: ["dots"],
    dotActions: ["fulfilled_order", "cancel_order"],
  },
  {
    id: 6,
    fullName: "Vyarth Verma",
    email: "arths1234@xyz.com",
    cdSetQuantity: 1,
    address: "3041 Clarksburg Park Road, Phoenix, Zip- 85075",
    action: ["dots"],
    dotActions: ["fulfilled_order", "cancel_order"],
  },
];

const CDOrdersAndBanner = () => {
  const { loading, error, sendRequest } = useHttp(transformData);
  const {
    loading: loadingBanner,
    error: errorBanner,
    sendRequest: sendRequestBanner,
  } = useHttp(transformDataBanners);
  const {
    loading: loadingBannerCreate,
    error: errorBannerCreate,
    sendRequest: sendRequestBannerCreate,
  } = useHttp(transformDataBannerCreate);
  const [search, setSearch] = useState(null);
  const [initialRender, setInitialRender] = useState(false);
  const [activeTab, setActiveTab] = useState("banners");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(USERS_RECORDS_PER_PAGE);
  const [users, setUsers] = useState<DataTypes[]>([]);
  const [cdOrdersList, setCdOrdersList] = useState<DataTypes[]>(items);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showInputAlert, setShowInputAlert] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [showMultipleActions, setShowMultipleActions] = useState(false);
  const [files, setFiles] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [openScheduler, setOpenScheduler] = useState(false);
  const [resetModal, setResetModal] = useState(false);
  const [eventDate, setEventDate] = useState<any>(new Date());
  const [eventEndDate, setEventEndDate] = useState<any>(new Date());
  const [startTimeFormat, setStartTimeFormat] = useState("AM");
  const [startTimeMin, setStartTimeMin] = useState<any>(0);
  const [startTimeHour, setStartTimeHour] = useState<any>(0);
  const [startTimeBox, setStartTimeBox] = useState(false);
  const [bannersData, setBannersData] = useState<any>(null);
  const [filterYearValue, setFilterYearValue] = useState(null);
  const [title, setTitle] = useState<any>('')

  const [editorState, setEditorState] = useState<any>('')

  const ref1 = useRef(null) as any;

  const handleSchedulerClose = () => {
    setOpenScheduler(false);
    setResetModal(!resetModal);
    setEventDate(new Date());
    setStartTimeMin(0);
    setTitle('')
    setEditorState('')
    setEventEndDate(new Date())
  }

  function transformDataBanners(data: any) {
    if (data.status) {
      setBannersData(data.banners);
      setTotalRecords(data?.total)
    }
  }

  // function transformDataBannerCreate(data: any) {
  //     window.location.reload()
  // }


  function transformData(data: any) {
    let usersArr: any;
    if (data.status) {
      if (data.blocked_users) {
        usersArr = data.blocked_users.map((user: any) => {
          const obj: any = {};
          obj.id = user.id;
          obj.fullName = `${user.first_name || "--"} ${user.last_name || "--"}`;
          obj.email = user.email || "--";
          obj.mobile = user.contact_number || "--";
          obj.dateJoined = user.created_at
            ? moment(user.created_at).format("Do MMM, YY")
            : "--";
          obj.membership = user.membership || "--";
          // obj.action = ["fulfilled_order", "cancel_order"];

          return obj;
        });
      } else {
        usersArr = data.users.map((user: any) => {
          const obj: any = {};
          obj.id = user.id;
          obj.fullName = `${user.first_name || "--"} ${user.last_name || "--"}`;
          obj.email = user.email || "--";
          obj.mobile = user.contact_number || "--";
          obj.dateJoined = user.created_at
            ? moment(user.created_at).format("MM/DD/YY")
            : "--";
          obj.membership = user.membership || "--";

          const actions = [];

          if (user.blocked) {
            actions.push("unblock");
          } else {
            actions.push("block");
          }

          actions.push("delete");

          obj.action = actions;

          return obj;
        });
      }

      setTotalRecords(data.total);
      setUsers(usersArr);
    }
  }



  // useEffect(() => {
  //   const requestOptions: ReqOptions = {
  //     url: USERS_GET_URL,
  //     method: "GET",
  //     params: {
  //       tab: activeTab,
  //       page: currentPage,
  //       per_page: USERS_RECORDS_PER_PAGE,
  //     },
  //   };

  //   setInitialRender(true);
  //   sendRequest(requestOptions);
  // }, []);
  useEffect(() => {
    const reqOptions: ReqOptions = {
      url: "/banners/get_banners",
      method: "GET",
      params: {
        page: currentPage,
        per_page: USERS_RECORDS_PER_PAGE,
        search: search,
      },
    };
    setInitialRender(true);
    sendRequestBanner(reqOptions);
  }, []);

  useEffect(() => {
    if (initialRender) {
      if (activeTab === "banners") {
        const reqOptions: ReqOptions = {
          url: "/banners/get_banners",
          method: "GET",
          params: {
            page: currentPage,
            per_page: USERS_RECORDS_PER_PAGE,
            search: search,
          },
        };

        sendRequestBanner(reqOptions);
      } else {
        const reqOptions: ReqOptions = {
          url: USERS_GET_URL,
          method: "GET",
          params: {
            tab: activeTab,
            page: currentPage,
            per_page: USERS_RECORDS_PER_PAGE,
            search: search,
          },
        };

        sendRequest(reqOptions);
      }
    }
  }, [currentPage, activeTab]);

  useEffect(() => {
    if (search !== null) {
      const timer = setTimeout(() => {
        const reqOptions: ReqOptions = {
          url: USERS_GET_URL,
          method: "GET",
          params: {
            tab: activeTab,
            page: currentPage,
            per_page: USERS_RECORDS_PER_PAGE,
            search: search,
          },
        };

        sendRequest(reqOptions);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [search]);

  useEffect(() => {
    if (showSuccessAlert) {
      const timer = setTimeout(() => {
        setShowSuccessAlert(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [showSuccessAlert]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref1.current && !ref1.current.contains(event.target)) {
        setStartTimeBox(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // alert('xhb')

  const handleApiFetchAgain = () => {
    const reqOptions: ReqOptions = {
      url: "/banners/get_banners",
      method: "GET",
      params: {
        page: currentPage,
        per_page: USERS_RECORDS_PER_PAGE,
      },
    };
    sendRequestBanner(reqOptions);
  };

  const handleTabClick = (val: "all" | "pastcdorders" | "banners") => {
    setActiveTab(val);
    setCurrentPage(1);
  };

  const handlePageChange = (e: any, page: number) => {
    setCurrentPage(page);
  };

  const handleSearchInput = (e: any) => {
    setSearch(e.target.value);
  };

  const handleSuccessCall = (msg: string) => {
    setShowSuccessAlert(true);
    setSuccessMsg(msg);
  };

  const handleCheckSelected = (selected: boolean) => {
    setShowMultipleActions(selected);
  };

  const handleCallback = (files: any) => {
    if (files.length > 0) {
      setFiles(files);
      setOpen(false);
      setOpenPreview(true);
    }
  };

  useEffect(() => {
    setFiles([]);
  }, [resetModal]);

  const handleFiles = (files: any) => {
    setFiles(files);
  };

  const handleCreateBanner = () => {
    const formData = new FormData();
    const startTime = `${startTimeHour > 0 ? startTimeHour : "00"} : ${startTimeMin > 0 ? startTimeMin : "00"
      } ${startTimeFormat}`;

    formData.append("banner_image", files[0]);
    formData.append("schedule_date", moment(eventDate).format("YYYY-MM-DD"));
    formData.append("schedule_time", startTime);
    formData.append("end_date", moment(eventEndDate).format("YYYY-MM-DD"));
    formData.append("title", title)
    formData.append("description", editorState)

    const scheduleDate = moment(eventDate);
    const endDate = moment(eventEndDate);

    if (endDate.isBefore(scheduleDate)) {
      // Handle the error here, e.g., display an error message or return early
      setShowErrorAlert(true);
      const timer = setTimeout(() => {
        setShowErrorAlert(false);
      }, 5000);
      return;
    }

    const regex = /\S/; // to check if field is empty or not

    const titleField = title.trim()
    const descriptionField = editorState.trim()

    // console.log("submit time editor state:", editorState);
    

    if (!regex.test(titleField) && !regex.test(descriptionField)) {
      setShowInputAlert(true);
      const timer = setTimeout(() => {
        setShowInputAlert(false);
      }, 5000);
      return;
    }

    const reqOptions = {
      url: `/banners/create_banner`,
      method: "POST",
      contentType: "multipart/form-data",
      data: formData,
    };
    // console.log(formData);


    sendRequestBannerCreate(reqOptions);
  };

  useEffect(() => {
    if (filterYearValue !== null) {
      const reqOptions: ReqOptions = {
        url: "/banners/get_banners",
        method: "GET",
        params: {
          page: currentPage,
          per_page: USERS_RECORDS_PER_PAGE,
          year: filterYearValue
        },
      };
      sendRequestBanner(reqOptions);
    }
  }, [filterYearValue])

  const handleYearFilter = (val: any) => {
    setFilterYearValue(val);
  };


  function transformDataBannerCreate(data: any) {
    if (data.status) {
      handleSuccessCall("Banner created successfully");
      handleSchedulerClose();
      handleApiFetchAgain();
      setStartTimeHour(0);
      setEventDate(new Date());
      setStartTimeMin(0);
      setTitle('')
      setEditorState('')
      setEventEndDate(new Date())
    }
  }


  console.log("Editor State:", editorState);  

  return (
    <>
      {(loading || loadingBanner || loadingBannerCreate) && <Loader />}
      {showSuccessAlert && (
        <AlertCmp type="success" position="dashboard">
          {successMsg}
        </AlertCmp>
      )}
      {showErrorAlert && (
        <AlertCmp type="error" position="dashboard">
          {`End date cannot be less than start date`}
        </AlertCmp>
      )}
      {showInputAlert && (
        <AlertCmp type="error" position="dashboard">
          {`Input fields cannot be empty`}
        </AlertCmp>
      )}

      <TabBar handleClick={handleTabClick} tabs={tabs} />

      <Box className="header">
        <Topbar
          searchLabel="Search by name, email ID..."
          showBtn={activeTab === "banners" ? true : false}
          filterBtn={true}
          totalRecords={true}
          totalRecordsCount={totalRecords}
          handleSearchInput={handleSearchInput}
          handleYearFilter={handleYearFilter}
          // showMultipleActions={showMultipleActions}
          mutipleOptions={[
            SEND_MESSAGE_MULTIPLE_ACTION,
            BLOCK_PROFILE_MULTIPLE_ACTION,
            DELETE_USER_MULTIPLE_ACTION,
            EXPORT_MULTIPLE_ACTION,
          ]}
          handleCreateBtn={() => setOpen(true)}
          modal={{
            heading: "Upload Banner",
            inputs: [],
          }}
        />
      </Box>

      {activeTab === "all" ? (
        <>
          {cdOrdersList.length ? (
            <>
              <Tables
                showCheckbox={true}
                tab={activeTab}
                columns={columns}
                data={cdOrdersList}
                handleApiFetchAgain={handleApiFetchAgain}
                apiUrl={USERS_GET_URL}
                blockUrl={"/users/block_unblock_user"}
                handleSuccessCall={handleSuccessCall}
                handleCheckSelected={handleCheckSelected}

              // deleteModal={{
              //   heading: "Delete Banner?",
              //   content:
              //     "Are you sure you want to delete this user? This action is non-reversible and can not be undone.",
              //   primaryButton: "Delete user",
              //   secondaryButton: "Cancel",
              // }}
              />
              <Paginate
                page={currentPage}
                totalRecords={totalRecords}
                recordsPerPage={6}
                handlePageChange={handlePageChange}
              />
            </>
          ) : (
            <>
              {!loading && (
                <NoMember
                  heading="No New Orders Found"
                  content={`You’re all set! You’ve fulfilled all the new orders.`}
                />
              )}
            </>
          )}
        </>
      ) : activeTab === "pastcdorders" ? (
        <NoMember
          heading="No Past Orders Found"
          content={`You’re all set! You’ve fulfilled all the past orders.`}
        />
      ) : (
        <Box className="wrapper_banner">
          {bannersData ? (
            <>
              <Grid container spacing={4}>
                {bannersData.map((banner: any, i: number) => {
                  return (
                    <Grid key={banner.id} item xs={4}>
                      <Box className="admin_banner_grid_box">
                        <BannersCard {...banner} handleApiFetchAgain={handleApiFetchAgain} handleSuccessCall={handleSuccessCall} />
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
              <Paginate
                page={currentPage}
                totalRecords={totalRecords}
                recordsPerPage={6}
                handlePageChange={handlePageChange}
              />
            </>
          ) : (
            <>
              <NoMember
                heading="No Active Banners"
                content={`No banners are displaying on the homepage.`}
              />
              <Box className="wrapper_banner_button">
                <Button
                  variant="outlined"
                  color="primary"
                  icon={PlusImg}
                  size="s"
                  handleClick={() => setOpen(true)}
                >
                  Add a Banner
                </Button>
              </Box>
            </>
          )}
        </Box>
      )}

      {/* First Popup Modal */}
      <ModalImageUploadPopup
        open={open}
        handleClose={() => setOpen(false)}
        heading="Upload Banner"
        subHeading="Upload Banner Image"
        submitBtnText="Schedule"
        cb={handleCallback}
        resetModal={resetModal}
      />

      {/* Second Popup Modal */}
      <ModalPopup
        open={openPreview}
        handleClickOpen={() => { }}
        handleClose={() => {
          setOpenPreview(false);
          setOpen(false);
          setResetModal(!resetModal);
        }}
        heading="Upload Banner"
        closeBtn={true}
        primaryAction={() => { }}
        content={
          <>
            <Box sx={{ padding: "20px 0px", color: "#000" }}>
              <TextLabels variant="h100" weight={700}>
                Upload Banner Image
              </TextLabels>
            </Box>
            <Box>
              <img
                src={files[0]?.preview}
                width={"100%"}
                height={"277px"}
                style={{ objectFit: "cover" }}
              />
            </Box>
            <Box sx={{ margin: "25px 0" }}>
              <Box
                sx={{
                  position: "relative",
                  margin: "10px 0px",
                }}
              >
                <Button
                  type="button"
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "12px",
                    lineHeight: "14px",
                    textDecoration: "none",
                    backgroundColor: "#913713",
                    padding: "20px 20px !important",
                    "&:hover": {
                      backgroundColor: "#913713",
                    },
                  }}
                  btnClass="nextBtnClass changepwd-nextBtn"
                  handleClick={() => {
                    setOpenScheduler(true);
                    setOpenPreview(false);
                  }}
                  color="error"
                  variant="contained"
                >
                  Upload
                </Button>

                <DropZone
                  browseAnother={true}
                  browseAnotherText={"Reupload Banner"}
                  multiple={false}
                  files={files}
                  handleFiles={(files: any) => {
                    handleFiles(files);
                  }}
                />
              </Box>
            </Box>
          </>
        }
      /> 

      {/* Third Popup Modal */}
      <ModalPopup
        open={openScheduler}
        handleClickOpen={() => { }}
        handleClose={handleSchedulerClose}
        heading="Schedule Date and Time"
        closeBtn={true}
        primaryAction={() => { }}
        content={
          <>
            <Box className="wrapper_scheduler">
              <Box className="wrapper_date">
                <TextLabels variant="h5" color="#2C2D30" weight={700}>
                  Start Date
                </TextLabels>
                <Box sx={{ position: "relative" }}>
                  <ReactDatePicker
                    className="custom-datepicker"
                    dateFormat="MM/dd/yyyy"
                    selected={eventDate}
                    onChange={(date: any) => {
                      setEventDate(date);
                    }}
                    minDate={new Date()}
                  />
                  <Box
                    className="event-date-icon"
                    sx={{ position: "absolute", top: "21px", left: "10px" }}
                  >
                    <img src="icons/calendar.png" alt="" />
                  </Box>
                </Box>
              </Box>

              <Box className="wrapper_date">
                <TextLabels variant="h5" color="#2C2D30" weight={700}>
                  End Date
                </TextLabels>
                <Box sx={{ position: "relative" }}>
                  <ReactDatePicker
                    className="custom-datepicker"
                    dateFormat="MM/dd/yyyy"
                    selected={eventEndDate}
                    onChange={(date: any) => {
                      setEventEndDate(date);
                    }}
                    minDate={eventDate}
                  />
                  <Box
                    className="event-date-icon"
                    sx={{ position: "absolute", top: "21px", left: "10px" }}
                  >
                    <img src="icons/calendar.png" alt="" />
                  </Box>
                </Box>
              </Box>

              {/* <Box sx={{ position: "relative" }}>
                <InputsWithLabel
                  label="Time"
                  type="text"
                  name="start_time"
                  id="start_time"
                  placeholder="00 AM"
                  handleClick={() => setStartTimeBox(!startTimeBox)}
                  inputProps={{ readonly: true }}
                  // {
                  //   ...(startTimeHour>0)&&{'value':`${startTimeHour>0?startTimeHour:"00"} : ${startTimeMin>0?startTimeMin:"00"}`}
                  // }
                  value={`${startTimeHour > 0 ? startTimeHour : "00"} : ${
                    startTimeMin > 0 ? startTimeMin : "00"
                  } ${startTimeFormat}`}
                />
                <Box
                  className="event-clock-icon"
                  sx={{ position: "absolute", top: "62px", left: "10px" }}
                >
                  <img src="icons/clock.png" />
                </Box>
                {startTimeBox && (
                  <Box id="timeBox1" ref={ref1} sx={{}}>
                    <Box sx={{ textAlign: "center", marginBottom: "20px" }}>
                      <TextLabels variant="h200" weight={500}>
                        Enter Time
                      </TextLabels>
                    </Box>

                    <Box sx={{ display: "inline-flex" }}>
                      <Box sx={{ position: "relative" }}>
                        <Input
                          id="start_time_hour"
                          sx={{ width: "80px", height: "48px" }}
                          placeholder="00"
                          type="text"
                          handleChange={(e: any) =>
                            setStartTimeHour(
                              Math.min(
                                e.target.value
                                  .replace(/\D/g, "")
                                  .substring(0, 2),
                                12
                              )
                            )
                          }
                          value={startTimeHour}
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                            maxLength: 2,
                          }}
                        />
                        <Box sx={{ position: "absolute", top: "55px" }}>
                          <TextLabels variant="h200" weight={500}>
                            Hour
                          </TextLabels>
                        </Box>
                      </Box>

                      <Box sx={{ padding: "10px 10px 0px 10px" }}>
                        <img src="icons/colon.png" alt="" />
                      </Box>
                      <Box sx={{ position: "relative" }}>
                        <Input
                          id="start_time_min"
                          sx={{ width: "80px", height: "48px" }}
                          type="text"
                          placeholder="00"
                          handleChange={(e: any) =>
                            setStartTimeMin(
                              Math.min(
                                e.target.value
                                  .replace(/\D/g, "")
                                  .substring(0, 2),
                                59
                              )
                            )
                          }
                          value={startTimeMin}
                        />
                        <Box sx={{ position: "absolute", top: "55px" }}>
                          <TextLabels variant="h200" weight={500}>
                            Minute
                          </TextLabels>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          border: "0.75px solid #D5D7DB",
                          height: "45px",
                          marginLeft: "10px",
                        }}
                      >
                        <Button
                          btnClass="timeBtn"
                          sx={{}}
                          handleClick={() => setStartTimeFormat("AM")}
                        >
                          AM
                        </Button>
                        <Button
                          btnClass="timeBtn"
                          sx={{}}
                          handleClick={() => setStartTimeFormat("PM")}
                        >
                          PM
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box> */}
            </Box>

            <Box>
              <InputsWithLabel
                label="Banner Title"
                placeholder="Enter Banner Title"
                type="text"
                name="title"
                id="banner_title"
                value={title}
                handleInputChange={(e: any) => { console.log("ee", e.target.value); setTitle(e.target.value) }}
              />
              {/* <Editor value={editorState} onChange={(e: any) => {
                // setInputsValidation((prevObj: any) => ({
                //   ...prevObj,
                //   message: {
                //     ...prevObj.message,
                //     value: e.htmlValue
                //   }
                // }))
                console.log(editorState);
                
                setEditorState(e.target.value)
              }} /> */}

              <Editor
                value={editorState}
                onTextChange={(e: any) => {
                  // console.log("New HTML Value:", e.htmlValue); // Log the new HTML value
                  // console.log("Editor State:", editorState); // Debugging statement
                  setEditorState(e.htmlValue);
                }}
              />
              
              {/* <Editor
                value={editorState}
                onTextChange={(e: any) => {
                  const htmlValue = e.htmlValue.trim(); // Remove leading and trailing whitespace

                  // Define a regular expression to match non-whitespace characters
                  const regex = /\S/;

                  if (regex.test(htmlValue)) {
                    // If the field is not empty, update the editor state
                    setEditorState(htmlValue);
                  } 
                  // else {
                  //   // If the field is empty, handle the validation error
                  //   console.error('Field is empty');
                  //   // You can display an error message or prevent the update as needed
                  // }
                }}
              /> */}

            </Box>

            <Box className="scheduler_buttons" sx={{ margin: "25px 0" }}>
              <Button
                type="button"
                btnClass=""
                variant="outlined"
                color="primary"
                size="s"
                handleClick={() => {
                  setOpenScheduler(false);
                  setOpenPreview(true);
                  setFiles((prev: any) => {
                    return [...prev].map((item: any) => Object.assign(item, { preview: URL.createObjectURL(item) }))
                  })
                }}
              >
                Go Back
              </Button>
              <Button
                type="button"
                btnClass=""
                variant="contained"
                color="primary"
                size="s"
                handleClick={handleCreateBanner}
              // disable={startTimeHour < 1}
              >
                Schedule
              </Button>
            </Box>
          </>
        }
      />
    </>
  );
};

export default CDOrdersAndBanner;
