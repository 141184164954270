import { useEffect, useState } from "react";
import useHttp from "../../../utils/hooks/useHttp";
import moment from "moment";
import { transformEventWithStatus } from "../../../utils/events";

// admintStatus: admitted, admits

const useUserEvents = (userId: string, isAdmin?: boolean) => {
  const { loading, error, sendRequestWeb, sendRequest } =
    useHttp(transformData);
  const [events, setEvents] = useState<any>([]);

  function transformData(data: any) {
    if (!data?.status) return;
    const result = data.event.map((event: any) => {
      const start_time =
        event.start_date?.length > 8
          ? moment(event.start_date).utc(false).format("hh:mm A")
          : event.start_date;

      const eventWithStatus = transformEventWithStatus(event);
      let admitStatus = "admits";
      if (eventWithStatus.cancel) {
        admitStatus = "cancelled";
      } else if (
        eventWithStatus.status === "past" ||
        eventWithStatus.status === "live"
      ) {
        admitStatus = "admitted";
      }
      return {
        ...event,
        locationLink: event.location,
        location: event.venue,
        passCount: event.no_of_passes,
        ticketCount: event.no_of_tickets,
        admitStatus: admitStatus,
        admitNames: event.attendee?.map((user:any)=>user.name) || [],
        time: `${moment(event.date).format("MMMM D YYYY")}, 
      ${start_time}`,
        event_price: event.ticket_details
          ? event.ticket_details[1]?.advance_value
          : "--"
      };
    });
    setEvents(result);
  }

  useEffect(() => {
    const reqOptions = {
      url: `/user_events?user_id=${userId}`,
      method: "GET"
    };

    // const requestOptions: ReqOptions = {
    //   url: `/event_managments?tab=past`,
    //   method: "GET",
    //   params: {
    //     tab: 'past',
    //     page: 1,
    //     per_page: 8,
    //   },
    // };
    if (isAdmin) {
      sendRequest(reqOptions);
    } else {
      sendRequestWeb(reqOptions);
    }
  }, []);

  return { loading, error, events };
};

export default useUserEvents;
