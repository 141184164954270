import React from "react";
import Dashboard from "../../templates/dashboard";
import Layout from "../../organism/layout";

const DashboardPage = () => {
  return (
    <Layout pageName="Dashboard" menuActive="Dashboard">
      <Dashboard />
    </Layout>
  );
};

export default DashboardPage;
