import React from "react";
import Membership from "../../templates/membership";
import Layout from "../../organism/layout";

const MembershipPage = () => {
  return (
    <Layout pageName="Membership" menuActive="Membership">
      <Membership />
      
    </Layout>
  );
};

export default MembershipPage;
