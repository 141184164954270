import React, { useEffect, useState } from "react";
import { PropType } from "./types/propType";
import { Box } from "@mui/material";
import "./style.css";

const WebsiteArtistImage = ({ image, name, type, previewBanner, lengthCheck, marginLeft, arrLength }: PropType) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [baseRoute, setBaseRoute] = useState('');

  useEffect(() => {
    const url = window.location.href;
    const parts = url.split('/');
    const baseRoute = `/${parts[3]}`;
     // Assuming the base part is at index 3
    setBaseRoute(baseRoute);
  }, []);

  return (
    <Box
      className={
        previewBanner
          ? "artist_image_website_info artist_image_website_info_preview"
          : "artist_image_website_info"
      }
      style={{
        marginLeft: `${marginLeft}px`,
      }}

    >
      <Box
        className={
          // previewBanner ? 
          // "artist_image_line_new" 
          window?.location?.pathname == '/music-masala' ? "artist_image_website_box" : "artist_image_line_new"
        }
      >
        <Box >
          <img style={arrLength == 1 && screenWidth < 700 ? {width: '200px', height: '300px'} : {width: '100%'}} className="artist_img_tag" src={image} />
        </Box>
        <Box
        className={
          previewBanner
            ? "artist_image_name_wrapper artist_image_name_wrapper_preview"
            : "artist_image_name_wrapper"
        }
      >
        {/* {name} */}
        {/* {
           name?.split(' ').map((names:any)=>(
            <div className="artist_name_split">
            {names}
            </div>
           ))
        } */}
       
          {name && <h4 >{name}</h4>}
  

        {/* {type && <div style={{display:'flex',justifyContent:'center'}}><p style={{display:"inline"}}>({type})</p></div>} */}
      </Box>
      </Box>
      
    </Box>
  );
};

export default WebsiteArtistImage;
