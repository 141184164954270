/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Layout from "../../organism/layout";
import ProfileLeftSideContainer from "../../organism/profileLeftSideContainer/ProfileLeftSideContainer";
import { Grid } from "@mui/material";
import ProfileRightSideConatiner from "../../organism/profileRightSideContainer/ProfileRightSideConatiner";
import "./style.css";
import profileManagementApi from "../../../utils/hooks/profileManagementApi";
import AlertCmp from "../../atoms/alert";
import Loader from "../../atoms/loader";
import { useLocation, useNavigate } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";
import { inputDataField } from "./inputFieldData";
import getUserDataApi from "../../../utils/hooks/getUserDataApi";

const EditProfileManagement = () => {
  const { getAllMembershipData, createProfileApi, loading, error } =
    profileManagementApi();
  const { getUserDeatils, userDataerror, userDataloading } = getUserDataApi();
  const [leftSideData, setLeftSideData] = useState<any>(inputDataField);
  const [membershipsData, setMembershipData] = useState<any>([]);
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [responseData, setResponseData] = useState<any>(null);
  const [showErr, setShowErr] = useState<any>({
    email: null,
    zipcode: null,
    mobile_no: null,
  });

  const { state } = useLocation();
  const navigate = useNavigate();

  const handleonChange = (newValue: any) => {
    const selectedId = newValue?.map((item: any) => item?.id);
    setSelectedOptions(selectedId);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault(); // prevent form from refreshing the page
    let apiUrl = "create_member_from_admin",
      apiMethod = "POST";
    if (state) {
      const {
        record: { id },
      } = state;
      apiUrl = `update_member_from_admin?user_id=${id}`;
      apiMethod = "PUT";
    }
    const form = e.target;
    let formData = new FormData(form);
    let formObj: any = Object.fromEntries(formData?.entries());
    for (var key in formObj) {
      if (formObj[key] === "") {
        delete formObj[key];
      }
    }
    const data: any = {
      ...formObj,
      ...(selectedOptions?.length && { membership_ids: selectedOptions }),
    };
    if (Object.values(showErr)?.every((item) => item === null)) {
      const response = await createProfileApi(data, apiUrl, apiMethod);
      setResponseData(response);
      if (response?.status) {
        setTimeout(() => {
          navigate("/user-management");
        }, 3000);
      }
    }
  };

  let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  let zipcode = /^\d{1,6}$/;
  let phoneNumber = /^\d{1,15}$/;

  //validate form
  const validateForm = (name: string, value: string) => {
    switch (name) {
      case "email":
        if (emailRegex?.test(value)) {
          return null;
        } else {
          return "Please Enter Valid Email";
        }
      case "zipcode":
        if (zipcode?.test(value)) {
          return null;
        } else {
          return "Please Enter Valid  Zip Code";
        }
      case "mobile_no":
        if (phoneNumber?.test(value)) {
          return null;
        } else {
          return "Please Enter Valid Mobile Number";
        }
      default:
        return null;
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Assuming each input field has a 'name' attribute
    const { name, value } = e.target;
    const errMsg = validateForm(name, value);
    setShowErr({ ...showErr, [name]: errMsg });
    setLeftSideData((prevState: any) => {
      return prevState.map((field: any) => {
        if (field.name === name) {
          return { ...field, value: value };
        }
        return field;
      });
    });
  };

  const handleDatePickerChange = (date: any, name: string) => {
    setLeftSideData((prevState: any) => {
      return prevState.map((field: any) => {
        if (field.name === name) {
          return { ...field, value: date };
        }
        return field;
      });
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setResponseData(null);
    }, 5000);
    return function cleanup() {
      clearInterval(responseData);
    };
  }, [responseData]);

  useEffect(() => {
    //get membership api call
    const getAllMembership = async () => {
      const { memberships } = await getAllMembershipData("list_membership");
      setMembershipData(memberships);
    };
    getAllMembership();
    return () => {};
  }, []);

  useEffect(() => {
    const getUserData = async () => {
      if (state) {
        const {
          record: { id },
        } = state;
        const { user } = await getUserDeatils("edit_member_from_admin", id);
        const clonedObject = cloneDeep(inputDataField);
        clonedObject.forEach((field: any) => {
          if (user.hasOwnProperty(field.name)) {
            field.value = user?.[field.name];
          }
        });
        setLeftSideData(clonedObject);
        setSelectedOptions(user?.membership_ids);
      }
    };
    getUserData();
    return () => {};
  }, [state]);

  return (
    <Layout pageName="Add user" menuActive="User Management">
      {(loading || userDataloading) && <Loader />}
      {(responseData || error || userDataerror) && (
        <AlertCmp
          type={responseData?.status ? "success" : "error"}
          position="dashboard"
        >
          {responseData?.message ?? error ?? userDataerror}
        </AlertCmp>
      )}
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div className="outerConatinerBox">
              <ProfileLeftSideContainer
                leftSideData={leftSideData?.slice(0, 8)}
                handleChange={handleChange}
                membershipsData={membershipsData}
                handleonChange={handleonChange}
                selectedOptions={selectedOptions}
                showErr={showErr}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="outerConatinerBox">
              <ProfileRightSideConatiner
                rightSideData={leftSideData?.slice(8)}
                handleChange={handleChange}
                handleDatePickerChange={handleDatePickerChange}
                isEdit={state ? true : false}
              />
            </div>
          </Grid>
        </Grid>
      </form>
    </Layout>
  );
};

export default EditProfileManagement;
