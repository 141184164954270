import { Box, TextField } from "@mui/material";
import InputsWithLabel from "../../molecules/inputsWithLabel";
import Autocomplete from "@mui/material/Autocomplete";
import '../../pages/editProfileManagement/style.css'




const ProfileLeftSideContainer = ({leftSideData,membershipsData,handleonChange,selectedOptions,handleChange,showErr}:any) => {

  return (    
    <div>
      {leftSideData?.map((item:any) => (
        <Box>
          { item?.type === "dropdown" ? (
            <>
            
              <label className="inputLabel">{item?.label}</label>
              <Autocomplete
                multiple
                limitTags={2}
                id={item?.name}
                options={membershipsData}
                getOptionLabel={(option:any) =>option?.membership_type}
                renderInput={(params) => (
                  <TextField {...params} placeholder={item?.placeHolder} />
                )}
                sx={{ width: "500px" }}
                onChange={(e:any, value:any) => handleonChange(value)}
                defaultValue={membershipsData?.filter((option: any) => selectedOptions?.find((i:number) => i === option?.id))}
                value={membershipsData?.filter((option: any) => selectedOptions?.find((i:any) => i === option?.id))}
              />
            </>
          ) : (
            <>
              <InputsWithLabel
                label={item?.label}
                type={item.type}
                name={item.name}
                id={item.name}
                placeholder={item.placeHolder}
                value={item?.value}
                handleInputChange={handleChange}
                helperText={showErr?.[item?.name] && true}
                msg={showErr?.[item?.name]}
              />
            </>
          )}
        </Box>
      ))}
    </div>
  );
};

export default ProfileLeftSideContainer;
