import React from "react";
import Layout from "../../organism/layout";
import ParticipantList from "../../templates/participantList";

const ParticipantListPage = () => {
  return (
    <Layout pageName="Event Management" menuActive="Event Management">
      <ParticipantList />
    </Layout>
  );
};

export default ParticipantListPage;
