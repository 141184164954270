import React from "react";
import { TextField, InputAdornment, Box } from "@mui/material";
import SearchImg from "./images/Search.svg";
import { PropType } from "./types/propType";
import "./style.css";

const SearchInput = ({ label, handleSearchInput }: PropType) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
      }}
      className="search_input"
    >
      <img src={SearchImg} style={{ position: "absolute", left: "10px" }} />
      <TextField
        id="input-with-sx"
        InputLabelProps={{
          style: { color: "#9FA1A6", fontSize: "14px", paddingLeft: "20px" },
        }}
        label={label}
        variant="outlined"
        sx={{
          width: "100%",
          height: "50px",
        }}
        onChange={handleSearchInput}
      />
    </Box>
  );
};

export default SearchInput;
