import React from "react";
import NotFoundImg from "./images/notfound.svg";
import TextLabels from "../../atoms/textLabels";
import { Box } from "@mui/material";
import "./style.css";

const NotFound = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <img src={NotFoundImg} className="not_found_img" />
      <Box
        sx={{
          width: "50%",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TextLabels variant="h300" weight={500} color="#404145">
          No User Found
        </TextLabels>
        <Box
          sx={{
            marginTop: "15px",
          }}
        ></Box>
        <TextLabels variant="h200" weight={400} color="#7E8085">
          Currently there are no users registered in your system.
        </TextLabels>
      </Box>
    </Box>
  );
};

export default NotFound;
