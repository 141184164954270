import React from "react";
import BackBtn from "../../molecules/backBtn";
import TextLabels from "../../atoms/textLabels";
import { Box } from "@mui/material";
// import { MyWrapperResponsive } from "../../atoms/masonaryImageGllery";

const EventGalleryImages = () => {
  return (
    <>
      <BackBtn prevLink="/event-gallery" />
      <Box
        sx={{
          marginTop: "30px",
          marginBottom: "15px",
        }}
      >
        <TextLabels variant="h200" weight={700}>
          Event 1 Photos (6)
        </TextLabels>
      </Box>
      {/* <MyWrapperResponsive galleryData={[]} /> */}
    </>
  );
};

export default EventGalleryImages;
