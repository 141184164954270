import React from "react";
import { PropType } from "./types/propTypes";

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";

import Header from "../../molecules/header";
import SideBar from "../../molecules/sidebar";

import "./style.css";

const drawerWidth = 240;

const Layout = ({ marginTop="30px", children, window, pageName, menuActive }: PropType) => {
  const [mobileOpen, setMobileOpen] = React.useState(false); 

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Header
        pageName={pageName}
        drawerWidth={drawerWidth}
        handleDrawerToggle={handleDrawerToggle}
      />
      <SideBar
        menuActive={menuActive}
        drawerWidth={drawerWidth}
        handleDrawerToggle={handleDrawerToggle}
        mobileOpen={mobileOpen}
        window={window}
      />
      <Box component="main" className="content_container">
        <Toolbar />
        <Box
          sx={{
            marginTop: marginTop,
          }}
          className="content_inner_container"
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
