import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import TextLabels from "../textLabels";
import ActiveImg from "./images/active.svg";
import ScheduleImg from "./images/schedule.svg";
import PastImage from "./images/past.svg";
import MenuImg from "./images/menu.svg";
import ToggleMenu from "../toggleMenu";
import useHttp from "../../../utils/hooks/useHttp";
import Loader from "../loader";
import moment from "moment";
import useOutsideAlerter from "../../../utils/hooks/useOutsideAlerter";
import "./styles.css";
import { ModalInputs } from "./types/modalInputs";
import { PropType } from "./types/propType";

const FeedBackCard = ({
  cardDetails,
  handleToggleMenu,
  handleCloseOptions,
}: PropType) => {
  const wrapperRef = useRef(null);
  const clickRef = useRef(null);

  useOutsideAlerter(wrapperRef, clickRef, handleCloseOptions);
  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
  
    return formattedDate;
  }

  return (
    <>
      {/* {loading && <Loader />} */}
      <Box className="feedback_box">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <TextLabels variant="h300" weight={700}>
            {cardDetails.name}
          </TextLabels>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            className="menu_box"
          >
            <TextLabels variant="h100" weight={300} color="">
              {formatDate(cardDetails.created_at)}
            </TextLabels>
            &nbsp;&nbsp;
            <div
              onClick={(e) => handleToggleMenu(e, cardDetails.id)}
              ref={clickRef}
            >
            </div>
          </Box>
        </Box>
        {/* <Box
          sx={{
            paddingBottom: "15px",
          }}
        ></Box> */}
        <TextLabels variant="h200" weight={400} color="#606266">
          {cardDetails.email}
        </TextLabels>
        {cardDetails?.mobile && <><div style={{marginTop: '8px'}} />
        <TextLabels variant="h200" weight={400} color="#606266">
          {cardDetails?.mobile}
        </TextLabels></>}
        <Box
          sx={{
            paddingBottom: "15px",
          }}
        ></Box>
        <TextLabels variant="h200" weight={400} color="#9FA1A6">
          {cardDetails.comment}
        </TextLabels>
      </Box>
    </>
  );
};

export default FeedBackCard;
