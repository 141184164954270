import dayjs from "dayjs";

export const inputDataField = [
    {
      type: "text",
      label: "Full Name",
      name: "full_name",
      placeHolder: "Enter Full Name",
      value: "",
    },
    {
      type: "email",
      label: "Email",
      name: "email",
      placeHolder: "Enter Email",
      value: "",
    },
    {
      type: "phone",
      label: "Phone Number",
      name: "mobile_no",
      placeHolder: "Enter Phone Number",
      value: "",
    },
    {
      type: "dropdown",
      label: "Membership Type",
      name: "membership_id",
      placeHolder: "Select Membership Type",
      value:[],
    },
    {
      type: "text",
      label: "Address",
      name: "address",
      placeHolder: "Enter Address",
      value: "",
    },
    {
      type: "text",
      label: "State",
      name: "state",
      placeHolder: "Enter State",
      value:""
    },
    {
      type: "text",
      label: "City",
      name: "city",
      placeHolder: "Enter City",
      value:""
    },
    {
      type: "text",
      label: "Zip Code",
      name: "zipcode",
      placeHolder: "Enter ZipCode",
      value:""
    },
    {
      type: "text",
      label: "Purchased",
      name: "purchase_type",
      placeHolder: "Enter Purchase Item",
      value:"Manual"
    },
    {
      type: "date",
      label: "Purchased On",
      name: "membership_purchase_date",
      value:dayjs(new Date()),
    },
    {
      type: "text",
      label: "Number of Passes",
      name: "no_of_pass",
      placeHolder: "Enter Number of Passes",
      value:""
    },
    {
      type: "date",
      label: "Give On",
      name: "membership_given_date",
      placeHolder: "Enter Full Name",
      value: null,
    },
    {
      type: "text",
      label: "Amount",
      name: "manual_membership_price",
      placeHolder: "Enter Amount",
      value: "",
    },
    {
      type: "textarea",
      label: "Notes",
      name: "membership_notes",
      placeHolder: "Enter Full Name",
      value: "",
    },
  ];



export const dummyApiData:any={
    "full_name": "Shubham Rawat New Tested",
    "email": "test@gmai.innn",
    "mobile_no": "01234567890",
    "address": "test address new test",
    "state": "Haryana",
    "city": "Faridabad",
    "zipcode": "121001",
    "purchase_type": "Online",
    "membership_purchase_date": "03/29/2024",
    "no_of_pass": "3",
    "membership_given_date": "03/30/2024",
    "manual_membership_price": "200",
    "membership_id": [
        87
    ],
    "membership_notes":"Teste noTe"
}