import React from "react";
import { PropType } from "./types/propTypes";
import { Card } from "@mui/material";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  color: theme.palette.text.secondary,
  display: "flex",
  flexDirection: "column",
  boxShadow: "unset",
  border: "1px solid #E8EAED",
  borderRadius: "8px",
}));

const EventItem = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.secondary,
  display: "flex",
  flexDirection: "column",
  boxShadow: "unset",
}));

const EventManagementItem = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  display: "flex",
  flexDirection: "column",
  boxShadow: "unset",
}));

const Cards = ({ children, cardType }: PropType) => {
  return (
    <>
      {cardType === "stats" && <Item>{children}</Item>}
      {cardType === "events" && <EventItem>{children}</EventItem>}
      {cardType === "events_item" && (
        <EventManagementItem>
          {children}
        </EventManagementItem>
      )}
    </>
  );
};

export default Cards;
