import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'

export interface SendEmailState {
    emailResponse: any;
    error: string | null;
    isLoading: boolean;
}
const initialState: SendEmailState = {
    emailResponse: null,
    error: null,
    isLoading: false
}

export const sendEmail = createAsyncThunk<any, { email: string,host:string}>(
    "change-password/send-email",
    // async ({ email,host="https://dvdg2jux95pvr.cloudfront.net" }) => {
    async ({ email,host=`${process.env.REACT_APP_FRONTEND_URL}` }) => {
        try {
          const body = {
              user: {
                email,
                host
            }
          }
  
          const config = {
              headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                //Authorization: localStorage.getItem("token"),
              }
            };
  
          const response: any = await axios.post(`${process.env.REACT_APP_API_URL_DEV}/users/get_password_token`, body, config);
  
  
          return response;
        } catch (error) {
          
        }
      }
  ) as any;

export const sendEmailSlice = createSlice({
    name: 'sendEmail',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(sendEmail.pending, (state) => {
            state.isLoading=true
        });
        builder.addCase(sendEmail.fulfilled, (state, action) => {
            
            state.emailResponse = action.payload.data;
            state.error = null;
            state.isLoading = false;
          });
        builder.addCase(sendEmail.rejected, (state, action) => {
            state.emailResponse = null;
            state.error = action.payload as string;
            state.isLoading = false;
          });
      
    },
  })

  export default sendEmailSlice.reducer;
