import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import "./style.css";
import { Button } from "../../atoms/buttons";
import { useNavigate } from "react-router-dom";

const FeedbackWelcome = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box className="content-centered">
      <Typography variant="h1" className="welcome-heading">
        Thank you!
      </Typography>
      <Typography variant="body1" className="welcome-message">
        We’ve received your valuable feedback.
      </Typography>
      <Button
        variant="outlined"
        size="s"
        color="info"
        handleClick={() => navigate("/")}
      >
        Go to homepage
      </Button>
    </Box>
  );
};

export default FeedbackWelcome;
