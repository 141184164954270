import React,{useState,useEffect} from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import TextLabels from '../../atoms/textLabels';
import { Input } from '../../atoms/inputs';
import ErrorMessage from '../../atoms/errorMessage';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Button } from '../../atoms/buttons';
import { AppDispatch, RootState } from '../../../utils/store';
import { useDispatch, useSelector } from 'react-redux';
import { sendEmail } from '../../../utils/store/slices/changePasswordSlice';
import AlertCmp from '../../atoms/alert';
import { resetPassword } from '../../../utils/store/slices/resetPasswordSlice';
import Loader from '../../atoms/loader';

const Item = styled(Paper)(({ theme }) => ({
    //backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    //textAlign: 'center',
    //color: theme.palette.text.secondary,
    border:'none', 
    boxShadow:'none',
    backgroundColor:'#FAE4DB'
}));

export default function ChangePasswordOrganism() {
    const {error,isLoading,emailResponse} = useSelector((state: RootState) => state.sendEmail);
    const {error:resetError,isLoading:resetLoading,resetResponse} = useSelector((state: RootState) => state.resetPassword);
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')
    const [showNewPassword, setShowNewPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [step1, setStep1] = useState(true)
    const [step2, setStep2] = useState(false)
    const [newPwd,setNewPwd] = useState('')
    const [confirmPwd,setConfirmPwd] = useState('')
    const [newPwdError,setNewPwdError] = useState('')
    const [confirmPwdError,setConfirmPwdError] = useState('')
    const [successMsg,setSuccessMsg] = useState(false)
    const [resetMsg,setResetMsg] = useState(false)
    const [tokenError,setTokenError] = useState(false)
    const [resetErr,setResetErr] = useState(false)
    const [search, setSearch] = useSearchParams();
    // const pwdRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
    const pwdRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()-_=+{};:,<.>]).{8,}$/
    const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    const handleStep1 = () => {
        setSuccessMsg(false)
        setTokenError(false)
        if(!email){
            setEmailError('Enter email');
            return;
        }else if(!(emailRegex.test(email))){
            setEmailError('Enter valid email');
            return;
        }else{
            dispatch(sendEmail({
                email: email
            }))
            setEmailError('')
        }
        
        
        
    }
    useEffect(() => {
        if(search.get('reset_token')){
           setStep1(false)
           setStep2(true)
        }
    }, [search])
    
    useEffect(() => {
    
      if(emailResponse?.message && emailResponse?.status){
        setEmail('')
        setSuccessMsg(true)
      }else if(emailResponse?.status != true){
        setTokenError(true)
      }
    }, [emailResponse])
    useEffect(() => {
      if(resetResponse?.message && resetResponse?.status){
         setNewPwd('')
         setConfirmPwd('')
         setResetMsg(true)
         setTimeout(()=>{navigate('/login');},1000)
      }else{
        if(resetResponse?.error && resetResponse?.error?.length>0){
           setResetErr(true)
        }
      }
    }, [resetResponse])
    
    const handleNewPwdValidation = (val:string) => {
        if(!val){
        setNewPwdError('Enter your new password.');
        return;
        }else if(!(pwdRegex.test(val))){
        setNewPwdError('Minimum 8 characters are allowed and use atleast one uppercase letter, one lowercase letter, one number and one special character.');
        return;
        }else{
        setNewPwdError('');
        }
    }
    const handleConfirmPwdValidation = (val:string) => {
        if(!val){
        setConfirmPwdError('Enter your confirm password.');
        return;
        }else{
        setConfirmPwdError('');
        }
        if(val != newPwd){
        setConfirmPwdError('This password doesn’t match the new password. ');
        return;
        }else{
        setConfirmPwdError('');
        }
    }

    const handleChangePassword = () => {
        setResetMsg(false)
        if(!newPwd){
        setNewPwdError('Enter your new password.');
        return;
        }else if(!(pwdRegex.test(newPwd))){
        setNewPwdError('Minimum 8 characters are allowed and use atleast one uppercase letter, one lowercase letter, one number and one special character.');
        return;
        }else{
        setNewPwdError('');
        }
        if(!confirmPwd){
        setConfirmPwdError('Enter your confirm password.');
        return;
        }else{
        setConfirmPwdError('');
        }
        if(confirmPwd != newPwd){
        setConfirmPwdError('This password doesn’t match the new password. ');
        return;
        }else{
        setConfirmPwdError('');
        }
        dispatch(resetPassword({
            reset_token: search.get('reset_token'),
            password:newPwd
          }))
        

    }
    

    return <>
                {(isLoading||resetLoading) && <Loader/>}
                {(tokenError && emailResponse?.message) && (
                    <Box className="toastBox">
                    <AlertCmp type="error">{emailResponse?.message}</AlertCmp>
                    </Box>
                )}
                {(resetErr && resetResponse?.error?.length>0) && (
                    <Box className="toastBox">
                    <AlertCmp type="error">{resetResponse?.error[0]}</AlertCmp>
                    </Box>
                )}
                {(successMsg && emailResponse?.message) && (
                    <Box className="toastBox">
                    <AlertCmp type="success">{emailResponse?.message}</AlertCmp>
                    </Box>
                )}
                {(resetMsg && resetResponse?.message) && (
                    <Box className="toastBox">
                    <AlertCmp type="success">{resetResponse?.message}</AlertCmp>
                    </Box>
                )}
            
                <Box sx={{
                //height: "100vh",
                // display: "flex",
                // flexDirection: "column",
                // justifyContent: "center",
                padding: 0,
                width:"70%"
                }}>
                
                <Item>
                <>
                <Box
                sx={{ textAlign: "center", display: "none" }}
                className="mobile-login-logo"
                >
                <img src="/images/profile-imsom.png" />
                </Box>
                
                <TextLabels fontFamily='Poppins' variant="subtitle2" color="#3C3E42" weight={600}>{step1 ? 'Forgot Password' : 'Change Password'}</TextLabels>
                {
                    step1 ? 
                    <>
                    <TextLabels fontFamily='Poppins' variant="h5" color="#7E8085" weight={400}>Enter your registered email address to receive a link to reset your password</TextLabels>
                
                    <Box 
                    sx={{ mt: 1 }}>
                        {/* <InputLabel sx={{color:theme=>theme.label.main}}>Email</InputLabel> */}
                    <Box sx={{margin:'25px 0'}}>
                    
                    <TextLabels variant="h5" color="#2C2D30" weight={700}>Enter registered email ID</TextLabels>
                        <Input id="standard-number"
                        label="Email"
                        type="email"
                        name="email"
                        placeholder="Enter email here" 
                        variant="outlined"
                        value={email}
                        handleChange={(e:any)=>{
                            setEmail(e.target.value);
                            !(emailRegex.test(e.target.value)) ? setEmailError('Enter valid email') : setEmailError('');
                        }}
                        sx={{marginTop:"10px",width:"100%",backgroundColor:'#ffffff',borderRadius:'4px',fontWeight:'400',fontSize:'14px'}}
                        />
                    {
                        emailError && <ErrorMessage>{emailError}</ErrorMessage>
                    }    
                    </Box>
                    
                    <Box sx={{margin:'25px 0'}}>
                    <Box sx={{
                        position:"relative",
                        margin:"10px 0px"
                        }}>
                    <Button type="button"
                    disable={(!email)||emailError||isLoading?true:false} 
                    sx={{
                        position:"absolute",
                        top:0,
                        right:0,
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "12px",
                        lineHeight: "14px",
                        
                        textDecoration:"none",
                        backgroundColor:email ? '#913713' : '#9FA1A6',
                        '&:hover': {
                            backgroundColor:email ? '#913713' : '#9FA1A6'
                        }

                    }} 
                    btnClass='nextBtnClass changepwd-nextBtn' handleClick={handleStep1} color="error"  variant="contained" >Next</Button>
                    <Button 
                    sx={{
                        backgroundColor:'#FAE4DB',
                        border:'1px solid #913713',
                        color:'#913713',
                        '&:hover': {
                            backgroundColor: '#FAE4DB',
                            color:'#913713'
                        }
                    }} 
                    type="button" btnClass='loginSubmitBtn changepwd-backBtn' color="error" handleClick={() => navigate(-1)}  variant="contained" >Back</Button>

                    </Box>
                    
                    
                    </Box>
                    
                    
                    </Box>
                    </>
                    :
                    step2 ? 
                    <>
                    <TextLabels fontFamily='Poppins' variant="h5" color="#7E8085" weight={400}>Create a new password to login with it from the new session.</TextLabels>
                    <Box component="form" noValidate 
                    sx={{ mt: 1 }}>
                        
                    <Box sx={{margin:'25px 0'}}>
                    <Box sx={{
                        position:"relative",
                        margin:"10px 0px"
                        }}>
                            <TextLabels variant="h5" color="#2C2D30" weight={700}>Enter new password</TextLabels>

                    </Box>
                    
                    <Box sx={{position:"relative"}}>
                    
                    <Input id="standard-number"
                        name="password"
                        label="Password"
                        value={newPwd}
                        type={showNewPassword?'text':'password'}
                        placeholder="Enter new password here"
                        handleChange={(e:any)=>{
                            setNewPwd(e.target.value);
                            handleNewPwdValidation(e.target.value);
                        }}
                        handleKeyPress={(e:any)=>handleNewPwdValidation}
                        sx={{width:"100%", backgroundColor:'#ffffff',borderRadius:'4px',fontWeight:'400',fontSize:'14px',lineHeight:'16px'}}
                    />
                    <img style={{position:"absolute", right:"9px", top:"13px"}} src='icons/password.png' onClick={()=>{setShowNewPassword(!showNewPassword);}} />
                    </Box>
                    {
                        newPwdError && <ErrorMessage>{newPwdError}</ErrorMessage>
                    }
                    
                    </Box>

                    <Box sx={{margin:'20px 0'}}>
                    <Box sx={{
                        position:"relative",
                        margin:"10px 0px"
                        }}>
                            <TextLabels variant="h5" color="#2C2D30" weight={700}>Confirm new password</TextLabels>

                    </Box>
                    
                    <Box sx={{position:"relative"}}>
                    
                    <Input id="standard-number"
                        name="password"
                        label="Password"
                        value={confirmPwd}
                        type={showConfirmPassword?'text':'password'}
                        placeholder="Enter here"
                        handleChange={(e:any)=>{
                            setConfirmPwd(e.target.value)
                            handleConfirmPwdValidation(e.target.value)
                        }}
                        handleKeyPress={(e:any)=>handleConfirmPwdValidation}
                        sx={{width:"100%", backgroundColor:'#ffffff',borderRadius:'4px',fontWeight:'400',fontSize:'14px',lineHeight:'16px'}}
                    />
                    <img style={{position:"absolute", right:"9px", top:"13px"}} src='icons/password.png' onClick={()=>{setShowConfirmPassword(!showConfirmPassword);}} />
                    </Box>
                    {
                        confirmPwdError && <ErrorMessage>{confirmPwdError}</ErrorMessage>
                    }
                    </Box>

                    <Box sx={{margin:'25px 0'}}>
                    <Button 
                    disable={((newPwd==''||confirmPwd==''||newPwdError!=''||confirmPwdError!='')||resetLoading)?true:false}
                    sx={{
                        backgroundColor:'#913713',
                        '&:hover': {
                            backgroundColor:'#913713'
                        }
                    }} 
                    type="button" handleClick={handleChangePassword} btnClass='loginSubmitBtn' color="error" fullWidth={true} variant="contained" >Next</Button>
                    
                    </Box>

                    </Box>
                    </>
                    :
                    <></>
                }
                
                </>
                </Item>

                </Box>
           </>

}