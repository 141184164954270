import { Box, InputAdornment, TextareaAutosize } from "@mui/material";
import React from "react";
import InputsWithLabel from "../../molecules/inputsWithLabel";
import { Button } from "../../atoms/buttons";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "../../pages/editProfileManagement/style.css";
import dayjs from "dayjs";

const ProfileRightSideConatiner = ({
  rightSideData,
  handleChange,
  handleDatePickerChange,
  isEdit
}: any) => {
  const style = {
    buttonConatiner: {
      display: "flex",
      justifyContent: "end",
      alignItems: "center",
      marginTop: "20px",
    } as React.CSSProperties,
  };

  return (
    <div>
      {rightSideData?.map((item: any) =>
        item?.type === "textarea" ? (
          <>
            <label className="inputLabel">{item?.label}</label>
            <TextareaAutosize
              style={{
                width: "100%",
                border: "2px solid #E8EAED",
                outline: "none",
                borderRadius: "5px",
                padding: "10px",
              }}
              color="neutral"
              minRows={5}
              placeholder="Enter Note"
              name={item?.name}
              id={item?.name}
              value={item?.value}
              onChange={handleChange}
            />
          </>
        ) : item?.type === "date" ? (
          <>
            <label className="inputLabel">{item?.label}</label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  slotProps={{ textField: { name: item?.name }}}
                  onChange={(date) => handleDatePickerChange(date, item?.name)}
                  value={item?.value && dayjs(new Date(item?.value))}
                  disableFuture
                />
              </DemoContainer>
            </LocalizationProvider>
          </>
        ) : (
          <Box>
            <InputsWithLabel
              label={item?.label}
              type={item.type}
              name={item.name}
              id={item.id}
              placeholder={item.placeHolder}
              value={item?.value}
              handleInputChange={handleChange}
              inputProps={item?.name === "manual_membership_price" && {
                startAdornment: (
                  <InputAdornment position="start">
                    <span
                      style={{
                        color: "#092532",
                        fontSize: "16px",
                      }}
                    >
                      $
                    </span>
                  </InputAdornment>
                ),
                inputProps: {
                  min: 0,
                },
              }}
            />
          </Box>
        )
      )}
      <Box style={style.buttonConatiner}>
        <Button
          variant="contained"
          color="primary"
          size="s"
          type="submit"
        >
          {`${isEdit ? 'Update' : 'Create'}`}
        </Button>
      </Box>
    </div>
  );
};

export default ProfileRightSideConatiner;
