import React, { useState, useEffect } from "react";
import { Box, Grid, TextareaAutosize } from "@mui/material";
import SectionHeading from "../../molecules/sectionHeading";
import AboutUsImg from "./images/about.png";
import ArtistImg1 from "./images/artist1.png";
import ArtistImg2 from "./images/artist2.png";
import ArtistImg3 from "./images/artist3.png";
import ArtistImg4 from "./images/artist4.png";
import ArtistImg5 from "./images/artist5.png";
import ArtistRoundImg1 from "./images/not-available-circle-svgrepo-com.svg";
import ArtistRoundImg2 from "./images/artistround2.png";
import ArtistRoundImg3 from "./images/artistround3.png";
import ArtistRoundImg4 from "./images/artistround4.png";
import ArtistRoundImg5 from "./images/artistround5.png";
import ArtistRoundImg6 from "./images/artistround6.png";
import ArrowImg from "./images/arrow.svg";
import WebsiteArtistImage from "../../atoms/websiteArtistImage";
import CardSection from "../../molecules/cardSection";
import ArtistCircleInfo from "../../molecules/artistCircleInfo";
import { Link } from "react-router-dom";
import AcknowledgementSection from "../../organism/acknowledgementSection";
import "./style.css";
import useHttp from "../../../utils/hooks/useHttp";
import { ArtistModel } from "../../../utils/types/artist";
import useGetApi from "../../../utils/hooks/usegetApi";
import SaveIcon from '@mui/icons-material/Save';
import useEditAboutTitle from "../../../utils/hooks/useEditableTitle";
import Faq from "./faq";
import Loader from "../../atoms/loader";


const artistPerformedData = [
  {
    image: ArtistImg1,
    name: "Apoorva Gokhale",
  },
  {
    image: ArtistImg2,
    name: "Apoorva Gokhale",
  },
  {
    image: ArtistImg3,
    name: "Apoorva Gokhale",
  },
  {
    image: ArtistImg4,
    name: "Apoorva Gokhale",
  },
  {
    image: ArtistImg5,
    name: "Apoorva Gokhale",
  },
];

// const committeeData = [
//   {
//     image: ArtistRoundImg1,
//     designation: "President",

//     name: "Dr. Ameeta Kelekar",
//     email: "ameeta.kelekar@gmail.com",
//   },
//   {
//     image: ArtistRoundImg2,
//     designation: "President",
//     name: "Dr. Ameeta Kelekar",
//     email: "ameeta.kelekar@gmail.com",
//   },
//   {
//     image: ArtistRoundImg3,
//     designation: "President",
//     name: "Dr. Ameeta Kelekar",
//     email: "ameeta.kelekar@gmail.com",
//   },
//   {
//     image: ArtistRoundImg4,
//     designation: "President",
//     name: "Dr. Ameeta Kelekar",
//     email: "ameeta.kelekar@gmail.com",
//   },
//   {
//     image: ArtistRoundImg5,
//     designation: "President",
//     name: "Dr. Ameeta Kelekar",
//     email: "ameeta.kelekar@gmail.com",
//   },
//   {
//     image: ArtistRoundImg6,
//     designation: "President",
//     name: "Dr. Ameeta Kelekar",
//     email: "ameeta.kelekar@gmail.com",
//   },
// ];

// const directorsData = [
//   {
//     image: ArtistRoundImg1,
//     name: "Dr. Ameeta Kelekar",
//     email: "ameeta.kelekar@gmail.com",
//   },
//   {
//     image: ArtistRoundImg2,
//     name: "Dr. Ameeta Kelekar",
//     email: "ameeta.kelekar@gmail.com",
//   },
//   {
//     image: ArtistRoundImg3,
//     name: "Dr. Ameeta Kelekar",
//     email: "ameeta.kelekar@gmail.com",
//   },
//   {
//     image: ArtistRoundImg4,
//     name: "Dr. Ameeta Kelekar",
//     email: "ameeta.kelekar@gmail.com",
//   },
//   {
//     image: ArtistRoundImg5,
//     name: "Dr. Ameeta Kelekar",
//     email: "ameeta.kelekar@gmail.com",
//   },
//   {
//     image: ArtistRoundImg6,
//     name: "Dr. Ameeta Kelekar",
//     email: "ameeta.kelekar@gmail.com",
//   },
// ];

const AboutUs = () => {
  const [toggleExpand1, setToggleExpand1] = useState(false);
  const [toggleExpand2, setToggleExpand2] = useState(false);

  const {
    loading: loadingBoard,
    error: errorBoard,
    sendRequest: sendRequestBoard,
  } = useHttp(transformDataBoard);
  const [committeeData, setCommitteeData] = useState<any>([]);
  const [committeeMobileData, setCommitteeMobileData] = useState<any>([]);
  const [directorsData, setDirectorsData] = useState<any>([]);
  const [directorsMobileData, setDirectorsMobileData] = useState<any>([]);
  const [AdvisoryData, setAdvisoryData] = useState<any>([]);
  const [artists, setArtists] = useState<ArtistModel[]>([]);
  const [buttonClicked, setButtonClicked] = useState<any>(false);
  const [viewAll, setViewAll] = useState<any>("View All")
  const [buttonBoardClicked, setButtonBoardClicked] = useState<any>(false);
  const [showText, setShowText] = useState<any>([]);
  const [isEditing, setIsEditing] = useState(true);
  const [savebtnShow, setSavebtnShow] = useState<boolean>(false)


  const {
    // loading: loadingArtists,
    // error: errorArtists,
    sendRequest: sendRequestForArtists,
  } = useHttp(transformArtistData);


  function transformDataBoard(data: any) {
    setCommitteeData(
      data.board_members.filter((item: any) => {
        if (item.division == "Executive Committe") {
          return item;
        }
      })
    );
    setCommitteeMobileData(
      data.board_members.filter((item: any) => {
        if (item.division == "Executive Committe") {
          return item;
        }
      })
    );

    setDirectorsData(
      data.board_members.filter((item: any) => {
        if (item.division == "Board of Directors") {
          return item;
        }
      })
    );
    setDirectorsMobileData(
      data.board_members.filter((item: any) => {
        if (item.division == "Board of Directors") {
          return item;
        }
      })
    )
    setAdvisoryData(
      data.board_members.filter((item: any) => {
        if (item.division == "Advisory Board") {
          return item;
        }
      })
    );
  }
  const handleClickMobile = () => {
    setButtonClicked(!buttonClicked);
    setViewAll("View Less")
  };
  const handleClickBoardMobile = () => {
    setButtonBoardClicked(!buttonBoardClicked);
  };
  function transformArtistData(data: any) {
    setArtists(data?.artists_list?.slice(0, 5) || [])
  }


  const handleExpand1 = () => {
    setToggleExpand1(true);
  };

  const handleCollpase1 = () => {
    setToggleExpand1(false);
  };

  const handleExpand2 = () => {
    setToggleExpand2(true);
  };

  const handleCollpase2 = () => {
    setToggleExpand2(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const reqOptions = {
      url: "/board_memebers/get_board_memebers",
      method: "GET",
      params: {},
    };
    sendRequestBoard(reqOptions);
    sendRequestForArtists({
      url: "/artist_list",
      method: "GET"
    })
  }, []);
  const webToken = localStorage.getItem("web_token");
  const userInfo: any = localStorage.getItem("web_user") ?? null;
  const userRole = userInfo && JSON.parse(userInfo);



  const { callGetApi } = useGetApi();


  const apiCall = async () => {
    const result1 = await callGetApi('about_us');
    setShowText(result1?.AboutUs?.[0]);
  }


  useEffect(() => {
    // Inside the useEffect hook, make the GET request
    apiCall();
  }, []);
  const { aboutUsTitle } = useEditAboutTitle();

  const showTextMidle = () => {
    return showText?.middle_text?.replace("38 years", `${new Date().getFullYear() - 1980} years`)
  }
  const handleSaveClick = async () => {
    setIsEditing(false);
    const about = {
      image_text: showText?.image_text,
      bottom_first_para: showText?.bottom_first_para,
      bottom_last_para: showText?.bottom_last_para,
      middle_text: showTextMidle()

      // right_text:showText?.right_text,
      // bottom_text:showText?.bottom_text
    }
    await aboutUsTitle({ about }, 1, "about_us");
    // apiCall();
    setSavebtnShow(false);

  };
  const handleInputChange = (e: any) => {
    setShowText({ ...showText, [e.target.id]: e.target.value })
    setSavebtnShow(true);
  };

  // loading screen 
  const  [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000)
  },[])

  return (
    <Box>
      {loading && <Loader />}
      {savebtnShow && <SaveIcon className="save_icon" onClick={handleSaveClick} />}
      <Box className="about_us_top_website hero_section">
        <SectionHeading
          title="About Us"
          classNameBox="about_us_heading_img_box"
          classNameImg="about_us_heading_img"
        // marginRight="-200"
        />
        <Box className="about_us_content_website">
          <CardSection>
            <Grid container justifyContent="center" display="flex" flexDirection="column" className="about_us_extra_box_website">
              {/* <Grid item md={3} sm={12} xs={12}>
              <img src={AboutUsImg} className="about_us_image_website" />
            </Grid> */}
              <Grid item md={12} sm={12} xs={12}>
                {isEditing && userRole?.role === "Admin" ? (
                  <div className="input_tag_wrapper">
                    <TextareaAutosize
                      id="image_text"
                      value={showText?.image_text}
                      onChange={handleInputChange}
                      className="about_us_top_left"
                      style={{ color: "white", paddingInline: 0 }}

                    />
                  </div>
                ) : (
                  <p onClick={() => setIsEditing(true)} className="about_us_desc">
                    {showText?.image_text}
                  </p>)}

              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <Box className="about_bio_expand">
                  <Box>
                    {toggleExpand1 ? (
                      <div onClick={handleCollpase1}>
                        <p>
                          <span>-</span>&nbsp;Collapse
                        </p>
                      </div>
                    ) : (
                      <div onClick={handleExpand1}>
                        <p>
                          <span>+</span>&nbsp;Expand
                        </p>
                      </div>
                    )}
                  </Box>
                  {toggleExpand1 && (
                    <p contentEditable={webToken ? "true" : "false"}>
                      {showTextMidle()}
                    </p>
                  )}
                </Box>
                {isEditing && userRole?.role === "Admin" ? (
                  <Box >
                    <TextareaAutosize
                      id="middle_text"
                      value={showTextMidle()}
                      onChange={handleInputChange}
                      className="about_us_top_right"
                      style={{ color: "white", paddingInline: "22px", textAlign: "justify" }}
                    />
                  </Box>
                ) : (
                  <p className="about_us_bio_website" onClick={() => setIsEditing(true)}>
                    {showTextMidle()}
                  </p>
                )}
              </Grid>
            </Grid>
          </CardSection>
        </Box>
      </Box>
      <Box className="about_us_numbers_website">
        <Grid container>
          <Grid item xs={4} className="about_us_grid">
            <Box className="about_us_number_box_website">
              <h4>35+</h4>
              <h6>
                Musical
                <br /> Seasons
              </h6>
            </Box>
          </Grid>
          <Grid item xs={4} className="about_us_grid">
            <Box className="about_us_number_box_website">
              <h4>250+</h4>
              <h6>
                Music
                <br /> Concerts
              </h6>
            </Box>
          </Grid>
          <Grid item xs={4} className="about_us_grid about_us_no_border">
            <Box className="about_us_number_box_website">
              <h4>85+</h4>
              <h6>
                Talented
                <br /> Artists
              </h6>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="artist_performed_website">
        <h2 className="artist_section_heading">
          Artists who’ve performed for us
        </h2>
        <Box className="artist_performed_images_website">
          <Grid container spacing={4}>
            {artists.map((artist, i) => {
              return (
                <Grid item sm={12 / artists.length} xs={6}>
                  <WebsiteArtistImage key={i} image={artist.artist_image[0]} name={artist.name} />
                </Grid>
              );
            })}
          </Grid>
        </Box>
        <Box className="about_us_artist_view_all_website">
          <Link to="/all-artists">
            <p>
              View All
              <img src={ArrowImg} />
            </p>
          </Link>
        </Box>
      </Box>
      <Box className="about_us_extra_website">
        <CardSection>
          <Box className="about_us_extra_box_website">
            {isEditing && userRole?.role === "Admin" ? (
              <div className="input_tag_wrapper">
                <TextareaAutosize
                  id="bottom_first_para"
                  value={showText?.bottom_first_para}
                  onChange={handleInputChange}
                  className="bottom_para_left"
                  style={{ color: "white", paddingInline: 0 }}

                />
              </div>
            ) : (
              <p onClick={() => setIsEditing(true)} className="about_us_extra_para_bold_website">
                {showText?.bottom_first_para}
              </p>)}

            <Box className="about_us_extra_expand">
              <Box>
                {toggleExpand2 ? (
                  <div onClick={handleCollpase2}>
                    <p>
                      <span>-</span>&nbsp;Collapse
                    </p>
                  </div>
                ) : (
                  <div onClick={handleExpand2}>
                    <p>
                      <span>+</span>&nbsp;Expand
                    </p>
                  </div>
                )}
              </Box>
              {toggleExpand2 && (
                <p onClick={() => setIsEditing(true)}>
                  {showText?.bottom_last_para}
                </p>
              )}
            </Box>
            {isEditing && userRole?.role === "Admin" ? (
              <div className="input_tag_wrapper">
                <TextareaAutosize
                  id="bottom_last_para"
                  value={showText?.bottom_last_para}
                  onChange={handleInputChange}
                  className="botttom_para_right"
                  style={{ color: "white", paddingInline: 0 }}

                />
              </div>
            ) : (
              <p onClick={() => setIsEditing(true)} className="about_us_extra_para_website">
                {showText?.bottom_last_para}
              </p>)}

          </Box>
        </CardSection>
      </Box>
      <Box className="about_us_internal_website">
        <SectionHeading
          title="Internal Committee"
          pos={true}
          classNameBox="internal_heading_img_box"
          classNameImg="internal_heading_img"
          marginTop="15"
          width="20%"
          marginRight="-200"
        />
        <p contentEditable={webToken ? "true" : "false"} className="about_us_internal_subtitle_website">
          IMSOM wouldn’t have been possible without a talented and giving set of
          volunteers who give the organisation their time and effort
        </p>
        <Box className="about_us_executive_website">
          <h4 className="about_us_executive_title_website">
            Executive Committee
          </h4>
          <Box className="about_us_executive_images m-desktop">
            <Grid container spacing={4}>
              {committeeData?.map((person: any, i: any) => {
                return (
                  <Grid
                    item
                    sm={6}
                    md={4}
                    xs={12}
                    key={i}
                    className={`${i % 3 === 2
                        ? "executive_grid"
                        : "executive_grid executive_border"
                      }`}
                  >
                    <ArtistCircleInfo
                      {...person}
                      ArtistRoundImg1={ArtistRoundImg1}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          <Box className="about_us_executive_images m-mobile">
            <Grid container spacing={4}>
              {
                buttonClicked ? (
                  committeeMobileData?.map((person: any, i: any) => {
                    return (
                      <Grid
                        item
                        sm={6}
                        md={4}
                        xs={12}
                        key={i}
                        className={`${i % 3 === 2
                            ? "executive_grid"
                            : "executive_grid executive_border"
                          }`}
                      >
                        <ArtistCircleInfo
                          {...person}
                          ArtistRoundImg1={ArtistRoundImg1}
                        />
                      </Grid>
                    );
                  })
                ) : (
                  committeeMobileData.slice(0, 2)?.map((person: any, i: any) => {
                    return (
                      <Grid
                        item
                        sm={6}
                        md={4}
                        xs={12}
                        key={i}
                        className={`${i % 3 === 2
                            ? "executive_grid"
                            : "executive_grid executive_border"
                          }`}
                      >
                        <ArtistCircleInfo
                          {...person}
                          ArtistRoundImg1={ArtistRoundImg1}
                        />
                      </Grid>
                    );
                  })
                )
              }
              { }
            </Grid>
            <Box className="about_us_artist_view_all_website">
              <Link to="#" onClick={handleClickMobile}>
                <p>
                  {buttonClicked ? ' View Less' : ' View All'}
                  <img src={ArrowImg} />
                </p>
              </Link>
            </Box>
          </Box>

        </Box>

        <Box className="about_us_executive_website">
          <h4 className="about_us_executive_title_website">
            Board of Directors
          </h4>
          <Box className="about_us_executive_images m-desktop">
            <Grid container spacing={4}>
              {directorsData?.map((person: any, i: any) => {
                return (
                  <Grid
                    item
                    sm={6}
                    md={4}
                    xs={12}
                    key={i}
                    className={`${i % 3 === 2
                        ? "executive_grid"
                        : "executive_grid executive_border"
                      }`}
                  >
                    <ArtistCircleInfo
                      {...person}
                      ArtistRoundImg1={ArtistRoundImg1}
                    />
                  </Grid>
                );
              })}
            </Grid>
            <Box className="about_us_artist_view_all_website">
              {/* <Link to="#">
            <p>
              View All
              <img src={ArrowImg} />
            </p>
          </Link> */}
            </Box>
          </Box>
          <Box className="about_us_executive_images m-mobile">
            <Grid container spacing={4}>
              {
                buttonBoardClicked ? (
                  directorsMobileData?.map((person: any, i: any) => {
                    return (
                      <Grid
                        item
                        sm={6}
                        md={4}
                        xs={12}
                        key={i}
                        className={`${i % 3 === 2
                            ? "executive_grid"
                            : "executive_grid executive_border"
                          }`}
                      >
                        <ArtistCircleInfo
                          {...person}
                          ArtistRoundImg1={ArtistRoundImg1}
                        />
                      </Grid>
                    );
                  })
                )
                  : (
                    directorsMobileData.slice(0, 2)?.map((person: any, i: any) => {
                      return (
                        <Grid
                          item
                          sm={6}
                          md={4}
                          xs={12}
                          key={i}
                          className={`${i % 3 === 2
                              ? "executive_grid"
                              : "executive_grid executive_border"
                            }`}
                        >
                          <ArtistCircleInfo
                            {...person}
                            ArtistRoundImg1={ArtistRoundImg1}
                          />
                        </Grid>
                      );
                    })
                  )
              }

            </Grid>
            <Box className="about_us_artist_view_all_website">
              <Link to="#" onClick={handleClickBoardMobile}>
                <p>
                  {buttonBoardClicked ? ' View Less' : ' View All'}
                  <img src={ArrowImg} />
                </p>
              </Link>
            </Box>
          </Box>
        </Box>

        <Box className="about_us_executive_website">
          <h4 className="about_us_executive_title_website">Advisory Board</h4>
          <Box className="about_us_executive_images">
            <Grid container spacing={4}>
              {AdvisoryData?.map((person: any, i: any) => {
                return (
                  <Grid
                    item
                    sm={6}
                    md={4}
                    xs={12}
                    key={i}
                    className={`${i % 3 === 2
                        ? "executive_grid"
                        : "executive_grid executive_border"
                      }`}
                  >
                    <ArtistCircleInfo
                      {...person}
                      ArtistRoundImg1={ArtistRoundImg1}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
        <CardSection>
          <Faq />
        </CardSection>

        {/* <AcknowledgementSection /> */}
      </Box>
    </Box>
  );
};

export default AboutUs;
