import React from "react";
import NoMemberImg from "./images/no_member.svg";
import NoAnnouncementImg from "./images/no_announcement.svg";
import TextLabels from "../../atoms/textLabels";
import { Box } from "@mui/material";
import { PropType } from "./types/propType";
import "./style.css";

const NoMember = ({ heading, content }: PropType) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <img
        src={heading === "No Announcement" ? NoAnnouncementImg : NoMemberImg}
        className="not_found_img"
      />
      <Box
        sx={{
          width: "50%",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            marginBottom: "15px",
          }}
        >
          <TextLabels variant="h300" weight={500}>
            {heading}
          </TextLabels>
        </Box>
        <Box
          sx={{
            width: "80%",
            marginLeft: "10%",
            lineHeight: 1,
          }}
        >
          <TextLabels variant="h200" weight={400} color="#7E8085">
            {content}
          </TextLabels>
        </Box>
      </Box>
    </Box>
  );
};

export default NoMember;
