export const ANNOUNCEMENT_RECORDS_PER_PAGE = 10
export const ANNOUNCEMENT_GET_URL = '/announcements'

export const USERS_RECORDS_PER_PAGE = 10
export const USERS_GET_URL = '/users/get_user_list'

export const MEMBERSHIP_RECORDS_PER_PAGE = 20
export const MEMBERSHIP_GET_URL = '/memberships'

export const SEND_MESSAGE_MULTIPLE_ACTION = 'send_message'
export const BLOCK_PROFILE_MULTIPLE_ACTION = 'block_profile'
export const DELETE_USER_MULTIPLE_ACTION = 'delete_user'
export const EXPORT_MULTIPLE_ACTION = 'export'

export const DOTS_SEND_MESSAGE = 'send_message'
export const DOTS_VIEW_PROFILE = 'view_profile'
export const DOTS_BLOCK_PROFILE = 'block_profile'
export const DOTS_DELETE_USER = 'delete_user'