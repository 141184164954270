import React from "react";
import Layout from "../../organism/layout";
import EventManagement from "../../templates/eventManagement";

const EventManagementPage = () => {
  return (
    <Layout pageName="Event Management" menuActive="Event Management">
      <EventManagement />
    </Layout>
  );
};

export default EventManagementPage;
