import React, { useEffect, useState } from "react";
import { Box, Grid, TextField, TextareaAutosize } from "@mui/material";
import LineImg from "./images/line.svg";
import FlowerImg from "./images/flower.svg";
import SectionHeading from "../../molecules/sectionHeading";
import "./style.css";
import SaveIcon from '@mui/icons-material/Save';
import useGetApi from "../../../utils/hooks/usegetApi";
import useEditAboutTitle from "../../../utils/hooks/useEditableTitle";


const WelcomeSection = ({ handleInputChange, handleSaveClick, isEditing, setIsEditing, showText }: any) => {
  const lengthImages: any = localStorage.getItem("sliderLength")
  const webToken = localStorage.getItem("web_token");
  const userInfo: any = localStorage.getItem("web_user") ?? null;
  const userRole = userInfo && JSON.parse(userInfo);
  const [savebtnShow, setSavebtnShow] = useState<boolean>(false)





  return (
    <>
      <Box className={lengthImages == "6" ? "mhhhhh" : ""}>
        {savebtnShow && <SaveIcon className="save_icon" onClick={handleSaveClick} />}
        <Box className="welcome_section">
          {/* <img src={FlowerImg} className="flower_image_website_top" alt="" /> */}
          <Box className="welcome_heading">
            {/* <SectionHeading
          title="Welcome to"
          subTitle="Indian Music Society Of Minnesota (IMSOM)"
          marginTop="23"
          marginTopMobile="50"
          width="265"
          marginRight="-183"
        /> */}
            <Box className="welcome_content">
              <Grid container spacing={0}>
                <Grid item xs={0} sm={0} className="welcome_content_grid">

                  {isEditing && userRole?.role === "Admin" ? (<></>
                    // <Box sx={{
                    //   '& .MuiTextField-root': { m: 1, width: '97%' },
                    // }}>
                    //   <TextareaAutosize
                    //     id="left_text"

                    //     value={showText?.left_text}
                    //     onChange={handleInputChange}
                    //     className="input_tag "
                    //     style={{ color: "white", paddingInline: 0, marginTop: "15px" }}
                    //   />
                    //   {/* <textarea rows={1} className='auto-expand' id="textarea1" placeholder="Enter some text..."></textarea> */}

                    // </Box>
                  ) : (
                    <></>
                    // <h6 className="welcome_left_content" onClick={() => setIsEditing(true)}>
                    //   {showText?.left_text}
                    // </h6>
                    )}
                </Grid>
                <Grid item xs={12} sm={6} className="welcome_content_grid">
                  {isEditing && userRole?.role === "Admin" ? (
                    <></>
                    // <div className="input_tag_wrapper">
                    //   <TextareaAutosize
                    //     id="right_text"
                    //     value={showText?.right_text}
                    //     onChange={handleInputChange}
                    //     className="input_tag_right"
                    //     style={{ color: "white", paddingInline: 0 }}

                    //   />
                    // </div>
                  ) : (<></>
                    // <p className="welcome_right_content" onClick={() => setIsEditing(true)}>
                    //   {showText?.right_text}
                    // </p>
                    )}

                </Grid>
              </Grid>
            </Box>
          </Box>
          {/* <img src={FlowerImg} className="flower_image_website_bottom" alt="" /> */}
        </Box>
      </Box>
    </>
  );
};

export default WelcomeSection;



// <p contentEditable="true" className="welcome_right_content">
// For more than{" "}
// <span  className="welcome_span_bold">42 years</span>, IMSOM has
// been bringing the best in Indian classical music to the Twin
// Cities and fostering a greater understanding between people of
// different cultures.
// </p>