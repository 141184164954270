import React from "react";
import DecorationImg from "./images/decoration.svg";
import { PropType } from "./types/propType";
import "./style.css";

const CardSection = ({children }: PropType) => {
  return (
    <div className="decoration_section">
      <img src={DecorationImg} className="decor decor1" />
      <img src={DecorationImg} className="decor decor2" />
      {children}
      <img src={DecorationImg} className="decor decor3" />
      <img src={DecorationImg} className="decor decor4" />
    </div>
  );
};

export default CardSection;
