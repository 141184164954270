import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'

export interface ResetPasswordState {
    resetResponse: any;
    error: string | null;
    isLoading: boolean;
}

const initialState: ResetPasswordState = {
    resetResponse: null,
    error: null,
    isLoading: false
}

export const resetPassword = createAsyncThunk<any, { reset_token: string,password:string}>(
    "change-password/reset",
    async ({ reset_token,password }) => {
        try {
          const body = {
              user: {
                reset_token,
                password
            }
          }
  
          const config = {
              headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                //Authorization: localStorage.getItem("token"),
              }
            };
  
          const response: any = await axios.post(`${process.env.REACT_APP_API_URL_DEV}/users/reset_password`, body, config);
  
  
          return response;
        } catch (error) {
          
        }
      }
  ) as any;



export const resetPasswordSlice = createSlice({
    name: 'resetPassword',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(resetPassword.pending, (state) => {
            state.isLoading=true
        });
        builder.addCase(resetPassword.fulfilled, (state, action) => {
            
            state.resetResponse = action.payload.data;
            state.error = null;
            state.isLoading = false;
          });
        builder.addCase(resetPassword.rejected, (state, action) => {
            state.resetResponse = null;
            state.error = action.payload as string;
            state.isLoading = false;
          });
      
    },
  })

  export default resetPasswordSlice.reducer;