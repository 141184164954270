import React from "react";
import BackImg from "./images/back.svg";
import { Box } from "@mui/material";
import TextLabels from "../../atoms/textLabels";
import { PropType } from "./types/propType";
import { useNavigate } from "react-router-dom";

const BackBtn = ({ prevLink }: PropType) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(prevLink);
  };

  return (
    <Box
      onClick={handleNavigation}
      sx={{
        cursor: "pointer",
      }}
    >
      <img src={BackImg} />
      &nbsp;&nbsp;
      <TextLabels variant="h200" weight={500}>
        Back
      </TextLabels>
    </Box>
  );
};

export default BackBtn;
