import Layout from "../../organism/layout";
import SettingTemplate from "../../templates/setting";

const SettingPage = () => {
  return (
    <Layout pageName="Settings" menuActive="Settings" marginTop="0px">
      <SettingTemplate />
    </Layout>
  );
};

export default SettingPage;
