import React, { useContext, useEffect, useState, useRef, ReactNode } from "react";
import { Box, Grid, Typography } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import DropZone from "../../atoms/dropZone";
import InputsWithLabel from "../../molecules/inputsWithLabel";
// import { Editor } from "react-draft-wysiwyg";
import { Editor } from "primereact/editor";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Button } from "../../atoms/buttons";
import { EventContext } from "../../organism/wizardSteps";
import ErrorMessage from "../../atoms/errorMessage";
import LimitErrorImg from "./images/ic_error.svg";
import { convertToHTML, convertFromHTML } from "draft-convert";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./style.css";
import useHttp from "../../../utils/hooks/useHttp";
import { ReqOptions } from "../../../utils/types/reqOptions";
import Loader from "../../atoms/loader";
import AlertCmp from "../../atoms/alert";
import { PropType } from "./types/propTypes";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ModalPopup from "../../atoms/modalPopup";
import { useSearchParams } from "react-router-dom";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import { canvasPreview } from "./canvasPreview";
import { useNavigate } from "react-router-dom";
import { useDebounceEffect } from "./useDebounceEffect";
import "react-image-crop/dist/ReactCrop.css";
import useOutsideAlerter from "../../../utils/hooks/useOutsideAlerter";
import { deepCopy } from "../../../utils/objects";

interface CroppedImage {
  file: File;
  preview: string;
}


const OutsideClickWrapper = (props: {
  children: ReactNode;
  handleClose: () => void;
}) => {
  const ref = useRef<HTMLDivElement>();
  useOutsideAlerter(ref, ref, props.handleClose);
  return <Box sx={{ cursor: "pointer" }} ref={ref}>{props.children}</Box>;
};

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        height: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const Step2 = ({ handleStep, handleOpenPreviewPopup }: PropType) => {
  const [toggleMenu, setToggleMenu] = useState<boolean>(false);
  const [modalIndex, setModalIndex] = useState<number>(0);
  const [currentIndex, setCurrentIndex] = useState<any | null>(null);
  const navigate = useNavigate();
  const dropZoneRef = useRef<HTMLDivElement>(null);
  const cropRef = useRef(null) as any;
  const [crop, setCrop] = useState<Crop>();
  const [errorCustom, setErrorCustom] = useState<boolean>(false);
  const [search, setSearch] = useSearchParams();


  function getCount(str: any) {
    return str.split(" ").filter(function (num: any) {
      return num !== "";
    }).length;
  }

  const getEditorProps = (i: number) => ({
    handleBeforeInput: (val: any) => {
      const wordCount = getCount(
        editorState[i].getCurrentContent().getPlainText()
      );
      if (val && wordCount > 250) {
        return "handled";
      }
      return "not-handled";
    },
    handlePastedText: (val: any) => {
      const wordCount = getCount(
        editorState[i].getCurrentContent().getPlainText()
      );
      return getCount(val) + wordCount > 250;
    },
  });

  const handleCropChange = (c: Crop, index: number) => {
    setCrop(c);
  };

  const [imgSrc, setImgSrc] = useState<any>([]);
  const previewCanvasRef = useRef<any[]>([]);
  const imgRef = useRef<any[]>([]);
  const blobUrlRef = useRef<any>([]);
  const [completedCrop, setCompletedCrop] = useState<any[]>([]);
  const [scale, setScale] = useState(1);

  const { loading, error, sendRequest } = useHttp(transformData);
  const {sendDeleteRequest} =useHttp(deleteTransform);
  const [responseMsg, setResponseMsg] = useState("");
  const [responseType, setResponseType] = useState<boolean>(false);
  const { formData, handleFormDataInput, updateDataForPreview } = useContext(EventContext);
 
  const [aspect, setAspect] = useState<number | undefined>(151 / 264);

  const draftRef = useRef(false);
  const [eventId, setEventId] = useState(
    formData?.event_managment?.id ? formData?.event_managment?.id : null
  );


  const [artistNo, setArtistNo] = React.useState<number>(
    formData?.event_managment?.artists
      ? formData?.event_managment?.artists?.length
      : 0
  );
  const prevArtistNo = useRef(artistNo);
  const [accordionStates, setAccordionStates] = useState<any>(
    formData?.event_managment?.artists
      ? new Array(Number(formData?.event_managment?.artists?.length)).fill(true)
      : []
  );



  useEffect(() => {
    if (artistNo < 1) return () => {
      prevArtistNo.current = artistNo;
    };
    setAccordionStates(new Array(Number(artistNo)).fill(true));
    if (prevArtistNo.current > artistNo) {
      setEditorState((prev: any) => prev.slice(0, artistNo));
      setStep2Data((prev: any) => prev.slice(0, artistNo));
    } else {

      setEditorState((prev: any) => {
        return [
          ...prev,
          ...Array.from({ length: artistNo - prev.length }).fill(
            EditorState.createEmpty()
          )
        ];
      });
      setStep2Data((prev: any) => {
        const arr = [...prev];
        return [...arr, ...Array.from({ length: artistNo - prev.length }).map((_,) => ({
          name: { validate: true, value: "", errorMsg: "Please enter name" },
          performance_type: {
            validate: true,
            value: "",
            errorMsg: "Please enter performance"
          },
          bio: {
            validate: true,
            value: "",
            errorMsg: "Please enter artist bio"
          }
        }))]

      });
    }
    
    return () => {
      prevArtistNo.current = artistNo;
    };
  }, [artistNo]);

  const prevCompletedCrop = useRef<any>(completedCrop);
  const prevImgSrc = useRef<any>(imgSrc)
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [editorState, setEditorState] = useState<any>([]);
  const [step2Data, setStep2Data] = useState<any>(
    formData?.event_managment?.artists ? formData?.event_managment?.artists : []
  );
  const [editorLoad, setEditorLoad] = useState(false);
  const [openBlock, setOpenBlock] = useState(false);
  const handleClickOpenBlock = () => {
    setOpenBlock(true);
  };

  const handleCloseBlock = (index: number) => {
    setCompletedCrop(deepCopy(prevCompletedCrop.current))
    setImgSrc(deepCopy(prevImgSrc.current));
    setOpenBlock(false);
  };

  // const deleteArtist = (e:any,id:any) => {
  //   e.preventDefault();
  //   console.log('step2Data',step2Data)
  //   const resp=step2Data.filter((item:any)=>item.id !== id);
  //   console.log('checksss',resp)
  //   setStep2Data(resp)
  // }


  function transformData(data: any) {
    if (data?.events?.id) {

      setStep2Data(data?.events?.artists);
      !eventId && setEventId(data?.events?.id);
      handleFormDataInput({ event_managment: data?.events });
    }
    if (data?.status) {
      setResponseMsg("Data saved successfully.");
      setResponseType(true);

      if (draftRef.current) {
        setTimeout(() => {
          navigate("/event-management");
        }, 1500);
      } else {
        setTimeout(() => {
          handleStep && handleStep("step3");
        }, 1500);
      }
    }
  }

  function deleteTransform(data: any) {
    if (data?.status) {
      setResponseMsg("Delete  successfully.");
      setResponseType(true);
  }
}

  useOutsideAlerter(dropZoneRef, dropZoneRef, () => { setOpenBlock(false) })

  // useEffect(() => {
  //   if (editorState.length) {
  //     const stepDataArr: any[] = [...step2Data];
  //     // stepDataArr.forEach((edit: any, i: number) => {
  //     //   if (editorState[i] && typeof edit.bio == "object") {
  //     //     edit.bio.value = convertToHTML(editorState[i].getCurrentContent());
  //     //   } else if (editorState[i] && typeof edit.bio == "string") {
  //     //     edit.bio = convertToHTML(editorState[i].getCurrentContent());
  //     //   }
  //     // });
  //     setEditorLoad(true);
  //     setStep2Data(stepDataArr)
  //     // console.log("qqq",stepDataArr, editInputFormData(stepDataArr))
  //     //editInputFormData(stepDataArr)

  //   }
  // }, [editorState]);
  useEffect(() => {
    let arr: any[] = [];
    updateDataForPreview({ artists: resolveArtist(step2Data || []) })
    if (
      step2Data?.length > 0 &&
      typeof step2Data[0]?.bio == "string" &&
      editorLoad === false
    ) {
      step2Data.forEach((edit: any, i: number) => {
        //arr = JSON.parse((JSON.stringify(editorState)));
        if (edit?.bio && typeof edit?.bio == "string") {
          //const contentBlock = convertFromRaw(edit?.bio) as any;
          // console.log(
          //   "uuuuuuuuuu",
          //   EditorState.createWithContent(convertFromHTML(edit?.bio) as any)
          // );
          arr.push(edit?.bio
            // EditorState.createWithContent(convertFromHTML(edit?.bio) as any)
          );
        }
      });
      setEditorState(arr);
    }
  }, [step2Data]);


  // useEffect(() => {
  //   if (!eventId || formData?.event_managment?.artists?.length == 0) {
  //     setAccordionStates(new Array(Number(artistNo)).fill(true));
  //     setEditorState(new Array<any>(artistNo).fill(EditorState.createEmpty()));
  //     const arr = [];
  //     for (let i = 0; i < Number(artistNo); i++) {
  //       arr.push({
  //         name: { validate: true, value: "", errorMsg: "Please enter name" },
  //         performance_type: {
  //           validate: true,
  //           value: "",
  //           errorMsg: "Please enter performance",
  //         },
  //         bio: {
  //           validate: true,
  //           value: "",
  //           errorMsg: "Please enter artist bio",
  //         },
  //       });
  //     }
  //     setStep2Data(arr);
  //   } else {
  //     if (artistNo > formData?.event_managment?.artists?.length) {
  //       // setStep2Data((prev)=>{
  //       //   return ([...prev,])
  //       // })
  //     }
  //   }
  // }, [artistNo, eventId]);


  const handleFiles = (files: any, i: any) => {
    let imgs: any[] = [];
    files.forEach((file: any) => {
      imgs.push(file);
    });
    // const stepData = [...step2Data];
    // stepData[i]["artist_image"] = imgs[0];
    // setStep2Data(stepData);
    setSelectedFile(imgs[0]);
    setModalIndex(i);
    prevCompletedCrop.current = completedCrop;
    prevImgSrc.current = imgSrc;
    setOpenBlock(true);
  };



  useEffect(() => {
    // const plainText = editorState.getCurrentContent().getPlainText();
    // console.log('Editor plain text:', plainText);
  }, [editorState]);

  const handleChange = (event: SelectChangeEvent) => {
    setArtistNo(parseInt(event.target.value || '0'));
    // setArtistNo(step2Data?.length);
  };
  // const handleChange = (step2Data: SelectChangeEvent) => {
  //   setArtistNo(parseInt(step2Data.target.value || '0'));
  // };
  const onEditorStateChange = (e: any, i: number) => {
    const arr = [...editorState];
    arr[i] = e.htmlValue;
    setEditorState(arr);

    const stepDataArr = [...step2Data];

    // if ((convertToHTML(e.getCurrentContent()) as any) === "<p></p>") {
    //   stepDataArr[i].bio.validate = false;
    // } else {
    //   stepDataArr[i].bio.validate = true;
    // }

    setStep2Data(stepDataArr);
  };

  // const onEditorStateChange = (state: any) => {
  //   setEditorState(state);
  // };

  const handleAccordion = (index: number) => {
    const arr = [...accordionStates];
    arr[index] = !arr[index];
    setAccordionStates(arr);
  };


  const editInputFormData = (records: any) => {
    let arr: any = [];
    records.forEach((item: any) => {
      if (item.id != 0) {
        item.name = {
          errorMsg: "Please enter name",
          validate: true,
          value: typeof item.name != "object"
            ? item?.name
            : item?.name?.value
        };
        item.performance_type = {
          errorMsg: "Please enter performance",
          validate: true,
          value: typeof item.performance_type != "object"
            ? item.performance_type
            : item.performance_type?.value
        };
        item.bio = {
          errorMsg: "Please enter artist bio",
          validate: true,
          value: typeof item.bio != "object"
            ? item.bio
            : item.bio?.value
        };
        // if (item?.artist_image) {
        //   artistObj.artist_image = item?.artist_image;
        // }
        arr.push(item);
      }
    });
    // setStep2Data(arr)
    return arr
  }

  const handleTransformData = () => {
    const artistsData: any[] = [];
    step2Data.forEach((artist: any, i: number) => {
      const artistObj: any = {
        name:
          eventId && typeof artist.name != "object"
            ? artist.name
            : artist.name?.value?.trim(),
        performance_type:
          eventId && typeof artist.performance_type != "object"
            ? artist.performance_type
            : artist.performance_type?.value?.trim(),
        //artist_image:artist.artist_image,
        bio:
          eventId && typeof artist.bio != "object"
            ? artist?.bio
            : artist?.bio?.value.trim(),
        //bio: editorState
      };
      if (artist?.artist_image) {
        artistObj.artist_image = artist?.artist_image;
      }
      if (eventId && typeof artist.name != "object") {
        artistObj.id = artist.id;
      }
      artistsData.push(artistObj);
    });
    return artistsData;
  };

  const resolveArtist = (step2Data: any) => {
    const artistsData: any[] = [];
    step2Data.forEach((artist: any, i: number) => {
      const artistObj: any = {
        name:
          eventId && typeof artist.name != "object"
            ? artist.name
            : artist.name?.value?.trim(),
        performance_type:
          eventId && typeof artist.name != "object"
            ? artist.performance_type
            : artist.performance_type?.value?.trim(),
        //artist_image:artist.artist_image,
        bio: artist.bio
        // eventId && typeof artist.name != "object"
        //   ? artist.bio
        //   : artist.bio.value,

        //bio: editorState
      };
      if (artist?.artist_image) {
        if (Array.isArray(artist?.artist_image)) {
          artistObj.artist_image = artist?.artist_image
        } else if (artist?.artist_image.size) {
          artistObj.artist_image = [URL.createObjectURL(artist?.artist_image)]
        }
      }
      if (eventId && typeof artist.name != "object") {
        artistObj.id = artist.id;
      }
      artistsData.push(artistObj);
    });

    return artistsData;
  }

  const checkError = () => {
    let error = false;

    const artistData = handleTransformData();

    artistData?.length > 0 &&
      artistData.forEach((artist: any, i: number) => {

        if (!artist["artist_image"]) {
          error = true;
        }
        if (!("artist_image" in artist) && eventId == null) {
          error = true;
        }
        Object.keys(artist).forEach((key, j) => {
          if (artist[key]?.value?.toString().trim() == "" || artist[key] == "<p></p>") {
            error = true;
          }
        });
      });
    return error;
  };

  const handleStep2Submit = (e: any, draft: boolean) => {
    e.preventDefault();

    setResponseMsg("");
    setResponseType(false);

    const error = checkError();

    if (error) {
    } else {

      const artistData = handleTransformData();
      const data: any = {
        event_managment: {
          artists_attributes: artistData,
          draft: formData?.event_managment?.draft != null ? formData?.event_managment?.draft : true,
        },
      };
      if (eventId) {
        data.event_managment.id = eventId;
      }
      const requestOptions: ReqOptions = {
        url: eventId ? `/event_managments/${eventId}` : `/event_managments`,
        method: eventId ? "PUT" : "POST",
        contentType: "multipart/form-data",
        data: data,
      };

      draftRef.current = draft;
      sendRequest(requestOptions);
    }
    

    // validation check
    // let error = false;

    // const artistArr = [...step2Data];

    // artistArr.forEach((artist: any, i: number) => {
    //   Object.keys(artist).forEach((key, j) => {
    //     if (!artist[key].value) {
    //       artist[key].validate = false;
    //       error = true;
    //     }
    //   });
    // });

    // if (error) {
    //   setStep2Data(artistArr);
    // } else {
    //   const artistData = handleTransformData();
    //   handleFormDataInput({ artists_attributes: artistData });
    // }
  };

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }
  const handleAccordionInputs = (e: any, i: number) => {
    // console.log("handleFunt", step2Data)
    const stepData: any = [...step2Data];
    var key = e.target.name;
    if (typeof stepData[i][key] == "string") {
      stepData[i][key] = e.target.value;
    } else {
      stepData[i][key].value = e.target.value;
      if (e.target.value) {
        stepData[i][key].validate = true;
      } else {
        stepData[i][key].validate = false;
      }
      const containsNumericCharacters = /^[a-zA-Z ]*$/.test(e.target.value);
      stepData[i][key].validate = containsNumericCharacters;
    }
    setStep2Data(stepData);
  };

  const handleFileSelection = (files: any, i: number) => {
    if (files && files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        const imgArr = [...imgSrc];
        imgArr[i] = reader.result?.toString() || "";
        setImgSrc(imgArr);
      });
      reader.readAsDataURL(files[0]);
    }
    handleFiles(files, i);
  };
  useEffect(() => {
    if (error) {
      setResponseMsg("Something went wrong! Try again..");
      setResponseType(false);
    }
  }, [error]);

  function onDownloadCropClick(index: number) {
    if (!previewCanvasRef.current[index]) {
      throw new Error("Crop canvas does not exist");
    }

    previewCanvasRef.current[index].toBlob((blob: any) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }
      if (blobUrlRef.current[index]) {
        URL.revokeObjectURL(blobUrlRef.current[index]);
      }
      blobUrlRef.current[index] = URL.createObjectURL(blob);
      imgRef.current[index] = blobUrlRef.current[index];

      const file = new File([blob], `croppedImage${index}.jpg`, {
        type: "image/jpeg",
      }); // Adjust the file name and type as needed
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = () => {

        // const stepData = [...step2Data];
        // stepData[index]["artist_image"] = file;
        setStep2Data((prev: any) => {
          const newObj = [...prev]
          newObj[index]["artist_image"] = file;
          return newObj;
        });
        setOpenBlock(false);

      };
    });
  }


  const handleImageDelete = (index: number) => {
    previewCanvasRef.current[index] = null;
    imgRef.current[index] = null;
    blobUrlRef.current[index] = null;

    // remove image from src
    const imgArr = [...imgSrc];
    imgArr[index] = null;
    setImgSrc(imgArr);

    // remove image from request
    const stepData = [...JSON.parse(JSON.stringify(step2Data))];
    stepData[index]["artist_image"] = null;
    setStep2Data(stepData);

    // remove from completed crop
    const cropData = [...completedCrop];
    cropData[index] = null;
    setCompletedCrop(cropData);
  };

  // const cropImageSaveChanges = () => {
  //   if (croppedImage?.file && croppedImage?.preview) {
  //     const stepData = [...step2Data];
  //     stepData[modalIndex]["artist_image"] = croppedImage?.file;
  //     stepData[modalIndex]["artist_image"].preview = croppedImage?.preview;
  //     setStep2Data(stepData);
  //     setOpenBlock(false);
  //     setCroppedImage(null);
  //   }
  // };

  useDebounceEffect(
    async () => {
      if (
        completedCrop[modalIndex]?.width &&
        completedCrop[modalIndex]?.height &&
        imgRef.current[modalIndex] &&
        previewCanvasRef.current[modalIndex]
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current[modalIndex],
          previewCanvasRef.current[modalIndex],
          completedCrop[modalIndex],
          scale
        );
      }
    },
    100,
    [completedCrop, scale]
  );

  const isValid = artistNo && checkError() == false ? false : true;


  const res= step2Data.every((obj:any)=> Object.values(obj).every((item:any) => item.value !== ""));
  
  const resNew = step2Data.every((obj: any) => Object.keys(obj).every(key => obj[key] !== ""))


  const deleteItems=(i:any)=>{
   const resp=[...accordionStates],respnew=[...step2Data];
    resp.splice(i,1);
    respnew.splice(i,1);
    // console.log('respnew',respnew)
    setAccordionStates(resp)
    setStep2Data(respnew)
   


  // setResponseMsg("");
  // setResponseType(false);
  setArtistNo(step2Data.length-1);
  if(step2Data[i]?.id){
// const eventId=step2Data[i]?.id;  
    const requestOptions: ReqOptions = {
      url:`/artists/${step2Data[i]?.id}`,
      method:"DELETE",
    };
  
   sendDeleteRequest(requestOptions);
  //  console.log("step2datataaaa",step2Data)

  }



    // const reqOptions: ReqOptions = {
    //   url: `/event_managments/${eventId}`,
    //   method: "GET",
    // };
    // eventRequest(reqOptions);
    // if (search.get("eid")) {
    //   const requestOptions: ReqOptions = {
    //     url: `/event_managments/${search.get("eid")}`,
    //     method: "GET",
    //   };

    //   sendRequest(requestOptions);
    //   console.log('formData',formData)
    //   setStep2Data( formData?.event_managment?.artists ?? [])

    // }

  }


  // console.log('step2Data',step2Data)


  useEffect(()=>{
    // console.log('window is changed')
  },[window.location])

  // console.log(33333,step2Data,accordionStates)


  return (
    <>
      {loading && <Loader />}
      {responseMsg && (
        <AlertCmp
          type={responseType ? "success" : "error"}
          position="dashboard"
        >
          {responseMsg}
        </AlertCmp>
      )}

      <Box className="step2_box">
        <Box className="step2_input">
          <Box className="step2_select_dropdown">
            <Grid container>
              <Grid item xs={6}>
                <h6 className="step2_heading">
                  Select Number of Main Artist *{" "}
                  <span className="step2_heading_span">
                    (this will appear on banner)
                  </span>
                </h6>
                <Select
                // value={artistNo ? artistNo.toString() : ''}
                  value={step2Data?.length==0?"":step2Data?.length}
                  onChange={handleChange}
                  displayEmpty
                  className={`step2_select`}
                  label="For Eg. 3 0r 4"
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {[0, 1, 2, 3, 4, 5, 6].map((option, i) => {
                    return (
                      <MenuItem value={option} key={i}>
                        {option}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
            </Grid>
          </Box>

          {accordionStates.length ? (
            <>
              {accordionStates.map((elem: any, i: number) => {
                return (
                  <Box key={i} className="accordion_box">

                    <Accordion
                      expanded={elem}
                      className="artist_accordion"
                      
                     
                    >
                      <AccordionSummary
                        expandIcon={
                          elem ? (
                            <>
                              <Box className="accordion_btn collpase_btn" onClick={() => handleAccordion(i)} >
                              - Collpase
                              </Box>
                            </>
                          ) : (
                            <>
                            <Box className="accordion_btn"  onClick={() => handleAccordion(i)}>+ Expand</Box>
                            </>
                          )
                        }
                        aria-controls={`panel${i + 1}a-content`}
                        id={`panel${i + 1}a-header`}
                        className="accordion_header"
                       
                      >
                        <div style={{display:"flex",justifyContent:"space-between",width:"100%"}}>
                        <p className="artist_heading">Artist {i + 1}</p>
                        <Button sx={{
                          margin : '10px',
                          padding:"2px !important",
                          

                        }} variant="outlined" handleClick={()=>deleteItems(i)}>Delete</Button>

                        </div>
                      </AccordionSummary>
                      <AccordionDetails className="accordion_content">
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            className="accordion_upload_grid"
                          >
                            <h6 className="accordion_upload_title">
                              Upload Artist’s Image *
                            </h6>
                            <Box className={step2Data[i]?.artist_image ? "artistImageBox" : ""}>
                              {/* {step2Data[i]?.artist_image ||
                              (completedCrop && completedCrop[i]) ? ( */}
                              <>
                                {step2Data[i]?.artist_image ? (
                                  <>
                                    <Box
                                      sx={{
                                        position: "absolute",
                                        top: "50%",
                                        background:
                                          "linear-gradient(358.51deg, #000000 0.99%, rgba(18, 18, 18, 0) 91.66%)",
                                        height: "129px",
                                        width: "99%",
                                        borderRadius:
                                          "0px 0px 3.09474px 3.09474px"
                                      }}
                                    ></Box>
                                    <Box
                                      className="menuHorizontal"
                                      style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "center"
                                      }}
                                    >
                                      <span className="textDotted" onClick={() => {
                                        setCurrentIndex(i);
                                        setToggleMenu(!toggleMenu);
                                      }}><MoreHorizIcon /></span>
                                      {step2Data[i]?.artist_image && (
                                        <img
                                          // alt="artist profile"
                                          style={{ height: "258px" }}
                                          src={step2Data[i]?.artist_image[0]}
                                        />
                                      )}
                                    </Box>
                                  </>
                                ) : <DropZone
                                  multiple={false}
                                  files={files}
                                  handleFiles={(files) =>
                                    handleFileSelection(files, i)
                                  }
                                />}

                                {toggleMenu && currentIndex == i && (
                                  <Box
                                    sx={{
                                      position: "absolute",
                                      right: "-55px",
                                      bottom: "-55px",
                                      width: "100px",
                                      background: "#FDFCFC",
                                      boxShadow:
                                        "0px 0px 2px rgba(0, 0, 0, 0.06), 0px 3px 8px rgba(0, 0, 0, 0.1)",
                                      borderRadius: "8px"
                                    }}
                                  >
                                    <OutsideClickWrapper
                                      handleClose={() => setToggleMenu(false)}
                                    >
                                      <DropZone
                                        replace={true}
                                        multiple={false}
                                        files={files}
                                        handleFiles={(files) => {
                                          handleFileSelection(files, i);
                                        }}
                                      />


                                      <Box
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => handleImageDelete(i)}
                                      >
                                        <Typography className="event-step2-toggle delete">
                                          <img
                                            src="icons/delete.png"
                                            style={{ paddingRight: "12px" }}
                                            alt="delete"
                                          />
                                          Delete
                                        </Typography>
                                      </Box>
                                    </OutsideClickWrapper>
                                  </Box>
                                )}

                                {completedCrop && completedCrop[i] && (
                                  <>
                                    <div>
                                      <canvas
                                        className={`canvas_${i}`}
                                        ref={(ref: any) => {
                                          if (ref) {
                                            previewCanvasRef.current[i] = ref;
                                          }
                                        }}
                                        style={{
                                          border: "1px solid black",
                                          objectFit: "contain",
                                          width: `calc((264px*${completedCrop[i].width})/151px)`,
                                          height: "260px",
                                          display: step2Data[i]?.artist_image ? "inline" : "none"
                                        }}
                                      />
                                    </div>
                                  </>
                                )}

                                {/* <img
                                  style={{ width: "151px", minHeight: "248px" }}
                                  src={
                                    step2Data[i]?.artist_image[0]
                                      ? step2Data[i]?.artist_image[0]
                                      : step2Data[i]?.artist_image.preview
                                      ? step2Data[i]?.artist_image.preview
                                      : ""
                                  }
                                /> */}
                              </>

                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={8}
                            className="accordion_input_grid"
                          >
                            <InputsWithLabel
                              label="Artist Name *"
                              placeholder="Title goes here"
                              type="text"
                              name={`name`}
                              id={`artist_name${i + 1}`}
                              value={
                                eventId && typeof step2Data[i]?.name != "object"
                                  ? step2Data[i]?.name
                                  : step2Data[i]?.name?.value
                              }
                              handleInputChange={(e) =>
                                handleAccordionInputs(e, i)
                              }
                            />
                            {step2Data[i] &&
                              step2Data[i]?.name?.validate === false && (
                                <ErrorMessage>
                                  {step2Data[i]?.name?.errorMsg}
                                </ErrorMessage>
                              )}

                            <InputsWithLabel
                              label="Performance Type *"
                              placeholder="Eg Vocal, Tabla, Harmonium, Sarangi..."
                              type="text"
                              name={`performance_type`}
                              id={`artist_name${i + 1}`}
                              value={

                                eventId &&
                                  typeof step2Data[i]?.performance_type !=
                                  "object"
                                  ? step2Data[i]?.performance_type
                                  : step2Data[i].performance_type?.value
                              }
                              handleInputChange={(e) =>
                                handleAccordionInputs(e, i)
                              }
                            />
                            {step2Data[i] &&
                              step2Data[i].performance_type?.validate ===
                              false && (
                                <ErrorMessage>
                                  {step2Data[i].performance_type?.errorMsg}
                                </ErrorMessage>
                              )}

                       
                            <Box className="editor_box">
                              <h6 className="editor_box_title">Artist Bio *</h6>
                              {/* <Editor
                                editorState={editorState[i]}
                                onEditorStateChange={
                                  (e) => onEditorStateChange(e, i)
                                  //onEditorStateChange(e)
                                }
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                placeholder="Enter bio here (max 250 words)"
                                {...getEditorProps(i)}
                              /> */}
                              <Editor
                                // @ts-ignore
                                value={
                                  typeof step2Data[i]?.bio == 'string'
                                    ? step2Data[i]?.bio
                                    : step2Data[i]?.bio?.value
                                }
                                className="editorClassName"
                                placeholder="Enter bio here (max 250 words)"
                                // name="bio"
                                onTextChange={(e: any) => {
                                  const inputHtmlValue = e?.htmlValue || '';
                                  if (inputHtmlValue != "") {
                                    setEditorState((prevEditorState:any) => {
                                      const newState = [...prevEditorState];
                                      newState[i] = inputHtmlValue;
                                      return newState;
                                    });
                                    // setStep2Data((prev: any) => [...prev, { ...step2Data[i], bio:inputHtmlValue, validate: true, errorMsg:"" }]);


                                    setStep2Data((prevStep2Data: any) => {
                                      const updatedData: any = prevStep2Data.map((it: any, index: any) => {
                                        if (index === i) {
                                          it.bio = {
                                            validate: true,
                                            value: inputHtmlValue,
                                            errorMsg: ""
                                          }
                                        }
                                        return it;
                                      });
                                      return updatedData;
                                    });
                                  } else {
                                    // setStep2Data((prevStep2Data: any) => {
                                    //   const updatedData: any = prevStep2Data.map((it: any, index: any) => {
                                    //     if (index === i) {
                                    //       // it.bio.validate = false
                                    //       // stepData[i][key].validate
                                    //     }
                                    //     return it;
                                    //   });
                                    //   return updatedData;
                                    // });
                                    // setStep2Data((prev: any) => [...prev, { ...step2Data[i], bio:"", validate: false, errorMsg:"kjldfd" }]);

                                    setStep2Data((prevStep2Data: any) => {
                                      const updatedData: any = prevStep2Data.map((it: any, index: any) => {
                                        if (index === i) {
                                         it.bio = {
                                            validate: false,
                                            value: "",
                                            errorMsg: "Please enter artist bio"
                                          }
                                        }
                                        return it;
                                      });
                                      return updatedData;
                                    });



                                  }
                                }} />
                              {/* {editorState[i]
                                ?.getCurrentContent()
                                .getPlainText() &&
                                getCount(
                                  editorState[i]
                                    .getCurrentContent()
                                    .getPlainText()
                                ) > 250 && (
                                  <Box className="editor_words_limit_error_box">
                                    <img
                                      src={LimitErrorImg}
                                      alt="limit error"
                                    />
                                    <span className="word_exceed_limit">
                                      Words exceed the character limit (250
                                      words)
                                    </span>
                                  </Box>
                                )} */}
                              {step2Data[i] &&
                                step2Data[i].bio?.validate === false && (
                                  <ErrorMessage>
                                    {step2Data[i].bio?.errorMsg}
                                  </ErrorMessage>
                                )}
                            </Box>

                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                );
              })}
            </>
          ) : (
            <></>
          )}
        </Box>
        <Box className="step_button_container">
          <Grid container>
            <Grid item xs={6}>
              <Button
                disable={isValid}
                sx={{
                  border: "1px solid #913713",
                  color: "#913713",
                  height: "44px",
                  padding: "0px",
                  "&:hover": {
                    border: "1px solid #913713",
                    backgroundColor: "#FFFFFF",
                    color: "#913713",
                  },
                }}
                variant="outlined"
                color="primary"
                size="s"
                handleClick={handleOpenPreviewPopup}
              >
                Preview Banner
              </Button>
            </Grid>
            <Grid item xs={6} className="step_confirm_button">
              <Button
                btnClass="draft_btn"
                variant="outlined"
                color="primary"
                size="s"
                sx={{
                  marginRight: "30px",
                }}
                handleClick={(e) => handleStep2Submit(e, true)}
              >
                Save as Draft
              </Button>
              <Button
                disable={isValid || !res || !resNew || step2Data.length === 0}
                color="primary"
                size="s"
                handleClick={(e) => handleStep2Submit(e, false)}

              >
                Next
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <ModalPopup
        open={openBlock}
        handleClickOpen={handleClickOpenBlock}
        handleClose={() => handleCloseBlock(modalIndex)}
        heading="Select cropped zone"
        closeBtn={true}
        primaryAction={() => { }}
        content={
          <>
            <Box>
              Move and resize the bounded rectangle to select area to be
              displayed on banner. Make sure the individual face comes in the
              frame.
            </Box>
            <Box sx={{ padding: "20px 0px", color: "#000" }}>
              Please preview the event before proceeding.
            </Box>
            <Box>
              {imgSrc[modalIndex] && (
                <ReactCrop
                  crop={crop}
                  onChange={(_, percentCrop) => setCrop(percentCrop)}
                  onComplete={(c) => {
                    const cropCompleteArr = [...completedCrop];
                    cropCompleteArr[modalIndex] = c;
                    setCompletedCrop(cropCompleteArr);
                  }}
                  aspect={aspect}
                >
                  <img
                    ref={(ref: any) => {
                      if (ref) {
                        imgRef.current[modalIndex] = ref;
                      }
                    }}
                    alt="Crop me"
                    src={imgSrc[modalIndex]}
                    style={{
                      transform: `scale(${scale})`,
                    }}
                    onLoad={onImageLoad}
                  />
                </ReactCrop>
              )}
            </Box>
            <Box sx={{ margin: "25px 0" }}>
              <Box
                sx={{
                  position: "relative",
                  margin: "10px 0px",
                }}
              >
                <Button
                  type="button"
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "12px",
                    lineHeight: "14px",
                    textDecoration: "none",
                    backgroundColor: "#913713",
                    padding: "20px 20px !important",
                    "&:hover": {
                      backgroundColor: "#913713",
                    },
                  }}
                  btnClass="nextBtnClass changepwd-nextBtn"
                  handleClick={() => onDownloadCropClick(modalIndex)}
                  color="error"
                  variant="contained"
                >
                  Save Changes
                </Button>
                <DropZone
                  browseAnotherText="Browse another image"
                  browseAnother={true}
                  multiple={false}
                  files={files}
                  handleFiles={(files) =>
                    handleFileSelection(files, modalIndex)
                  }
                />
              </Box>
            </Box>
          </>
        }
      />
    </>
  );
};

export default Step2;
function eventRequest(reqOptions: ReqOptions) {
  throw new Error("Function not implemented.");
}

