import React, { useEffect, useState, useRef, ReactNode } from "react";
import { EventType } from "./types/eventType";
import Topbar from "../../molecules/topbar";
import TabBar from "../../atoms/tabs";
import Event1Img from "./images/event1.png";
import Event2Img from "./images/event2.png";
import Event3Img from "./images/event3.png";
import Grids from "../../atoms/grids";
import useHttp from "../../../utils/hooks/useHttp";
import { ReqOptions } from "../../../utils/types/reqOptions";
import Loader from "../../atoms/loader";
import { Box, Grid, styled } from "@mui/material";
import Cards from "../../atoms/cards";
import TextLabels from "../../atoms/textLabels";
import MenuImg from "./images/menu.svg";
import moment from "moment";
import { useNavigate, Link } from "react-router-dom";
import { USERS_RECORDS_PER_PAGE } from "../../../utils/configs";
import Paginate from "../../atoms/pagination";
import ArrowImg from "./images/arrow.svg";
import PencilImg from "./images/pencil.svg";
import NoMember from "../../molecules/nomember";
import "./style.css";
import { Button } from "../../atoms/buttons";
import AlertCmp from "../../atoms/alert";
import useOutsideAlerter from "../../../utils/hooks/useOutsideAlerter";
import useOutsideClick from "../../../utils/hooks/useOutsideClick";
import ModalPopup from "../../atoms/modalPopup";
interface ActionItemProps {
  onClick: () => void;
  iconUrl: string;
  label: ReactNode;
  color: string;
}

const ActionItem = (props: ActionItemProps) => {
  const { label, iconUrl, onClick, color } = props;
  return (
    <li onClick={onClick}>
      <img src={iconUrl} />
      <TextLabels variant="h200" weight={500} color={color || "#7E8085"}>
        {label}
      </TextLabels>
    </li>
  );
};

const tabs = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "upcoming",
    label: "Upcoming (Live)",
  },
  {
    value: "schedule",
    label: "Scheduled",
  },
  {
    value: "cancel",
    label: "Cancelled",
  },
  {
    value: "past",
    label: "Past",
  },
  {
    value: "draft",
    label: "Saved Drafts",
  },
];

const items: EventType[] = [
  {
    eventId: 1,
    eventTitle: "Tabla Jugalbandi",
    eventImage: "",
    eventDate: "June, 15 2022",
    bannerImg: Event1Img,
    status: "Live",
    location: "234, Taj Place, Mumbai, India - 322011",
    tickets: "Paid: VVIP, Gate",
    ticketBookings: 245,
  },
  {
    eventId: 2,
    eventTitle: "Tabla Jugalbandi",
    eventImage: "",
    eventDate: "June, 15 2022",
    bannerImg: Event2Img,
    status: "Scheduled",
    location: "234, Taj Place, Mumbai, India - 322011",
    tickets: "Paid: VVIP, Gate",
    ticketBookings: 245,
  },
  {
    eventId: 3,
    eventTitle: "Tabla Jugalbandi",
    eventImage: "",
    eventDate: "April, 23 2023",
    bannerImg: Event3Img,
    status: "Past",
    location: "234, Taj Place, Mumbai, India - 322011",
    tickets: "Paid: VVIP, Gate",
    ticketBookings: 245,
  },
];

const eventItems: { gridType: "images"; items: EventType[] } = {
  gridType: "images",
  items,
};

const sortOptions = [
  {
    key: "name",
    label: "Name",
  },
  {
    key: "location",
    label: "Location",
  },
  {
    key: "date",
    label: "Date",
  },
];

const tabsName: any = {
  upcoming: {
    heading: "No Live Event Found",
    content:
      'Currently, there are no live events on the website. Click "Create" to create an event.',
  },
  schedule: {
    heading: "No Scheduled Event Found",
    content:
      'Currently, no event is scheduled to be published.Click "Create" to create an event.',
  },
  cancel: {
    heading: "No Cancelled Events Found",
    content: "No event has been cancelled till date.",
  },
  past: {
    heading: "No Past Events Found",
    content: "There are no past events to display.",
  },
  draft: {
    heading: "No Saved Drafts",
    content: "You haven’t saved any drafts yet.",
  },
  all_events: {
    heading: "No All Events Found",
    content: "There are no all events to display.",
  },
};

const EventManagement = () => {
  const { loading, error, sendRequest } = useHttp(transformData);
  const navigate = useNavigate();
  const [search, setSearch] = useState(null);
  const [events, setEvents] = useState<any[]>([]);
  const [openDelete, setOpenDelete] = useState<any>(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [toggleMenu, setToggleMenu] = useState<number | null>(null);
  const DEFAULT_TAB = "all_events";
  const [tab, setTab] = useState(DEFAULT_TAB);
  const [selectedEvent, setSelectedEvent] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterYearValue, setFilterYearValue] = useState(null);
  const [deleteModalId, setDeleteModalId] = useState<any>(null);
  const [cancelModalId, setCancelModalId] = useState<any>(null);
  const [hideShow, setHideShow] = useState<any>();
  const [totalRecords, setTotalRecords] = useState(USERS_RECORDS_PER_PAGE);
  const wrapperRef = useRef(null);
  const clickRef = useRef(null);

  const {
    loading: loadingDelete,
    error: errorDelete,
    sendRequest: sendRequestDelete,
  } = useHttp(transformDataDelete);

  const {
    loading: cancelLoading,
    error: cancelError,
    sendRequest: sendEventCancelRequest,
  } = useHttp(transformDataOfCancelEvent);

  const [responseMsg, setResponseMsg] = useState("");
  const [responseType, setResponseType] = useState<boolean>(false);
  function transformDataDelete(data: any) {
    if (data?.status) {
      setResponseMsg("Event deleted successfully.");
      setResponseType(true);

      handleCloseDelete();
      // setTimeout(() => {
      //   window.location.reload();
      // }, 5000);

      const requestOptions: ReqOptions = {
        url: `/event_managments`,
        method: "GET",
        params: {
          tab: tab,
          year: filterYearValue,
          page: 1,
          per_page: USERS_RECORDS_PER_PAGE,
          search: search,
        },
      };

      sendRequest(requestOptions);
    }
  }

  function transformDataOfCancelEvent(data: any) {
    if (data?.status) {
      setResponseMsg("Event cancelled successfully.");
      setResponseType(true);

      closeCancelModal();
      // setTimeout(() => {
      //   window.location.reload();
      // }, 5000);

      const requestOptions: ReqOptions = {
        url: `/event_managments`,
        method: "GET",
        params: {
          tab: tab,
          year: filterYearValue,
          page: 1,
          per_page: USERS_RECORDS_PER_PAGE,
          search: search,
        },
      };

      sendRequest(requestOptions);
    }
  }

  const handleDeleteEvent = () => {
    setResponseMsg("");
    setResponseType(false);
    const requestOptions: ReqOptions = {
      url: `/event_managments/${deleteModalId}`,
      method: "DELETE",
    };

    sendRequestDelete(requestOptions);
  };

  const handleCancelEvent = () => {
    setResponseMsg("");
    setResponseType(false);
    const formData = new FormData();
    formData.append("id", cancelModalId);
    const requestOptions: ReqOptions = {
      url: `/cancel_event`,
      method: "PATCH",
      contentType: "multipart/form-data",
      data: formData,
    };

    sendEventCancelRequest(requestOptions);
  };

  useEffect(() => {
    if (errorDelete || cancelError) {
      setResponseMsg("Something went wrong! Try again..");
      setResponseType(false);
    }
  }, [errorDelete, cancelError]);

  useOutsideClick(wrapperRef, handleCloseOptions);
  function handleCloseOptions() {
    // alert("lll")
    setSelectedEvent(null);
  }

  function transformData(data: any) {
    if (data.status) {
      if (data && data?.events) {
        const eventsData = data.events.map((event: any) => {
          let status = "";

          const todayDate = moment(new Date()).format("YYYY-MM-DD");
          if (event.cancel) {
            status = "cancelled";
          } else if (todayDate > event.date) {
            status = "past";
          } else if (todayDate === event.date) {
            if (event.schedule_flag) {
              status = "scheduled";
            } else {
              const start_time = event.start_date;
              const end_time = event.end_date;

              const startTime24h = start_time.substr(-2);
              const endTime24h = end_time.substr(-2);

              const startTimeString = start_time;
              const currentDate = new Date();
              const [hours, minutes] = startTimeString.split(":");

              let startTimeHour: any = "";
              if (startTime24h === "AM") {
                startTimeHour = parseInt(hours);
              } else {
                startTimeHour = parseInt(hours) + 12;
              }

              currentDate.setHours(startTimeHour, parseInt(minutes), 0);

              const endTimeString = end_time;
              const currentEndDate = new Date();
              const [hoursEnd, minutesEnd] = endTimeString.split(":");

              let endTimeHour: any = "";
              if (endTime24h === "AM") {
                endTimeHour = parseInt(hoursEnd);
              } else {
                endTimeHour = parseInt(hoursEnd) + 12;
              }

              currentEndDate.setHours(endTimeHour, parseInt(minutesEnd), 0);

              const momentNow = new Date();
              if (momentNow < currentDate) {
                status = "upcoming";
              } else {
                if (momentNow < currentEndDate) {
                  status = "live";
                } else {
                  status = "past";
                }
              }
            }
          } else {
            if (event.schedule_flag) {
              status = "scheduled";
            } else {
              status = "upcoming";
            }
          }

          event.status = status;
          return event;
        });
        setEvents(data?.events);
      }
      setTotalRecords(data.total);
    }
  }
  const handlePageChange = (e: any, page: number) => {
    setCurrentPage(page);
  };
  const handleTabChange = (tabNo: any) => {
    setTab(tabNo == "all" ? "all_events" : tabNo);
  };
  useEffect(() => {
    const requestOptions: ReqOptions = {
      url: `/event_managments`,
      method: "GET",
      params: {
        tab: tab,
        year: filterYearValue,
        page: 1,
        per_page: USERS_RECORDS_PER_PAGE,
        search: search,
      },
    };

    sendRequest(requestOptions);
  }, [tab]);

  useEffect(() => {
    const requestOptions: ReqOptions = {
      url: `/event_managments`,
      method: "GET",
      params: {
        tab: tab,
        year: filterYearValue,
        page: currentPage,
        per_page: USERS_RECORDS_PER_PAGE,
        search: search,
      },
    };

    sendRequest(requestOptions);
  }, [currentPage]);

  useEffect(() => {
    if (search !== null) {
      const timer = setTimeout(() => {
        const requestOptions: ReqOptions = {
          url: `/event_managments`,
          method: "GET",
          params: {
            tab: tab,
            year: filterYearValue,
            page: 1,
            per_page: USERS_RECORDS_PER_PAGE,
            search: search,
          },
        };

        sendRequest(requestOptions);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [search]);

  const handleSearchInput = (e: any) => {
    setSearch(e.target.value);
  };

  const handleYearFilter = (val: any) => {
    setFilterYearValue(val);
  };

  const openCancelModal = (id: any) => {
    setCancelModalId(id);
    setCancelModal(true);
  };

  const closeCancelModal = () => {
    setCancelModal(false);
  };

  const handleClickOpenDelete = (id: any) => {
    setDeleteModalId(id);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  useEffect(() => {
    if (filterYearValue !== null) {
      const requestOptions: ReqOptions = {
        url: `/event_managments`,
        method: "GET",
        params: {
          year: filterYearValue,
          tab: tab,
          page: 1,
          per_page: USERS_RECORDS_PER_PAGE,
          search: search,
        },
      };

      sendRequest(requestOptions);
    }
  }, [filterYearValue]);

  useEffect(() => {}, [events]);

  const handleEventAction = (e: any, event: any) => {
    e.stopPropagation();
    setHideShow(Math.random());
    setSelectedEvent((prev: any) => (prev?.id === event.id ? null : event));
  };

  const viewAction = {
    color: "#7E8085",
    iconUrl: "images/eye.svg",
    label: "View",
    onClick: (contentId: string) => {
      navigate(`/event-details/${contentId}`);
    },
  };

  const deleteAction = {
    color: "#7E8085",
    iconUrl: "images/delete.svg",
    label: "Delete",
    onClick: (contentId: string) => {
      handleClickOpenDelete(contentId);
    },
  };

  const editAction = {
    color: "#913713",
    iconUrl: "images/edit.svg",
    label: "Edit",
    onClick: (contentId: string) => {
      navigate(`/create-event?eid=${contentId}&edit=true`);
    },
  };

  const cancelAction = {
    color: "#7E8085",
    iconUrl: "icons/close.png",
    label: "Cancel",
    onClick: (contentId: string) => {
      openCancelModal(contentId);
    },
  };

  const pastActionItems = [viewAction];

  const generalActionItems = [
    editAction,
    viewAction,
    deleteAction,
    cancelAction,
  ];

  const draftActionItems = [deleteAction];
  const cancelActionItems = [editAction, viewAction];

  const actionItems = (() => {
    if (selectedEvent?.status === "cancelled") return cancelActionItems;
    if (selectedEvent?.status === "draft" || selectedEvent?.draft)
      return draftActionItems;
    if (selectedEvent?.status === "past") return pastActionItems;
    return generalActionItems;
  })();

  function formatDate(inputDate: any) {
    const date = new Date(inputDate);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear().toString();

    return `${month}/${day}/${year}`;
  }

  return (
    <>
      {(loading || loadingDelete || cancelLoading) && <Loader />}
      {responseMsg && (
        <AlertCmp
          type={responseType ? "success" : "error"}
          position="dashboard"
        >
          {responseMsg}
        </AlertCmp>
      )}
      {error && (
        <AlertCmp type="error" position="dashboard">
          Something went wrong! Try again..
        </AlertCmp>
      )}
      <TabBar handleClick={handleTabChange} tabs={tabs} defaultValue="all" />
      {/* <Grids
        spacing={2}
        items={eventItems}
        size={4}
        sizeMd={6}
        sizeSm={6}
        sizeM={12}
      /> */}
      <Topbar
        searchLabel="Search by event name, instrument..."
        showBtn={true}
        filterBtn={true}
        sortOptions={sortOptions}
        handleSearchInput={handleSearchInput}
        totalRecords={true}
        handleYearFilter={handleYearFilter}
        pageName="/create-event"
        totalRecordsCount={totalRecords}
        modal={{
          heading: "Create Membership",
          inputs: [],
        }}
      />
      {events && events?.length > 0 ? (
        <Grid container spacing={2}>
          {
            <>
              {events.map((content, i) => {
                return (
                  <>
                    {
                      <>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          sm={6}
                          lg={4}
                          key={i}
                          className="event_image_item_grid"
                          //onClick={() => handleEventClick(content.id, i)}
                        >
                          <Cards cardType="events_item">
                            <div
                              className="event_management_image_card"
                              style={{
                                backgroundImage: `url(${""})`,
                              }}
                            >
                              <Box
                                className={`event_management_detail_box ${
                                  content.draft
                                    ? "event_management_detail_box_mid"
                                    : ""
                                }`}
                              >
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="flex-start"
                                  className="event_management_top_title"
                                >
                                  <Box
                                    display="flex"
                                    flexDirection="column"
                                    className="event_management_top_title_content"
                                  >
                                    <TextLabels
                                      className="spanOverLapcss"
                                      variant="h200"
                                      color="#ffffff"
                                      weight={500}
                                    >
                                      {content?.title}
                                    </TextLabels>

                                    {content.draft ? (
                                      <></>
                                    ) : (
                                      <>
                                        <Box
                                          sx={{ paddingBottom: "7.5px" }}
                                        ></Box>
                                        <TextLabels
                                          variant="h100"
                                          color="#ffffff"
                                          weight={400}
                                        >
                                          {content?.date &&
                                            moment
                                              .utc(content?.date)
                                              .format("MMMM DD, YYYY")}
                                        </TextLabels>
                                      </>
                                    )}
                                  </Box>
                                  <Box
                                    display="flex"
                                    sx={{ position: "relative" }}
                                    className="event_management_menu_dots"
                                  >
                                    <div
                                      ref={clickRef}
                                      style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        justifyContent: "right",
                                        height: "12px",
                                        paddingTop: "2px",
                                      }}
                                      onClick={(e) =>
                                        handleEventAction(e, content)
                                      }
                                    >
                                      <img
                                        alt="action menu"
                                        src="images/menu-icon.svg"
                                      />
                                    </div>

                                    {selectedEvent &&
                                      selectedEvent.id === content.id && (
                                        <Box ref={wrapperRef}>
                                          <Box
                                            className={
                                              selectedEvent.status === "past" ||
                                              selectedEvent.draft
                                                ? "toggle_menu toggle_menu_events toggle_menu_events_past"
                                                : selectedEvent.status ===
                                                  "cancelled"
                                                ? "toggle_menu toggle_menu_events_cancelled"
                                                : "toggle_menu toggle_menu_events"
                                            }
                                            sx={{
                                              position: "absolute",
                                              top: "10px",
                                              right: 0,
                                            }}
                                          >
                                            <ul>
                                              {actionItems.map(
                                                (actionItem, i) => (
                                                  <ActionItem
                                                    key={i}
                                                    {...actionItem}
                                                    onClick={() =>
                                                      actionItem.onClick(
                                                        content.id
                                                      )
                                                    }
                                                  />
                                                )
                                              )}
                                            </ul>
                                          </Box>
                                        </Box>
                                      )}
                                  </Box>
                                </Box>
                                {/* {content.artists.length ? (
                                  <></>
                                ) : (
                                  <Box
                                    display="flex"
                                    sx={{ position: "relative" }}
                                    className="event_management_menu_dots"
                                  >
                                    <div
                                      //ref={clickRef}
                                      style={{
                                        display: "flex",
                                      }}
                                    >
                                      <img
                                        src="images/menu-icon.svg"
                                        onClick={(e) =>
                                          handleEventAction(content.id)
                                        }
                                      />
                                    </div>
                                    {eventId == content.id && (
                                      <EventAction
                                        event={content?.id}
                                        tab={tab}
                                      />
                                    )}
                                  </Box>
                                )} */}
                                {content.artists.length ? (
                                  <>
                                    {content.draft ? (
                                      <></>
                                    ) : (
                                      <>
                                        <Box
                                          className={`event_management_artist_image event_management_artist_image${content?.artists?.length}`}
                                          sx={
                                            content?.artists &&
                                            content?.artists?.length == 1
                                              ? { float: "right" }
                                              : content?.artists &&
                                                content?.artists?.length > 4
                                              ? {
                                                  justifyContent: "end",
                                                  paddingTop: "8px",
                                                }
                                              : {}
                                          }
                                        >
                                          {content?.artists &&
                                            content?.artists?.length > 0 &&
                                            content?.artists?.map(
                                              (i: any, indx: number) => {
                                                const styl = `artist_length${content?.artists?.length}`;

                                                if (
                                                  content?.artists?.length == 5
                                                ) {
                                                  return (
                                                    indx < 3 && (
                                                      <img
                                                        className={styl}
                                                        key={indx}
                                                        src={i?.artist_image}
                                                      />
                                                    )
                                                  );
                                                } else if (
                                                  content?.artists?.length == 6
                                                ) {
                                                  return (
                                                    indx < 3 && (
                                                      <img
                                                        className={styl}
                                                        key={indx}
                                                        src={i?.artist_image}
                                                      />
                                                    )
                                                  );
                                                } else {
                                                  return (
                                                    <img
                                                      className={styl}
                                                      key={indx}
                                                      src={i?.artist_image}
                                                    />
                                                  );
                                                }
                                              }
                                            )}
                                        </Box>
                                        {content?.artists &&
                                          content?.artists?.length > 4 && (
                                            <>
                                              <Box
                                                className="event_management_artist_image"
                                                sx={
                                                  content?.artists &&
                                                  content?.artists?.length > 4
                                                    ? {
                                                        justifyContent: "end",
                                                      }
                                                    : {}
                                                }
                                              >
                                                {content?.artists?.map(
                                                  (i: any, indx: number) => {
                                                    const styl = `artist_length${content?.artists?.length}`;
                                                    const showIndex =
                                                      content?.artists
                                                        ?.length == 5
                                                        ? 2
                                                        : 2;

                                                    return (
                                                      indx > showIndex && (
                                                        <img
                                                          className={styl}
                                                          key={indx}
                                                          src={i?.artist_image}
                                                        />
                                                      )
                                                    );
                                                  }
                                                )}
                                              </Box>
                                            </>
                                          )}
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Box>
                            </div>
                          </Cards>
                          {content.draft ? (
                            <Box className="event_management_details event_management_details_draft">
                              <Box className="event_management_draft_status">
                                <span>Drafts</span>
                              </Box>
                              <Box className="event_management_draft_button">
                                <Button
                                  variant="outlined"
                                  size="s"
                                  icon={PencilImg}
                                  handleClick={() =>
                                    navigate(
                                      `/create-event?eid=${content.id}&edit=true`
                                    )
                                  }
                                >
                                  Continue Editing
                                </Button>
                              </Box>
                            </Box>
                          ) : (
                            <Box className="event_management_details">
                              <div style={{ display: "flex" }}>
                                <Box>
                                  <span
                                    className={`event_status event_status_${content.status}`}
                                  >
                                    {content.status}
                                  </span>
                                </Box>
                                {content?.schedule_flag && (
                                  <div style={{ marginLeft: "10px" }}>
                                    <TextLabels variant="h100" weight={600}>
                                      {formatDate(content?.schedule_date)}
                                    </TextLabels>
                                  </div>
                                )}
                                {content?.sub_type && (
                                  <span
                                    className={`event_status event_status_sub_type`}
                                  >
                                    <TextLabels
                                      color={"#875f21"}
                                      variant="h100"
                                      weight={400}
                                    >
                                      {`${
                                        content?.sub_type == "free"
                                          ? "Free"
                                          : "Paid"
                                      } Event`}
                                    </TextLabels>
                                  </span>
                                )}
                                {content?.sub_type && (
                                  <span
                                    className={`event_status event_status_type`}
                                  >
                                    <TextLabels
                                      color={"#fff"}
                                      variant="h100"
                                      weight={400}
                                    >
                                      {`${
                                        content?.event_type == "imsom"
                                          ? "Imsom"
                                          : "3rd Party"
                                      } Event`}
                                    </TextLabels>
                                  </span>
                                )}
                              </div>
                              <TextLabels variant="h100" weight={700}>
                                Location
                              </TextLabels>
                              <Box
                                sx={{
                                  marginTop: "0px",
                                }}
                              ></Box>
                              <TextLabels variant="h200" weight={400}>
                                {content.venue}
                              </TextLabels>
                              <Box
                                sx={{
                                  marginTop: "15px",
                                }}
                              ></Box>
                              {content?.event_type === "imsom" && (
                                <Link
                                  to={`/event_participants/${content.id}`}
                                  className="participant_link"
                                >
                                  <p>
                                    Participant List
                                    <img src={ArrowImg} alt="" />
                                  </p>
                                </Link>
                              )}
                            </Box>
                          )}
                        </Grid>
                      </>
                    }
                  </>
                );
              })}
            </>
          }
        </Grid>
      ) : (
        <NoMember
          heading={tabsName[tab].heading}
          content={tabsName[tab].content}
        />
      )}
      {events && events?.length > 0 && (
        <Paginate
          page={currentPage}
          totalRecords={totalRecords}
          recordsPerPage={USERS_RECORDS_PER_PAGE}
          handlePageChange={handlePageChange}
        />
      )}
      <ModalPopup
        open={cancelModal}
        handleClickOpen={openCancelModal}
        handleClose={closeCancelModal}
        heading={"Cancel Event?"}
        content={
          "Are you sure you want to cancel this event? This action is non-reversible and can not be undone."
        }
        primaryButton={"Cancel Event"}
        secondaryButton={"Cancel"}
        primaryAction={handleCancelEvent}
      />
      <ModalPopup
        open={openDelete}
        handleClickOpen={handleClickOpenDelete}
        handleClose={handleCloseDelete}
        heading={"Delete Event?"}
        content={
          "Are you sure you want to delete this event? This action is non-reversible and can not be undone."
        }
        primaryButton={"Delete Event"}
        secondaryButton={"Cancel"}
        primaryAction={handleDeleteEvent}
      />
    </>
  );
};

export default EventManagement;
