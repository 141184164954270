import React, { useEffect, useState } from "react";
import { PropType } from "./types/propType";
import { Box } from "@mui/material";
import LocationImg from "./images/location.svg";
import TimeImg from "./images/time.svg";
// import LineImg from "./images/line.svg";
import alertTriangle from './images/alert-triangle.svg'
import "./style.css";
import { Button } from "../../atoms/buttons";
// import HeroBtnImg from "./images/Group 162956.svg";
import HeroBtnImg from "./images/Button.svg";
// import WebsiteBtnImg from "./images/goToWebsite.svg"
import WebsiteBtnImg from "./images/goToWebsite2.svg";
// import Free from './images/Free.svg'
import Free from "./images/Frame.svg";
import { useNavigate } from "react-router-dom";
import WebChangePassword from "../../organism/webChangePassword";
import WebForgotPassword from "../../organism/webForgotPassword";
import WebPasswordDialog from "../../organism/webPasswordDialog";
import WebOtpDialog from "../../organism/webOtpDialog";
import WebRegisterDialog from "../../organism/webRegisterDialog";
import WebLoginDialog from "../../organism/webLoginDialog";
import { ReqOptions } from "../../../utils/types/reqOptions";
import useHttp from "../../../utils/hooks/useHttp";
import AlertCmp from "../../atoms/alert";

const HeroText = ({
  title,
  location,
  time,
  price,
  previewBanner,
  id,
  eventType,
  url,
  subType,
}: PropType) => {
  const navigate = useNavigate();
  const [openOtpDialog, setOpenOtpDialog] = useState(false);
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [openForgotPasswordDialog, setOpenForgotPasswordDialog] =
    useState(false);
  const [openChangePasswordDialog, setOpenChangePasswordDialog] =
    useState(false);
  const [openRegisterDialog, setOpenRegisterDialog] = useState(false);
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const [toggleMobileMenu, setToggleMobileMenu] = useState(false);
  const [resetToken, setResetToken] = useState<any>(null);
  const [activeURL, setActiveURL] = useState<any>(null);
  const [showErrorAlert, setShowErrorAlert] = useState({
    show: false,
    message: "",
  });
  const [showAlert, setShowAlert] = useState({
    show: false,
    message: "",
  });
  const [errorText, setErrorText] = useState<any>(false);
  const [eventId, setEventId] = useState<any>("");

  const handleClick = () => {
    const token = localStorage.getItem("web_token");
    const guestUser = localStorage.getItem("guest_user");
    const currentTime = new Date(); // This will get the current time
    const eventTime = new Date(time);
    // @ts-ignore
    const timeDifference = eventTime - currentTime;
    const fourHoursInMilliseconds = 4 * 60 * 60 * 1000;

    if (eventType == "third_party") {
      const newTab = window.open(url, "_blank");
    } else {
      if (token == null && guestUser == null) {
        setEventId(id);
        setOpenLoginDialog(true);
      } else {
        if (timeDifference <= fourHoursInMilliseconds) {
          setErrorText(true);
          setTimeout(() => {
            setErrorText(false);
          }, 5000);
        } else if (subType === "paid") {
          navigate(`/ticket-booking/${id}`);
        }
      }
    }
  };

  const handleAlertBox = (message: any) => {
    setShowAlert({
      show: true,
      message: message,
    });

    setTimeout(() => {
      setShowAlert({
        show: false,
        message: "",
      });
    }, 5000);
  };

  const handleAlertErrorBox = (message: any) => {
    setShowErrorAlert({
      show: true,
      message,
    });

    setTimeout(() => {
      setShowErrorAlert({
        show: false,
        message: "",
      });
    }, 5000);
  };

  const { loading, error, sendRequest } = useHttp(transformData);
  function transformData(data: any) {
    if (data?.status) {
      if (data?.payments?.length > 0) {
        setActiveURL(data?.payments[0]?.payment_link);
      }
    }
  }

  const getURL = () => {
    const reqOptions: ReqOptions = {
      url: `/payment_links`,
      method: "GET",
      params: {
        status: "true",
      },
    };

    sendRequest(reqOptions);
  };

  useEffect(() => {
    getURL();
  }, []);

  const handleCloseLoginDialog = (event: any, reason: any) => {
    if (reason && reason == "backdropClick") return;
    setOpenLoginDialog(false);
    eventId(null);
  };

  const handleCloseRegisterDialog = (event: any, reason: any) => {
    if (reason && reason == "backdropClick") return;
    setOpenRegisterDialog(false);
  };

  const handleCloseOtpDialog = (event: any, reason: any) => {
    if (reason && reason == "backdropClick") return;
    setOpenOtpDialog(false);
  };

  const handleClosePasswordDialog = (event: any, reason: any) => {
    if (reason && reason == "backdropClick") return;
    setOpenPasswordDialog(false);
  };

  const handleCloseForgotPasswordDialog = (event: any, reason: any) => {
    if (reason && reason == "backdropClick") return;
    setOpenForgotPasswordDialog(false);
  };

  const handleCloseChangePasswordDialog = (event: any, reason: any) => {
    if (reason && reason == "backdropClick") return;
    setOpenChangePasswordDialog(false);
  };

  const handleOpenLoginDialog = () => {
    setOpenLoginDialog(true);
    setOpenRegisterDialog(false);
    // setOpenOtpDialog(false);
    setToggleMobileMenu(false);
    setOpenPasswordDialog(false);
    setOpenForgotPasswordDialog(false);
    setOpenChangePasswordDialog(false);
  };

  const handleOpenRegisterDialog = () => {
    setOpenRegisterDialog(true);
    setOpenLoginDialog(false);
    // setOpenOtpDialog(false);
    setOpenPasswordDialog(false);
    setOpenForgotPasswordDialog(false);
    setOpenChangePasswordDialog(false);
  };

  const handleOpenOtpDialog = () => {
    setOpenOtpDialog(true);
    setOpenLoginDialog(false);
    setOpenRegisterDialog(false);
    setOpenPasswordDialog(false);
    setOpenForgotPasswordDialog(false);
    setOpenChangePasswordDialog(false);
  };

  const handleOpenPasswordDialog = () => {
    setOpenPasswordDialog(true);
    setOpenOtpDialog(false);
    setOpenLoginDialog(false);
    setOpenRegisterDialog(false);
    setOpenForgotPasswordDialog(false);
    setOpenChangePasswordDialog(false);
  };

  const handleOpenForgotPasswordDialog = () => {
    setOpenPasswordDialog(false);
    // setOpenOtpDialog(false);
    setOpenLoginDialog(false);
    setOpenRegisterDialog(false);
    setOpenChangePasswordDialog(false);
    setOpenForgotPasswordDialog(true);
  };
  const webToken = localStorage.getItem("web_token");

  const imageUrl =
    eventType === "third_party"
      ? WebsiteBtnImg
      : subType === "free"
      ? Free
      : HeroBtnImg;

  return (
    <Box>
      {errorText && (
        <AlertCmp type="error_web" position="user_side">
          we are no longer accepting bookings
        </AlertCmp>
      )}
      <Box
        className={
          previewBanner
            ? "hero_heading_box hero_heading_box_preview"
            : "hero_heading_box"
        }
      >
        <h6
          className={
            previewBanner ? "hero_heading hero_heading_line" : "hero_heading"
          }
          style={{
            color: "#fff",
          }}
        >
          <span
            contentEditable={webToken ? "true" : "false"}
            className="hero_heading_span_line"
          >
            {title}
          </span>
        </h6>
        {/* <img className="hero_heading_line" src={LineImg} /> */}
      </Box>
      <Box className="hero_info">
        <img src={LocationImg} alt="Not-Found" />
        <p
          className={
            previewBanner
              ? "hero_info_text hero_info_text_preview"
              : "hero_info_text"
          }
        >
          {location}
        </p>
      </Box>
      <Box className="hero_info">
        <img src={TimeImg} alt="Not_Found" />
        <p
          className={
            previewBanner
              ? "hero_info_text hero_info_text_preview"
              : "hero_info_text"
          }
        >
          {time}
        </p>
      </Box>
      {price && (
        <Box className="hero_price_info">
          <Box className="hero_booking_box">
            {/* <span
              className={
                previewBanner
                  ? "hero_price_booking hero_price_booking_preview"
                  : "hero_price_booking"
              }
            >
              Get Tickets
            </span> */}
          </Box>
          {/* <Box className="hero_price_count">
            <h4
              className={
                previewBanner
                  ? "hero_price_tag hero_price_tag_preview"
                  : "hero_price_tag"
              }
            >
              ${price}
            </h4>
            <p
              className={
                previewBanner
                  ? "hero_price_count_para hero_price_count_para_preview"
                  : "hero_price_count_para"
              }
            >
              Onwards
            </p>
          </Box> */}
        </Box>
      )}
      {previewBanner ? (
        <></>
      ) : (
        <>
          <Box className="hero_button">
          {subType === "free" && (
              <p
                className="hero_info_text"
                style={{ marginTop: "0px", width: "300px", marginBottom: "40px" }}
              >
                <img src={alertTriangle} alt="" /> &nbsp; This concert is free.
              </p>
            )}
            <Button
              variant="outlined"
              color="info"
              size="s"
              sx={{
                fontFamily: "Raleway",
                fontWeight: "700",
                fontSize: "14px",
                height: "42px",
                zIndex: "9999",
              }}
              handleClick={() => navigate(`/upcoming-event/${id}`)}
            >
              Event Details
            </Button>
            
          </Box>
          <Box className="hero_button_image">
            <img
              src={imageUrl}
              // onClick={() => {navigate(`/ticket-booking/${id}`)}}
              onClick={() => {
                handleClick();
              }}
              alt="Not-Found"
            />
          </Box>
        </>
      )}
      {/* login dialog box */}
      <WebLoginDialog
        open={openLoginDialog}
        handleClose={handleCloseLoginDialog}
        handleOpenDialog={handleOpenRegisterDialog}
        handleOpenOtpDialog={handleOpenOtpDialog}
        handleAlertBox={handleAlertBox}
        handleAlertErrorBox={handleAlertErrorBox}
        handleOpenForgotPasswordDialog={handleOpenForgotPasswordDialog}
        eventId={eventId}
      />

      {/* register dialog box */}
      <WebRegisterDialog
        open={openRegisterDialog}
        handleClose={handleCloseRegisterDialog}
        handleOpenDialog={handleOpenLoginDialog}
        handleOtpOpen={() => {
          setOpenRegisterDialog(false);
          setOpenOtpDialog(true);
        }}
        handleAlertBox={handleAlertBox}
        handleAlertErrorBox={handleAlertErrorBox}
        page=""
      />

      {/* otp dialog box */}
      <WebOtpDialog
        open={openOtpDialog}
        handleClose={handleCloseOtpDialog}
        handleAlertBox={handleAlertBox}
        handlePasswordOpen={handleOpenPasswordDialog}
        handleAlertErrorBox={handleAlertErrorBox}
      />

      <WebPasswordDialog
        open={openPasswordDialog}
        handleClose={handleClosePasswordDialog}
        handleOpenDialog={handleOpenPasswordDialog}
        handleAlertBox={handleAlertBox}
        handleAlertErrorBox={handleAlertErrorBox}
      />

      <WebForgotPassword
        open={false}
        handleClose={handleCloseForgotPasswordDialog}
        handleAlertBox={handleAlertBox}
        handleAlertErrorBox={handleAlertErrorBox}
      />

      <WebChangePassword
        open={false}
        handleClose={handleCloseChangePasswordDialog}
        handleAlertBox={handleAlertBox}
        handleAlertErrorBox={handleAlertErrorBox}
        handleOpenLoginDialog={handleOpenLoginDialog}
        resetToken={resetToken}
      />
    </Box>
  );
};

export default HeroText;
