import React, { useState } from "react";
import CardSection from "../../molecules/cardSection";
import { Box, Grid } from "@mui/material";
import WebsiteArtistImage from "../../atoms/websiteArtistImage";
import TitleDivider from "../../atoms/titleDivider";
import { PropType } from "./types/propType";
import "./style.css";
import index from "../../atoms/anchor";




const ArtistDetailSection = ({
  artist_image,
  name,
  performance_type,
  desc,
  bio,
  acknowledgement
}: PropType) => {
  const [toggleExpand, setToggleExpand] = useState(false);
  const [showMore, setShowMore] = useState(false);



  const handleExpand = () => {
    setToggleExpand(true);
  };

  const handleCollpase = () => {
    setToggleExpand(false);
  };
 


  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  function sliceTextAtWordBoundary(text: any, limit: any) {
    if (text?.length <= limit) {
      return text;
    }

    // const words = text?.split(' ');
    const words = text ? text.split(' ') : '';
    let slicedText = '';
    let characterCount = 0;

    for (const word of words) {
      if (characterCount + word?.length <= limit) {
        slicedText += word + ' ';
        characterCount += word?.length + 1; // +1 for the space
      } else {
        break;
      }
    }

    return slicedText.trim() + '...';
  }

  const slicedBio = showMore ? bio : sliceTextAtWordBoundary(bio, 200);

  return (
    <Box className="artist_detail_section_website">
      <CardSection>
        <Grid container className="artist_detail_grid_website" spacing={4}>
          <Grid
            item
            md={2}
            sm={12}
            xs={12}
            className="artist_detail_image_mobile"
          >
            <WebsiteArtistImage image={artist_image} />
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
            <Box className="artist_detail_info_website">
              <Box className="artist_heading_box_website">
                <TitleDivider>
                  <h4 className="artist_heading_box_website_name" >
                    {name} 
                  </h4>
                  <span style={{color:"white"}}>({performance_type})</span>
                </TitleDivider>
              </Box>
              {/* <p dangerouslySetInnerHTML={{ __html: acknowledgement }}></p> */}
            </Box>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Box className="artist_detail_bio_expand">
              <Box>
                {toggleExpand ? (
                  <div onClick={handleCollpase}>
                    <p>
                      <span>-</span>&nbsp;Collapse
                    </p>
                  </div>
                ) : (
                  <div onClick={handleExpand}>
                    <p>
                      <span>+</span>&nbsp;Expand
                    </p>
                  </div>
                )}
              </Box>
              {toggleExpand && <p className="custom-content"  dangerouslySetInnerHTML={{ __html: bio }} />}
            </Box> 
            <Box className="artist_detail_bio_website">
              <div className="custom-content"  dangerouslySetInnerHTML={{ __html: slicedBio }} />
              {bio?.length > 200 && (
                <p onClick={toggleShowMore} className="view-more-link">
                  {showMore ? 'View Less' : 'View More'}
                </p>
              )}
            </Box>
          </Grid>
        </Grid>
      </CardSection>
    </Box>
  );
};

export default ArtistDetailSection;
