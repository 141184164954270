import React, { useState, useEffect } from "react";
import HeroBanner from "../../organism/heroBanner";
import WelcomeSection from "../../organism/welcomeSection";
import LimitedSection from "../../organism/limitedSection";
import FeelMusicSection from "../../organism/feelMusicSection";
import MembershipSection from "../../organism/membershipSection";
import PastEventSection from "../../organism/pastEventSection";
import AnnouncementCardSection from "../../organism/announcementCardSection";
import GallerySection from "../../organism/gallerySection";
import useGetApi from "../../../utils/hooks/usegetApi";
import useEditAboutTitle from "../../../utils/hooks/useEditableTitle";
import SaveIcon from "@mui/icons-material/Save";
import useHttp from "../../../utils/hooks/useHttp";
import "./index.css";

const Home = () => {
  const [isEditing, setIsEditing] = useState(true);

  const [showText, setShowText] = useState<any>([]);
  const [savebtnShow, setSavebtnShow] = useState<boolean>(false);

  const handleInputChange = (e: any) => {
    setShowText({ ...showText, [e.target.id]: e.target.value });
    setSavebtnShow(true);
  };
  const { callGetApi } = useGetApi();

  const apiCall = async () => {
    const result1 = await callGetApi("homes");
    setShowText(result1?.HomeText?.[0]);
  };

  useEffect(() => {
    // Inside the useEffect hook, make the GET request
    apiCall();
  }, []);

  const { aboutUsTitle } = useEditAboutTitle();

  const handleSaveClick = async () => {
    setIsEditing(false);
    const home_text = {
      left_text: showText.left_text,
      right_text: showText?.right_text,
      bottom_text: showText?.bottom_text,
    };
    await aboutUsTitle({ home_text }, 1, "homes");
    apiCall();
    setSavebtnShow(false);
  };
  const [banner, setBanner] = useState<any>([]);
  const transformBanner = (data: any) => {
    if (data.status) {
      setBanner(data?.banners || []);
    }
  };
  const {
    loading: loadBanner,
    error: bannerErr,
    errorFlag: bannerErrFlag,
    sendRequest,
  } = useHttp(transformBanner);
  useEffect(() => {
    const reqOptions = {
      url: `/banners/get_today_banner`,
      method: "GET",
      params: {
        page: 1,
        per_page: 6,
      },
    };

    sendRequest(reqOptions);
  }, []);
  const [carouselContent, setCarouselContent] = useState<any>([]);
  function transformData(data: any) {
    if (data.status) {
      const eventsData = data.events;
      const eventsArr = [];
      for (const event of eventsData) {
        let obj = {
          textContent: {
            id: event.id,
            title: event.title,
            location: `${event.venue}`,
            price: event.ticket_details
              ? event.ticket_details[0]?.advance_value
              : "--",
          },
          artists: event.artists,
        };
        eventsArr.push(obj);
      }
      setCarouselContent(eventsArr);
    }
  }
  const { loading, error, errorFlag, sendRequestWithoutToken } =
    useHttp(transformData);

  useEffect(() => {
    const reqOptions = {
      url: `/event_managments`,
      method: "GET",
      params: {
        tab: "both_events",
        // page: 1,
        // per_page: 6,
      },
    };

    sendRequestWithoutToken(reqOptions);
  }, []);

  return (
    <>
      {savebtnShow && (
        <SaveIcon className="save_icon" onClick={handleSaveClick} />
      )}

      {/* {(carouselContent?.length > 0 || banner.length > 0) &&  <HeroBanner />} */}
      <HeroBanner />
      <WelcomeSection
        handleInputChange={handleInputChange}
        handleSaveClick={handleSaveClick}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        showText={showText}
      />
      {/* <LimitedSection /> */}
      {/* <FeelMusicSection handleInputChange={handleInputChange} handleSaveClick={handleSaveClick} isEditing={isEditing} setIsEditing={setIsEditing} showText={showText}/> */}
      <div className="membership_div">
        <MembershipSection />
      </div>
      {/* <PastEventSection /> */}
      <AnnouncementCardSection />
      {/* <GallerySection /> */}
    </>
  );
};

export default Home;
