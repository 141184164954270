import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useHttp from "../../../utils/hooks/useHttp";
import Loader from "../../atoms/loader";
import SectionHeading from "../../molecules/sectionHeading";
import ArrowImg from "./images/arrow.svg";
import "./style.css";
import SimpleSlider from "../../atoms/webCarousel/newCarousel";

interface Props {
    galleryData:any;
}

const GallerySection = (props:Props) => {
  return (
    <>
      <Box className="gallery_section">
        <SectionHeading
          title="Gallery"
          marginRight="-252"
          marginBottom="10"
          width="106"
        />
        {/* <WebCarousel carouselContent={galleryData} type="gallery" /> */}
        <SimpleSlider carouselContent={props.galleryData} type="gallery" />
        <Box className="gallery_section_link_container">
          <Box className="gallery_section_link_btn">
            <Link to="/event/gallery/1">
              <p>
                View All
                <img src={ArrowImg} alt={"arrow"} />
              </p>
            </Link>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default GallerySection;
