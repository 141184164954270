import { Box, Button, Grid, Paper, useMediaQuery } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { Link, useNavigate } from "react-router-dom";
import HeroImages from "../../molecules/heroImages";
import HeroText from "../../molecules/heroText";
import ArrowImg from "./images/arrow.svg";
import ArrowPinkImg from "./images/arrow_pink.svg";
import "./slick.css";
import "./style.css";
import { PropType } from "./types/propType";
import { useEffect, useState } from "react";
import { maxWidth, width } from "@mui/system";
import CardSection from "../../molecules/cardSection";
import Image from "./images/img4.png";
import { SettingsInputHdmiOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const WebCarousel = ({ carouselContent, type, banner }: PropType) => {
  localStorage.setItem("sliderLength", carouselContent.length);
  // const isSm = useMediaQuery("(max-width: 600px)");
  const isMobile = useMediaQuery("(max-width:600px)");
  const [showMore, setShowMore] = useState<string | number>("");

  const toggleShowMore = (index: any) => {
    if (showMore === index) {
      setShowMore("");
    } else {
      setShowMore(index);
    }
    // console.log(11111,items.index)
  };

  // const slicedBio = showMore ? bio : sliceTextAtWordBoundary(bio, 200);

  function sliceTextAtWordBoundary(text: any, limit: any) {
    if (text?.length == limit) {
      return text;
    }

    // const words = text?.split(' ');
    const words = text ? text.split(" ") : "";
    let slicedText = "";
    let characterCount = 0;

    for (const word of words) {
      if (characterCount + word?.length <= limit) {
        slicedText += word + " ";
        characterCount += word?.length + 1; // +1 for the space
      } else {
        break;
      }
    }

    return slicedText.trim() + "...";
  }

  var items = [
    {
      name: "Random Name #1",
      description: "Probably the most random thing you have ever seen!",
    },
    {
      name: "Random Name #2",
      description: "Hello World!",
    },
  ];
  function Item(props: any) {
    return (
      <Paper>
        <h2>{props.item.name}</h2>
        <p>{props.item.description}</p>

        <Button className="CheckButton">Check it out!</Button>
      </Paper>
    );
  }
  const carouselStyle: React.CSSProperties = {
    height: "500px", // Adjust the height as per your requirement
    // display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    background: "transparent",
    marginRight: "15px",
    marginTop: "80px",
  };

  const imageStyle: React.CSSProperties = {
    maxHeight: "100%",
    // width: '100%',
    height: "100%",
    // objectFit: 'cover',
    cursor: "pointer",
    marginTop: "130px",
    padding: "20px",
  };
  const imageStyleMobile: React.CSSProperties = {
    maxHeight: "100%",
    width: "100%",
    height: "100%",
    // objectFit: 'cover',
    cursor: "pointer",
    marginTop: "130px",
    padding: "20px",
  };
  const mediaQuery = "@media screen and (max-width: 768px)";
  const responsiveImageStyle: React.CSSProperties = {
    ...imageStyle,
    width: "100% !important",
  };
  const navigate = useNavigate();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [activeIndex, setActiveIndex] = useState<any>(0);
  useEffect(() => {
    // Start at the first index when the component loads
    // setActiveIndex(0);
    const timeoutId = setTimeout(() => {
      if (screenWidth < 600) {
        // AutoPlay starts after the timeout
        setActiveIndex(1); // This will start the autoplay from the first index
      }
    }, 300);
  }, [screenWidth]);

  const handleNext = () => {
    setActiveIndex(
      (prevIndex: number) => (prevIndex + 1) % carouselContent.length
    );
  };

  const handlePrev = () => {
    setActiveIndex(
      (prevIndex: number) =>
        (prevIndex - 1 + carouselContent.length) % carouselContent.length
    );
  };

  // console.log(carouselContent, "data");

  if (type === "hero_banner" || type === "gallery") {
    return (
      <>
        <Carousel
          className={
            type === "hero_banner"
              ? "carousel carousel_hero_banner"
              : "carousel"
          }
          index={activeIndex}
          navButtonsAlwaysInvisible={type === "gallery" ? false : true}
          navButtonsAlwaysVisible={type === "gallery" ? true : false}
          // autoPlay={true}
          onChange={() => {
            setShowMore("");
          }}
          // autoPlay={type === "hero_banner" ? true : false}

          // navButtonsProps={{
          //   style: {
          //     backgroundColor: "transparent",
          //     borderRadius: 0,
          //   },
          // }}
          // NextIcon={<img src={ArrowImg} className="next_icon" />}
          // PrevIcon={<img src={ArrowImg} className="prev_icon" />}
          // interval={1}
          interval={5000} // Set the autoplay interval to 1000 milliseconds (1 second)
          navButtonsProps={{
            style: {
              backgroundColor: "transparent",
              borderRadius: 0,
              justifyContent: "center", // Center the arrows below the slides
            },
          }}
          // NextIcon={<img src={ArrowImg} className="next_icon" onClick={handleNext} />}
          // PrevIcon={<img src={ArrowImg} className="prev_icon" onClick={handlePrev} />}
          NextIcon={
            <IconButton onClick={handleNext} style={{ color: "#E97E54" }}>
              <ArrowBackIosIcon />
            </IconButton>
          }
          PrevIcon={
            <IconButton onClick={handlePrev} style={{ color: "#E97E54" }}>
              <ArrowForwardIosIcon />
            </IconButton>
          }
          indicatorIconButtonProps={{
            style: {
              padding: "10px", // 1
              color: "#E97E54", // 3
            },
          }}
          activeIndicatorIconButtonProps={{
            style: {
              color: "red", // 2
            },
          }}
          indicatorContainerProps={
            type === "hero_banner"
              ? {
                  style: {
                    textAlign: "left",
                    border: "none",
                    bottom: "-100px",
                    left: "48px",
                    zIndex: "99",
                    position: "absolute",
                  },
                }
              : type === "gallery"
              ? {
                  style: {
                    marginTop: "10px",
                    zIndex: "12",
                    position: "absolute",
                  },
                }
              : {
                  style: {
                    textAlign: "center",
                    border: "none",
                  },
                }
          }
        >
          {carouselContent.concat(banner).map((item: any, i: number) => {
            if (type == "hero_banner") {
              if (item?.type == "carouselContent") {
                return <HeroItem key={i} item={item} />;
              } else {
                return (
                  <div>
                    <Paper key={item?.id} style={carouselStyle}>
                      {/* {item.banner_image[0] && isMobile && (
                        // <img className="hero_banner_donate_image" src={item.banner_image?.[0]} alt={item.title}  style={ imageStyleMobile}/>
                          <CardSection>
                        <>
                          <div className="main_div">          
                                <>
                                  
                                  <div className="left_div ">
                                    <img src={item.banner_image?.[0]}alt="" />
      
                                  </div>
                                  <div className="right_div">
                                    <h1 className="heading_desc">{item?.title}</h1>
                                    <p className="artist_desc" dangerouslySetInnerHTML={{ __html: item?.description }}></p>
                  
                                  </div>
                                </>
                  
                             
                          </div>
                        </>
                      </CardSection>
                    )} */}
                      {item.banner_image[0] && (
                        // <img className="hero_banner_donate_image" src={item.banner_image?.[0]} alt={item.title}  style={ imageStyle}/>
                        <div className="main_div">
                          <CardSection>
                            <>
                              <div className="banner_wrapper">
                                <div className="left_div ">
                                  <img
                                    className="banner_image"
                                    src={item.banner_image?.[0]}
                                    alt=""
                                  />
                                </div>
                                <div className="right_div">
                                  <h1 className="heading_desc">
                                    {item?.title}
                                  </h1>
                                  {showMore === i ? (
                                    <p
                                      className="artist_desc"
                                      dangerouslySetInnerHTML={{
                                        __html: item?.description,
                                      }}
                                    ></p>
                                  ) : (
                                    <p
                                      className="artist_desc"
                                      dangerouslySetInnerHTML={{
                                        __html: sliceTextAtWordBoundary(
                                          item?.description,
                                          200
                                        ),
                                      }}
                                    ></p>
                                  )}
                                  {item?.description?.length > 200 && (
                                    <p
                                      onClick={() => toggleShowMore(i)}
                                      className="view-more-link"
                                      style={{
                                        width: "76%",
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        color: "white",
                                        textDecoration: "none",
                                        fontWeight: "500",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      {showMore === i
                                        ? "View Less"
                                        : "View More"}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </>
                          </CardSection>
                        </div>
                      )}
                    </Paper>
                  </div>
                );
              }
            } else if (type === "gallery") {
              return <GalleryItem key={i} carouselContent={carouselContent} />;
            } else {
              return (
                <div
                  onClick={() => {
                    navigate("/donate");
                  }}
                >
                  <Paper key={banner?.id} style={carouselStyle}>
                    <img
                      src={banner?.banner_image[0]}
                      alt={banner?.title}
                      style={imageStyle}
                    />
                  </Paper>
                </div>
              );
            }
          })}

          {/* {banner && banner.map((banner: any) => (
          <div onClick={() => { navigate("/donate") }}>
            <Paper key={banner.id} style={carouselStyle}>
              <img src={banner.banner_image[0]} alt={banner.title} style={imageStyle} />
            </Paper>
          </div>
        ))} */}
        </Carousel>
        <div className="arrow" style={{ textAlign: "center" }}>
          <IconButton onClick={handlePrev} style={{ color: "#E97E54" }}>
            <ArrowBackIosIcon />
          </IconButton>
          <IconButton onClick={handleNext} style={{ color: "#E97E54" }}>
            <ArrowForwardIosIcon />
          </IconButton>
        </div>
      </>
    );
  }

  if (type === "announcement_card") {
    const convertedCarousel = [];
    const chunkSize = 3;

    for (let i = 0; i < carouselContent.length; i += chunkSize) {
      const chunk = carouselContent.slice(i, i + chunkSize);
      convertedCarousel.push(chunk);
    }
    return (
      <>
        <Carousel
          className={"carousel"}
          navButtonsAlwaysInvisible={true}
          navButtonsAlwaysVisible={false}
          autoPlay={true}
          interval={5000}
          navButtonsProps={{
            style: {
              backgroundColor: "transparent",
              borderRadius: 0,
              justifyContent: "center", // Center the arrows below the slides
            },
          }}
          NextIcon={
            <IconButton onClick={handleNext} style={{ color: "#E97E54" }}>
              <ArrowBackIosIcon />
            </IconButton>
          }
          PrevIcon={
            <IconButton onClick={handlePrev} style={{ color: "#E97E54" }}>
              <ArrowForwardIosIcon />
            </IconButton>
          }
          indicatorIconButtonProps={{
            style: {
              padding: "10px", // 1
              color: "#E97E54", // 3
            },
          }}
          activeIndicatorIconButtonProps={{
            style: {
              color: "red", // 2
            },
          }}
          indicatorContainerProps={{
            style: {
              textAlign: "center",
              border: "none",
            },
          }}
        >
          {convertedCarousel.map((announcements: any, i: number) => {
            return (
              <AnnouncementItem
                announcements={announcements}
                carouselContent={carouselContent}
              />
            );
          })}
        </Carousel>
      </>
    );
  }
  return null;
};

function HeroItem({ item }: any) {
  // const [renderfirst, setRenderfirst] = useState(true)
  // useEffect(() => {
  //   const toggleCss=()=>
  //   {
  //     setRenderfirst(false)
  //     setTimeout(()=>
  //     {
  //       setRenderfirst(true);
  //     },10000)
  //   }
  //   toggleCss();
  //   return () => clearTimeout(toggleCssClass);

  // },)

  return (
    <Paper className="carousel_slide" style={{ display: "block" }}>
      <Box sx={{ flexGrow: 1 }} className="carousel_slide_box">
        <Grid container spacing={2}>
          <Grid item sm={12} md={5} xs={12}>
            <HeroText {...item.textContent} />
          </Grid>
          <Grid item sm={12} md={7} xs={12} className="carousel_image_grid">
            <HeroImages images={item.artists} />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}

function AnnouncementItem({ announcements }: any) {
  // console.log("itemmmmm",announcements)
  return (
    <Paper className="carousel_slide">
      <Box>
        <Grid container spacing={2} className="announcement_carousel_grid">
          {announcements.map((item: any) => (
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              className="announcement_carousel_grid_item"
              key={item.id}
            >
              <h4 className="announcement_card_title">{item.title}</h4>
              {/* <p className="announcement_card_subtitle">{item.message}</p> */}
              <p
                dangerouslySetInnerHTML={{ __html: item.message }}
                className="announcement_card_subtitle"
              />
              <Link
                to={`/announcement-detail/${item.id}`}
                className="announcement_card_link"
              >
                <p>
                  Learn More
                  <img src={ArrowPinkImg} alt="arrow" />
                </p>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Paper>
  );
}

function GalleryItem({ carouselContent }: any) {
  return (
    <Box className="gallery_grid_container">
      <Grid container spacing={2} className="gallery_carousel_grid">
        <Grid
          item
          xs={12}
          sm={4}
          display={{ xs: "none", sm: "block" }}
          className="gallery_carousel_grid_item"
        >
          <Box className="gallery_image_box">
            <img src={carouselContent[0]?.event_images[0]} alt="gallery" />
          </Box>
          <h6 className="gallery_image_title">{carouselContent[0]?.title}</h6>
        </Grid>
        <Grid item xs={12} sm={4} className="gallery_carousel_grid_item">
          <Box>
            <Box className="gallery_image_box_landscape">
              <img src={carouselContent[1]?.event_images[0]} alt="gallery" />
            </Box>
            <h6 className="gallery_image_title">{carouselContent[2]?.title}</h6>
          </Box>
          <Box className="gallery_second_image">
            <Box className="gallery_image_box_landscape">
              <img src={carouselContent[2]?.event_images[0]} alt="gallery" />
            </Box>
            <h6 className="gallery_image_title">{carouselContent[2]?.title}</h6>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          display={{ xs: "none", sm: "block" }}
          className="gallery_carousel_grid_item"
        >
          <Box className="gallery_image_box">
            <img src={carouselContent[3]?.event_images[0]} alt="gallery" />
          </Box>
          <h6 className="gallery_image_title">{carouselContent[3]?.title}</h6>
        </Grid>
      </Grid>
    </Box>
  );
}

export default WebCarousel;
