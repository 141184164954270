import { Box, Grid, debounce } from "@mui/material";
import { convertFromHTML, convertToHTML } from "draft-convert";
import { EditorState } from "draft-js";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import useHttp from "../../../utils/hooks/useHttp";
import { ReqOptions } from "../../../utils/types/reqOptions";
import AlertCmp from "../../atoms/alert";
import { Button } from "../../atoms/buttons";
import ErrorMessage from "../../atoms/errorMessage";
import Loader from "../../atoms/loader";
import ModalFormPopup from "../../atoms/modalFormPopup";
import ModalImageUploadPopup from "../../atoms/modalImageUploadPopup";
import ModalPopup from "../../atoms/modalPopup";
import TextLabels from "../../atoms/textLabels";
import InputsWithLabel from "../../molecules/inputsWithLabel";
import { EventContext } from "../../organism/wizardSteps";
import LimitErrorImg from "./images/ic_error.svg";
import step4Img1 from "./images/logoKFAIsquare.jpg";
import step4Img2 from "./images/logoLegacy.jpg";
import step4Img3 from "./images/logoLocalfiles.jpg";
import step4Img4 from "./images/logoMSAB.jpg";
import "./style.css";
import dayjs from 'dayjs';

interface Props {
  handleOpenPreviewPopup: any;
}

const Step4 = ({ handleOpenPreviewPopup }: Props) => {
  const thumbsContainer: any = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 10,
    marginLeft: 20,
  };
  const thumb: any = {
    display: "inline-flex",
    borderRadius: 8,
    marginBottom: 8,
    marginRight: 20,
    width: 72,
    height: 72,
  };
  const thumbInner: any = {
    display: "flex",
    minWidth: 0,
    position: "relative",
    width: "100%",
    height: "100%",
    borderRadius: "8px",
  };
  const img: any = {
    display: "block",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "8px",
  };
  const [editorLengthError, setEditorLengthError] = useState(false)
  const { loading, error, sendRequest } = useHttp(transformData);
  const [responseMsg, setResponseMsg] = useState("");
  const [publishModal, setPublishModal] = useState(false);
  const [scheduleModal, setScheduleModal] = useState(false);
  const [responseType, setResponseType] = useState<boolean>(false);
  const { formData, handleFormDataInput } = useContext(EventContext);
  const [eventId, setEventId] = useState(
    formData?.event_managment?.id ? formData?.event_managment?.id : null
  );
  // const [draftCheck, setDraftCheck] = useState(false);
  const draftRef = useRef(false);

  const [files, setFiles] = useState<any[]>([]);
  const [selectedImages, setSelectedImages] = useState<any[]>([]);
  const [defaultImages, setDefaultImages] = useState<any[]>([step4Img1, step4Img2, step4Img3, step4Img4]);
  const [defaultContact, setDefaultContact] = useState<{
    contact1: [string, string];
    contact2: [string, string];
  }>({
    contact1: ["Ameeta Kelekar", "ameeta.kelekar@gmail.com"],
    contact2: ["Abhinav Sharma", "toabhinavs25@gmail.com"]
  });

  const [eventImages, setEventImages] = useState<any[]>(
    formData?.event_managment?.event_images ?
      formData?.event_managment?.event_images : []
  );

  // console.log("form data: ", formData);


  const navigate = useNavigate();
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const inputs: any = [
    {
      label: "Date",
      placeholder: "DD/MM/YYYY",
      type: "date",
      name: "schedule_date",
      id: "schedule_date",
      grid: 2,
      errorMsg: "Please select start date",
      inputProps: { max: formData?.event_managment?.date, min: dayjs() },
    },
    {
      label: "Time",
      placeholder: "",
      type: "time",
      name: "schedule_time",
      id: "schedule_time",
      grid: 2,
      errorMsg: "Please enter time",
    },
  ];


  const onDrop = (acceptedFiles: any) => {
    // console.log("file debugging log: ", acceptedFiles);

    // console.log(acceptedFiles, 'acceptedFilesacceptedFiles');
    setFiles((prevImages) => [...prevImages, ...acceptedFiles]);
    //setFiles(acceptedFiles);
    // const file = acceptedFiles[0];
    // setFiles(file);

    const updatedImages = acceptedFiles.map((file: any) => ({
      file,
      preview: URL.createObjectURL(file),
    }));
    setSelectedImages((prevImages) => [...prevImages, ...updatedImages]);
  };
  useEffect(() => {
    // console.log("file data debugging: ", files);
  }, [files]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      //"image/*": [],
      "image/jpeg": [],
      "image/jpg": [],
      "image/png": [],
    },
    onDrop,
  });

  const [editorState, setEditorState] = useState(
    formData?.event_managment?.acknowledgement
      ? EditorState.createWithContent(
        convertFromHTML(formData?.event_managment?.acknowledgement) as any
      )
      : EditorState.createWithContent(
        convertFromHTML(`This concert has been made possible, in part, by voters of Minnesota through grants from The Minnesota State Arts Board, thanks to a legislative appropriation from the Arts and Cultural Heritage Fund.  IMSOM's concert series are made possible, in part, by the UHG "United for Giving" program , matching grants from Honeywell, US Bank, 3M, Wells Fargo and Winnebago, generous donations from late Dr V Premanand & Family, generosity of IMSOM's members and donors. Media partners for all IMSOM events are KFAI Radio (90.3 FM/106.7 FM) and LOCALFILES.com.`) as any
      )
    // : EditorState.createEmpty()
  );
  const [open, setOpen] = useState(false);
  const [step4Data, setStep4Data] = useState<any>({
    acknowledgement: {
      value: "",
    },
    contact_person_first_name: {
      value: formData?.event_managment?.contact_person_first_name
        ? formData?.event_managment?.contact_person_first_name
        : defaultContact.contact1[0],
      validate: true,
      errorMsg: "Please enter name",
    },
    contact_person_first_email: {
      value: formData?.event_managment?.contact_person_first_email
        ? formData?.event_managment?.contact_person_first_email
        : defaultContact.contact1[1],
      validate: true,
      errorMsg: "Please enter email",
      emailValidate: true,
    },
    contact_person_second_name: {
      value: formData?.event_managment?.contact_person_second_name
        ? formData?.event_managment?.contact_person_second_name
        : defaultContact.contact2[0],
      validate: true,
      errorMsg: "Please enter name",
    },
    contact_person_second_email: {
      value: formData?.event_managment?.contact_person_second_email
        ? formData?.event_managment?.contact_person_second_email
        : defaultContact.contact2[1],
      validate: true,
      errorMsg: "Please enter email",
      emailValidate: true,
    },
  });

  useEffect(() => {
    const stepDataObj = JSON.parse(JSON.stringify(step4Data));
    stepDataObj.acknowledgement.value = convertToHTML(
      editorState.getCurrentContent()
    );
    setStep4Data(stepDataObj);
  }, [editorState]);

  function getCount(str: any) {
    return str.split(" ").filter(function (num: any) {
      return num !== "";
    }).length;
  }


  const handleEditorBlur = (editorState: EditorState) => {
    const length = getCount(editorState.getCurrentContent().getPlainText())
    if (length > 250) return setEditorLengthError(true);
    else setEditorLengthError(false)
  }

  const handleEditorInputChange = useCallback(debounce(handleEditorBlur), [])

  function transformData(data: any) {
    if (data?.events?.id) {
      !eventId && setEventId(data?.events?.id);
      handleFormDataInput({ event_managment: data?.events });
    }
    if (data?.status) {
      setResponseMsg("Data saved successfully.");
      setResponseType(true);

      if (draftRef.current) {
        setTimeout(() => {
          navigate("/event-management");
        }, 1500);
      } else {
        setTimeout(() => {
          navigate(`/event-details/${eventId}`);
        }, 1500);
      }
    }
  }

  const onEditorStateChange = (editorState: any) => {
    setEditorState(editorState);
    handleEditorInputChange(editorState)
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const checkError = () => {
    let error = false;
    const stepDataObj = JSON.parse(JSON.stringify(step4Data));
    if (editorLengthError) return true;
    Object.keys(stepDataObj).forEach((key, j) => {

      //if (!stepDataObj[key].value && stepDataObj[key].validate) {
      if (
        key == "contact_person_first_email" &&
        !emailRegex.test(stepDataObj[key].value)
      ) {
        stepDataObj[key].emailValidate = false;
        error = true;
      }
      if (
        key == "contact_person_second_email" &&
        !emailRegex.test(stepDataObj[key].value)
      ) {
        stepDataObj[key].emailValidate = false;
        error = true;
      }
      if (!stepDataObj[key].value.trim()) {
        stepDataObj[key].validate = false;
        error = true;
      }
    });

    return error;
  };

  const handleOpenPublishPopup = () => {
    setPublishModal(true);
  };

  const handleClosePublishPopup = () => {
    setPublishModal(false);
  };

  const handleOpenSchedulePopup = () => {
    setPublishModal(false);
    setScheduleModal(true);
  };

  const handleCloseSchedulePopup = () => {
    setScheduleModal(false);
  };

  const handleScheduleGoBack = () => {
    setPublishModal(true);
    setScheduleModal(false);
  };

  const handleScheduleLaterAction = (e: any, inputsValue: any) => {

    const stepDataObj = JSON.parse(JSON.stringify(step4Data));
    const error = checkError();

    if (error) {
      setStep4Data(stepDataObj);
    } else {
      setResponseMsg("");
      setResponseType(false);
      e.preventDefault();

      // const event_managment:any = {
      //   draft:draft,
      //   acknowledgement: step4Data.acknowledgement.value,
      //   contact_person_first_name: step4Data.contact_person_first_name.value,
      //   contact_person_first_email: step4Data.contact_person_first_email.value,
      //   contact_person_second_name: step4Data.contact_person_second_name.value,
      //   contact_person_second_email: step4Data.contact_person_second_email.value,

      // }

      const formData1: any = new FormData();
      formData1.append("event_managment[draft]", false);
      formData1.append("event_managment[schedule_flag]", true);
      formData1.append(
        "event_managment[schedule_date]",
        inputsValue.schedule_date.value
      );
      formData1.append(
        "event_managment[schedule_time]",
        inputsValue.schedule_time.value
      );
      formData1.append(
        "event_managment[acknowledgement]",
        step4Data.acknowledgement.value
      );
      formData1.append(
        "event_managment[contact_person_first_name]",
        step4Data.contact_person_first_name.value
      );
      formData1.append(
        "event_managment[contact_person_first_email]",
        step4Data.contact_person_first_email.value
      );
      formData1.append(
        "event_managment[contact_person_second_name]",
        step4Data.contact_person_second_name.value
      );
      formData1.append(
        "event_managment[contact_person_second_email]",
        step4Data.contact_person_second_email.value
      );
      for (let i = 0; i < files.length; i++) {
        //formData1.append(`event_managment[event_images][${i}]`, files[i]);
        formData1.append(`event_managment[event_images][]`, files[i]);
      }
      for (let i = 0; i < defaultImages.length; i++) {
        let fileType;
        if (defaultImages[i].toLowerCase().endsWith(".jpeg") || defaultImages[i].toLowerCase().endsWith(".jpg")) {
          fileType = "image/jpeg";
        } else if (defaultImages[i].toLowerCase().endsWith(".png")) {
          fileType = "image/png";
        } else {
          fileType = "image/jpeg";
        }
        const defaultImageFile = new File([], defaultImages[i].split("/").pop(), { type: fileType });
        formData1.append(`event_managment[event_images][]`, defaultImageFile);
      }

      // const formDataObject = Object.fromEntries(formData1);
      // const mergedObject = { ...event_managment, ...formDataObject };
      // console.log('zzzz',mergedObject)
      // const data:any = {
      //   event_managment:mergedObject
      // }
      // console.log({data})
      //  ___________

      if (eventId) {
        //data.event_managment.id = eventId;
        formData1.append("event_managment[id]", eventId);
        formData1.append("event_managment[cancel]", false);
      }
      const requestOptions: ReqOptions = {
        url: eventId ? `/event_managments/${eventId}` : `/event_managments`,
        method: eventId ? "PUT" : "POST",
        contentType: "multipart/form-data",
        data: formData1,
      };

      sendRequest(requestOptions);
    }
  };

  const handleStep4Submit = async (e: any, draft: boolean) => {
    e.preventDefault();
    const stepDataObj = JSON.parse(JSON.stringify(step4Data));

    //debugging
    // console.log("step 4 data from local storage : ", stepDataObj);

    const error = checkError();

    if (error) {
      setStep4Data(stepDataObj);
    } else {
      setResponseMsg("");
      setResponseType(false);

      // const event_managment:any = {
      //   draft:draft,
      //   acknowledgement: step4Data.acknowledgement.value,
      //   contact_person_first_name: step4Data.contact_person_first_name.value,
      //   contact_person_first_email: step4Data.contact_person_first_email.value,
      //   contact_person_second_name: step4Data.contact_person_second_name.value,
      //   contact_person_second_email: step4Data.contact_person_second_email.value,

      // }

      const formData1: any = new FormData();
      formData1.append("event_managment[draft]", draft);
      formData1.append("event_managment[schedule_flag]", false);
      formData1.append(
        "event_managment[acknowledgement]",
        step4Data.acknowledgement.value
      );
      formData1.append(
        "event_managment[contact_person_first_name]",
        step4Data.contact_person_first_name.value
      );
      formData1.append(
        "event_managment[contact_person_first_email]",
        step4Data.contact_person_first_email.value
      );
      formData1.append(
        "event_managment[contact_person_second_name]",
        step4Data.contact_person_second_name.value
      );
      formData1.append(
        "event_managment[contact_person_second_email]",
        step4Data.contact_person_second_email.value
      );

      //uploading the locally added images
      for (let i = 0; i < files.length; i++) {
        //formData1.append(`event_managment[event_images][${i}]`, files[i]);
        formData1.append(`event_managment[event_images][]`, files[i]);
      }

      // checking that the images are already present in the event or not
      if (eventImages.length === 0) {
        for (let i = 0; i < defaultImages.length; i++) {
          let fileType;
          if (defaultImages[i].toLowerCase().endsWith(".jpeg") || defaultImages[i].toLowerCase().endsWith(".jpg")) {
            fileType = "image/jpeg";
          } else if (defaultImages[i].toLowerCase().endsWith(".png")) {
            fileType = "image/png";
          } else {
            fileType = "image/jpeg";
          }
          // console.log("default Image", defaultImages[i]);
          // console.log("");


          const imageBlob: any = await fetch(defaultImages[i]).then((response) => response.blob());
          // console.log("image blob", imageBlob);

          const defaultImageFile = new File([imageBlob], defaultImages[i], { type: fileType });
          formData1.append(`event_managment[event_images][]`, defaultImageFile);
        }
      }
      else {
        for (let i = 0; i < eventImages.length; i++) {
          // console.log(eventImages.length)
          let fileType;
          if (eventImages[i][0].toLowerCase().endsWith(".jpeg") || eventImages[i][0].toLowerCase().endsWith(".jpg")) {
            fileType = "image/jpeg";
          } else if (eventImages[i][0].toLowerCase().endsWith(".png")) {
            fileType = "image/png";
          } else {
            fileType = "image/jpeg";
          }

          //debugging
          // console.log("Image URL:", eventImages[i][0]);

          try {
            const imageBlob = await fetch(eventImages[i][0]).then(response => {
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              return response.blob();
            });

            // console.log("Image Blob:", imageBlob);

            // let imgUrl = eventImages[i][0];

            // if(imgUrl.includes('http')){
            //   imgUrl = "https"+imgUrl.substring(4);
            // }`

            // const updatedImage = new File([imageBlob], imgUrl, { type: fileType });

            const updatedImage = new File([imageBlob], eventImages[i][0], { type: fileType });

            // console.log("Updated Image:", updatedImage); 

            formData1.append(`event_managment[event_images][]`, updatedImage);
          } catch (error) {
            console.error("Error fetching image:", error);
          }
        }

        // for (let i = 0; i < eventImages.length; i++) {
        //   let fileType;
        //   if (eventImages[i][0].toLowerCase().endsWith(".jpeg") || eventImages[i][0].toLowerCase().endsWith(".jpg")) {
        //     fileType = "image/jpeg";
        //   } else if (eventImages[i][0].toLowerCase().endsWith(".png")) {
        //     fileType = "image/png";
        //   } else {
        //     fileType = "image/jpeg"; 
        //   }
        //   console.log(eventImages, typeof(eventImages));
        //   console.log("default Image",defaultImages[i]);

        //   const imageBlob: any = await fetch(eventImages[i][0]).then((response) => response.blob());
        //   console.log(typeof(imageBlob))

        //   console.log("image blob", imageBlob);

        //   const updatedImage = new File([imageBlob], eventImages[i][0], { type: fileType });

        //   console.log("updated image",updatedImage);


        //   formData1.append(`event_managment[event_images][]`, updatedImage);
        // }
        // for (let i = 0; i < eventImages.length; i++) {
        //   let fileType;
        //   const fileExtension = eventImages[i][0].split('.').pop().toLowerCase();
        //   if (fileExtension === "jpeg" || fileExtension === "jpg") {
        //     fileType = "image/jpeg";
        //   } else if (fileExtension === "png") {
        //     fileType = "image/png";
        //   } else {
        //     fileType = "image/jpeg"; // Default to JPEG if the file extension is not recognized
        //   }
        //   const imageBlob: any = await fetch(eventImages[i][0]).then((response) => response.blob());
        //   const updatedImage = new File([imageBlob], eventImages[i][0], { type: fileType });
        //   formData1.append(`event_managment[event_images][]`, updatedImage);
        // }

      }




      // const formDataObject = Object.fromEntries(formData1);
      // const mergedObject = { ...event_managment, ...formDataObject };
      // console.log('zzzz',mergedObject)
      // const data:any = {
      //   event_managment:mergedObject
      // }
      // console.log({data})

      // ______________
      if (eventId) {
        //data.event_managment.id = eventId;
        formData1.append("event_managment[id]", eventId);
        formData1.append("event_managment[cancel]", false);
      }
      const requestOptions: ReqOptions = {
        url: eventId ? `/event_managments/${eventId}` : `/event_managments`,
        method: eventId ? "PUT" : "POST",
        contentType: "multipart/form-data",
        data: formData1,
      };
      draftRef.current = draft;
      sendRequest(requestOptions);


      // console.log("final data",requestOptions);
    }


  };

  useEffect(() => {
    if (error) {
      setResponseMsg("Something went wrong! Try again..");
      setResponseType(false);
    }
  }, [error]);

  const handleInputs = (e: any) => {
    const stepData = JSON.parse(JSON.stringify(step4Data));
    stepData[e.target.name].value = e.target.value;
    if (e.target.name === "contact_person_first_name") {
      setDefaultContact((prev: any) => ({ ...prev, contact1: [e.target.value, defaultContact.contact1[1]] }))
    } else if (e.target.name === "contact_person_first_email") {
      setDefaultContact((prev: any) => ({ ...prev, contact1: [defaultContact.contact1[0], e.target.value] }))
    } else if (e.target.name === "contact_person_second_name") {
      setDefaultContact((prev: any) => ({ ...prev, contact2: [e.target.value, defaultContact.contact2[1]] }))
    } else if (e.target.name === "contact_person_second_email") {
      setDefaultContact((prev: any) => ({ ...prev, contact2: [defaultContact.contact2[0], e.target.value] }))
    }
    if (
      (e.target.name === "contact_person_first_email" ||
        e.target.name === "contact_person_second_email") &&
      !emailRegex.test(e.target.value)
    ) {
      stepData[e.target.name].emailValidate = false;
    } else {
      stepData[e.target.name].emailValidate = true;
    }
    if (e.target.value) {
      stepData[e.target.name].validate = true;
    } else {
      stepData[e.target.name].validate = false;
    }
    setStep4Data(stepData);
  };

  const handleFiles = (files: any) => {
  };

  const handleRemove = (index: number, file: any) => {
    setFiles(files?.filter((f) => f !== file));
    setSelectedImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages.splice(index, 1);
      return updatedImages;
    });
  };

  const handleRemoveOldImages = (index: number, file: any) => {
    // const reqOptions = {
    //   url: `/event_managments/${file}/delete_image`,
    //   method: "DELETE",
    // };

    // sendRequest(reqOptions);

    // setFiles(files?.filter((f) => f !== file));
    setEventImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages.splice(index, 1);
      return updatedImages;
    });
  };

  useEffect(() => {
  }, [eventImages]);

  const selectedThumbs = eventImages.map((file: any, i: number) => (
    <div style={thumb} key={i}>
      <div style={thumbInner}>
        <img
          alt=""
          src="images/remove.svg"
          className="remove_icon"
          onClick={(e: React.MouseEvent) => handleRemoveOldImages(i, file[1])}
        />
        {file && <img src={file[0]} style={img} alt="" />}
      </div>
    </div>
  ));

  // useEffect(() => {
  //   const updatedImages = defaultImages.map((file: any) => ({
  //     file,
  //     preview: URL.createObjectURL(file),
  //   }));
  //   setSelectedImages((prevImages) => [...prevImages, ...updatedImages]); 
  // },[defaultImages])

  const handleRemoveClick = (index: number) => {
    const updatedImages = defaultImages.filter((_, i) => i !== index);
    setDefaultImages(updatedImages);
  };


  const defaultImagesThumbs: any = () => {
    const x = defaultImages.map((file: any, i: number) => {
      return <div style={thumb} key={i}>
        <div style={thumbInner}>
          <img
            alt=""
            src="images/remove.svg"
            className="remove_icon"
            onClick={(e: React.MouseEvent) => handleRemoveClick(i)}

          />
          <img src={file} alt="" style={img} />
        </div>
      </div>
    })
    return x
  }

  const thumbs = selectedImages.map((file: any, i: number) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          alt=""
          src="images/remove.svg"
          className="remove_icon"
          onClick={(e: React.MouseEvent) => handleRemove(i, file?.file)}
        />
        {file && <img src={file.preview} style={img} />}
      </div>
    </div>
  ));

  const handleNameKey = (event: any) => {
    const isNumericKey = event.keyCode >= 48 && event.keyCode <= 57;
    if (isNumericKey) {
      event.preventDefault();
    }
  };

  const isNotValidForm = checkError();

  return (
    <>
      {loading && <Loader />}
      {responseMsg && (
        <AlertCmp
          type={responseType ? "success" : "error"}
          position="dashboard"
        >
          {responseMsg}
        </AlertCmp>
      )}
      <Box>
        <Box className="step4_editor_box">
          <h6 className="step4_input_title step4_editor_box_title">
            Acknowledgement{" "}
            <span className="step4_input_title_span step4_editor_title_span">
              (optional)
            </span>
          </h6>
          <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={onEditorStateChange}
            placeholder="Enter bio here (max 250 words)"
          // {...getEditorProps()}

          />
          {editorLengthError && (
            <Box className="editor_words_limit_error_box">
              <img src={LimitErrorImg} alt="limit error" />
              <span className="word_exceed_limit">
                Words exceed the character limit (250 words)
              </span>
            </Box>
          )}
        </Box>
        <Box className="step4_image_upload">
          <h6 className="step4_input_title step4_image_upload_title">
            Upload Sponsor Logos{" "}
            <span className="step4_input_title_span step4_image_upload_span">
              (optional)
            </span>
          </h6>
          <Box
            className="step4_image_upload_box"
            sx={{ display: "inline-flex" }}
          >
            <div
              className="step4_image_card_upload"
              {...getRootProps()}
            //onClick={handleClickOpen}
            >
              <input {...getInputProps()} />
              <TextLabels variant="h200" weight={500} color="#903713">
                +&nbsp;&nbsp;Add Images
              </TextLabels>
            </div>
            <aside style={thumbsContainer}>
              <Box display="flex" flexDirection="column">
                <Box>{thumbs}</Box>
              </Box>
              {eventImages.length ? (
                <Box display="flex" flexDirection="column">
                  <Box>
                    {
                      selectedThumbs
                    }
                  </Box>
                </Box>
              )
                :
                (
                  <Box display="flex" flexDirection="column">
                    <Box>
                      {
                        defaultImagesThumbs()
                      }
                    </Box>
                  </Box>
                )
              }
            </aside>
          </Box>
        </Box>

        <Box className="step4_contact_input">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <InputsWithLabel
                    label="Contact Person 1 *"
                    placeholder="Enter name"
                    id="contact1_name"
                    name="contact_person_first_name"
                    type="text"
                    handleKeyDown={handleNameKey}
                    handleInputChange={handleInputs}
                    value={step4Data.contact_person_first_name.value ? step4Data.contact_person_first_name.value : defaultContact.contact1[0]}
                  />
                  {step4Data &&
                    step4Data.contact_person_first_name.validate === false && (
                      <ErrorMessage>
                        {step4Data.contact_person_first_name.errorMsg}
                      </ErrorMessage>
                    )}
                </Grid>
                <Grid item xs={6}>
                  <InputsWithLabel
                    label="Email *"
                    placeholder="Enter email here"
                    id="contact1_email"
                    name="contact_person_first_email"
                    type="text"
                    handleInputChange={handleInputs}
                    value={step4Data.contact_person_first_email.value ? step4Data.contact_person_first_email.value : defaultContact.contact1[1]}
                  />
                  {step4Data &&
                    step4Data.contact_person_first_email.validate === false && (
                      <ErrorMessage>
                        {step4Data.contact_person_first_email.errorMsg}
                      </ErrorMessage>
                    )}
                  {step4Data &&
                    step4Data.contact_person_first_email.emailValidate === false &&
                    step4Data.contact_person_first_email.validate === true && (
                      <ErrorMessage>Please enter valid email</ErrorMessage>
                    )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={4}>

                <Grid item xs={6} className="contact2">
                  <InputsWithLabel
                    label="Contact Person 2 *"
                    placeholder="Enter name"
                    id="contact2_name"
                    name="contact_person_second_name"
                    type="text"
                    handleKeyDown={handleNameKey}
                    handleInputChange={handleInputs}
                    value={step4Data.contact_person_second_name.value ? step4Data.contact_person_second_name.value : defaultContact.contact2[0]}
                  />
                  {step4Data &&
                    step4Data.contact_person_second_name.validate === false && (
                      <ErrorMessage>
                        {step4Data.contact_person_second_name.errorMsg}
                      </ErrorMessage>
                    )}
                </Grid>
                <Grid item xs={6} className="contact2">
                  <InputsWithLabel
                    label="Email *"
                    placeholder="Enter email here"
                    id="contact2_email"
                    name="contact_person_second_email"
                    type="text"
                    handleInputChange={handleInputs}
                    value={step4Data.contact_person_second_email.value ? step4Data.contact_person_second_email.value : defaultContact.contact2[1]}
                  />
                  {step4Data &&
                    step4Data.contact_person_second_email.validate === false && (
                      <ErrorMessage>
                        {step4Data.contact_person_second_email.errorMsg}
                      </ErrorMessage>
                    )}
                  {step4Data &&
                    step4Data.contact_person_second_email.emailValidate === false &&
                    step4Data.contact_person_second_email.validate === true && (
                      <ErrorMessage>Please enter valid email</ErrorMessage>
                    )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box className="step_button_container">
          <Grid container>
            <Grid item xs={6}>
              <Button
                sx={{
                  border: "1px solid #913713",
                  color: "#913713",
                  height: "44px",
                  padding: "0px",
                  "&:hover": {
                    border: "1px solid #913713",
                    backgroundColor: "#FFFFFF",
                    color: "#913713",
                  },
                }}
                variant="outlined"
                color="primary"
                size="s"
                handleClick={handleOpenPreviewPopup}
              >
                Preview Banner
              </Button>
            </Grid>
            <Grid item xs={6} className="step_confirm_button">
              <Button
                btnClass="draft_btn"
                variant="outlined"
                color="primary"
                size="s"
                sx={{
                  marginRight: "30px",
                }}
                handleClick={(e) => handleStep4Submit(e, true)}
              >
                Save as Draft
              </Button>
              <Button
                disable={isNotValidForm}
                color="primary"
                size="s"
                handleClick={() => {
                  if (!isNotValidForm) handleOpenPublishPopup();
                }}
              // handleClick={(e) => handleStep4Submit(e, false)}
              >
                Publish
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box >
      <ModalImageUploadPopup
        open={open}
        handleClose={handleClose}
        heading="Add Image"
      />
      <ModalPopup
        heading="Confirm Event Publishing"
        content="You're about to publish your event. Would you like to publish it now or schedule for later?"
        primaryAction={(e: any) => handleStep4Submit(e, false)}
        handleClose={handleClosePublishPopup}
        handleClickOpen={handleOpenPublishPopup}
        secondaryAction={handleOpenSchedulePopup}
        open={publishModal}
        primaryButton="Publish Now"
        secondaryButton="Schedule For Later"
        closeBtn={true}
      />
      <ModalFormPopup
        heading="Schedule Date and Time"
        closeBtn={true}
        confirmBtn="Schedule"
        handleClose={handleCloseSchedulePopup}
        inputs={inputs}
        open={scheduleModal}
        scheduleBtn={true}
        primaryAction={handleScheduleLaterAction}
        secondaryButton="Go back"
        secondaryAction={handleScheduleGoBack}
      />
    </>
  );
};

export default Step4;
