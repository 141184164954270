import WebLayout from "../../organism/webLayout";
import TicketBooking from "../../templates/ticketBooking";

const TicketBookingPage = () => {
  return  <TicketBooking />;

  
  ;
};

export default TicketBookingPage;
