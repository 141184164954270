import React, { forwardRef, useImperativeHandle } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { PropType } from "./types/propType";
import "./style.css";

const TabBar = ({
  handleClick,
  defaultValue = "all",
  tabs,
  fullWidth,
}: PropType) => {
  const [value, setValue] = React.useState(defaultValue);


  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{ width: "100%" }}
      className={fullWidth ? "tab_box tab_box_full_width" : "tab_box"}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="primary tabs example"
      >
        {tabs.map((tab, i) => {
          if (handleClick) {
            return (
              <Tab
                key={i}
                value={tab.value}
                label={tab.label}
                className="tab_item"
                onClick={() => handleClick(tab.value)}
              />
            );
          }

          return (
            <Tab
              key={i}
              value={tab.value}
              label={tab.label}
              className="tab_item"
            />
          );
        })}
      </Tabs>
    </Box>
  );
};

export default TabBar;
