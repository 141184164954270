import React from "react";
import { Box } from "@mui/material";
import BackImg from "./images/back.svg";
import SectionHeading from "../../molecules/sectionHeading";
import ArtistImg1 from "./images/artist1.png";
import ArtistImg2 from "./images/artist2.png";
import ArtistImg3 from "./images/artist3.png";
import ArtistImg4 from "./images/artist4.png";
import ArtistImg5 from "./images/artist5.png";
import ArtistImg6 from "./images/artist6.png";
import ArtistDetailSection from "../../organism/artistDetailSection";
import { Link } from "react-router-dom";
import "./style.css";

const artistDetailsData = [
  {
    artist_image: ArtistImg1,
    name: "Apoorva Gokhale",
    performance_type: "vocal",
    desc: "Born in the family of traditional legendary musicians, Apoorva Gokhale has carved a niche for herself as one of the sought after well-known vocalists of the younger generation with a firm background of Gwalior Gharana. ",
    bio: "She has an impressive musical lineage and has proudly and responsibly inherited the musical qualities from her grandfather, the late Gayanacharya Pandit Gajananrao Joshi and her great- grandfather Pandit Antubua Joshi, an eminent court musician in the erstwhile state of Aundh, district Satara. At a very tender age of five, she initially received a sound grooming from her grandfather Pandit Gajananrao Joshi, who insisted to see in her tonal perfection with just intonation and infused a keen sense of rhythm. Later on she received rigorous training in the form of guru-shishya parampara under the guidance and supervision of her uncle Pandit Madhukarrao Joshi, an eminent vocalist and violinist.",
  },
  {
    artist_image: ArtistImg2,
    name: "Apoorva Gokhale",
    performance_type: "vocal",
    desc: "Born in the family of traditional legendary musicians, Apoorva Gokhale has carved a niche for herself as one of the sought after well-known vocalists of the younger generation with a firm background of Gwalior Gharana. ",
    bio: "She has an impressive musical lineage and has proudly and responsibly inherited the musical qualities from her grandfather, the late Gayanacharya Pandit Gajananrao Joshi and her great- grandfather Pandit Antubua Joshi, an eminent court musician in the erstwhile state of Aundh, district Satara. At a very tender age of five, she initially received a sound grooming from her grandfather Pandit Gajananrao Joshi, who insisted to see in her tonal perfection with just intonation and infused a keen sense of rhythm. Later on she received rigorous training in the form of guru-shishya parampara under the guidance and supervision of her uncle Pandit Madhukarrao Joshi, an eminent vocalist and violinist.",
  },
  {
    artist_image: ArtistImg3,
    name: "Apoorva Gokhale",
    performance_type: "vocal",
    desc: "Born in the family of traditional legendary musicians, Apoorva Gokhale has carved a niche for herself as one of the sought after well-known vocalists of the younger generation with a firm background of Gwalior Gharana. ",
    bio: "She has an impressive musical lineage and has proudly and responsibly inherited the musical qualities from her grandfather, the late Gayanacharya Pandit Gajananrao Joshi and her great- grandfather Pandit Antubua Joshi, an eminent court musician in the erstwhile state of Aundh, district Satara. At a very tender age of five, she initially received a sound grooming from her grandfather Pandit Gajananrao Joshi, who insisted to see in her tonal perfection with just intonation and infused a keen sense of rhythm. Later on she received rigorous training in the form of guru-shishya parampara under the guidance and supervision of her uncle Pandit Madhukarrao Joshi, an eminent vocalist and violinist.",
  },
  {
    artist_image: ArtistImg4,
    name: "Apoorva Gokhale",
    performance_type: "vocal",
    desc: "Born in the family of traditional legendary musicians, Apoorva Gokhale has carved a niche for herself as one of the sought after well-known vocalists of the younger generation with a firm background of Gwalior Gharana. ",
    bio: "She has an impressive musical lineage and has proudly and responsibly inherited the musical qualities from her grandfather, the late Gayanacharya Pandit Gajananrao Joshi and her great- grandfather Pandit Antubua Joshi, an eminent court musician in the erstwhile state of Aundh, district Satara. At a very tender age of five, she initially received a sound grooming from her grandfather Pandit Gajananrao Joshi, who insisted to see in her tonal perfection with just intonation and infused a keen sense of rhythm. Later on she received rigorous training in the form of guru-shishya parampara under the guidance and supervision of her uncle Pandit Madhukarrao Joshi, an eminent vocalist and violinist.",
  },
  {
    artist_image: ArtistImg5,
    name: "Apoorva Gokhale",
    performance_type: "vocal",
    desc: "Born in the family of traditional legendary musicians, Apoorva Gokhale has carved a niche for herself as one of the sought after well-known vocalists of the younger generation with a firm background of Gwalior Gharana. ",
    bio: "She has an impressive musical lineage and has proudly and responsibly inherited the musical qualities from her grandfather, the late Gayanacharya Pandit Gajananrao Joshi and her great- grandfather Pandit Antubua Joshi, an eminent court musician in the erstwhile state of Aundh, district Satara. At a very tender age of five, she initially received a sound grooming from her grandfather Pandit Gajananrao Joshi, who insisted to see in her tonal perfection with just intonation and infused a keen sense of rhythm. Later on she received rigorous training in the form of guru-shishya parampara under the guidance and supervision of her uncle Pandit Madhukarrao Joshi, an eminent vocalist and violinist.",
  },
  {
    artist_image: ArtistImg6,
    name: "Apoorva Gokhale",
    performance_type: "vocal",
    desc: "Born in the family of traditional legendary musicians, Apoorva Gokhale has carved a niche for herself as one of the sought after well-known vocalists of the younger generation with a firm background of Gwalior Gharana. ",
    bio: "She has an impressive musical lineage and has proudly and responsibly inherited the musical qualities from her grandfather, the late Gayanacharya Pandit Gajananrao Joshi and her great- grandfather Pandit Antubua Joshi, an eminent court musician in the erstwhile state of Aundh, district Satara. At a very tender age of five, she initially received a sound grooming from her grandfather Pandit Gajananrao Joshi, who insisted to see in her tonal perfection with just intonation and infused a keen sense of rhythm. Later on she received rigorous training in the form of guru-shishya parampara under the guidance and supervision of her uncle Pandit Madhukarrao Joshi, an eminent vocalist and violinist.",
  },
];



const BuyCDDetail = () => {
  return (
    <Box>
      <Box className="gallery_page_top_website">
        <Box className="gallery_page_top_navigation_website">
          <Link to="/buy-cd">
            <img src={BackImg} className="back_img" />
          </Link>
          <SectionHeading title="Hindustani Vocal" />
        </Box>
        <Box className="buy_cd_detail_para_box">
          <p>
            The selected tracks feature a wide spectrum of compositions drawn
            from various genres, in a variety of Ragas and set to different
            Talas. A total of five hours of music featured in this special
            compilation!
          </p>
        </Box>
      </Box>
      <Box className="buy_cd_artist_deatils">
        {artistDetailsData.map((details, i) => {
          return <ArtistDetailSection key={i} {...details} />;
        })}
      </Box>
    </Box>
  );
};

export default BuyCDDetail;
