import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import BuyCDStep1 from "../buyCDStep1";
import BuyCDStep2 from "../buyCDStep2";
import BuyCDStep3 from "../buyCDStep3";
import "./style.css";

const stepsData: any = {
  step1: {
    label: "Profile & Membership",
  },
  step2: {
    label: "Event Booking History",
  },
  // step3: {
  //   label: "Order History",
  // },
};

const BuyCDWizardSteps = () => {
  const [wizardstep, setWizardStep] = useState<"step1" | "step2" | "step3">(
    "step1"
  );

  const handleStep = (val: "step1" | "step2" | "step3") => {
    setWizardStep(val);
  };

  return (
    <Box className="buy_cd_wizard_page">
      <Box className="wizard_steps buy_cd_wizard_steps">
        <Grid container spacing={2}>
          {Object.keys(stepsData).map((step: any, i: number) => {
            return (
              <Grid
                item
                xs={4}
                key={i}
                className={`${
                  step === wizardstep ? "wizard_step_active" : ""
                } wizard_step ${
                  Number(step.slice(-1)) < Number(wizardstep.slice(-1))
                    ? "wizard_done"
                    : ""
                }`}
              >
                <Box
                  className="wizard_step_option"
                  onClick={() => handleStep(step)}
                >
                  <h6 className="wizard_title">{stepsData[step].label}</h6>
                  <hr className="wizard_option_border" />
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Box className="buy_cd_steps_box">
        <Box>
          {wizardstep === "step1" && <BuyCDStep1 handleStep={handleStep} />}
        </Box>
        <Box>
          {wizardstep === "step2" && <BuyCDStep2 handleStep={handleStep} />}
        </Box>
        <Box>
          {wizardstep === "step3" && <BuyCDStep3 handleStep={handleStep} />}
        </Box>
      </Box>
    </Box>
  );
};

export default BuyCDWizardSteps;
