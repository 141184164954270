import React, { useEffect } from "react";
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  Grid,
} from "@mui/material";
import SectionHeading from "../../molecules/sectionHeading";
import { Button } from "../../atoms/buttons";
import CDImg from "./images/cd_img.svg";
import { Link } from "react-router-dom";
import ArrowImg from "./images/arrow.svg";
import { useNavigate } from "react-router-dom";
import "./style.css";

const cdsData = [
  {
    title: "CD 1",
    subtitle: "Hindustani Vocals",
  },
  {
    title: "CD 2",
    subtitle: "Hindustani Instrumental",
  },
  {
    title: "CD 3",
    subtitle: "Carnatic Vocal & Instrumental",
  },
  {
    title: "CD 4",
    subtitle: "Carnatic Vocal & Instrumental",
  },
];

const BuyCD = () => {
  const navigate = useNavigate();
  const [age, setAge] = React.useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box>
      <Box className="buy_cd_top">
        <SectionHeading title="Musical CD Set" />
        <p className="buy_cd_top_para1">
          Experience the best of Hindustani and Carnatic classical music with
          our exclusive 4-CD set!
        </p>
        <p className="buy_cd_top_para2">
          Purchase multiple sets to share the magic of Indian classical music
          with your loved ones making it a unique and thoughtful gift that
          stands out from the rest.
        </p>
      </Box>
      <Box className="buy_cd_select_box">
        <Box className="past_event_selectbox_website">
          <p>No. of Sets</p>
          <Box className="past_year_dropdown_website">
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                value={age}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                renderValue={
                  age !== ""
                    ? undefined
                    : () => "Select no. of sets to purchase"
                }
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box className="buy_cd_price_box">
          <h4 className="buy_cd_price">$0</h4>
        </Box>
        <Box className="buy_cd_paypal_button">
          <Button
            variant="outlined"
            color="info"
            size="s"
            handleClick={() => navigate("/user-profile")}
            sx={{
              width: "100%",
              margin: "8px",
            }}
          >
            Pay via Paypal
          </Button>
        </Box>
      </Box>
      <Box className="buy_cd_names">
        <Grid container spacing={{ sm: 4, xs: 2 }}>
          {cdsData.map((cd, i) => {
            return (
              <Grid item sm={3} xs={6} className="buy_cd_grid">
                <img src={CDImg} />
                <h4>{cd.title}</h4>
                <p>{cd.subtitle}</p>
                <Link to="/buy-cd/1" className="buy_cd_link">
                  View Details <img src={ArrowImg} />
                </Link>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default BuyCD;
