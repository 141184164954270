import React from "react";
import { PropType } from "./types/propType";
import { Box, Grid } from "@mui/material";
import "./style.css";

const ArtistCircleInfo = ({
  board_memeber_image,
  position,
  member_name,
  email,
  ArtistRoundImg1,
}: PropType) => {
  return (
    <Box>
      <Grid container>
        <Grid item xs={4} className="artist_round_image_grid">
          <img
            src={board_memeber_image[0] || ArtistRoundImg1}
            className="artist_round_image"
          />
        </Grid>
        <Grid item xs={8}>
          {position && <p className="artist_round_designation">{position}</p>}
          <h4 className="artist_round_name">{member_name}</h4>
          <p className="artist_round_email">{email}</p>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ArtistCircleInfo;
