import React,{useState} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { PropType } from './types/propTypes'
import './style.css'


export default function Login({children}:PropType) {
    
  return (
    <Box sx={{ flexGrow: 1 }}>
      
      <Grid container spacing={2} sx={{ height: '100vh' }}>
        <Grid item xs={12} sm={7} md={7} lg={7} className='login-grid-1'
        sx={{
            backgroundImage: 'url(images/imsom.png)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            //backgroundSize: 'cover',
            //backgroundPosition: 'center',
            backgroundSize:"100% 100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Box>
            <img src='images/transparent-logo.png'/>
          </Box>

        </Grid>
          
        <Grid item xs={12} sm={5} md={5} lg={5} className='login-grid-2' 
        
        >
          {children}
          
        </Grid>
        
      </Grid>
    </Box>
  );
}