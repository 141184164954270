import React from 'react'
import WebDialog from '../../molecules/webDialog'
import { Box } from '@mui/material'

const PaymentCompletion = ({
    open,
    handleClose,
    ticket,
}: any) => {
    return (
        <WebDialog
            title="Payment"
            open={open}
            handleClose={handleClose}
            primaryButtonLabel="Payment"
            subTitle="Payment"
        >
            <Box style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: "250px"
            }}>

                <h4 className="login_dialog_subtitle">
                    You've successfully bought {ticket} tickets
                </h4>
            </Box>
        </WebDialog >
    )
}

export default PaymentCompletion