import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import CloseImg from "./images/close.svg";
import InputsWithLabel from "../../molecules/inputsWithLabel";
import { Button } from "../../atoms/buttons";
import TickImg from "./images/tick.svg";
import DeleteImg from "./images/delete.svg";
import PencilImg from "./images/pencil.svg";
import { PropType } from "./types/addressPropType";
import useHttp from "../../../utils/hooks/useHttp";
import Loader from "../../atoms/loader";
import AlertCmp from "../../atoms/alert";
import ModalPopup from "../../atoms/modalPopup";

interface AddressModel {
  address_line_first: string;
  address_line_second: string;
  city: string;
  state: string;
  country: string;
  zipcode: string;
  default?: boolean;
  id?: string;
}

interface Props {
  value: AddressModel;
  handleSubmit: (value: AddressModel) => void;
  handleDelete: () => void;
}

const AddressForm = (props: Props) => {
  const { handleSubmit, handleDelete } = props;
  const [address, setAddress] = useState<AddressModel>({
    address_line_first: "",
    address_line_second: "",
    city: "",
    country: "",
    zipcode: "",
    state: ""
  });

  const [validate, setValidate] = useState<AddressModel>({
    address_line_first: "",
    address_line_second: "",
    city: "",
    country: "",
    zipcode: "",
    state: ""
  });

  const {
    address_line_first,
    address_line_second,
    city,
    country,
    zipcode,
    state
  } = address;

  const handleInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target;

    switch (name) {
      case "zipcode":
        if (/^[0-9]+$/.test(value) && value.length < 7) {
          setAddress((prev) => ({ ...prev, [name]: value }));
        }
        break;
      case "country":
      case "state":
      case "city":
        if (/^[a-zA-Z ]+$/.test(value)) {
          setAddress((prev) => ({ ...prev, [name]: value }));
        }
        break;
      default:
        setAddress((prev) => ({ ...prev, [name]: value }));
    }
    if (!value) {
      setAddress((prev) => ({ ...prev, [name]: value }));
      setValidate((prev) => ({ ...prev, [name]: "*Required field" }));
    } else {
      setValidate((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const validateForm = () => {
    let isValid = true;
    const {
      address_line_first,
      address_line_second,
      city,
      country,
      zipcode,
      state
    } = address;
    const obj = {
      address_line_first,
      address_line_second,
      city,
      country,
      zipcode,
      state
    };
    for (let key in obj) {
      // @ts-ignore
      if (!address[key]) {
        setValidate((prev) => ({ ...prev, [key]: "*Required field" }));
        isValid = false;
      } else {
        setValidate((prev) => ({ ...prev, [key]: "" }));
      }
    }
    // Validate the zipcode separately for 5 to 6 digits
    if (zipcode) {
      const zipRegex = /^[0-9]{5,6}$/; // Regular expression for 5 to 6 digits
      if (!zipRegex.test(zipcode)) {
        setValidate((prev) => ({ ...prev, zipcode: "Zipcode should have 5 to 6 digits" }));
        isValid = false;
      } else {
        setValidate((prev) => ({ ...prev, zipcode: "" }));
      }
    }
    return isValid;
  };

  const handleFormSubmit = () => {
    let isValid = validateForm();
    if (!isValid) return;
    handleSubmit(address);
  };

  useEffect(() => {
    if (props.value) {
      setAddress(props.value);
    }
  }, [props.value]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleFormSubmit();
      }}
    >
      <Box>
        <Box sx={{ marginTop: "30px" }} />
        <InputsWithLabel
          type="text"
          label="Country *"
          placeholder="Country *"
          labelColor="#FDFCFC"
          name="country"
          id="country"
          value={country}
          handleInputChange={(e) => handleInputChange(e)}
          msg={validate.country}
          required

        />
        <Box sx={{ marginTop: "30px" }} />
        <InputsWithLabel
          type="text"
          label="Zip Code *"
          placeholder="Zip Code *"
          // minValue={100000}
          labelColor="#FDFCFC"
          name="zipcode"
          id="zipcode"
          value={zipcode}
          msg={validate.zipcode}
          handleInputChange={(e) => handleInputChange(e)}
          required={true}

        />
        <Box sx={{ marginTop: "30px" }} />
        <InputsWithLabel
          type="text"
          label="State *"
          placeholder="State *"
          labelColor="#FDFCFC"
          name="state"
          id="state"
          value={state}
          msg={validate.state}
          required

          handleInputChange={(e) => handleInputChange(e)}
        />
        <Box sx={{ marginTop: "30px" }} />
        <InputsWithLabel
          type="text"
          label="City *"
          placeholder="City *"
          labelColor="#FDFCFC"
          name="city"
          id="city"
          value={city}
          msg={validate.city}
          handleInputChange={(e) => handleInputChange(e)}
          required

        />
        <Box sx={{ marginTop: "30px" }} />
        <InputsWithLabel
          type="text"
          label="Address Line 1 *"
          placeholder="Address Line 1 *"
          labelColor="#FDFCFC"
          name="address_line_first"
          id="address_line_first"
          msg={validate.address_line_first}
          value={address_line_first}
          handleInputChange={(e) => handleInputChange(e)}
          required

        />
        <Box sx={{ marginTop: "30px" }} />
        <InputsWithLabel
          type="text"
          label="Address Line 2 "
          placeholder="Address Line 2 "
          labelColor="#FDFCFC"
          name="address_line_second"
          id="address_line_second"
          value={address_line_second}
          handleInputChange={(e) => handleInputChange(e)}
          msg={validate.address_line_first}
        // required
        />
      </Box>
      <Box className="buy_cd_step2_edit_buttons">
        <Button
          variant="outlined"
          color="info"
          icon={TickImg}
          size="s"
          type="submit"
        //   handleClick={(e) => handleFormSubmit()}
        >
          Save Changes
        </Button>
        <p className="buy_cd_step2_delete_button" onClick={handleDelete}>
          <img alt="delete" src={DeleteImg} />
          Delete
        </p>
      </Box>
    </form>
  );
};

const ProfileAddresses = ({ addressData }: PropType) => {
  const [openDelete, setOpenDelete] = useState(false);
  const [newAddress, setNewAddress] = useState<AddressModel | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [addressesData, setAddressesData] = useState<AddressModel[]>([]);
  const [showSuccessAlert, setShowSuccessAlert] = useState({
    show: false,
    message: ""
  });
  const { loading, sendRequest, sendRequestWeb } = useHttp(transformData);

  function transformData(data: any) {
    if (data.status) {
      const user = localStorage.getItem("user");
      const webuser = localStorage.getItem("web_user");
      if (openDelete) {
        setAddressesData((prev) => {
          const userJSON = user || webuser;
          if (!userJSON) return prev;
          const userData = JSON.parse(userJSON);
          const addresses = prev.filter((item) => item.id !== data.address.id);
          userData.addresses = addresses;
          localStorage.setItem(
            webuser ? "web_user" : "user",
            JSON.stringify(userData)
          );
          return addresses;
        });
      } else {
        localStorage.setItem(
          webuser ? "web_user" : "user",
          JSON.stringify(data.user)
        );
        setAddressesData(data.user.addresses);
        // setAddressesData(data.user.addresses);
      }
      setShowSuccessAlert({ show: true, message: data.message });
      setNewAddress(null);
      setSelectedIndex(null);
      setOpenDelete(false);
      setTimeout(() => {
        setShowSuccessAlert({ show: false, message: "" });
      }, 5000);
    }
  }

  const handleEditAddress = (index: number) => {
    setSelectedIndex(index);
  };

  const handleCloseEdit = () => {
    setSelectedIndex(null);
  };

  useEffect(() => {
    if (addressData) {
      setAddressesData(addressData);
    }
  }, [addressData]);

  //   useEffect(() => {
  //     if (addressesData.length) {
  //       if (!initalRender) {
  //         setEditAddress(new Array(addressesData.length).fill(false));
  //       } else {
  //         const arr = [...editAddress];
  //         arr.push(true);
  //         setEditAddress(arr);
  //       }
  //       setInitialRender(true);
  //     }
  //   }, [addressesData]);

  const handleAddAddress = () => {
    setNewAddress({
      address_line_first: "",
      address_line_second: "",
      city: "",
      country: "",
      zipcode: "",
      state: ""
    });
  };


  const handleAddressSubmit = (addressesData: AddressModel[]) => {
    const user = localStorage.getItem("user");
    const webuser = localStorage.getItem("web_user");
    const userJSON = user || webuser;
    if (!userJSON) return;
    const userData = JSON.parse(userJSON);
    const reqOptions = {
      url: `/update_profile?id=${userData.id}`,
      method: "POST",
      data: {
        guest: {
          addresses_attributes: addressesData
        }
      }
    };

    if (webuser) return sendRequestWeb(reqOptions);
    return sendRequest(reqOptions);
  };

  const addAddress = (value: AddressModel) => {
    if (addressesData.length < 1) {
      value.default = true;
    }
    const addresses = [...addressesData, value];
    handleAddressSubmit(addresses);
  };

  const editAddress = (value: AddressModel) => {
    if (selectedIndex == null) return;
    const addresses = [...addressesData];
    addresses.splice(selectedIndex, 1, value);
    handleAddressSubmit(addresses);
  };

  const deleteAddress = () => {
    if (selectedIndex == null) return;

    const user = localStorage.getItem("user");
    const webuser = localStorage.getItem("web_user");
    if (!(user || webuser)) return;
    const reqOptions = {
      url: `/delete_address?id=${addressesData[selectedIndex].id}`,
      method: "DELETE"
    };
    if (user) return sendRequest(reqOptions);
    if (webuser) return sendRequestWeb(reqOptions);
  };

  // console.log(666,newAddress,addressesData)

  return (
    <>
      {loading && <Loader />}
      {showSuccessAlert.show && (
        <AlertCmp type="success_web" position="user_side">
          {showSuccessAlert.message}
        </AlertCmp>
      )}
      <Box className="buy_cd_step2_edit_address_box">
        {addressesData && addressesData.length > 0 ? (
          addressesData.map((address: any, i: number) => {
            return (
              <Box key={i}>
                {selectedIndex !== null && selectedIndex === i ? (
                  <Box className="buy_cd_step2_edit_address">
                    <Box className="buy_cd_step2_edit_address_top">
                      <p className="buy_cd_step2_address_label">
                        Address {i + 1}{" "}
                        {addressesData[i].default && "(Default)"}
                      </p>
                      <img
                        alt="close icon"
                        src={CloseImg}
                        className="buy_cd_step2_pencil_icon"
                        onClick={handleCloseEdit}
                      />
                    </Box>
                    <Grid container>
                      <Grid item md={7} xs={12}>
                        <AddressForm
                          handleDelete={() => {
                            setOpenDelete(true);
                          }}
                          handleSubmit={editAddress}
                          value={addressesData[selectedIndex]}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                ) : (
                  <Box className="buy_cd_step2_address" key={i}>
                    <Box>
                      <p className="buy_cd_step2_address_label">
                        Address {i + 1} {address.default && "(Default)"}
                      </p>
                      <p className="buy_cd_step2_address_line1">
                        {address.address_line_first},{" "}
                        {address.address_line_second}
                      </p>
                      <p className="buy_cd_step2_address_line2">
                        {address.city}, {address.state}, {address.country}
                      </p>
                    </Box>
                    <img
                      alt="edit icon"
                      src={PencilImg}
                      className="buy_cd_step2_pencil_icon"
                      onClick={() => handleEditAddress(i)}
                    />
                  </Box>
                )}
              </Box>
            );
          })
        ) : (
          <Box className="buy_cd_step2_address" sx={{ padding: "0px" }}>
            <p className="buy_cd_step2_address_label">No address to display</p>
          </Box>
        )}

        {newAddress && (
          <Box className="buy_cd_step2_edit_address">
            <Box className="buy_cd_step2_edit_address_top">
              <p className="buy_cd_step2_address_label">
                Address {addressesData.length + 1}
                {addressesData.length < 1 && " (Default)"}
              </p>
              <img
                alt="close icon"
                src={CloseImg}
                className="buy_cd_step2_pencil_icon"
                onClick={() => setNewAddress(null)}
              />
            </Box>
            <Grid container>
              <Grid item md={7} xs={12}>
                <AddressForm
                  handleDelete={() => {
                    setNewAddress(null);
                  }}
                  value={newAddress}
                  handleSubmit={addAddress}
                />
              </Grid>
              <Grid item md={5} xs={12}></Grid>
            </Grid>
          </Box>
        )}

        {(!newAddress || !(selectedIndex != null)) && (
          <Box sx={{ padding: { xs: "5px", md: "30px" } }}>
            <Button
              variant="outlined"
              color="info"
              size="s"
              handleClick={handleAddAddress}
            // sx={{ width:{xs:"100%", sm:""} }}
            >
              {addressesData.length > 0 ? '+ Add Another Address' : '+ Add Address'}
            </Button>
          </Box>
        )}
      </Box>
      <ModalPopup
        open={openDelete}
        handleClickOpen={() => {
          setOpenDelete(true);
        }}
        handleClose={() => {
          setOpenDelete(false);
        }}
        heading="Delete Address?"
        content="Are you sure you want to delete this address?"
        primaryButton="Delete"
        secondaryButton="Cancel"
        primaryAction={deleteAddress}
      />
    </>
  );
};

export default ProfileAddresses;
