const Twitter = ()=>{
    return <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
    <g clip-path="url(#clip0_5942_38409)">
      <path d="M28.848 10.1752C28.8675 10.4574 28.8675 10.7394 28.8675 11.0242C28.8675 19.6992 22.2633 29.7042 10.1875 29.7042V29.6992C6.62005 29.7042 3.1265 28.6823 0.124023 26.7557C0.642773 26.8182 1.16402 26.8494 1.68652 26.8507C4.6433 26.853 7.51503 25.8613 9.84027 24.0349C8.47026 24.009 7.14259 23.5557 6.04288 22.7382C4.94317 21.9208 4.1264 20.7801 3.70677 19.4757C4.69102 19.6657 5.70502 19.6264 6.67102 19.3624C3.60802 18.7437 1.40452 16.0527 1.40452 12.9274V12.8442C2.3176 13.3528 3.33953 13.6347 4.38427 13.6659C1.49902 11.7379 0.610273 7.90016 2.35227 4.89991C4.0005 6.92807 6.05692 8.58685 8.38798 9.76848C10.719 10.9501 13.2726 11.6282 15.8828 11.7587C15.6226 10.6384 15.6611 9.46937 15.9944 8.36862C16.3278 7.26786 16.9443 6.27391 17.7823 5.48616C20.4265 3.00041 24.5853 3.12791 27.0708 5.77091C28.5411 5.48059 29.9511 4.94153 31.24 4.17691C30.7499 5.69708 29.7243 6.9875 28.354 7.80816C29.6554 7.65472 30.9264 7.30623 32.124 6.77441C31.243 8.09382 30.1336 9.24544 28.848 10.1752Z" fill="#1D9BF0"/>
    </g>
    <defs>
      <clipPath id="clip0_5942_38409">
        <rect width="32" height="32" fill="white" transform="translate(0.124023 0.703125)"/>
      </clipPath>
    </defs>
  </svg>
}

export { Twitter }