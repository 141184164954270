import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./utils/routes/security/ProtectedRoute";
import ProtectedRouteUser from "./utils/routes/security/ProtectedRouteUser";
import { routes } from "./utils/routes";
import { ThemeProvider } from "@mui/material";
import { Theme } from "./utils/styles/theme";
import "./utils/fonts/bellania/Bellenia_Love.ttf";
import "./index.css";
import "@fontsource/poppins";
import WebLayout from "./components/organism/webLayout";

function App() {
  console.log("...");
  
  return (
    <ThemeProvider theme={Theme}>
      <BrowserRouter>
        <Routes>
          {routes.map((route) => {
            return (
              <Route
                key={route.path}
                path={route.path}
                element={
                  route.protected ? (
                    <ProtectedRoute>{route.component}</ProtectedRoute>
                  ) : route.userSide ? (
                    route.protectUserSide ? (
                      <ProtectedRouteUser>
                        <WebLayout>{route.component}</WebLayout>
                      </ProtectedRouteUser>
                    ) : (
                      <WebLayout>{route.component}</WebLayout>
                    )
                  ) : (
                    route.component
                  )
                }
              />
            );
          })}
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
