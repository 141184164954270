import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import InputsWithLabel from "../../molecules/inputsWithLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Button } from "../../atoms/buttons";
import TextLabels from "../../atoms/textLabels";
import "./style.css";
import { useNavigate } from "react-router";
import useHttp from "../../../utils/hooks/useHttp";
import Loader from "../../atoms/loader";
import ErrorMessage from "../../atoms/errorMessage";

const Feedback = () => {
  // const [userTypeSelect, setUserTypeSelect] = React.useState("");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState("");
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const { loading, error, sendRequest } = useHttp(transformData);
  const [isValid, setValid] = useState(true);

  const handleNameChange = (event: any) => {
    setName(event.target.value);
    setNameError(false);
  };

  const handleEmailChange = (event: any) => {
    // setEmail(event.target.value);
    // setEmailError(false);

    const inputEmail = event.target.value;
    setEmail(inputEmail);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(inputEmail);

    setValid(isValidEmail);
  };

  const handlePhoneChange = (e: any) => {
    const inputPhone = e.target.value;
    setPhone(inputPhone)

    const phoneNumberRegex = /^\d{10}$/;
    const isValidPhone = phoneNumberRegex.test(inputPhone);

    // setPhoneError(isValidPhone);
    if(isValidPhone) setPhoneError(false)
    else setPhoneError(true);
  }

  const handleMessageChange = (event: any) => {
    const words = event.target.value
      .split(" ")
      .filter((word: any) => word !== "");
    const wordCount = words.join(" ").length;
    if (wordCount <= 250) {
      setMessage(event.target.value);
    }
    setMessageError(false);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    if (!name.trim()) {
      setNameError(true);
      return;
    }

    if (!email.trim()) {
      setEmailError(true);
      return;
    }

    if (!phone.trim()) {
      setPhoneError(true);
      return;
    }

    if (!message.trim()) {
      setMessageError(true);
      return;
    }

    const formData = {
      name: name,
      email: email,
      mobile: phone,
      comment: message,
    };

    setName("");
    setEmail("");
    setPhone("")
    setMessage("");
    handleFeedback(formData);
    navigate("/feedback-welcome");
  };

  const handleFeedback = (formData: any) => {

    const reqOptions: any = {
      url: "/feedbacks/create_feedback",
      method: "post",
      data: {
        feedback: formData,
      },
    };

    sendRequest(reqOptions);
  };

  function transformData(data: any) {
  }

  // const handleChange = (event: SelectChangeEvent) => {
  //   setUserTypeSelect(event.target.value);
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  return (
    <>
      {loading && <Loader />}
      <form onSubmit={handleSubmit}>
        <Box className="feedback_top">
          <h4>Feedback</h4>
          <p>
            Your feedback is valuable to us!
            <br />
            Help IMSOM improve by sharing your thoughts.
          </p>
        </Box>
        <Box className="feedback_form">
          <InputsWithLabel
            name="name"
            id="name"
            label="Name *"
            placeholder="Enter name here"
            labelColor="#BABDC2"
            type="text"
            value={name}
            handleInputChange={handleNameChange}
            error={nameError}
            helperText={nameError ? "Name is required" : ""}
            msg="Name is required"
          />
          <Box
            sx={{
              marginTop: "30px",
            }}
          />
          <InputsWithLabel
            name="email"
            id="email"
            label="Email *"
            placeholder="Enter email here"
            labelColor="#BABDC2"
            type="email"
            value={email}
            handleInputChange={handleEmailChange}
          />

          {!isValid && (
            <ErrorMessage>Please enter a valid email address.</ErrorMessage>
          )}
          {/* <Box>
          <TextLabels variant="h5" color="#FDFCFC" weight={700}>
          User Type *
          </TextLabels>
          <Box
          className="web_dialog_select"
          sx={{
            marginTop: "10px",
          }}
          >
          <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Select
          value={userTypeSelect}
          onChange={handleChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          renderValue={
            userTypeSelect !== "" ? undefined : () => "Select User Type"
          }
          >
          <MenuItem value={"student"}>Student</MenuItem>
          <MenuItem value={"senior"}>Senior Citizen</MenuItem>
          <MenuItem value={"none"}>None of the above</MenuItem>
          </Select>
          </FormControl>
          </Box>
        </Box> */}
          <InputsWithLabel
            name="phone"
            id="phone"
            label="Phone"
            placeholder="Enter your phone number"
            labelColor="#BABDC2"
            type="number"
            value={phone}
            handleInputChange={handlePhoneChange}
          />
          {phoneError && (
            <ErrorMessage>Please enter a 10 digit number.</ErrorMessage>
          )}
          <Box
            sx={{
              marginTop: "30px",
            }}
          />
          <InputsWithLabel
            name="message"
            id="message"
            label="Message *"
            placeholder="Type your message here (Max 250 characters)"
            labelColor="#BABDC2"
            type="textarea"
            maxRows={4}
            value={message}
            handleInputChange={handleMessageChange}
            error={messageError}
            helperText={messageError ? "Message is required" : ""}
          />
          <Box className="feedback_buttons_box">
            <Button
              btnClass="feedback_button"
              type="submit"
              variant="outlined"
              color="info"
              size="s"
              sx={{
                fontFamily: "Raleway",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "14px",
                width: { xs: "100%", sm: "50%" },
              }}
            // disable={name && email && message ? false : true}
            >
              Send Feedback
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default Feedback;
