import { createTheme } from "@mui/material";

interface CustomTypographyVariant {
  fontSize: string;
  fontWeight?: number;
  lineHeight?: string;
}

declare module "@mui/material/styles" {
  interface Theme {
    label: {
      main: React.CSSProperties["color"];
    };
  }
  interface ThemeOptions {
    //
    label: {
      main: React.CSSProperties["color"];
    };
  }
  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h900?: any;
    h800?: any;
    h700?: any;
    h600?: any;
    h500?: any;
    h400?: any;
    h300?: any;
    h200?: any;
    h100?: any;
    p21?: any;
    p17?: any;
    p14?: any;
    caps1?: any;
    caps2?: any;
    caps3?: any;
  }
  interface Typography {
    customVariant: CustomTypographyVariant;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    h900: true;
    h800: true;
    h700: true;
    h600: true;
    h500: true;
    h400: true;
    h300: true;
    h200: true;
    h100: true;
    p21: true;
    p17: true;
    p14: true;
    caps1: true;
    caps2: true;
    caps3: true;
  }
}

export const Theme = createTheme({
  label: {
    main: "#2C2D30",
  },
  palette: {
    primary: {
      main: "#913713",
    },
    secondary: {
      main: "#9FA1A6",
    },
    error: {
      main: "#F2323F",
    },
    success: {
      main: "#3DBA4E",
    },
    info: {
      main: "#fff",
    },
    warning: {
      main: "#FCB017",
    },
  },
  typography: {
    fontFamily: "Inter",
    h900: {
      fontSize: "42px",
      lineHeight: "48px",
      color: "#141518",
      "@media (max-width:768px)": {
        fontSize: "36px",
      },
    },
    h800: {
      fontSize: "35px",
      lineHeight: "40px",
      color: "#141518",
      "@media (max-width:768px)": {
        fontSize: "30px",
      },
    },
    h700: {
      fontSize: "31px",
      lineHeight: "36px",
      color: "#141518",
      "@media (max-width:768px)": {
        fontSize: "26px",
      },
    },
    h600: {
      fontSize: "28px",
      lineHeight: "32px",
      color: "#141518",
      "@media (max-width:768px)": {
        fontSize: "24px",
      },
    },
    h500: {
      fontSize: "24px",
      lineHeight: "28px",
      color: "#141518",
      "@media (max-width:768px)": {
        fontSize: "20px",
      },
    },
    h400: {
      fontSize: "21px",
      lineHeight: "24px",
      color: "#141518",
      "@media (max-width:768px)": {
        fontSize: "17px",
      },
    },
    h300: {
      fontSize: "17px",
      lineHeight: "20px",
      color: "#141518",
      "@media (max-width:768px)": {
        fontSize: "14px",
      },
    },
    h200: {
      fontSize: "14px",
      lineHeight: "16px",
      color: "#141518",
      "@media (max-width:768px)": {
        fontSize: "12px",
      },
    },
    h100: {
      fontSize: "12px",
      lineHeight: "14px",
      color: "#141518",
      "@media (max-width:768px)": {
        fontSize: "10px",
      },
    },
    p21: {
      fontSize: "21px",
      lineHeight: "32px",
      color: "#141518",
      "@media (max-width:768px)": {
        fontSize: "17px",
      },
    },
    p17: {
      fontSize: "17px",
      lineHeight: "24px",
      color: "#141518",
      "@media (max-width:768px)": {
        fontSize: "14px",
      },
    },
    p14: {
      fontSize: "14px",
      lineHeight: "20px",
      color: "#141518",
      "@media (max-width:768px)": {
        fontSize: "12px",
      },
    },
    caps1: {
      fontSize: "16px",
      lineHeight: "20px",
      color: "#141518",
      textTransform: "uppercase",
      "@media (max-width:768px)": {
        fontSize: "14px",
      },
    },
    caps2: {
      fontSize: "13px",
      lineHeight: "16px",
      color: "#141518",
      textTransform: "uppercase",
      "@media (max-width:768px)": {
        fontSize: "12px",
      },
    },
    caps3: {
      fontSize: "12px",
      lineHeight: "14px",
      color: "#141518",
      textTransform: "uppercase",
      "@media (max-width:768px)": {
        fontSize: "10px",
      },
    },
    h1: {
      fontSize: "60px",
      color: "#092532",
      "@media (max-width:600px)": {
        fontSize: "50px",
      },
    },
    h2: {
      fontSize: "48px",
      color: "#092532",
      "@media (max-width:600px)": {
        fontSize: "40px",
      },
    },
    h3: {
      fontSize: "42px",
      color: "#092532",
      "@media (max-width:600px)": {
        fontSize: "36px",
      },
    },
    h4: {
      fontSize: "36px",
      color: "#092532",
      "@media (max-width:600px)": {
        fontSize: "30px",
      },
    },
    h5: {
      fontSize: "14px",
      color: "#092532",
      "@media (max-width:600px)": {
        fontSize: "12px",
      },
    },
    subtitle1: {
      fontSize: "28px",
      color: "#092532",
      "@media (max-width:600px)": {
        fontSize: "22px",
      },
    },
    subtitle2: {
      fontSize: "24px",
      color: "#092532",
      "@media (max-width:600px)": {
        fontSize: "18px",
      },
    },
    body1: {
      fontSize: "20px",
      color: "#092532",
      "@media (max-width:600px)": {
        fontSize: "16px",
      },
    },
    body2: {
      fontSize: "18px",
      color: "#092532",
      "@media (max-width:600px)": {
        fontSize: "14px",
      },
    },
    caption: {
      fontSize: "16px",
      color: "#092532",
      "@media (max-width:600px)": {
        fontSize: "12px",
      },
    },
    button: {
      fontSize: "16px",
      textDecoration: "none",
      textTransform: "none",
      fontWeight: 600,
      "@media (max-width:600px)": {
        fontSize: "14px",
      },
    },
  },
});
