import React, { useEffect, useState } from "react";
import SectionHeading from "../../molecules/sectionHeading";
import { Box, Grid } from "@mui/material";
import Event1Img from "./images/event1.png";
import Event2Img from "./images/event2.png";
import Event3Img from "./images/event3.png";
import ArrowImg from "./images/arrow.svg";
import { Link } from "react-router-dom";
import useHttp from "../../../utils/hooks/useHttp";
import "./style.css";
import moment from "moment";

interface Props {
  hideTitle?: boolean;
  hideViewMore?: boolean;
  pageCheck?: boolean;
  setNewArrFilter?: React.Dispatch<React.SetStateAction<any>>;
  filterArray?: any;
}

const PastEventSection: React.FC<Props> = ({
  hideTitle,
  hideViewMore,
  pageCheck,
  setNewArrFilter,
  filterArray,
}) => {
  const { loading, error, sendRequestWithoutToken } = useHttp(transformData);
  const [pastEvents, setPastEvents] = useState<any>([]);
  const [checkPages, setCheckPages] = useState<boolean>(true);

  useEffect(() => {
    setCheckPages(pageCheck || true);
  }, [pageCheck]);

  function transformData(data: any) {
    if (data.status) {
      const eventsData = data.events;
      console.log(eventsData, "events");

      const eventsArr = [];
      for (const event of eventsData) {
        const obj = {
          eventId: event?.id,
          image:
            event?.event_galleries &&
            event.event_galleries[0]?.event_images?.length
              ? event.event_galleries[0].event_images
              : ["images/event-default.png"],
          title: event.title,
          date: `${moment.utc(event.date).format("MMMM D YYYY")}, 
          ${event.start_date}`,
        };
        eventsArr.push(obj);
      }
      setNewArrFilter && setNewArrFilter(eventsArr ?? []);
      setPastEvents(eventsArr);
    }
  }

  useEffect(() => {
    getPastEvent();
    const handleResize = () => {
      getPastEvent();
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const handleResize = () => {
  //   // Your logic or function to be called on resize
  //   console.log('Window resized!');
  //   // Call your function here
  // }

  const getPastEvent = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 900) {
      const reqOptions = {
        url: `/event_managments`,
        method: "GET",
        params: {
          tab: "past",
          page: 1,
          per_page: pageCheck ? undefined : 2,
        },
      };

      sendRequestWithoutToken(reqOptions);
    } else {
      const reqOptions = {
        url: `/event_managments`,
        method: "GET",
        params: {
          tab: "past",
          page: 1,
          per_page: pageCheck ? undefined : 3,
        },
      };

      sendRequestWithoutToken(reqOptions);
    }
  };

  const renderEvents = (event: any, i: any) => {
    return (
      <Grid item xs={6} sm={6} md={4} className="event_grid" key={i}>
        <Link to={`/event/${event?.eventId}`} className="event_past_link">
          <Box className="event_content">
            <Box className="event_image">
              <img
                src={
                  (event?.image && event.image[0]) || "images/event-default.png"
                }
                className="past_event_image_website"
              />
            </Box>
            <Box className="event_text">
              <h6 className="event_title" title={event.title}>
                {event.title}
              </h6>
              <p className="event_date">{event.date}</p>
            </Box>
          </Box>
        </Link>
      </Grid>
    );
  };

  // const Arr=hideTitle ? pastEvents : filterArray

  return (
    <Box className="past_event_section">
      {!hideTitle && (
        <SectionHeading
          title="Past Events"
          classNameBox="past_event_heading_img_box"
          classNameImg="past_event_heading_img"
        />
      )}
      <Box className="past_event_box">
        <Grid container spacing={1} className="event_grid_container">
          {hideTitle ? (
            filterArray?.length > 0 ? (
              filterArray.map((event: any, i: number) => {
                return renderEvents(event, i);
              })
            ) : (
              <h3 style={{ color: "gray", textDecoration: "italic" }}>
                No Event to show!
              </h3>
            )
          ) : (
            pastEvents?.length &&
            pastEvents.map((event: any, i: number) => {
              return renderEvents(event, i);
            })
          )}
        </Grid>
      </Box>
      {!hideViewMore && (
        <Box className="event_link_container">
          <Box className="event_link_btn">
            <Link to="/past-events" style={{ textDecoration: "none" }}>
              <p>
                View All
                <img src={ArrowImg} />
              </p>
            </Link>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default PastEventSection;
