import React from "react";
import image from "./images/Group (1).svg";
import "./style.css";
import vector from "./images/Vector.svg";
import moment from "moment";
import { json } from "stream/consumers";
import backgroundImage from "./images/Asset 3 4.svg";
import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import useHttp from "../../../utils/hooks/useHttp";
import AlertCmp from "../../atoms/alert";
import { log } from "console";

const PriceDetails = ({ ticketDetails }: any): any => {

  const navigate = useNavigate();
  const { id } = useParams();

  const resp = ticketDetails?.ticket_details ?? [];
  // const timeString = ticketDetails.start_date;
  // const time = moment(timeString, "hh:mm A");
  // const newTime = time.subtract(4, "hours");
  // const newTimeString = newTime.format("hh:mm A");

  // converting the date and time in date-time format
  const fullDateTimeString = `${ticketDetails?.date?.split('T')[0]} ${ticketDetails.start_date}`;
  //now subtracting the time 
  const time = moment(fullDateTimeString, "YYYY-MM-DD hh:mm A").subtract(4, "hours");

  const newTimeString = time.format("YYYY-MM-DD hh:mm A");

  const [addTickets, setAddTickets] = useState<any>(false);
  const [ticketCounts, setTicketCounts] = useState<any>({});
  const [userTypeArray, setUserTypeArray] = useState<any>([]);
  const [membership, setMembership] = useState<any>(null);
  const [pURL, setPURL] = useState<any>(localStorage.getItem("previousPage"));
  const transformMembershipData = (data: any) => {
    setMembership(data?.Membership);
  };
  const {
    loading: load,
    error: err,
    sendRequestWithoutToken: getMembership,
  } = useHttp(transformMembershipData);
  useEffect(() => {
    const user = localStorage.getItem("web_user");
    if (user) {
      const web_user = JSON.parse(user);
      const reqOptions = {
        url: `/memberships/${web_user?.membership_id}`,
        method: "GET",
      };

      getMembership(reqOptions);
    }
    let tItem: any = localStorage.getItem("ticket_counts");
    let cItem: any = localStorage.getItem("user_type");
    if (pURL.split("/")[1] === "attendee-details") {
      if (![null, undefined, ""].includes(tItem)) {
        const t = JSON.parse(tItem);
        setTicketCounts(t[0]);
      }
      if (![null, undefined, ""].includes(cItem)) {
        const c = JSON.parse(cItem);
        setUserTypeArray(c[0]);
      }
      localStorage.setItem("previousPage", "");
    } else {
      setTicketCounts({});
      setUserTypeArray([]);
      localStorage.setItem("ticket_counts", "");
      localStorage.setItem("user_type", "");
    }
  }, []);
  const [showSuccessAlert, setShowSuccessAlert] = useState({
    show: false,
  });

  const addMemberTickets = (index: any, user_type: string) => {
    // When "Add Tickets" is clicked, update the state with the card's index and initial count of 0.
    if (
      (user_type === "Members" || user_type === "Member") &&
      membership == null
    ) {
      setShowSuccessAlert({
        show: true,
      });
      setTimeout(() => {
        setShowSuccessAlert({
          show: false,
        });
      }, 2500);
      return;
    }
    setTicketCounts((prevCounts: any) => ({
      ...prevCounts,
      [index]: 1,
    }));
    setUserTypeArray([
      ...userTypeArray,
      {
        name: "",
        email: "",
        user_type: user_type,
        use_pass: false,
      },
    ]);
  };

  // const handleIncrement = (index: any) => {
  //   // Increase the count of tickets for the card at the specified index.
  //   setTicketCounts((prevCounts: any) => ({
  //     ...prevCounts,
  //     [index]: (prevCounts[index] || 0) + 1,
  //   }));
  // };
  useEffect(() => {
    localStorage.setItem("ticket_counts", JSON.stringify([ticketCounts]));
    localStorage.setItem("user_type", JSON.stringify([userTypeArray]));
  }, [ticketCounts, userTypeArray]);

  const handleIncrement = (index: any, user_type: string) => {
    // Increase the count of tickets for the card at the specified index.
    setTicketCounts((prevCounts: any) => ({
      ...prevCounts,
      [index]: (prevCounts[index] || 0) + 1,
    }));

    setUserTypeArray([
      ...userTypeArray,
      {
        name: "",
        email: "",
        user_type: user_type,
        use_pass: false,
      },
    ]);
  };

  const handleDecrement = (index: any, user_type: string) => {
    setTicketCounts((prevCounts: any) => {
      const newCounts = { ...prevCounts };
      if (newCounts[index] !== undefined) {
        newCounts[index] = Math.max(newCounts[index] - 1, 0);
        if (newCounts[index] === 0) {
          delete newCounts[index]; // Remove the count if it reaches 0
        }
      }
      return newCounts;
    });
    // setUserTypeArray((prevUserTypeArray: any) =>
    // prevUserTypeArray.filter((item:any) => item?.user_type !== user_type)
    // );
    userTypeArray.pop();
    setUserTypeArray(userTypeArray);
  };

  return (
    <>
      {showSuccessAlert.show && (
        <AlertCmp type="error" position="user_side">
          {"Please be a member to avail the discount"}
        </AlertCmp>
      )}
      <div className="main_box">
        <h1 className="price_heading">Pricing & details</h1>
        <p className="price_heading_para">
          The online ticket sale will be closed for this concert effective &nbsp;
           {/* {moment(newTimeString).format("MMMM D YYYY")}, {moment(newTimeString).format('hh:mm A')} */}
           {moment(newTimeString, '"YYYY-MM-DD hh:mm A"').format("MMMM D YYYY")}, {moment(newTimeString, '"YYYY-MM-DD hh:mm A"').format('hh:mm A')}
           {/* {moment.utc(eventData.date).format("MMMM D YYYY")},{" "}
                {eventData.start_date} */}
        </p>
        <div className="priceDetails_priceBox">
          {resp.map((items: any, index: number) => (
            <div className="price_box">
              <h1 className="priceDetails_heading">{items?.title}</h1>
              <div className="priceDetails_price">
                ${items?.advance_value}
                <span className="priceDetails_static">/ticket</span>
              </div>
              <hr />
              <p className="priceDetails_desc">
                {/* {JSON.stringify(items?.title)}  */}
                {items?.title === "Price for Non Members *"
                  ? "Non Members"
                  : items?.title === "Members *"
                  ? "You MUST be an IMSOM member for 2023"
                  : items?.title === "Senior Citizens"
                  ? "You MUST be a Senior (Over 65 yrs)"
                  : items?.title === "Student"
                  ? "You MUST be a full-time student"
                  : items?.title === "General *"
                  ? "Everyone"
                  : items?.title ===
                    "Price for Members, Students, Senior Citizens *"
                  ? "Everyone"
                  : items?.title === "Senior citizen"
                  ? "You MUST be a Senior (Over 65 yrs)"
                  : items?.title === "General"
                  ? "Everyone"
                  : items?.title === "Members"
                  ? "You MUST be an IMSOM member for 2023"
                  : items?.title === "Non Members"
                  ? "Non Members"
                  : ""}
              </p>

              <hr />
              {ticketCounts[index] !== undefined ? ( // Check if this card has a count in the state
                <div className="btn_wrapper">
                  <button
                    onClick={() => handleDecrement(index, items?.title)}
                    className="decrement"
                  >
                    -
                  </button>
                  <input
                    value={ticketCounts[index]}
                    className="ticket_input"
                    type="text"
                  />
                  <button
                    onClick={() => handleIncrement(index, items?.title)}
                    className="increment"
                  >
                    +
                  </button>
                </div>
              ) : (
                <div className="btn_parent">
                  <button onClick={() => addMemberTickets(index, items?.title)}>
                    Add Tickets
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
        {userTypeArray?.length && (
          <Button
            className="proceed_button"
            variant="outlined"
            // disabled={countTicket === 0 ? true : false}
            style={{
              backgroundColor: "#fff",
              color: "#000",
            }}
            size="large"
            onClick={() => {
              if (userTypeArray?.length) {
                navigate(`/attendee-details/${id}`, {
                  state: {
                    userTypeArray: userTypeArray,
                  },
                });
              }
            }}
          >
            Proceed to Checkout
          </Button>
        )}
        <div className="priceDetails_bottom_decs">
          <div className="priceDetails_description">
            <div style={{ marginTop: "10px" }}>
              <img src={vector} alt="vector" />
            </div>
            <p className="priceDetails_bottom_decs_para">
              IMSOM reserves the right to confirm eligibility for discount
              tickets.
            </p>
          </div>
          <div className="priceDetails_description">
            <div style={{ marginTop: "10px" }}>
              <img src={vector} alt="vector" />
            </div>{" "}
            <p className="priceDetails_bottom_decs_para">
              {" "}
              Student discounts are available for high school and full-time
              college students. IMSOM may request proof of student status at the
              door. (High school or college ID with recent date, or college ID
              and current fee statement). Member status will be confirmed prior
              to the concert.
            </p>
          </div>
          <div className="priceDetails_description">
            <div style={{ marginTop: "10px" }}>
              <img src={vector} alt="vector" />
            </div>{" "}
            <p className="priceDetails_bottom_decs_para">
              {" "}
              Please remember to print the receipt and bring it to the show.
            </p>
          </div>
        </div>
        <p className="copyright_content1">
          IMSOM, established in 1980, is a registered non-profit (501(c)(3))
          organization serving Minneapolis-St.Paul and the greater Twin Cities
          Metro Area.
        </p>
        <p className="copyright_content2">
          Copyright 2023 Indian Music Society of Minnesota
        </p>
      </div>
    </>
  );
};
  
// }

export default PriceDetails;
