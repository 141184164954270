import React, { useEffect, useState } from "react";
import WebDialog from "../../molecules/webDialog";
import {
  DialogContent,
  DialogContentText,
  Box,
  DialogActions,
} from "@mui/material";
import InputsWithLabel from "../../molecules/inputsWithLabel";
import SectionHeading from "../../molecules/sectionHeading";
import useHttp from "../../../utils/hooks/useHttp";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "../../atoms/buttons";
import { PropType } from "./types/propType";
import ErrorImg from "./images/error.svg";
import { RootState } from "../../../utils/store";
import { sendEmail } from "../../../utils/store/slices/changePasswordSlice";
import Loader from "../../atoms/loader";
import { Password } from "@mui/icons-material";

const WebForgotPassword = ({
  open,
  handleClose,
  handleAlertBox,
  handleAlertErrorBox,
}: PropType) => {
  const [userEmail, setUserEmail] = useState("");
  const [validateEmail, setValidateEmail] = useState(true);
  const [disableButton, setDisableButton] = useState(true);
  const { error, isLoading, emailResponse } = useSelector(
    (state: RootState) => state.sendEmail
  );
  const dispatch = useDispatch();

  useEffect(() => {
    let disableFlag = false;
    if (userEmail.length === 0) {
      disableFlag = true;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(userEmail)) {
      disableFlag = true;
    }

    setDisableButton(disableFlag);
  }, [userEmail]);

  const handleInputChange = (e: any) => {
    setUserEmail(e.target.value);
    if (e.target.value !== "") {
      setValidateEmail(true);
    }
  };

  const handleResetLink = (e: any) => {
    e.preventDefault();
    const url = window.location.href;
    const lastIndex = url.lastIndexOf('/');
    const modifiedUrl = url.substring(0, lastIndex + 0); 

    if (userEmail.trim() === "") {
      setValidateEmail(false);
    } else {
      dispatch(
        sendEmail({
          email: userEmail,
          // host: "{url}+/password",

          host:`${modifiedUrl}/password`,
        })
        );
      }
    };


  useEffect(() => {
    if (error) {
      handleAlertErrorBox(error);
    }
  }, [error]);

  useEffect(() => {
    if (emailResponse) {
      if (emailResponse.status) {
        setUserEmail("");
        handleAlertBox(emailResponse.message);
      } else {
        handleAlertErrorBox(emailResponse.message);
      }
    }
  }, [emailResponse]);

  return (
    <>
      {isLoading && <Loader />}
      <WebDialog
        title="Welcome !"
        open={open}
        handleClose={handleClose}
        primaryButtonLabel="Sign In"
        subTitle="Sign in to continue"
      >
        <form onSubmit={handleResetLink}>
          <DialogContent>
            <DialogContentText>
              <SectionHeading title="Forgot Password?" />
              <p className="login_dialog_subtitle">
                A link will be sent to your email address to change your
                password.
              </p>
            </DialogContentText>
            <Box
              sx={{
                marginTop: "60px",
              }}
            />
            <InputsWithLabel
              label="Email"
              type="email"
              name="email"
              id="email"
              labelColor="#FDFCFC"
              placeholder="Enter email here"
              handleInputChange={handleInputChange}
              value={userEmail}
            />
            {/* {!formData["password"].validate && (
          <p className="web_register_error_label">
            <img src={ErrorImg} />
            <span>{formData["password"].errorMsg}</span>
          </p>
        )} */}
          </DialogContent>
          <DialogActions className="login_dialog_actions">
            <Button
              variant="outlined"
              color="info"
              size="s"
              type="submit"
              disable={disableButton}
            >
              Send Reset Link
            </Button>
          </DialogActions>
        </form>
      </WebDialog>
    </>
  );
};

export default WebForgotPassword;
