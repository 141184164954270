import React from "react";

import { Button } from "../buttons";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseImg from "./images/close.svg";
import TextLabels from "../textLabels";
import { PropType } from "./types/propType";
import PreviewBanner from "../../templates/previewBanner";
import "./style.css";

const ModalBannerPopup = ({
  heading,
  handleClose,
  open,
  closeBtn,
  bannerData,
}: PropType) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="modal_popup_simple_banner"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
          className="modal_title"
        >
          <TextLabels variant="h400" weight={500}>
            {heading}
          </TextLabels>
          {closeBtn && (
            <img
              src={CloseImg}
              onClick={handleClose}
              style={{ cursor: "pointer" }}
            />
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <PreviewBanner bannerData={bannerData} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModalBannerPopup;
