import WebDialog from "../../molecules/webDialog";
import {
  DialogContent,
  DialogContentText,
  Box,
  DialogActions,
} from "@mui/material";
import SectionHeading from "../../molecules/sectionHeading";
import { Button } from "../../atoms/buttons";
import { PropType } from "./types/propType";
import "./style.css";
import { useNavigate } from "react-router-dom";

const WebMailSuccessDialog = ({
  open,
  handleClose
}: PropType) => {

  const navigate = useNavigate()
  return (
    <>
      <WebDialog
        title="Joined Successfully!"
        open={open}
        handleClose={handleClose}
        primaryButtonLabel="Sign In"
        subTitle="Sign in to continue"
      >
        <DialogContent>
          <DialogContentText>
            <SectionHeading title="Joined Successfully!" />
            <p className="login_dialog_subtitle">You’ve successfully been added to the mailing list. </p>
          </DialogContentText>
          <Box style={{ marginTop: "50px" }}>
          </Box>
        </DialogContent>
        <DialogActions className="login_dialog_actions web_dialog_mail_join_btn">
          <Button
            variant="outlined"
            color="info"
            size="s"
            handleClick={ () => {
              handleClose();
              navigate('/')
            }}
          >
            Go to Homepage
          </Button>
        </DialogActions>
      </WebDialog>
    </>
  );
};

export default WebMailSuccessDialog;
