import React, { useEffect, useRef, useState } from "react";
import SuccessIcon from "./image/success-icon.png";
import Loader from "../../atoms/loader";
import AlertCmp from "../../atoms/alert";
import WebDialog from "../../molecules/webDialog";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import SectionHeading from "../../molecules/sectionHeading";
import InputsWithLabel from "../../molecules/inputsWithLabel";
import { Button } from "../../atoms/buttons";
import useHttp from "../../../utils/hooks/useHttp";
import { ReqOptions } from "../../../utils/types/reqOptions";
import { useNavigate } from "react-router-dom";

import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { log } from "console";

const PaymentPopup = ({
  open,
  handleClose,
  amount,
  type,
  membershipDetails,
  eventData,
  ticket,
  attendeeDetails,
}: any) => {
  const [paid, setPaid] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const paypalRef = useRef();
  const [first, setFirst] = useState(false);
  const navigate = useNavigate();

  const formatDateTime = (timestamp: any) => {
    const dateObj = new Date(timestamp);

    // Format time as "15:30:00"
    const hours = String(dateObj.getUTCHours()).padStart(2, "0");
    const minutes = String(dateObj.getUTCMinutes()).padStart(2, "0");
    const seconds = String(dateObj.getUTCSeconds()).padStart(2, "0");
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    // Format date as "13/07/2023"
    const day = String(dateObj.getUTCDate()).padStart(2, "0");
    const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = dateObj.getUTCFullYear();
    const formattedDate = `${day}/${month}/${year}`;

    return { time: formattedTime, date: formattedDate };
  };

  useEffect(() => {
    if (!first) return;
  }, [first]);

  console.log(
    amount,
    type,
    membershipDetails
    // eventData,
    // ticket,
    // attendeeDetails
  );

  function createOrder(data: any, actions: any) {
    let descriptionText = "";
    let itemName = "";
    //const now = new Date(eventData.date);
    //const year = now.getFullYear();
    //const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    //const day = String(now.getDate()).padStart(2, "0");

    const currentTransitionYear = new Date().getFullYear();

    //const formatedDate = `${year}${month}${day}`;

    // When the order is created for ticket purchase
    if (type === "ticket") {
      // To check if there is a ticket for any non members

      const now = new Date(eventData.date);
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
      const day = String(now.getDate()).padStart(2, "0");
      itemName = eventData.title;
      //const currentTransitionYear = new Date().getFullYear();

      const formatedDate = `${year}${month}${day}`;
      let user_type = attendeeDetails.filter(
        (user: any) => user.user_type === "Price for Non Members *"
      );

      const formattedTitle = eventData.title.substr(0, 9); //10 characters of title

      // When non members ticket is present
      if (user_type) {
        // descriptionText = `TIK-GEN-${formatedDate}-${ticket}`;  //OLD description
        descriptionText = `${formatedDate}-${formattedTitle}-TIK-GEN-${ticket}`;
      }
      //when non members ticket is not present
      else {
        // descriptionText = `TIK-MEM-${formatedDate}-${ticket}`; //OLD description
        // descriptionText = `TIC-MEM-${formatedDate}-${ticket}-${formattedTitle}`;
        descriptionText = `${formatedDate}-${formattedTitle}-TIK-MEM-${ticket}`;
      }
    }

    // When the order is created for the membership purchase
    if (type === "membership") {
      const membership = membershipDetails?.membership_type.toUpperCase();

      // descriptionText = `MEM-${membership}-${year}`;
      descriptionText = `MEM-${membership}-${currentTransitionYear}`;
      itemName = "MEMBERSHIP";
    }
    const currentDate = new Date().toISOString();
    return actions.order.create({
      intent: "CAPTURE",
      purchase_units: [
        {
          description: descriptionText,
          amount: {
            currency_code: "USD",
            value: amount.toString(),
            breakdown: {
              item_total: {
                currency_code: "USD",
                value: amount.toString(), // Ensure this matches the total of all items
              },
            },
          },
          items: [
            {
              name: itemName, // The name of the item
              sku: descriptionText, // The item ID or SKU
              unit_amount: {
                currency_code: "USD",
                value: amount.toString(), // The amount for the item
              },
              quantity: "1", // Quantity of the item
              date: currentDate,
            },
          ],
        },
      ],
    });
  }

  async function onApprove(data: any, actions: any) {
    const order = await actions.order.capture();
    setPaid(true);
    setPaymentStatus(order.status);
    if (type === "donation") {
      completeDonation(order);
    } else if (type === "membership") {
      completeMembership(order);
    } else if (type === "ticket") {
      completeTicket(order);
    }
  }

  function onError(err: any) {
    console.error("Error:", err);
  }

  const completeTicket = (order: any) => {
    const loggedInUser = localStorage.getItem("web_user");
    const user = JSON.parse(loggedInUser || "{}");
    const guest = localStorage.getItem("guest_user");
    const { time, date } = formatDateTime(order?.update_time);

    const requestOptions: ReqOptions = {
      url: `/payments/payment_details`,
      method: "POST",
      data: {
        payer_id: order?.payer?.payer_id,
        email:
          Object.values(user).length !== 0
            ? user?.email
            : order?.payer?.email_address,
        name: order?.payer?.name?.given_name,
        payment_time: time,
        payment_date: date,
        transaction_id: order?.id,
        type_of_purchase_ticket: user?.membership_name,
        user_type:
          guest == "true" || loggedInUser == null ? "guest" : "registered_user",
        transaction_type: type,
        total_amount: amount,
        event_managment_id: eventData?.id,
        no_of_purchase_tickets: ticket,
        membership_id: user?.membership_id,
        attendee_details: attendeeDetails,
      },
    };

    sendTicketRequest(requestOptions);

    if (guest !== "true") {
      const userRequestOptions: ReqOptions = {
        url: `/create_user`,
        method: "POST",
        data: {
          guest: {
            email: order?.payer?.email_address,
            country_code: "+1",
            contact_number: "",
            username: order?.purchase_units[0]?.shipping?.name?.full_name,
            user_type: "guest",
            signup_for_news_latter: "false",
          },
        },
      };
      sendUserRequest(userRequestOptions);
    }
  };

  const completeDonation = (order: any) => {
    const { time, date } = formatDateTime(order?.update_time);
    const requestOptions: ReqOptions = {
      url: ``,
      method: "POST",
      data: {
        payer_id: order?.payer?.payer_id,
        email: order?.payer?.email_address,
        name: order?.payer?.name?.given_name,
        payment_time: time,
        payment_date: date,
        transaction_id: order?.id,
        transaction_type: type,
        total_amount: amount,
      },
    };

    sendRequestWithoutToken(requestOptions);
  };

  const completeMembership = (order: any) => {
    const loggedInUser = localStorage.getItem("web_user");
    const user = JSON.parse(loggedInUser || "");
    const { time, date } = formatDateTime(order?.update_time);
    const requestOptions: ReqOptions = {
      url: `/payments/payment_details`,
      method: "POST",
      data: {
        payer_id: order?.payer?.payer_id,
        email: user?.email,
        name: user?.username,
        mobile_no: user?.contact_number,
        payment_time: time,
        payment_date: date,
        transaction_id: order?.id,
        type_of_purchase_ticket: membershipDetails?.membership_type,
        user_type: "registered_user",
        no_of_free_passes: membershipDetails?.no_of_free_passes,
        transaction_type: "membership",
        total_amount: amount,
        membership_id: membershipDetails?.id,
      },
    };

    sendMembership(requestOptions);
  };

  const transformMembershipData = () => {
    const loggedInUser = localStorage.getItem("web_user");
    if (loggedInUser) {
      const webUser = JSON.parse(loggedInUser || "");
      const user = {
        ...webUser,
        no_of_free_passes: membershipDetails?.no_of_free_passes,
        membership_type: membershipDetails?.membership_type,
        membership_id: membershipDetails?.id,
      };
      localStorage.setItem("web_user", JSON.stringify(user));
    }
    setTimeout(() => {
      handleClose();
    }, 3000);
    navigate("/memberships-welcome");
  };

  const transformDonationData = (data: any) => {
    setTimeout(() => {
      handleClose();
    }, 3000);
    navigate("/donate");
  };

  const transformTicketData = (data: any) => {
    const loggedInUser = localStorage.getItem("web_user");
    if (loggedInUser) {
      const webUser = JSON.parse(loggedInUser || "");
      const user = {
        ...webUser,
        no_of_free_passes:
          webUser?.no_of_free_passes - ticket >= 0
            ? webUser?.no_of_free_passes - ticket
            : 0,
      };
      localStorage.setItem("web_user", JSON.stringify(user));
    }
    setTimeout(() => {
      handleClose();
      navigate("/");
    }, 3000);
  };

  const transformUserData = (data: any) => {
    localStorage.removeItem("guest_user");
  };

  const { loading, error, sendRequestWithoutToken } = useHttp(
    transformDonationData
  );
  // Define other HTTP request hooks
  const {
    loading: load,
    error: err,
    sendRequestWithoutToken: sendMembership,
  } = useHttp(transformMembershipData);
  const {
    loading: loadin,
    error: er,
    sendRequestWithoutToken: sendTicketRequest,
  } = useHttp(transformTicketData);
  const {
    loading: loadUser,
    error: userErr,
    sendRequestWithoutToken: sendUserRequest,
  } = useHttp(transformUserData);

  return (
    <>
      <WebDialog
        title="Payment"
        open={open}
        handleClose={handleClose}
        primaryButtonLabel="Payment"
        subTitle="Payment"
      >
        {paymentStatus === null ? (
          <>
            <form onSubmit={() => {}}>
              <DialogContent>
                <DialogContentText>
                  <SectionHeading title="Payment" />
                  <h4 className="login_dialog_subtitle">
                    Pay ${amount} dollars via PayPal
                  </h4>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <PayPalScriptProvider
                  options={{
                    clientId: `${process.env.REACT_APP_PAYPAL_CLIENT_ID}`,
                  }}
                >
                  <Box style={{ width: "100%" }} ref={paypalRef}>
                    <PayPalButtons
                      style={{ layout: "vertical" }}
                      createOrder={createOrder}
                      onApprove={onApprove}
                      onError={onError}
                    />
                  </Box>
                </PayPalScriptProvider>
              </DialogActions>
            </form>
            <Box className="login_dialog_signup">
              <p>
                <span
                  className="login_dialog_signup_link"
                  role="button"
                  onClick={handleClose}
                >
                  No, thanks
                </span>
              </p>
            </Box>
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "50px",
              }}
            >
              <img src={SuccessIcon} alt="" height={80} width={80} />
              <p style={{ color: "white" }}>
                Your payment of ${amount} was successfull.
              </p>
            </div>
          </>
        )}
      </WebDialog>
    </>
  );
};

export default PaymentPopup;
