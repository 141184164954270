import React, { useEffect, useState } from "react";
import SectionHeading from "../../molecules/sectionHeading";
import { Box, Grid } from "@mui/material";
import ArtistImg1 from "./images/artist1.png";
import ArtistImg2 from "./images/artist2.png";
import ArtistImg3 from "./images/artist3.png";
import ArtistImg4 from "./images/artist4.png";
import ArtistImg5 from "./images/artist5.png";
import ArtistImg6 from "./images/artist6.png";
import Line from "./images/Fill-35.svg"
import WebsiteArtistImage from "../../atoms/websiteArtistImage";
import TitleDivider from "../../atoms/titleDivider";
import AcknowledgementSection from "../../organism/acknowledgementSection";
import "./style.css";
import { ArtistModel } from "../../../utils/types/artist";
import useHttp from "../../../utils/hooks/useHttp";
import Loader from "../../atoms/loader";

const artists = [
  {
    title: "Vocals",
    artists: [
      {
        image: ArtistImg1,
        name: "Lakshminarayana Subramaniam",
      },
      {
        image: ArtistImg2,
        name: "Lakshminarayana Subramaniam",
      },
      {
        image: ArtistImg3,
        name: "Lakshminarayana Subramaniam",
      },
      {
        image: ArtistImg4,
        name: "Lakshminarayana Subramaniam",
      },
      {
        image: ArtistImg5,
        name: "Lakshminarayana Subramaniam",
      },
    ],
  },
  {
    title: "Percussions",
    artists: [
      {
        image: ArtistImg1,
        name: "Lakshminarayana Subramaniam",
      },
      {
        image: ArtistImg2,
        name: "Lakshminarayana Subramaniam",
      },
      {
        image: ArtistImg3,
        name: "Lakshminarayana Subramaniam",
      },
      {
        image: ArtistImg4,
        name: "Lakshminarayana Subramaniam",
      },
      {
        image: ArtistImg5,
        name: "Lakshminarayana Subramaniam",
      },
    ],
  },
  {
    title: "String Instruments",
    artists: [
      {
        image: ArtistImg1,
        name: "Lakshminarayana Subramaniam",
      },
      {
        image: ArtistImg2,
        name: "Lakshminarayana Subramaniam",
      },
      {
        image: ArtistImg3,
        name: "Lakshminarayana Subramaniam",
      },
      {
        image: ArtistImg4,
        name: "Lakshminarayana Subramaniam",
      },
      {
        image: ArtistImg5,
        name: "Lakshminarayana Subramaniam",
      },
    ],
  },
  {
    title: "Reed Wind Instruments",
    artists: [
      {
        image: ArtistImg1,
        name: "Lakshminarayana Subramaniam",
      },
      {
        image: ArtistImg2,
        name: "Lakshminarayana Subramaniam",
      },
      {
        image: ArtistImg3,
        name: "Lakshminarayana Subramaniam",
      },
      {
        image: ArtistImg4,
        name: "Lakshminarayana Subramaniam",
      },
      {
        image: ArtistImg5,
        name: "Lakshminarayana Subramaniam",
      },
    ],
  },
];

const AllArtists = () => {

  const [artists,setArtists] = useState<ArtistModel[]>([]);
  const {loading, sendRequest: sendRequestForArtists } = useHttp(transformArtistData);

  function transformArtistData(data: any) {
    setArtists(data?.artists_list?.filter((artist:any)=>(artist.artist_image.length > 0)) || [])
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    sendRequestForArtists({
      url: "/artist_list",
      method: "GET"
    })
  }, []);

  return (
    <>
      {loading && <Loader />}
      <Box>
        <Box className="all_artist_top_website">
          <SectionHeading
            title="Artists who’ve performed for us"
            marginBottom="0"
            width="80%"
          />
          <p className="all_artists_subtitle_website">
            Among the many luminaries of Indian classical music IMSOM has
            presented over the years, here are a few that have graced our
            events.
          </p>
        </Box>
        <Box className="all_artist_instrument_website">
          <Box className="all_artist_section_images_website">
            <Grid container spacing={{ sm: 4, xs: 2 }}>
              {artists.map((artist) => (
                <Grid key={artist.id} item sm={12 / 5} xs={4}>
                  <WebsiteArtistImage
                    image={artist.artist_image[0]}
                    name={artist.name}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* {artists.map((art, i) => {
          return (
            <Box className="all_artist_section_website">
              <TitleDivider>
                <h4 className="all_artist_section_heading_website">
                  {art.title}<br/>
                  <img className="all_artist_section_heading_website_image" src={Line}/>
                </h4>
              </TitleDivider>
              <Box className="all_artist_section_images_website">
                <Grid container spacing={{ sm: 4, xs: 2 }}>
                  {art.artists.map((artist, i) => {
                    return (
                      <Grid key={i} item sm={12 / 5} xs={4}>
                        <WebsiteArtistImage {...artist} />
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Box>
          );
        })} */}
        </Box>
        {/* <AcknowledgementSection /> */}
      </Box>
    </>
  );
};

export default AllArtists;
