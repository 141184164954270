import React, { useRef, useState } from "react";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextLabels from "../textLabels";
import { PropType } from "./types/propType";
import CloseImg from "./images/close.svg";
import TabBar from "../tabs";
import UserImg from "./images/user.png";
import EventImg from "./images/event.png";
import ClockImg from "./images/clock.svg";
import CDImg from "./images/cd.svg";
import StarImg from "./images/star.svg";
import "./style.css";
import PreviewBanner from "../../templates/previewBanner";
import { Input } from "../inputs";
import { ShareLink } from "./images/share-link";
import { Button } from "../buttons";
import { Twitter } from "./images/twitter";
import { Facebook } from "./images/facebook";
import { Instagram } from "./images/instagram";
import { Whatsapp } from "./images/whatsapp";

const ShareModal = ({
  open,
  handleClose,
  heading,
  closeBtn,
  eventData
}: PropType) => {
  const [copied, setCopied] = useState(false);
  const inputRef = useRef<HTMLInputElement>();
  const shareURL = process.env.REACT_APP_FRONTEND_URL as string
  const URL  = `${shareURL}/event/${eventData.id}`;
const facebookUrl = `https://www.facebook.com/sharer.php?u=${URL}`
    const twitterUrl = `https://twitter.com/share?url=${URL}`
    const instagramUrl = `https://www.instagram.com/?url=${URL}`
    const whatsappUrl = `https://web.whatsapp.com/send?text=${URL}`

  const copyHandlar = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(URL)
        .then(() => {
          setCopied(true);
        })
        .catch((error) => {
          setCopied(false);
        });
    } else {
      inputRef.current?.select();
      if (document.execCommand("copy")) {
        setCopied(true);
      } else {
        setCopied(false);
      }
    }

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };
const handleCreateBtnSocial=()=>{
  const newTabUrl:any = URL; 
  window.open(newTabUrl, '_blank');
}
  return (
    <>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="modal_popup_profile"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{ display: "flex", justifyContent: "space-between" }}
            className="modal_title"
          >
            <TextLabels variant="h400" weight={500}>
              {heading}
            </TextLabels>
            {closeBtn && (
              <img
                alt="close icon"
                src={CloseImg}
                onClick={handleClose}
                style={{ cursor: "pointer" }}
              />
            )}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box className="share-banner-container">
                <PreviewBanner bannerData={{ event_managment: eventData }} />
              </Box>
              <Box className="share-input-container">
                <Input
                  inputRef={inputRef}
                  sx={{ width: "100%" }}
                  type="text"
                  value={URL}
                />
                <Box className="share-svg-container">
                  <ShareLink />
                </Box>
                <Box className="copy-button-container">
                  <Button
                    size="s"
                    variant="outlined"
                    color="primary"
                    disable={copied}
                    handleClick={copyHandlar}
                  >
                    {copied ? "Copied" : "Copy"}
                  </Button>
                </Box>
              </Box>
                <Box className="flex-container">
                    <a className="share-anchor-tag" href={facebookUrl} target="_blank">

                    <Facebook  />
                    </a>
                    <a className="share-anchor-tag" href={twitterUrl} target="_blank">

                    <Twitter />
                    </a>
                    <a className="share-anchor-tag" href={instagramUrl} target="_blank">

                    <Instagram />
                    </a>
                    <a className="share-anchor-tag" href={whatsappUrl} target="_blank">

                    <Whatsapp />
                    </a>
                </Box>
                {/* <Box style={{textAlign:"center"}}>
                <Button
                btnClass={
                  `btnCustomCss btn60percent`
                }
                variant="contained"
                color="primary"
                size="s"
                handleClick={
                  handleCreateBtnSocial
                }
              >
                Share now
              </Button>
                </Box> */}
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default ShareModal;
