import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import Artist1Img from "./images/img1.png";
import Artist2Img from "./images/img2.png";
import Artist3Img from "./images/img3.png";
import Artist4Img from "./images/img4.png";
import HeroText from "../../molecules/heroText";
import WebCarousel from "../../atoms/webCarousel";
import useHttp from "../../../utils/hooks/useHttp";
import Loader from "../../atoms/loader";
import AlertCmp from "../../atoms/alert";
import moment from "moment";
import "./style.css";
import Carousel from "react-material-ui-carousel";
import { Paper, Button } from "@mui/material";
import CardSection from "../../molecules/cardSection";
import { id } from "date-fns/locale";
import { useMediaQuery } from "@mui/material";

const HeroBanner = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [banner, setBanner] = useState<any>([]);
  const [showMore, setShowMore] = useState<string | number>("");
  const toggleShowMore = (index: number) => {
    if (showMore === index) {
      setShowMore("");
    } else {
      setShowMore(index);
    }
    // console.log(11111,items.index)
  };

  const { loading, error, errorFlag, sendRequestWithoutToken } =
    useHttp(transformData);

  const transformBanner = (data: any) => {
    if (data.status) {
      setBanner(data?.banners || []);
    }
  };

  const {
    loading: loadBanner,
    error: bannerErr,
    errorFlag: bannerErrFlag,
    sendRequest,
  } = useHttp(transformBanner);
  const [carouselContent, setCarouselContent] = useState<any>([]);

  function transformData(data: any) {
    if (data.status) {
      const eventsData = data.events;
      const eventsArr = [];
      for (const event of eventsData) {
        let obj = {
          textContent: {
            id: event.id,
            title: event.title,
            location: `${event.venue}`,
            time: `${moment.utc(event.date).format("MMMM D YYYY")}, 
            ${event.start_date}`,
            eventType: event?.event_type,
            url: event?.url,
            subType: event?.sub_type,
            price: event.ticket_details
              ? event.ticket_details[0]?.advance_value
              : "--",
          },
          artists: event.artists,
          type: "carouselContent",
        };
        eventsArr.push(obj);
      }
      setCarouselContent(eventsArr);
    }
  }

  useEffect(() => {
    const reqOptions = {
      url: `/event_managments`,
      method: "GET",
      params: {
        tab: "both_events",
        // page: 1,
        // per_page: 6,
      },
    };

    sendRequestWithoutToken(reqOptions);
  }, []);

  useEffect(() => {
    const reqOptions = {
      url: `/banners/get_today_banner`,
      method: "GET",
      params: {
        page: 1,
        per_page: 6,
      },
    };

    sendRequest(reqOptions);
  }, []);

  const carouselStyle: React.CSSProperties = {
    height: "auto", // Adjust the height as per your requirement
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "transparent",
    marginTop: "50px",
  };

  const imageStyle: React.CSSProperties = {
    maxHeight: "100%",
    width: "100%",
    height: "100%",
    objectFit: "cover",
  };
  function sliceTextAtWordBoundary(text: any, limit: any) {
    if (text?.length == limit) {
      return text;
    }

    // const words = text?.split(' ');
    const words = text ? text.split(" ") : "";
    let slicedText = "";
    let characterCount = 0;

    for (const word of words) {
      if (characterCount + word?.length <= limit) {
        slicedText += word + " ";
        characterCount += word?.length + 1; // +1 for the space
      } else {
        break;
      }
    }

    return slicedText.trim();
  }

  return (
    <>
      {loading && <Loader />}
      {errorFlag && (
        <AlertCmp type="error_web" position="user_side">
          Something went wrong! Try again..
        </AlertCmp>
      )}
      {/* <Box
        display="flex"
        flexDirection={'column'}
        alignItems="center"
        justifyContent="center"
        className="homeHeading"
      >
        <p className="homeWelcom">Welcome to</p>
        <p className="homeTitle">Indian Music Society Of Minnesota</p>
      </Box> */}
      <Box
        sx={{ flexGrow: 1, marginBottom: "20px" }}
        className={`hero_section ${
          carouselContent?.length < 1 ? "banner_container_box" : ""
        }`}
      >
        <Box className="hero_section_web_carousel">
          {carouselContent?.length > 0 ? (
            <WebCarousel
              banner={banner}
              carouselContent={carouselContent}
              type="hero_banner"
            />
          ) : (
            <Carousel
              indicators={true}
              sx={{
                overflow: "unset",
                height: `${isMobile ? "700px" : "unset"}`,
              }}
            >
              {banner.map((banner: any, index: number) => (
                <Paper key={banner.id} style={carouselStyle}>
                  {/* <img src={banner.banner_image[0]} alt={banner.title} style={imageStyle} />   */}
                  {/* <div className="main_div">
                      <CardSection>
                        <>
                          <div className="banner_wrapper">

                            <div className="left_div ">
                              jhggg
                              <img className="banner_image" src={banner.banner_image?.[0]} alt="" />

                            </div>
                            <div className="right_div">
                              <h1 className="heading_desc">{banner?.title}</h1>
                              {
                                showMore === index ? <p className="artist_desc" dangerouslySetInnerHTML={{ __html: banner?.description }}></p> :
                                  <p className="artist_desc" dangerouslySetInnerHTML={{ __html: sliceTextAtWordBoundary(banner?.description, 200) }}></p>
                              }
                              {banner?.description?.length > 200 && (
                                <p onClick={() => toggleShowMore(index)} className="view-more-link" style={{ width: "76%", display: "flex", justifyContent: "flex-start", color: "white", textDecoration: "none", fontWeight: "500", marginLeft: "10px", }}>
                                  {showMore === index ? 'View Less' : 'View More'}
                                </p>
                              )}

                            </div>
                          </div>
                        </>
                      </CardSection>
                    </div> */}
                </Paper>
              ))}
            </Carousel>
          )}
        </Box>
      </Box>
    </>
  );
};

export default HeroBanner;
