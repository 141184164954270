import React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { PropType } from "./types/propType";

const Paginate = ({
  page,
  handlePageChange,
  totalRecords,
  recordsPerPage,
}: PropType) => {
  return (
    <Stack
      spacing={2}
      sx={{
        alignItems: "center",
        marginTop: "30px",
        marginBottom: "30px",
      }}
    >
      <Pagination
        count={Math.ceil(totalRecords / recordsPerPage)}
        color="primary"
        page={page}
        onChange={handlePageChange}
      />
    </Stack>
  );
};

export default Paginate;
