import React, { useState, useContext, useEffect } from "react";
import { PropType } from "./types/propType";
import { Box, Grid } from "@mui/material";
import Step2 from "../../templates/step2";
import Step4 from "../../templates/step4";
import "./style.css";
import Step1 from "../../templates/eventBasicDetails";
import Step3 from "../../templates/eventTicketDetails";
import { EventFormData } from "./types/formData";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import useHttp from "../../../utils/hooks/useHttp";
import { ReqOptions } from "../../../utils/types/reqOptions";
import Loader from "../../atoms/loader";
import ModalBannerPopup from "../../atoms/modalBannerPopup";

type WizardStepsType = "step1" | "step2" | "step3" | "step4"

const stepsData: any = {
  step1: {
    label: "Basic Details",
  },
  step2: {
    label: "Artist Details",
  },
  step3: {
    label: "Ticket Details",
  },
  step4: {
    label: "Acknowledgement Details",
  },
};

export const EventContext = React.createContext<any>({
  formData: null,
  handleFormDataInput: (a: any) => {},
  formDataForPreview: null,
  updateDataForPreview: (a:any)=>{}
});

const WizardSteps = () => {
  const { loading, error, sendRequest } = useHttp(transformData);
  const [previewBannerPopup, setPreviewBannerPopup] = useState(false);
  function transformData(data: any) {
    if (data && data?.event) {
      setFormData({ event_managment: data?.event });
    }
  }

  const [search, setSearch] = useSearchParams();
  const [wizardstep, setWizardStep] = useState<WizardStepsType>("step1");

  const [formData, setFormData] = useState<any | EventFormData | {}>({});
  const [formDataForPreview, setFormDataForPreview] = useState<any | EventFormData | {}>({});

  const handleFormDataInput = (data: any) => {
    setFormData((prevState: any) => {
      const dataObj = JSON.parse(JSON.stringify(prevState));
      Object.keys(data).forEach((key, i) => {
        dataObj[key] = data[key];
      });
      return dataObj;
    });
  };


  const updateDataForPreview = (data: any) => {
    setFormDataForPreview((prevState: any) =>{ 
      const obj=  ({
        event_managment:{...prevState.event_managment, ...data}
      })
    return obj;
  })
  };


  const handleOpenPreviewPopup = () => {
    setPreviewBannerPopup(true);
  };

  const handleClosePreviewPopup = () => {
    setPreviewBannerPopup(false);
  };

  const saveData = async (formData: any) => {
    try {
      const config = {
        headers: {
          //"Content-Type":"application/json",
          "Content-Type": formData?.artists_attributes
            ? "multipart/form-data"
            : "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      };
      const data = await axios.post(
        `${process.env.REACT_APP_API_URL_DEV}/event_managments`,
        formData,
        config
      );
      if (data) {
      }
    } catch (e) {
    }
  };

  useEffect(() => {
    setFormDataForPreview(formData)
    //saveData(formData)
  }, [formData]);

  const handleStep = (val: "step1" | "step2" | "step3" | "step4") => {
    setWizardStep(val);
  };

  useEffect(() => {
    if (search.get("eid")) {
      const requestOptions: ReqOptions = {
        url: `/event_managments/${search.get("eid")}`,
        method: "GET",
      };

      sendRequest(requestOptions);
    }
  }, [search]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box>
          <Box className="wizard_steps">
            <Grid container spacing={2}>
              {Object.keys(stepsData).map((step: any, i: number) => {
                return (
                  <Grid
                    item
                    xs={3}
                    key={i}
                    className={`${
                      ((!(
                        formData?.event_managment?.artists &&
                        formData?.event_managment?.artists?.length > 0
                      ) &&
                        i == 1 &&
                        formData?.event_managment?.title == "") ||
                        !formData?.event_managment) &&
                      "disableFormTab"
                    } 
                  ${
                    !formData?.event_managment?.contact_person_first_name &&
                    i == 3 &&
                    formData?.event_managment?.ticket_details == null &&
                    "disableFormTab"
                  } 
                  ${
                    !(
                      formData?.event_managment?.ticket_details &&
                      formData?.event_managment?.ticket_details?.length > 0
                    ) &&
                    i == 2 &&
                    formData?.event_managment?.artists?.length == 0 &&
                    "disableFormTab"
                  } 
                  ${
                    step === wizardstep ? "wizard_step_active" : ""
                  } wizard_step ${
                      Number(step.slice(-1)) < Number(wizardstep.slice(-1))
                        ? "wizard_done"
                        : ""
                    }`}
                  >
                    <Box
                      className="wizard_step_option"
                      onClick={() => handleStep(step)}
                    >
                      <h6 className="wizard_title">{stepsData[step].label}</h6>
                      <hr className="wizard_option_border" />
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          <EventContext.Provider value={{ formData, handleFormDataInput,formDataForPreview,updateDataForPreview }}>
            <Box>
              {wizardstep === "step1" && (
                <Step1
                  handleStep={handleStep}
                  handleOpenPreviewPopup={handleOpenPreviewPopup}
                />
              )}
            </Box>
            <Box>
              {wizardstep === "step2" && (
                <Step2
                  handleStep={handleStep}
                  handleOpenPreviewPopup={handleOpenPreviewPopup}
                />
              )}
            </Box>
            <Box>
              {wizardstep === "step3" && (
                <Step3
                  handleStep={handleStep}
                  handleOpenPreviewPopup={handleOpenPreviewPopup}
                />
              )}
            </Box>
            <Box>
              {wizardstep === "step4" && (
                <Step4 handleOpenPreviewPopup={handleOpenPreviewPopup} />
              )}
            </Box>
          </EventContext.Provider>
        </Box>
      )}
      <ModalBannerPopup
        heading="Preview Banner"
        handleClickOpen={handleOpenPreviewPopup}
        handleClose={handleClosePreviewPopup}
        open={previewBannerPopup}
        closeBtn={true}
        bannerData={formDataForPreview}
      />
    </>
  );
};

export default WizardSteps;
