import React, { useEffect, useState } from "react";
import Tables from "../../atoms/tables";
import TabBar from "../../atoms/tabs";
import { DataTypes } from "./types/dataTypes";
import NotFound from "../../molecules/notfound";
import Topbar from "../../molecules/topbar";
import useHttp from "../../../utils/hooks/useHttp";
import { ReqOptions } from "../../../utils/types/reqOptions";
import moment from "moment";
import { USERS_RECORDS_PER_PAGE, USERS_GET_URL } from "../../../utils/configs";
import Paginate from "../../atoms/pagination";
import { Box } from "@mui/material";
import AlertCmp from "../../atoms/alert";
import { ModalInputs } from "../membership/types/modalInputs";
import CheckImg from "./images/check.svg";
import Loader from "../../atoms/loader";
import {
  SEND_MESSAGE_MULTIPLE_ACTION,
  BLOCK_PROFILE_MULTIPLE_ACTION,
  DELETE_USER_MULTIPLE_ACTION,
  EXPORT_MULTIPLE_ACTION,
  DOTS_SEND_MESSAGE,
  DOTS_VIEW_PROFILE,
  DOTS_DELETE_USER,
  DOTS_BLOCK_PROFILE,
} from "../../../utils/configs";

const columns: any = {
  all: [
    { key: "username", label: "Full Name" },
    { key: "email", label: "Email" },
    { key: "user_type", label: "User Type" },
    { key: "user_category", label: "User Category" },
    { key: "blockedText", label: "Blocked" },
    { key: "mailing_list", label: "Mailing List" },
    { key: "action", label: "Actions" },
  ],
  guest: [
    { key: "username", label: "Full Name" },
    { key: "email", label: "Email" },
    // { key: "user_category", label: "User Category" },
    { key: "dateJoined", label: "Date Joined" },
    { key: "action", label: "Actions" },
  ],
  members: [
    { key: "username", label: "Full Name" },
    { key: "email", label: "Email" },
    // { key: "membership", label: "Membership" },
    { key: "user_category", label: "User Category" },
    { key: "start_date", label: "Start Date" },
    { key: "blockedText", label: "Blocked" },
    { key: "mailing_list", label: "Mailing List" },
    { key: "action", label: "Actions" },
  ],
  registered: [
    { key: "username", label: "Full Name" },
    { key: "user_category", label: "User Category" },
    { key: "dateJoined", label: "Date Registered" },
    { key: "action", label: "Actions" },
  ],
  mailing: [
    { key: "email", label: "Email" },
    { key: "dateJoined", label: "Date" },
    { key: "action", label: "Actions" },
  ],
  board: [
    { key: "member_name", label: "Name" },
    { key: "email", label: "Email" },
    { key: "division", label: "Division" },
    { key: "position", label: "Position" },
    { key: "action", label: "Actions" },
  ],
};

const tabs = [
  {
    value: "all",
    label: "All Users",
  },
  {
    value: "registered",
    label: "Registered Users",
  },
  {
    value: "members",
    label: "Members",
  },
  {
    value: "guest",
    label: "Guest User",
  },
  {
    value: "mailing",
    label: "Mailing List",
  },
  {
    value: "board",
    label: "Internal Committee",
  },
];

const inputs: ModalInputs[] = [
  {
    label: "Member Name",
    placeholder: "Enter member name",
    type: "text",
    name: "member_name",
    id: "member_name",
    grid: 1,
    errorMsg: "Please enter membership type",
  },
  {
    label: "Email",
    placeholder: "Enter member email",
    type: "email",
    name: "member_email",
    id: "member_name",
    grid: 1,
    errorMsg: "Please enter membership type",
  },
  {
    label: "Division",
    placeholder: "Enter division",
    type: "select",
    name: "division",
    id: "division",
    grid: 1,
  },
  {
    label: "Position",
    placeholder: "Enter their position",
    type: "text",
    name: "position",
    id: "position",
    grid: 1,
    errorMsg: "Please enter membership price",
  },
];

const UserManagement = () => {
  const { loading, error, sendRequest } = useHttp(transformData);
  const {
    loading: loadingBoard,
    error: errorBoard,
    sendRequest: sendRequestBoard,
  } = useHttp(transformDataBoard);
  const {
    loading: loadingMailing,
    error: errorMailing,
    sendRequest: sendRequestMailing,
  } = useHttp(transformDataMailing);
  const [search, setSearch] = useState(null);
  const [initialRender, setInitialRender] = useState(false);
  const [activeTab, setActiveTab] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(USERS_RECORDS_PER_PAGE);
  const [users, setUsers] = useState<any>([]);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [showMultipleActions, setShowMultipleActions] = useState(false);
  const [filterYearValue, setFilterYearValue] = useState(null);
  const [filterMembership, setFilterMembership] = useState(null);
  const [filterUser, setFilterUser] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedCompleteUser, setSelectedCompleteUser] = useState<any>([]);

  function transformData(data: any) {
    // console.log("activeTab", activeTab);
    let usersArr: any;
    if (data.status) {
      usersArr = data.users.map((user: any) => {
        const obj: any = {};
        obj.id = user?.id;
        obj.username = user?.username;
        obj.email = user?.email != null ? user?.email : "--";
        obj.mobile = user?.contact_number || "--";
        obj.dateJoined = user?.created_at
          ? moment(user?.created_at).format("MM/DD/YY")
          : "--";
        obj.membership = user?.membership || "--";
        obj.user_category =
          user?.role_name === "None of above" || user?.role_name == "Guest"
            ? "-"
            : user?.role_name;
        obj.user_type =
          user?.role_name === "Guest"
            ? user?.role_name
            : ((user?.membership_name !== null) || (user?.membership_ids !== null && user?.membership_ids.length > 0))
            ? "Member"
            : "Registered";

        obj.addresses = user?.addresses;
        obj.mailing_list = user?.mailing_list ? (
          <img src={CheckImg} alt="Not_found" />
        ) : (
          "-"
        );
        obj.action = ["dots"];
        const commonActions = [
          "send_message_user",
          "view_profile_user",
          !user.blocked ? "block_profile_user" : "unblock_profile_user",
          "delete_user_user",
        ];
        const specificActions =
          activeTab === "members" || activeTab === "all" ? ["edit_user"] : [];
        obj.dotActions = [...commonActions, ...specificActions];
        obj.blocked = user.blocked ? true : false;
        obj.blockedText = user?.blocked ? "Blocked" : "-";
        obj.membership_name = user?.membership_name;
        obj.membership_ids = user?.membership_ids;
        obj.no_of_free_passes = user?.no_of_free_passes;
        obj.start_date = user?.membership_taken
          ? moment(user?.membership_taken).format("MM/DD/YY")
          : "-";
        return obj;
      });

      data.total && setTotalRecords(data?.total);
      // console.log("one", usersArr);
      setUsers(usersArr);
    }
  }

  function transformDataMailing(data: any) {
    if (data.status) {
      const usersArr = data.mailing_lists.map((user: any) => {
        const obj: any = {};
        obj.id = user.id;
        obj.email = user.email || "--";
        obj.dateJoined = user.created_at
          ? moment(user.created_at).format("MM/DD/YY")
          : "--";
        obj.action = ["dots"];

        obj.dotActions = ["send_message_user", "delete_user_user"];

        return obj;
      });

      data.total && setTotalRecords(data.total);
      // console.log("two", usersArr);

      setUsers(usersArr);
    }
  }

  function transformDataBoard(data: any) {
    if (data.status) {
      const usersArr = data?.board_members.map((board: any) => {
        const obj: any = {};
        obj.id = board.id;
        obj.member_name = board.member_name;
        obj.email = board.email;
        obj.division = board.division;
        obj.position = board.position;
        obj.action = ["dots"];
        obj.board_memeber_image = board?.board_memeber_image;

        obj.dotActions = ["edit_user_user", "delete_user_user"];

        return obj;
      });

      data.total && setTotalRecords(data.total);
      // console.log("three", usersArr);

      setUsers(usersArr);
    }
  }

  useEffect(() => {
    const requestOptions: ReqOptions = {
      url: USERS_GET_URL,
      method: "GET",
      params: {
        tab: activeTab,
        page: currentPage,
        per_page: USERS_RECORDS_PER_PAGE,
      },
    };

    setInitialRender(true);
    sendRequest(requestOptions);
  }, []);

  useEffect(() => {
    if (filterYearValue !== null) {
      if (activeTab == "mailing") {
        const requestOptions: ReqOptions = {
          url: `/mailing_lists/get_mailing_lists`,
          method: "GET",
          params: {
            page: currentPage,
            per_page: USERS_RECORDS_PER_PAGE,
            year: filterYearValue,
          },
        };

        sendRequestMailing(requestOptions);
      } else if (activeTab === "board") {
        const reqOptions: ReqOptions = {
          url: "/board_memebers/get_board_memebers",
          method: "GET",
          params: {
            year: filterYearValue,
            page: 1,
            per_page: USERS_RECORDS_PER_PAGE,
            search: search,
          },
        };
        sendRequestBoard(reqOptions);
      } else {
        const requestOptions: ReqOptions = {
          url: `/users/get_user_list`,
          method: "GET",
          params: {
            year: filterYearValue,
            tab: activeTab,
            page: 1,
            per_page: USERS_RECORDS_PER_PAGE,
            search: search,
          },
        };

        sendRequest(requestOptions);
      }
    }
  }, [filterYearValue]);

  useEffect(() => {
    if (filterMembership !== null) {
      const requestOptions: ReqOptions = {
        url: `/users/get_user_list`,
        method: "GET",
        params: {
          membership_type: filterMembership,
          tab: activeTab,
          page: 1,
          per_page: USERS_RECORDS_PER_PAGE,
          search: search,
        },
      };

      sendRequest(requestOptions);
    }
  }, [filterMembership]);

  useEffect(() => {
    if (filterUser !== null) {
      const requestOptions: ReqOptions = {
        url: `/users/get_user_list`,
        method: "GET",
        params: {
          user_type: filterUser,
          tab: activeTab,
          page: 1,
          per_page: USERS_RECORDS_PER_PAGE,
          search: search,
        },
      };

      sendRequest(requestOptions);
    }
  }, [filterUser]);

  useEffect(() => {
    if (initialRender) {
      if (filterUser == null) {
        if (activeTab === "mailing") {
          const reqOptions: ReqOptions = {
            url: "/mailing_lists/get_mailing_lists",
            method: "GET",
            params: {
              page: currentPage,
              per_page: USERS_RECORDS_PER_PAGE,
              search: search,
            },
          };

          sendRequestMailing(reqOptions);
        } else if (activeTab === "board") {
          const reqOptions: ReqOptions = {
            url: "/board_memebers/get_board_memebers",
            method: "GET",
            params: {
              page: currentPage,
              per_page: USERS_RECORDS_PER_PAGE,
              search: search,
            },
          };

          sendRequestBoard(reqOptions);
        } else {
          const reqOptions: ReqOptions = {
            url: USERS_GET_URL,
            method: "GET",
            params: {
              tab: activeTab,
              page: currentPage,
              per_page: USERS_RECORDS_PER_PAGE,
              search: search,
            },
          };

          sendRequest(reqOptions);
        }
      } else {
        if (activeTab === "mailing") {
          const reqOptions: ReqOptions = {
            url: "/mailing_lists/get_mailing_lists",
            method: "GET",
            params: {
              page: currentPage,
              per_page: USERS_RECORDS_PER_PAGE,
              search: search,
              user_type: filterUser,
            },
          };

          sendRequestMailing(reqOptions);
        } else if (activeTab === "board") {
          const reqOptions: ReqOptions = {
            url: "/board_memebers/get_board_memebers",
            method: "GET",
            params: {
              page: currentPage,
              per_page: USERS_RECORDS_PER_PAGE,
              search: search,
              user_type: filterUser,
            },
          };

          sendRequestBoard(reqOptions);
        } else {
          const reqOptions: ReqOptions = {
            url: USERS_GET_URL,
            method: "GET",
            params: {
              tab: activeTab,
              page: currentPage,
              per_page: USERS_RECORDS_PER_PAGE,
              search: search,
              user_type: filterUser,
            },
          };

          sendRequest(reqOptions);
        }
      }
    }
  }, [currentPage, activeTab]);

  useEffect(() => {
    if (search !== null) {
      let searchUrl = USERS_GET_URL;

      if (activeTab === "mailing") {
        searchUrl = "/mailing_lists/get_mailing_lists";
        const timer = setTimeout(() => {
          const reqOptions: ReqOptions = {
            url: searchUrl,
            method: "GET",
            params: {
              page: currentPage,
              per_page: USERS_RECORDS_PER_PAGE,
              search: search,
            },
          };

          sendRequestMailing(reqOptions);
        }, 2000);
        return () => clearTimeout(timer);
      } else if (activeTab === "board") {
        searchUrl = "/board_memebers/get_board_memebers";
        const timer = setTimeout(() => {
          const reqOptions: ReqOptions = {
            url: searchUrl,
            method: "GET",
            params: {
              page: currentPage,
              per_page: USERS_RECORDS_PER_PAGE,
              search: search,
            },
          };

          sendRequestBoard(reqOptions);
        }, 2000);
        return () => clearTimeout(timer);
      }

      const timer = setTimeout(() => {
        const reqOptions: ReqOptions = {
          url: searchUrl,
          method: "GET",
          params: {
            tab: activeTab,
            page: currentPage,
            per_page: USERS_RECORDS_PER_PAGE,
            search: search,
          },
        };

        sendRequest(reqOptions);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [search]);

  useEffect(() => {
    if (showSuccessAlert) {
      const timer = setTimeout(() => {
        setShowSuccessAlert(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [showSuccessAlert]);

  const handleApiFetchAgain = () => {
    if (activeTab === "mailing") {
      const reqOptions: ReqOptions = {
        url: "/mailing_lists/get_mailing_lists",
        method: "GET",
        params: {
          page: currentPage,
          per_page: USERS_RECORDS_PER_PAGE,
          search: search,
        },
      };

      sendRequestMailing(reqOptions);
    } else if (activeTab === "board") {
      const reqOptions: ReqOptions = {
        url: "/board_memebers/get_board_memebers",
        method: "GET",
        params: {
          page: currentPage,
          per_page: USERS_RECORDS_PER_PAGE,
          search: search,
        },
      };

      sendRequestBoard(reqOptions);
    } else {
      const reqOptions: ReqOptions = {
        url: USERS_GET_URL,
        method: "GET",
        params: {
          tab: activeTab,
          page: currentPage,
          per_page: USERS_RECORDS_PER_PAGE,
        },
      };

      sendRequest(reqOptions);
    }
  };

  const handleTabClick = (
    val: "all" | "gold" | "platinum" | "silver" | "bronze" | "board"
  ) => {
    setActiveTab(val);
    setCurrentPage(1);
  };

  const handlePageChange = (e: any, page: number) => {
    setCurrentPage(page);
  };

  const handleSearchInput = (e: any) => {
    setSearch(e.target.value);
  };

  const handleSuccessCall = (msg: string) => {
    setShowSuccessAlert(true);
    setSuccessMsg(msg);
  };

  const handleYearFilter = (val: any) => {
    setFilterYearValue(val);
  };

  const handleMembershipFilter = (val: any) => {
    setFilterMembership(val);
  };

  const handleUserFilter = (val: any) => {
    setFilterUser(val);
  };

  const handleCheckSelected = (selected: boolean) => {
    setShowMultipleActions(selected);
  };

  const handleSelectedUsers = (user: any) => {
    const arr = [];
    setSelectedUsers(user);
    for (let i = 0; i < users.length; i++) {
      if (user.includes(users[i]?.id)) {
        arr.push(users[i]);
      }
    }
    setSelectedCompleteUser(arr);
  };

  // console.log("activeTab", activeTab);

  // console.log("users", users);

  return (
    <>
      {(loading || loadingBoard || loadingMailing) && <Loader />}
      {showSuccessAlert && (
        <AlertCmp type="success" position="dashboard">
          {successMsg}
        </AlertCmp>
      )}
      {error && (
        <AlertCmp type="error" position="dashboard">
          Something went wrong! Try again..
        </AlertCmp>
      )}
      <TabBar handleClick={handleTabClick} tabs={tabs} />
      <Topbar
        tab={activeTab}
        searchLabel="Search by name, email ID"
        handleSearchInput={handleSearchInput}
        filterBtn={true}
        totalRecords={true}
        totalRecordsCount={users.length > 0 ? totalRecords : 0}
        handleYearFilter={handleYearFilter}
        handleMembershipFilter={handleMembershipFilter}
        handleUserFilter={handleUserFilter}
        handleSuccessCall={handleSuccessCall}
        handleApiFetchAgain={handleApiFetchAgain}
        selectedRecords={selectedUsers}
        completeSelectedUser={selectedCompleteUser}
        membershipFilter={
          activeTab === "mailing" || activeTab === "board" ? false : true
        }
        userTypeFilter={
          activeTab === "mailing" || activeTab === "board" ? false : true
        }
        showMultipleActions={showMultipleActions}
        boardMemberApi={true}
        apiUrl="/board_memebers/create_board_memeber"
        method="post"
        mutipleOptions={
          activeTab === "mailing"
            ? [
                SEND_MESSAGE_MULTIPLE_ACTION,
                DELETE_USER_MULTIPLE_ACTION,
                EXPORT_MULTIPLE_ACTION,
              ]
            : activeTab === "board"
            ? [DELETE_USER_MULTIPLE_ACTION]
            : [
                SEND_MESSAGE_MULTIPLE_ACTION,
                BLOCK_PROFILE_MULTIPLE_ACTION,
                DELETE_USER_MULTIPLE_ACTION,
                EXPORT_MULTIPLE_ACTION,
              ]
        }
        showBtn={activeTab === "board" ? true : false}
        modal={{
          heading: "Add Board Members",
          inputs,
        }}
        showAddUser={
          activeTab === "members" || activeTab === "all" ? true : false
        }
      />
      {users.length ? (
        <Box
          sx={{
            marginTop: "15px",
          }}
        >
          <Tables
            showCheckbox={true}
            tab={activeTab}
            columns={columns[activeTab]}
            data={users}
            handleApiFetchAgain={handleApiFetchAgain}
            apiUrl={USERS_GET_URL}
            handleCheckSelected={handleCheckSelected}
            blockUrl={"/users/block_unblock_user"}
            mailingUrl={activeTab === "mailing" ? false : true}
            handleSuccessCall={handleSuccessCall}
            handleMultipleSelection={handleSelectedUsers}
            memberModal={{
              heading: "Edit Board Member",
              inputs,
            }}
            deleteModal={{
              heading: "Delete User?",
              content:
                "Are you sure you want to delete this user? This action is non-reversible and can not be undone.",
              primaryButton: "Delete user",
              secondaryButton: "Cancel",
            }}
          />
          <Paginate
            page={currentPage}
            totalRecords={totalRecords}
            recordsPerPage={USERS_RECORDS_PER_PAGE}
            handlePageChange={handlePageChange}
          />
        </Box>
      ) : (
        <>{!loading && <NotFound />}</>
      )}
    </>
  );
};

export default UserManagement;
