import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { PropType } from "./types/propTypes";
import {TextareaAutosize} from "@mui/base";
import InputAdornment from "@mui/material/InputAdornment";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export function Input({
  handleClick,
  inputProps,
  handleKeyPress,
  id = "",
  name,
  label,
  type,
  shrink = true,
  variant = "outlined",
  sx,
  maxRows = 1,
  placeholder,
  handleChange,
  disable,
  value,
  minValue = 0,
  required,
  inputRef,
  characterLimit
}: PropType) {
  const maxdate = inputProps?.max ? dayjs(inputProps?.max) : undefined
  const mindate = inputProps?.min ? inputProps?.min : undefined
  return (
    <>
      {type === "date" ? (
           <LocalizationProvider dateAdapter={AdapterDayjs}>
           <DemoContainer components={['DatePicker', 'DatePicker']}>
            
             <DatePicker value={dayjs(value)}
             maxDate={maxdate}
             minDate={mindate}
             //@ts-ignore
                    onChange={(e:any)=>handleChange(e,name)}  />
           </DemoContainer>
          
         </LocalizationProvider>
         
           
        //  <TextField
        //   id={id}
        //   name={name}
        //   // label={label}
        //   type={type}
        //   placeholder="mm/dd/yyyy"
        //   maxRows={maxRows}
        //   // InputLabelProps={{
        //   //     shrink: shrink,
        //   // }}
        //   // variant={variant}

        //   sx={sx}
        //   onChange={handleChange}
        //   // value={value}
        //   value={dayjs(value).format("YYYY-MM-DD")}
        //   inputProps={{ min: inputProps?.min || moment(new Date()).format("YYYY-MM-DD") }}

        // /> 
      ) : (
        <>
          {type === "number" ? (
            <TextField
              id={id}
              name={name}
              disabled={disable}
              // label={label}
              type={type}
              placeholder={placeholder}
              maxRows={maxRows}
              // InputLabelProps={{
              //     shrink: shrink,
              // }}
              // variant={variant}
              sx={sx}
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              value={value && Math.abs(Number(value))}
              onKeyDown={(e) => {
                if (e.keyCode === 189) {
                  e.preventDefault();
                }
              }}
              InputProps={{
                inputProps: {
                  min: minValue || 0,
                },
              }}
            />
          ) : (
            <>
              {type === "textarea" ? (
                <TextareaAutosize
                  id={id}
                  name={name}
                  // label={label}
                  placeholder={placeholder}
                  minRows={maxRows}
                  // InputLabelProps={{
                  //     shrink: shrink,
                  // }}
                  // variant={variant}
                  className="textarea_input"
                  onChange={handleChange}
                  value={value}
                  disabled={disable}
                />
              ) : (
                <>
                  {type === "currency" ? (
                    <TextField
                      id={id}
                      name={name}
                      disabled={disable}
                      // label={label}
                      type="number"
                      placeholder={placeholder}
                      maxRows={maxRows}
                      // InputLabelProps={{
                      //     shrink: shrink,
                      // }}
                      // variant={variant}
                      sx={sx}
                      onChange={handleChange}
                      onKeyPress={handleKeyPress}
                      value={value && Math.abs(Number(value))}
                      onKeyDown={(e) => {
                        if (e.keyCode === 189) {
                          e.preventDefault();
                        }
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <span
                              style={{
                                color: "#092532",
                                fontSize: "16px",
                              }}
                            >
                              $
                            </span>
                          </InputAdornment>
                        ),
                        inputProps: {
                          min: 0,
                        },
                      }}
                    />
                  ) : (
                    <TextField
                      InputProps={inputProps}
                      id={id}
                      name={name}
                      // label={label}
                      type={type}
                      placeholder={placeholder}
                      maxRows={maxRows}
                      // InputLabelProps={{
                      //     shrink: shrink,
                      // }}
                      // variant={variant}
                      sx={sx}
                      onChange={handleChange}
                      value={value}
                      onClick={handleClick}
                      onKeyDown={handleKeyPress}
                      disabled={disable}
                      required={required}
                      inputRef={inputRef}
                      inputProps={{
                        maxLength: characterLimit ? characterLimit : '',
                      }}
                    />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
