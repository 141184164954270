import React from "react";
import WebLayout from "../../organism/webLayout";
import AllEvents from "../../templates/allEvents";
import BuyCDDetail from "../../templates/buyCDDetail";

const BuyCDDetailPage = () => {
  return <BuyCDDetail />;
};

export default BuyCDDetailPage;
