import React, { useEffect, useContext, useRef, useState } from "react";
import Layout from "../../organism/layout";
import InputsWithLabel from "../../molecules/inputsWithLabel";
import { Box, Grid } from "@mui/material";
import { Button } from "../../atoms/buttons";
import TextLabels from "../../atoms/textLabels";
import { Input } from "../../atoms/inputs";
import ModalPopup from "../../atoms/modalPopup";
import { Link, useNavigate } from "react-router-dom";
import useHttp from "../../../utils/hooks/useHttp";
import { ReqOptions } from "../../../utils/types/reqOptions";
import Loader from "../../atoms/loader";
import AlertCmp from "../../atoms/alert";
import { EventContext } from "../../organism/wizardSteps";
import { PropType } from "./types/propTypes";
import { styled } from "@mui/system";
import ErrorMessage from "../../atoms/errorMessage";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Paid } from "@mui/icons-material";

const Step3 = ({ handleStep, handleOpenPreviewPopup, }: PropType) => {
  const { loading, error, sendRequest } = useHttp(transformData);
  const [responseMsg, setResponseMsg] = useState("");
  const navigate = useNavigate();
  const [responseType, setResponseType] = useState<boolean>(false);
  const { formData, handleFormDataInput, updateDataForPreview } = useContext(EventContext);
  const draftRef = useRef(false);

  // console.log("xxxxxx", formData);
  const [eventId, setEventId] = useState(
    formData?.event_managment?.id ? formData?.event_managment?.id : null
  );

  const [openBlock, setOpenBlock] = useState(false);
  const [isImageDisabled, setIsImageDisabled] = useState(true);
  const [count, setCount] = useState(2);
  const [disableEditColumnFields, setDisableEditColumnFields] = useState(true);
  const [touched, setTouched] = useState([false]);
  const ticketCols = [
    {
      id: 0,
      title: "Price for Members, Students, Senior Citizens *",
      inp1_placeholder: "Enter pre-booking rate here",
      advance_value: "",
      inp2_placeholder: "Enter door rate here",
      door_value: "",
    },
    {
      id: 1,
      title: "Price for Non Members *",
      inp1_placeholder: "Enter pre-booking rate here",
      advance_value: "",
      inp2_placeholder: "Enter door rate here",
      door_value: "",
    },
  ];
  const [arr, setArr] = useState(
    formData?.event_managment?.ticket_details?.length > 0
      ? formData?.event_managment?.ticket_details
      : ticketCols
  );
  const [inputArr, setInputArr] = useState<any[]>(
    formData?.event_managment?.ticket_details?.length > 0
      ? formData?.event_managment?.ticket_details
      : ticketCols
  );

  function transformData(data: any) {
    // console.log({ data });
    if (data?.events?.id) {
      !eventId && setEventId(data?.events?.id);
      handleFormDataInput({ event_managment: data?.events });
    }
    if (data?.status) {
      setResponseMsg("Data saved successfully.");
      setResponseType(true);
      if (draftRef.current) {
        setTimeout(() => {
          navigate("/event-management");
        }, 1500);
      } else {
        setTimeout(() => {
          handleStep && handleStep("step4");
        }, 1500);
      }
    }
  }

  const handleClickOpenBlock = () => {
    setOpenBlock(true);
    setIsImageDisabled(false);
  };

  // const handleNextClick = () => {
  //   setIsImageDisabled(false);
  // };

  const handleCloseBlock = () => {
    setOpenBlock(false);
    setInputArr(arr);
    let setModalMsg: any = localStorage.getItem("setModalMsg")
    if (setModalMsg == "1") {
      toast.success("Column edit Successfully")
    }
  };

  const checkIsValid = () => {
    if (inputArr.length < 1) return false;
    return inputArr.every((input) => {
      return !!input.title?.trim()
    })
  }

  const addAnotherColumn = () => {
    const newArr = inputArr
    newArr.push({
      id: count + 1,
      title: "",
      inp1_placeholder: "Enter pre-booking rate here",
      advance_value: "",
      inp2_placeholder: "Enter door rate here",
      door_value: "",
    })
    setInputArr(newArr);
    // inputArr
    // arr.concat({
    //   id: count,
    //   title: "",
    //   inp1_placeholder: "Enter pre-booking rate here",
    //   advance_value: "",
    //   inp2_placeholder: "Enter door rate here",
    //   door_value: "",
    // })
    // );
    setCount(count + 1);
  };
  const removeColumn = (id: any) => {
    const newArr = inputArr.filter((i: any) => i.id != id)
    setInputArr(inputArr.filter((i: any) => i.id != id));
    setCount(newArr.length)
  };
  const handleInput = (val: string, id: any) => {
    // setArr((prevArr)=>prevArr.map((i:any)=>i.id==id ? {...i,value:val}:i))
    let newArr = JSON.parse(JSON.stringify(inputArr));
    const editArr = newArr.filter((i: any) => i.id == id);

    if (editArr?.length > 0) {
      editArr[0].title = val;
      // console.log({ arr });
    }
    setInputArr(newArr);
  };

  // useEffect(() => {
  //   if (formData?.event_managment?.ticket_details)
  //     setDisableEditColumnFields(false);
  // }, [formData.event_managment.ticket_details]);

  useEffect(() => {
    updateDataForPreview({ ticket_details: arr });
  }, [arr]);

  const usePrevious = (value: any) => {
    const ref = useRef();

    useEffect(() => {
      ref.current = value;
    }, [value]);

    return ref.current;
  };

  const prevItems: any = usePrevious(arr);

  useEffect(() => {
  }, [arr]);
  const gridSize = 12 / arr?.length;

  const handleStep3Submit = (e: any, draft: boolean) => {
    setResponseMsg("");
    setResponseType(false);
    e.preventDefault();
    const data: any = {
      event_managment: {
        // ticket_details: arr,
        ticket_details: arr.map((ticket: any) => ({
          ...ticket,
          advance_value: paymentType === 'free' ? 0 : ticket.advance_value,
          door_value: paymentType === 'free' ? 0 : ticket.door_value,
          
        })),
        draft: formData?.event_managment?.draft != null ? formData?.event_managment?.draft : true,
        sub_type: paymentType,
      },

    };
  
    if (eventId) {
      data.event_managment.id = eventId;
    }
    const requestOptions: ReqOptions = {
      url: eventId ? `/event_managments/${eventId}` : `/event_managments`,
      method: eventId ? "PUT" : "POST",
      data: data,
    };
    draftRef.current = draft;
    sendRequest(requestOptions);
  };

  const handlePriceField = (e: any, id: string, field: string) => {
    let newArr = JSON.parse(JSON.stringify(arr));
    const editArr: any = newArr.filter((i: any) => i.id === id);
    if (editArr?.length > 0) {
      editArr[0][field] = e.target.value;
    }
    setArr(newArr);
  };

  const handleNameKey = (event: any) => {
    const isNumericKey = event.keyCode >= 48 && event.keyCode <= 57;
    if (isNumericKey) {
      event.preventDefault();
    }
  };

  const DisabledImage = styled("img")({
    pointerEvents: "none",
    opacity: 1,
  });

  useEffect(() => {
    if (error) {
      setResponseMsg("Something went wrong! Try again..");
      setResponseType(false);
    }
  }, [error]);

  const [paymentType, setPaymentType] = useState<any>(
    formData?.event_managment?.sub_type ? formData?.event_managment?.sub_type : "paid"
  )

  const handleEventTypeChange = (event: any) => {
    setPaymentType(event.target.value);
  };
  const labelColor: any = '2C2D30'


  return (
    <>
      <ToastContainer />
      {loading && <Loader />}
      {responseMsg && (
        <AlertCmp
          type={responseType ? "success" : "error"}
          position="dashboard"
        >
          {responseMsg}
        </AlertCmp>
      )}

      <div style={{ marginTop: '20px', marginBottom: '10px' }}>
        <TextLabels
          variant="h5"
          color={`${labelColor || "#2C2D30"}`}
          weight={700}
        >
          {`Select type of Event *`}
        </TextLabels>
        <div style={{ marginTop: "10px", }}>
          <label style={{
            fontSize: '14px',
            fontWeight: '400'
          }}>
            <input
              style={{ borderColor: '#913713' }}
              type="radio"
              value="free"
              checked={paymentType === 'free'}
              onChange={handleEventTypeChange}
            />
            Free for all
          </label>
          <label style={{
            fontSize: '14px',
            fontWeight: '400',
            marginLeft: '70px'
          }}>
            <input
              type="radio"
              value="paid"
              checked={paymentType === 'paid'}
              onChange={handleEventTypeChange}
            />
            Has charges per user type
          </label>
        </div>
      </div>
      <Box>
        <Grid container>
          <Grid item xs={4} sm={3} md={2}>
            <Box>
              <TextLabels
                fontFamily="Poppins"
                variant="h100"
                color="#1A1A1A"
                weight={600}
              >
                Ticket Type
              </TextLabels>
            </Box>
            <Box sx={{ paddingTop: "15px" }}>
              <Button
                sx={{
                  border: "2px solid #913713",
                  backgroundColor: "#FFFFFF",
                  height: "42px",
                  width: "100px",
                  padding: "12px 20px",
                  color: "#913713",
                  borderRadius: "12px",
                  "&:hover": {
                    backgroundColor: "#FFFFFF",
                    color: "#913713",
                  },
                }}
                color="primary"
                size="s"
              >
                ADVANCE
              </Button>
            </Box>
            <Box sx={{ paddingTop: "25px" }}>
              <Button
                sx={{
                  border: "2px solid #913713",
                  backgroundColor: "#FFFFFF",
                  height: "42px",
                  width: "100px",
                  padding: "12px 20px",
                  color: "#913713",
                  borderRadius: "12px",
                  "&:hover": {
                    backgroundColor: "#FFFFFF",
                    color: "#913713",
                  },
                }}
                color="primary"
                size="s"
              >
                DOOR
              </Button>
            </Box>
          </Grid>
          <Grid item xs={8} sm={9} md={10}>
            <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
              <Grid container>
                {arr &&
                  arr.length > 0 &&
                  arr.map((item: any) => {
                    return (
                      <>
                        {item?.title && (
                          <Grid item xs={gridSize}>
                            <Box>
                              <TextLabels
                                fontFamily="Poppins"
                                variant="h100"
                                color="#1A1A1A"
                                weight={600}
                              >
                                {item?.title}
                              </TextLabels>
                            </Box>
                            <Box sx={{ padding: "15px 10px 10px 10px" }}>
                              <Input
                                sx={{ width: "100%" }}
                                placeholder={item?.inp1_placeholder}
                                disable={paymentType == 'free' ? true : false}
                                type="currency"
                                handleChange={(e) =>
                                  handlePriceField(e, item?.id, "advance_value")
                                }
                                value={paymentType == 'free' ? 0 : item?.advance_value}
                              />
                            </Box>
                            <Box sx={{ padding: "10px 10px" }}>
                              <Input
                                sx={{ width: "100%" }}
                                placeholder={item?.inp2_placeholder}
                                disable={paymentType == 'free' ? true : false}
                                type="currency"
                                handleChange={(e) =>
                                  handlePriceField(e, item?.id, "door_value")
                                }
                                value={ paymentType == 'free' ? 0 : item?.door_value }
                              />
                              {item.door_value != "" && item.advance_value != "" && parseInt(item.door_value) < parseInt(item.advance_value) && <ErrorMessage>
                                Door amount must greater than or equal to advance amount
                              </ErrorMessage>}
                            </Box>
                          </Grid>
                        )}
                      </>
                    );
                  })}
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
        {/* <Button
          sx={{
            backgroundColor: "white",
            color: "black",
            padding: "0px !important",
            fontSize: "14px",
            "&:hover": {
              backgroundColor: "white",
              color: "#black",
            },
          }}
          type="button"
          handleClick={() => setOpenBlock(true)}
          btnClass="loginSubmitBtn"
          color="error"
          variant="contained"
          icon="icons/ticket-edit.png"
          disable={disableEditColumnFields}
        >
          Edit column fields
        </Button> */}

        <DisabledImage
          src="icons/ticket-edit.png"
          sx={{
            height: "16px",
            width: "16px",
            marginTop: "7px",
            display: isImageDisabled ? "block" : "none",
          }}
          alt=""
        />
        <Button
          sx={{
            backgroundColor: "#ffffff",
            border: "none !important",
            padding: "0px !important",
            marginLeft: "8px",
            fontSize: "14px",
            "&:hover": {
              border: "none !important",
            },
          }}
          type="button"
          variant="outlined"
          handleClick={() => {
            setInputArr(arr);
            setOpenBlock(true)
          }}
        // icon="icons/ticket-edit.png"
        // disable={disableEditColumnFields}
        // disable={
        //   arr.filter(
        //     (i: any) => i.advance_value == "" || i.door_value == ""
        //   )?.length > 0
        // }
        >
          Edit column fields
        </Button>
      </Box>
      <ModalPopup
        open={openBlock}
        handleClickOpen={handleClickOpenBlock}
        handleClose={() => {
          localStorage.removeItem("setModalMsg")
          handleCloseBlock()

        }}
        heading="Edit Fields"
        closeBtn={true}
        primaryAction={() => { }}
        content={
          <>
            {inputArr &&
              inputArr.length > 0 &&
              inputArr.map((item: any) => {
                return (
                  <>
                    <Box>
                      <Box
                        className="removeColumn"
                        sx={{
                          position: "relative",
                          margin: "10px 0px",
                        }}
                      >
                        <TextLabels variant="h100" color="#221714" weight={700}>
                          Column Heading *
                        </TextLabels>

                        <Button
                          sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            backgroundColor: "#FFFFFF",
                            color: "#913713",
                            padding: "0px !important",
                            "&:hover": {
                              backgroundColor: "#FFFFFF",
                              color: "#913713",
                            },
                          }}
                          type="button"
                          handleClick={() => removeColumn(item?.id)}
                          btnClass="loginSubmitBtn"
                          color="error"
                          variant="contained"
                          icon="icons/remove-column.png"
                        >
                          Remove Column
                        </Button>
                      </Box>
                      <Box>
                        <Input
                          sx={{ width: "100%" }}
                          value={item?.title}
                          handleKeyPress={handleNameKey}
                          handleChange={(e: any) =>
                            handleInput(e.target.value, item?.id)
                          }
                          type="text"
                          placeholder="Enter column heading here"
                        />
                      </Box>
                    </Box>
                  </>
                );
              })}
            {inputArr.length < 4 && (
              <Box sx={{ padding: "20px 0" }}>
                <Button
                  sx={{
                    backgroundColor: "#FFFFFF",
                    color: "#913713",
                    padding: "0px !important",
                    fontSize: "14px",
                    "&:hover": {
                      backgroundColor: "#FFFFFF",
                      color: "#913713",
                    },
                  }}
                  type="button"
                  handleClick={() => addAnotherColumn()}
                  btnClass="loginSubmitBtn"
                  color="error"
                  variant="contained"
                  icon="icons/add.png"
                >
                  Add another column
                </Button>
              </Box>
            )}
            <Box sx={{ margin: "25px 0", textAlign: "center" }}>
              <Button
                sx={{
                  backgroundColor: "#913713",
                  "&:hover": {
                    backgroundColor: "#913713",
                  },
                }}
                type="button"
                disable={!checkIsValid()}
                handleClick={() => {
                  setArr(inputArr);
                  localStorage.setItem("setModalMsg", "1")
                  handleCloseBlock();
                }}
                btnClass="loginSubmitBtn"
                color="error"
                variant="contained"
              >
                Save Changes
              </Button>
            </Box>
          </>
        }
      />

      <Box sx={{ paddingBottom: "30px" }}>
        <Box className="step_button_container">
          <Grid container>
            <Grid item xs={6}>
              <Button
                sx={{
                  border: "1px solid #913713",
                  color: "#913713",
                  height: "44px",
                  padding: "0px",
                  "&:hover": {
                    border: "1px solid #913713",
                    backgroundColor: "#FFFFFF",
                    color: "#913713",
                  },
                }}
                variant="outlined"
                color="primary"
                size="s"
                handleClick={handleOpenPreviewPopup}
              >
                Preview Banner
              </Button>
            </Grid>
            <Grid item xs={6} className="step_confirm_button">
              <Button
                btnClass="draft_btn"
                variant="outlined"
                color="primary"
                size="s"
                sx={{
                  marginRight: "30px",
                  border: "1px solid #913713",
                  color: "#913713",
                  height: "44px",
                  padding: "0px",
                  "&:hover": {
                    border: "1px solid #913713",
                    backgroundColor: "#FFFFFF",
                    color: "##913713",
                  },
                }}
                handleClick={(e) => handleStep3Submit(e, true)}
              >
                Save as Draft
              </Button>
              <Button
                disable={
                  paymentType == 'paid' ?
                  arr.filter(
                    (i: any) => (i.advance_value == "" || i.door_value == "" || parseInt(i.door_value) < parseInt(i.advance_value))
                  )?.length > 0 : false
                }
                sx={{
                  backgroundColor: "#913713",
                  height: "44px",
                  padding: "0px",
                  "&:hover": {
                    backgroundColor: "#913713",
                    color: "#FFFFFF",
                  },
                }}
                handleClick={(e) => handleStep3Submit(e, false)}
                color="primary"
                size="s"
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Step3;
