import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import NoMember from "../../molecules/nomember";
import TickImg from "../../templates/buyCDStep2/images/tick.svg";
import CancelImg from "../../templates/buyCDStep2/images/cancel.svg";
import useUserEvents from "../../templates/buyCDStep2/useUserEvents";
import Loader from "../loader";
import TextLabels from "../textLabels";
import Locationimg from "./images/location.svg";
import TicketImg from "./images/ticket.svg";
import TimeImg from "./images/time.svg";
import "./style.css";
import EventCardWebsite from "../../organism/eventCardWebsite";

const UserEvents = (props: { userId: string }) => {
  const { userId } = props;
  const { loading, events } = useUserEvents(userId, true);

  const renderAttendeeInfo = (event: any) => {
    if (event.admitStatus === "admits") {
      return (
        <Box className="modal_popup_event_ticket">
          <img src={TicketImg} />
          <Box className="modal_popup_event_ticket_details">
            <Box
              sx={{
                marginBottom: "5px"
              }}
            >
              <TextLabels variant="h200" weight={400} color="#221714">
                Admits {event.admitNames?.length || ""}
              </TextLabels>
            </Box>
            <Box sx={{ marginBottom: "5px" }}>
              {(event.ticketCount > 0) && (
                <TextLabels variant="h100" weight={400} color="#221714">
                  ({ `${event.ticketCount} ticket`})
                </TextLabels>
              )}
            </Box>
            <Box>
              <TextLabels variant="h200" weight={400} color="#7E8085">
                {event.admitNames?.map((name: string, i: number) => {
                  return (
                    <span key={i}>
                      {name} {i === event.admitNames?.length - 1 ? "" : " ,"}
                    </span>
                  );
                })}
              </TextLabels>
            </Box>
          </Box>
        </Box>
      );
    }
    if (event.admitStatus === "admitted") {
      return (
        <Box className="modal_popup_event_ticket">
          <img src={TickImg} />
          <Box className="modal_popup_event_ticket_details">
            <Box
              sx={{
                marginBottom: "5px"
              }}
            >
              <TextLabels variant="h200" weight={400} color="#221714">
                Admitted {event.admitNames?.length || ""}
              </TextLabels>
            </Box>
            <Box sx={{ marginBottom: "5px" }}>
              {(event.ticketCount  > 0) && (
                <TextLabels variant="h100" weight={400} color="#221714">
                  ({ `${event.ticketCount} ticket`})
                </TextLabels>
              )}
            </Box>
            <Box>
              <TextLabels variant="h200" weight={400} color="#7E8085">
                {event.admitNames?.map((name: string, i: number) => {
                  return (
                    <span key={i}>
                      {name} {i === event.admitNames?.length - 1 ? "" : " ,"}
                    </span>
                  );
                })}
              </TextLabels>
            </Box>
          </Box>
        </Box>
      );
    }

    return (
      <Box className="modal_popup_event_ticket">
        <img src={CancelImg} />
        <Box className="modal_popup_event_ticket_details">
          <Box
            sx={{
              marginBottom: "5px"
            }}
          >
            <TextLabels variant="h200" weight={400} color="#221714">
              Event Cancelled
            </TextLabels>
          </Box>
          <Box>
            <TextLabels variant="h200" weight={400} color="#7E8085">
              {(event.ticketCount > 0) && (
                <span style={{ textDecoration: "line-through" }}>
                  ({`${event.ticketCount} ticket`})
                </span>
              )}
              <br />
              <span>*Refund will be credited to source account.</span>
            </TextLabels>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <>
      {loading && <Loader />}

      {events?.length > 0 ? (
        events.map((event: any, i: number) => {
          return (
            <Box className="modal_popup_event_card" key={i}>
              <Grid container>
                <Grid item xs={12}>
                  <Box sx={{ marginBottom: "15px" }}>
                    <TextLabels variant="h200" color="#221714" weight={500}>
                      {event.title}
                    </TextLabels>
                  </Box>
                </Grid>
                {/* <Grid item xs={6}>
                  <EventCardWebsite hideViewMore data={event} previewBanner />
                </Grid> */}
                <Grid item xs={6} className="modal_popup_event_details" sx={{paddingX:0}}>
                  {renderAttendeeInfo(event)}
                  <Box className="modal_popup_event_location_details">
                    <img src={Locationimg} />
                    <Box className="modal_popup_event_location_text">
                      <TextLabels variant="h100" weight={400} color="#7E8085">
                        {event.location}
                      </TextLabels>
                    </Box>
                  </Box>
                  <Box className="modal_popup_event_location_details">
                    <img src={TimeImg} />
                    <Box className="modal_popup_event_location_text">
                      <TextLabels variant="h100" weight={400} color="#7E8085">
                        {event.time}
                      </TextLabels>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          );
        })
      ) : (
        <NoMember
          heading="No Bookings"
          content={`User hasn't book any event yet.`}
        />
      )}
    </>
  );
};

export default UserEvents;
