import React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import { Box } from "@mui/material";
import { PropType } from "./types/propType";
import CloseImg from "./images/close.svg";
import "./style.css";
import zIndex from "@mui/material/styles/zIndex";

const WebDialog = ({ children, open, handleClose }: PropType) => {
  return (
    <Dialog open={open} onClose={handleClose} className="login_dialog_website">
      <Box className="web_dialog_close_image" >
        <img src={CloseImg} onClick={handleClose} alt="" style={{zIndex:'1000'}} />
      </Box>
      <Box className="login_dialog_content_box">{children}</Box>
    </Dialog>
  );
};

export default WebDialog;
