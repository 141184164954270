import React, { useState, useEffect } from "react";
import WebDialog from "../../molecules/webDialog";
import {
  DialogContent,
  DialogContentText,
  Box,
  Grid,
  DialogActions,
} from "@mui/material";
import InputsWithLabel from "../../molecules/inputsWithLabel";
import SectionHeading from "../../molecules/sectionHeading";
import { Button } from "../../atoms/buttons";
import { Link } from "react-router-dom";
import { PropType } from "./types/propType";
import { Input } from "../../atoms/inputs";
import TextLabels from "../../atoms/textLabels";
import useHttp from "../../../utils/hooks/useHttp";
import OtpInput from "react-otp-input";
import Loader from "../../atoms/loader";
import ErrorImg from "./images/error.svg";
import AlertCmp from "../../atoms/alert";
import "./style.css";

const WebOtpDialog = ({
  open,
  handleClose,
  handleOpenDialog,
  handleAlertBox,
  handlePasswordOpen,
  handleAlertErrorBox,
}: PropType) => {
  const [otp, setOtp] = useState("");
  const [otpValidation, setOtpValidation] = useState(true);
  const [disableButton, setDisableButton] = useState(true);
  const { loading, error, sendRequestWithoutToken } = useHttp(transformData);

  useEffect(() => {
    let disableFlag = false;
    if (otp.length !== 4) {
      disableFlag = true;
    }

    setDisableButton(disableFlag);
  }, [otp]);

  useEffect(() => {
    if (!open) {
      setOtp("");
    }
  }, [open]);

  function transformData(data: any) {
    if (data.status) {
      if (data.message !== "Resend otp to user email!!") {
        handleAlertBox(data.message);
        handlePasswordOpen();
      } else {
        handleAlertBox(data.message);
      }
    } else {
      handleAlertErrorBox(data.message);
    }
  }

  const handleOtpVerify = (e: any) => {
    e.preventDefault();
    if (!otp || otp.length !== 4) {
      setOtpValidation(false);
    } else {
      const user = JSON.parse(localStorage.getItem("user_register") as any);

      const dataObj = {
        guest: {
          email_otp: otp,
          id: user.id,
        },
      };

      const reqOptions = {
        url: `/verify_otp`,
        method: "POST",
        data: dataObj,
      };

      sendRequestWithoutToken(reqOptions);
    }
  };

  const handleOtpInput = (val: any) => {
    var reg = /^-?\d*\.?\d*$/;
    const res= reg.test(val); 
    if(res){
      setOtp(val);
      setOtpValidation(true);
    }

  };

  const handleResendOTP = () => {
    const user = JSON.parse(localStorage.getItem("user_register") as any);
    const dataObj = {
      guest: {
        email: user.email,
      },
    };

    const reqOptions = {
      url: `/resend_otp`,
      method: "POST",
      data: dataObj,
    };

    sendRequestWithoutToken(reqOptions);
  };

  useEffect(() => {
    if (error) {
      handleAlertErrorBox(error);
    }
  }, [error]);

  return (
    <>
      {loading && <Loader />}
      <WebDialog
        title="Welcome !"
        open={open}
        handleClose={handleClose}
        primaryButtonLabel="Sign In"
        subTitle="Sign in to continue"
      >
        <form onSubmit={handleOtpVerify}>
          <DialogContent>
            <DialogContentText>
              <SectionHeading title="Welcome !"  />
              <p className="login_dialog_subtitle">
                Enter otp sent to your entered email
              </p>
            </DialogContentText>
            <Box
              sx={{
                marginTop: "45px",
              }}
            />
            <Box>
              <TextLabels variant="h5" color="#FDFCFC" weight={700}>
                Enter OTP
              </TextLabels>
              <Grid container spacing={6}>
                <Grid item xs={12} className="web_otp_dialog_grid_padding">
                  <OtpInput
                    value={otp}
                    onChange={handleOtpInput}
                    numInputs={4}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => (
                      <input  {...props} placeholder="*" />
                    )}
                    containerStyle={{
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                    inputStyle={{
                      width: "15%",
                      height: "40px",
                      textAlign: "center",
                      borderRadius: "4px",
                      border: "none",
                      fontFamily: "Inter",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  />
                </Grid>
              </Grid>
              {!otpValidation && (
                <p className="web_register_error_label">
                  <img src={ErrorImg} />
                  <span>* Please enter valid OTP</span>
                </p>
              )}
            </Box>
            <Box className="web_otp_dialog_resend_box">
              <p onClick={handleResendOTP} style={{ cursor: "pointer" }}>
                Resend
              </p>
            </Box>
          </DialogContent>
          <DialogActions className="login_dialog_actions">
            <Button
              variant="outlined"
              color="info"
              size="s"
              type="submit"
              disable={disableButton}
            >
              Verify
            </Button>
          </DialogActions>
        </form>
      </WebDialog>
    </>
  );
};

export default WebOtpDialog;
