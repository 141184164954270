import './index.css'
import TopArrow from './image/TopArrow.svg'

function GoToTop() {

  const handleTotop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Add smooth scrolling behavior
    });
  }

  return (
    <>
      <div onClick={handleTotop} className="gototop_mainDiv">
        <img src={TopArrow} alt="" />
      </div>
    </>
  )
}

export default GoToTop