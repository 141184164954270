import SearchInput from "../../atoms/searchInput";
import { Button } from "../../atoms/buttons";
import { Box } from "@mui/material";
import PlusImg from "./images/plus.svg";
import SortImg from "./images/sort.svg";
import { PropType } from "./types/propType";
import ModalFormPopup from "../../atoms/modalFormPopup";
import FilterImg from "./images/filter_new.svg";
import TextLabels from "../../atoms/textLabels";
import React, { useEffect, useRef, useState } from "react";
import useOutsideAlerter from "../../../utils/hooks/useOutsideAlerter";
import { useNavigate, useLocation } from "react-router-dom";
import CloseImg from "./images/close.svg";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import DownloadImg from "./images/download.svg";
import { MEMBERSHIP_GET_URL } from "../../../utils/configs";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  SEND_MESSAGE_MULTIPLE_ACTION,
  DELETE_USER_MULTIPLE_ACTION,
  BLOCK_PROFILE_MULTIPLE_ACTION,
  EXPORT_MULTIPLE_ACTION,
} from "../../../utils/configs";
import Loader from "../../atoms/loader";
import ModalPopup from "../../atoms/modalPopup";
import ModalMessagePopup from "../../atoms/modalMessagePopup";
import useHttp from "../../../utils/hooks/useHttp";
import "./style.css";

const multipleButtonProperties = {
  height: "42px",
  fontFamily: "Poppins",
  fontWeight: "600",
  fontSize: "12px",
};

const exportbuttonProperties = {
  height: "42px",
  fontFamily: "Poppins",
  fontWeight: "600",
  fontSize: "12px",
  width: "42px",
  paddingLeft: "36px",
};
const dowwnloadBtn = {
  marginLeft: "16px",
  marginRight: "-14px",
};
const Topbar = ({
  showBtn,
  searchLabel,
  modal,
  tab,
  sortBtn,
  filterBtn,
  sortOptions,
  apiUrl,
  method,
  totalRecords,
  handleApiFetchAgain,
  handleSuccessCall,
  handleSearchInput,
  handleCreateBtn,
  handleYearFilter,
  handleMembershipFilter,
  handleUserFilter,
  totalRecordsCount,
  pageName,
  showMultipleActions,
  mutipleOptions,
  confirmBtn,
  membershipFilter,
  userTypeFilter,
  boardMemberApi,
  selectedRecords,
  showButton,
  completeSelectedUser,
  showAddUser,
}: PropType) => {
  const [open, setOpen] = React.useState(false);
  const [toggleSortMenu, setToggleSortMenu] = useState(false);
  const [toggleFiltermenu, setToggleFilterMenu] = useState(false);
  const [openBlock, setOpenBlock] = React.useState(false);
  const [openUnblock, setOpenUnblock] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openMessage, setOpenMessage] = React.useState(false);
  const [modalMessage, setModalMessage] = React.useState("");
  const [filterYear, setFilterYear] = React.useState("");
  const [filterMembership, setFilterMembership] = React.useState("");
  const [membershipList, setMembershipList] = React.useState<any>(null);
  const [filterUser, setFilterUser] = React.useState("");
  const [openMembershipDelete, setOpenMembershipDelete] = useState<any>(false);
  const [openMembershipDisable, setOpenMembershipDisable] =
    useState<any>(false);
  const {
    loading: loadingDelete,
    error: errorDelete,
    sendRequest: sendRequestDelete,
  } = useHttp(transformDataDelete);
  const {
    loading: loadingBlock,
    error: errorBlock,
    sendRequest: sendRequestBlock,
  } = useHttp(transformDataBlock);
  const {
    loading: loadingMessage,
    error: errorMessage,
    sendRequest: sendRequestMessage,
  } = useHttp(transformDataMessage);
  const {
    loading: loadingDownload,
    error: errorDownload,
    sendRequest: sendRequestDownload,
  } = useHttp(transformDataDownload);
  const {
    loading: loadingmembership,
    error: errorMembership,
    sendRequest: sendRequestMembership,
  } = useHttp(transformDataMembership);
  const handleYearChange = (event: SelectChangeEvent) => {
    setFilterYear(event.target.value);
  };

  const handleMembershipChange = (event: SelectChangeEvent) => {
    setFilterMembership(event.target.value);
  };

  const handleUserChange = (event: SelectChangeEvent) => {
    setFilterUser(event.target.value);
  };

  function transformDataDelete(data: any) {
    if (data.status) {
      if (handleSuccessCall) {
        handleSuccessCall(data.message);
      }
      handleCloseDelete();
      if (handleApiFetchAgain) {
        handleApiFetchAgain();
      }
    }
  }

  function transformDataBlock(data: any) {
    if (data.status) {
      if (handleSuccessCall) {
        handleSuccessCall(data.message);
      }
      handleCloseBlock();
      if (handleApiFetchAgain) {
        handleApiFetchAgain();
      }
    }
  }

  function transformDataMessage(data: any) {
    if (data.status) {
      if (handleSuccessCall) {
        handleSuccessCall(data.message);
      }
      handleCloseMessage();
    }
  }

  function transformDataDownload(data: any) {
    const link = document.createElement("a");
    link.href = data?.file_link;
    link.setAttribute("download", "");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // link.download = "filename"; // You can set the desired file name here
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
  }

  function transformDataMembership(data: any) {
    if (data.status) {
      const members = data.memberships.map((member: any, i: number) => {
        const obj: any = {};
        obj.id = member.id;
        obj.type = member.membership_type;

        return obj;
      });

      setMembershipList(members);
    }
  }

  const handleClickOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleClickOpenBlock = () => {
    setOpenBlock(true);
  };

  const handleClickOpenUnlock = () => {
    setOpenUnblock(true);
  };

  const handleMembershipDelete = () => {
    setOpenMembershipDelete(true);
  };

  const handleMembershipDisable = () => {
    setOpenMembershipDisable(true);
  };

  const handleClickOpenMessage = () => {
    setOpenMessage(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCloseBlock = () => {
    setOpenBlock(false);
  };
  const handleCloseUnblock = () => {
    setOpenUnblock(false);
  };

  const handleCloseMessage = () => {
    setOpenMessage(false);
  };

  const navigate = useNavigate();
  const wrapperRef = useRef(null);
  const clickRef = useRef(null);
  const location = useLocation();

  useOutsideAlerter(wrapperRef, clickRef, handleCloseOptions);

  function handleCloseOptions() {
    setToggleSortMenu(false);
  }

  function handleCloseFilterOptions() {
    setToggleFilterMenu(false);
  }

  const handleClickOpen = () => {
    pageName ? navigate(pageName) : setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSortMenu = () => {
    setToggleSortMenu(!toggleSortMenu);
  };

  const handleFilterMenu = () => {
    setToggleFilterMenu(!toggleFiltermenu);
  };

  const handleClearFilter = () => {
    setFilterYear("");
    handleYearFilter("");
  };

  const handleClearMmebershipFilter = () => {
    setFilterMembership("");
    handleMembershipFilter("");
  };

  const handleClearUserFilter = () => {
    setFilterUser("");
    handleUserFilter("");
  };

  const handleMultipleDelete = () => {
    if (tab === "mailing") {
      const reqOptions = {
        url: "/mailing_lists/delete_mailing_list",
        method: "delete",
        data: {
          id: selectedRecords,
        },
      };
      sendRequestDelete(reqOptions);
    } else if (tab === "board") {
      const reqOptions = {
        url: "/board_memebers/delete_board_memeber",
        method: "delete",
        data: {
          id: selectedRecords,
        },
      };
      sendRequestDelete(reqOptions);
    } else {
      const reqOptions = {
        url: "/users/delete_user",
        method: "delete",
        data: {
          user_id: selectedRecords,
        },
      };
      sendRequestDelete(reqOptions);
    }
  };

  const MultipleMembershipsDelete = () => {
    const reqOptions = {
      url: "/memberships/bulk_destroy",
      method: "post",
      data: {
        id: selectedRecords,
      },
    };
    sendRequestDelete(reqOptions);
    setOpenMembershipDelete(false);
  };

  const MultipleMembershipsDisable = () => {
    const reqOptions = {
      url: "/memberships/bulk_bolck_unblock",
      method: "post",
      data: {
        id: selectedRecords,
      },
    };

    sendRequestDelete(reqOptions);
    setOpenMembershipDisable(false);
  };

  const handleMultipleBlock = () => {
    const reqOptions = {
      url: "/users/block_unblock_user",
      method: "post",
      data: {
        user_id: selectedRecords,
        type: "block",
      },
    };

    sendRequestBlock(reqOptions);
  };

  const handleMultipleUnblock = () => {
    const reqOptions = {
      url: "/users/block_unblock_user",
      method: "post",
      data: {
        user_id: selectedRecords,
        type: "unblock",
      },
    };

    sendRequestBlock(reqOptions);
    handleCloseUnblock();
  };

  const handleSendMessage = () => {
    if (tab === "mailing") {
      const reqOptions = {
        url: "/mailing_lists/send_message",
        method: "POST",
        data: {
          id: selectedRecords,
          message: modalMessage,
        },
      };

      sendRequestMessage(reqOptions);
    } else {
      const reqOptions = {
        url: "/users/send_message",
        method: "POST",
        data: {
          user_id: selectedRecords,
          message: modalMessage,
        },
      };

      sendRequestMessage(reqOptions);
    }
  };

  const handleMultipleDownload = () => {
    const reqOptions = {
      url: "/users/download_csv",
      method: "POST",
      data: {
        user_id: selectedRecords,
      },
    };

    sendRequestDownload(reqOptions);
  };
  const membershipMultipleDownload = () => {
    const reqOptions = {
      url: "/memberships/download_csv",
      method: "POST",
      data: {
        id: selectedRecords,
      },
    };

    sendRequestDownload(reqOptions);
  };

  useEffect(() => {
    if (membershipFilter) {
      const requestOptions = {
        url: MEMBERSHIP_GET_URL,
        method: "GET",
      };

      sendRequestMembership(requestOptions);
    }
  }, [membershipFilter]);

  const handleEmailMessage = (val: any) => {
    setModalMessage(val);
  };

  useEffect(() => {
    if (filterYear) {
      handleYearFilter(filterYear);
    }
  }, [filterYear]);

  useEffect(() => {
    if (filterMembership) {
      handleMembershipFilter(filterMembership);
    }
  }, [filterMembership]);

  useEffect(() => {
    if (filterUser) {
      handleUserFilter(filterUser);
    }
  }, [filterUser]);

  const [appear, setAppear] = useState<any>("");
  useEffect(() => {
    if (completeSelectedUser) {
      let allBlocked = true;
      let allUnblocked = true;

      for (const item of completeSelectedUser) {
        if (item.blocked) {
          allUnblocked = false;
        } else {
          allBlocked = false;
        }
      }

      let appearValue = "none";

      if (allBlocked) {
        appearValue = "block";
      } else if (allUnblocked) {
        appearValue = "unblock";
      }

      setAppear(appearValue);
    }
  }, [completeSelectedUser]);
  return (
    <>
      {(loadingDelete || loadingBlock || loadingMessage || loadingDownload) && (
        <Loader />
      )}
      <Box display="flex" justifyContent="space-between">
        <Box className="topbar_text_details">
          <TextLabels variant="h200" weight={400}>
            Showing List -{" "}
            {location.pathname === "/cd-orders-banners" && `2023 - `}
            <span className="topbar_grey_total">
              (Total - {totalRecordsCount})
            </span>
          </TextLabels>
          {filterYear && (
            <span className="filter_year_span">
              {filterYear}
              <img src={CloseImg} onClick={handleClearFilter} alt="Not-Found" />
            </span>
          )}
          {filterMembership && (
            <span className="filter_year_span">
              {filterMembership}
              <img
                src={CloseImg}
                onClick={handleClearMmebershipFilter}
                alt="Not-Found"
              />
            </span>
          )}

          {filterUser && (
            <span className="filter_year_span">
              {filterUser}
              <img
                src={CloseImg}
                onClick={handleClearUserFilter}
                alt="Not-Found"
              />
            </span>
          )}
        </Box>
        {showMultipleActions ? (
          <Box className="multiple_options_buttons ">
            {showButton && (
              <>
                <Box className="multiple_options_buttons_margin">
                  <Button
                    variant="outlined"
                    size="s"
                    sx={multipleButtonProperties}
                    handleClick={handleMembershipDelete}
                  >
                    Delete Membership
                  </Button>
                </Box>
                <Box className="multiple_options_buttons_margin">
                  <Button
                    variant="outlined"
                    size="s"
                    sx={multipleButtonProperties}
                    handleClick={handleMembershipDisable}
                  >
                    Disable Membership
                  </Button>
                  <span style={{ margin: "8px" }}></span>
                  <Button
                    variant="outlined"
                    size="s"
                    icon={DownloadImg}
                    sx={exportbuttonProperties}
                    handleClick={membershipMultipleDownload}
                  >
                    {""}
                  </Button>
                </Box>
              </>
            )}

            {mutipleOptions.map((option: any, i: number) => {
              return (
                <Box
                  className={`multiple_options_buttons_margin ${
                    i === mutipleOptions.length - 1
                      ? "multiple_options_no_margin"
                      : ""
                  }`}
                >
                  <>
                    {option === SEND_MESSAGE_MULTIPLE_ACTION && (
                      <Button
                        variant="outlined"
                        size="s"
                        sx={multipleButtonProperties}
                        handleClick={handleClickOpenMessage}
                      >
                        Send Message
                      </Button>
                    )}
                    {option === BLOCK_PROFILE_MULTIPLE_ACTION && (
                      <>
                        {appear == "block" ? (
                          <Button
                            variant="outlined"
                            size="s"
                            sx={multipleButtonProperties}
                            handleClick={handleClickOpenUnlock}
                          >
                            Unblock Profile
                          </Button>
                        ) : appear == "unblock" ? (
                          <Button
                            variant="outlined"
                            size="s"
                            sx={multipleButtonProperties}
                            handleClick={handleClickOpenBlock}
                          >
                            Block Profile
                          </Button>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                    {option === DELETE_USER_MULTIPLE_ACTION && (
                      <Button
                        variant="outlined"
                        size="s"
                        sx={multipleButtonProperties}
                        handleClick={handleClickOpenDelete}
                      >
                        Delete User
                      </Button>
                    )}
                    {option === DELETE_USER_MULTIPLE_ACTION && (
                      <Button
                        variant="outlined"
                        size="s"
                        icon={DownloadImg}
                        sx={{
                          ...exportbuttonProperties,
                          marginLeft: "16px",
                          marginRight: "-14px",
                        }}
                        handleClick={handleMultipleDownload}
                      >
                        {""}
                      </Button>
                    )}
                  </>
                </Box>
              );
            })}
          </Box>
        ) : (
          <Box display="flex" className="topbar_buttons_box">
            <Box className="topbar_search_box">
              {window.location.pathname != "/cd-orders-banners" && (
                <SearchInput
                  label={searchLabel}
                  handleSearchInput={handleSearchInput}
                />
              )}
            </Box>
            {showBtn && (
              <Button
                btnClass={`${
                  (modal?.heading == "Create Membership" ||
                    modal?.heading == "Create Announcement") &&
                  "membership-top-bar "
                } btnCustomCss`}
                variant="contained"
                color="primary"
                icon={PlusImg}
                size="s"
                handleClick={
                  handleCreateBtn ? handleCreateBtn : handleClickOpen
                }
              >
                Create
              </Button>
            )}

            {showAddUser && (
              <Button
                btnClass="membership-top-bar  btnCustomCss"
                variant="contained"
                color="primary"
                icon={PlusImg}
                size="s"
                handleClick={() => navigate("/user-management/edit-profile")}
              >
                Add Member
              </Button>
            )}

            {filterBtn && (
              <Box
                className="sort_dropdown_container"
                sx={{
                  marginLeft: "10px",
                }}
              >
                <Box ref={clickRef}>
                  <Button
                    btnClass="topbar-filter"
                    variant="outlined"
                    color="primary"
                    icon={FilterImg}
                    size="s"
                    handleClick={handleFilterMenu}
                  >
                    Filter
                  </Button>
                </Box>
                {toggleFiltermenu && (
                  <Box
                    className="sort_dropdown filter_dropdown"
                    ref={wrapperRef}
                  >
                    <Box className="filter_dropdown_top">
                      <TextLabels variant="h200" color="#221714" weight={500}>
                        Apply Filter
                      </TextLabels>
                      <img
                        src={CloseImg}
                        onClick={handleCloseFilterOptions}
                        style={{
                          cursor: "pointer",
                        }}
                        alt="Not-Found"
                      />
                    </Box>
                    <Box className="filter_dropdown_options">
                      <Box className="filter_dropdown_options_label">
                        <TextLabels variant="h100" weight={400} color="#221714">
                          Year
                        </TextLabels>
                      </Box>
                      <Box>
                        <FormControl sx={{ width: "100%", margin: "0px" }}>
                          <Select
                            value={filterYear}
                            onChange={handleYearChange}
                            displayEmpty
                            className="select_filter_years"
                            inputProps={{ "aria-label": "Without label" }}
                            renderValue={
                              filterYear !== ""
                                ? () => filterYear
                                : () => (
                                    <span
                                      style={{
                                        color: "#BABDC2",
                                      }}
                                    >
                                      Select Year
                                    </span>
                                  )
                            }
                          >
                            <MenuItem
                              value={`${
                                new Date().getFullYear() - 1
                              }-${new Date().getFullYear()}`}
                            >
                              {new Date().getFullYear() - 1}-
                              {new Date().getFullYear()}
                            </MenuItem>
                            <MenuItem
                              value={`${new Date().getFullYear() - 2}-${
                                new Date().getFullYear() - 1
                              }`}
                            >
                              {new Date().getFullYear() - 2}-
                              {new Date().getFullYear() - 1}
                            </MenuItem>
                            <MenuItem
                              value={`${new Date().getFullYear() - 3}-${
                                new Date().getFullYear() - 2
                              }`}
                            >
                              {new Date().getFullYear() - 3}-
                              {new Date().getFullYear() - 2}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                    {tab != "guest" && (
                      <Box>
                        {membershipFilter && (
                          <Box className="filter_dropdown_options">
                            <Box className="filter_dropdown_options_label">
                              <TextLabels
                                variant="h100"
                                weight={400}
                                color="#221714"
                              >
                                Membership Type
                              </TextLabels>
                            </Box>
                            <Box>
                              <FormControl
                                sx={{ width: "100%", margin: "0px" }}
                              >
                                <Select
                                  value={filterMembership}
                                  onChange={handleMembershipChange}
                                  displayEmpty
                                  className="select_filter_years"
                                  inputProps={{ "aria-label": "Without label" }}
                                  renderValue={
                                    filterMembership !== ""
                                      ? () => filterMembership
                                      : () => (
                                          <span
                                            style={{
                                              color: "#BABDC2",
                                            }}
                                          >
                                            All Type
                                          </span>
                                        )
                                  }
                                >
                                  {membershipList.map((member: any) => {
                                    return (
                                      <MenuItem
                                        value={`${String(
                                          member.type
                                        ).toLowerCase()}`}
                                        key={member.id}
                                      >
                                        {member.type}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Box>
                          </Box>
                        )}
                        {userTypeFilter && (
                          <Box className="filter_dropdown_options">
                            <Box className="filter_dropdown_options_label">
                              <TextLabels
                                variant="h100"
                                weight={400}
                                color="#221714"
                              >
                                User Category
                              </TextLabels>
                            </Box>
                            <Box>
                              <FormControl
                                sx={{ width: "100%", margin: "0px" }}
                              >
                                <Select
                                  value={filterUser}
                                  onChange={handleUserChange}
                                  displayEmpty
                                  className="select_filter_years"
                                  inputProps={{ "aria-label": "Without label" }}
                                  renderValue={
                                    filterUser !== ""
                                      ? () => filterUser
                                      : () => (
                                          <span
                                            style={{
                                              color: "#BABDC2",
                                            }}
                                          >
                                            All Type
                                          </span>
                                        )
                                  }
                                >
                                  <MenuItem value={"student"}>Student</MenuItem>
                                  <MenuItem value={"senior"}>
                                    Senior Citizen
                                  </MenuItem>
                                  <MenuItem value={"none"}>
                                    None of above
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            )}
            {sortBtn && (
              <Box
                className="sort_dropdown_container"
                sx={{
                  marginLeft: "10px",
                }}
              >
                <Box ref={clickRef}>
                  <Button
                    variant="outlined"
                    color="primary"
                    icon={SortImg}
                    size="s"
                    handleClick={handleSortMenu}
                  >
                    Sort
                  </Button>
                </Box>
                {toggleSortMenu && (
                  <Box className="sort_dropdown" ref={wrapperRef}>
                    <ul>
                      {sortOptions?.map((option, i) => {
                        return (
                          <li key={i}>
                            <TextLabels variant="h200" weight={500}>
                              {option.label}
                            </TextLabels>
                          </li>
                        );
                      })}
                    </ul>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        )}
      </Box>
      <ModalFormPopup
        heading={modal.heading}
        inputs={modal.inputs}
        closeBtn={true}
        confirmBtn={
          confirmBtn
            ? "create"
            : modal.heading === "Create Announcement"
            ? "Publish Announcement"
            : modal.heading === "Add Board Members"
            ? "+ Add Member"
            : "Save Changes"
        }
        handleClose={handleClose}
        boardMemberApi={boardMemberApi}
        apiUrl={apiUrl}
        method={method}
        open={open}
        handleApiFetchAgain={handleApiFetchAgain}
        handleSuccessCall={handleSuccessCall}
      />
      <ModalPopup
        open={openBlock}
        handleClickOpen={handleClickOpenBlock}
        handleClose={handleCloseBlock}
        heading="Block User?"
        content="Are you sure you want to block this user?"
        primaryButton="Block"
        secondaryButton="Cancel"
        primaryAction={handleMultipleBlock}
      />
      <ModalPopup
        open={openUnblock}
        handleClickOpen={handleClickOpenUnlock}
        handleClose={handleCloseUnblock}
        heading="Unblock User?"
        content="Are you sure you want to Unblock these user?"
        primaryButton="Unblock"
        secondaryButton="Cancel"
        primaryAction={handleMultipleUnblock}
      />
      <ModalPopup
        open={openDelete}
        handleClickOpen={handleClickOpenDelete}
        handleClose={handleCloseDelete}
        heading="Delete User?"
        content="Are you sure you want to delete this user? This action is non-reversible and can not be undone."
        primaryButton="Delete user"
        secondaryButton="Cancel"
        primaryAction={handleMultipleDelete}
      />
      <ModalPopup
        open={openMembershipDelete}
        handleClickOpen={handleClickOpenDelete}
        handleClose={() => {
          setOpenMembershipDelete(false);
        }}
        heading="Delete Memberships?"
        content="Are you sure you want to delete these Memberships? This action is non-reversible and can not be undone."
        primaryButton="Delete"
        secondaryButton="Cancel"
        primaryAction={MultipleMembershipsDelete}
      />
      <ModalPopup
        open={openMembershipDisable}
        handleClickOpen={handleClickOpenDelete}
        handleClose={() => {
          setOpenMembershipDisable(false);
        }}
        heading="Disable Memberships?"
        content="Are you sure you want to disable these Memberships? This action is non-reversible and can not be undone."
        primaryButton="Disable"
        secondaryButton="Cancel"
        primaryAction={MultipleMembershipsDisable}
      />
      <ModalMessagePopup
        open={openMessage}
        handleClose={handleCloseMessage}
        heading="Send Message"
        closeBtn={true}
        primaryButton="Confirm"
        secondaryButton="Cancel"
        handleEmailMessage={handleEmailMessage}
        primaryAction={handleSendMessage}
        secondaryAction={handleCloseMessage}
        messageEmail={selectedRecords}
      />
    </>
  );
};

export default Topbar;
