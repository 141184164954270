import React from 'react'
import Box from '@mui/material/Box';
import { PropType } from './types/propTypes'

function ErrorMessage({children} : PropType) {
  return (
    <Box 
    style={{
        
        display:"flex",
        //justifyContent:"center",
        alignItems:"center",
        color:"#DC4852",
        fontWeight: 400,
        fontSize: "12px",
        paddingTop: "2px"
    }}
    >
      <img src="icons/error.png" style={{marginRight:"5px"}} alt='' />{children}
    </Box>
  )
}

export default ErrorMessage;