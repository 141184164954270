import React, { useRef, useState } from "react";
import { Grid, Box } from "@mui/material";
import Cards from "../cards";
import { PropType } from "./types/propTypes";
import TextLabels from "../textLabels";
import MenuImg from "./images/menu.svg";
import ModalImageUploadPopup from "../modalImageUploadPopup";
import ModalPopup from "../modalPopup";
import EditImg from "./images/edit.svg";
import DeleteImg from "./images/delete.svg";
import { useNavigate } from "react-router-dom";
import ToggleMenu from "../toggleMenu";
import useOutsideAlerter from "../../../utils/hooks/useOutsideAlerter";
import "./style.css";
import EventGalleryImageUpload from "../eventGalleryImageUploadModal";
import { AnyAction } from "redux";
import useHttp from "../../../utils/hooks/useHttp";

const Grids = ({ spacing, items, size, sizeMd, sizeSm, sizeM, imgClick, tab }: PropType) => {
  const [open, setOpen] = React.useState(false);
  const [event, setEvent] = React.useState<any>(null);
  const [openDeleteAll, setOpenDeleteAll] = React.useState(false);
  const [toggleMenu, setToggleMenu] = useState<number | null>(null);
  const [activeEvent, setActiveEvent] = useState<number | null>(null);
  const [showBelowGrid, setShowBelowGrid] = useState<number | null>(null);
  const wrapperRef = useRef(null);
  const clickRef = useRef(null);
  const navigate = useNavigate();
  // const { loading, error, sendRequest } = useHttp(transformData);
  // function transformData(data: any) {
  //   if (data.status) {
  //     handleCloseDeleteAll();

  //     if (handleSuccessCall) {
  //       handleSuccessCall(data.message);
  //     }
  //     handleApiFetchAgain();
  //   }
  // }

  useOutsideAlerter(wrapperRef, clickRef, handleCloseOptions);

  function handleCloseOptions() {
    setToggleMenu(null);
  }

  const handleClickOpen = (eventId: any) => {
    setOpen(true);
    setEvent(eventId);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [deleteGalleryId, setdeleteGalleryId] = useState<any>()

  const handleClickOpenDeleteAll = (content: any) => {
    setdeleteGalleryId(content.eventId)
    setOpenDeleteAll(true);
  };

  function transformData(data: any) {
    window.location.reload()
    setOpenDeleteAll(false);
  }
  const { loading, error, sendRequest } = useHttp(transformData);

  const handleGalleryDelete = () => {
    const reqOptions = {
      url: `/event_gallery/delete_event_gallery_image`,
      method: "DELETE",
      data: {
        event_id:  deleteGalleryId
        }
    };

    sendRequest(reqOptions);
  }

  const handleCloseDeleteAll = () => {
    setOpenDeleteAll(false);
  };

  const handleToggleMenu = (e: React.MouseEvent, eventId: number) => {
    e.stopPropagation();
    if (eventId === toggleMenu) {
      setToggleMenu(null);
    } else {
      setToggleMenu(eventId);
    }
  };

  const handleEventClick = (eventId: number, currentIndex: number) => {
    // setActiveEvent(eventId);
    // const modulusOf4 = Number(currentIndex % 4);
    // let gridPos;
    // switch (modulusOf4) {
    //   case 0:
    //     gridPos = currentIndex + 4;
    //     break;
    //   case 1:
    //     gridPos = currentIndex + 3;
    //     break;
    //   case 2:
    //     gridPos = currentIndex + 2;
    //     break;
    //   case 3:
    //     gridPos = currentIndex + 1;
    //     break;
    //   default:
    //     gridPos = currentIndex + 4;
    //     break;
    // }
    // setShowBelowGrid(gridPos);
    // navigate(`/event-gallery/${eventId}`, { replace: true });
  };

  return (
    <>
      <Grid container spacing={spacing}>
        {items.gridType === "cards" &&
          items.items?.map((content: any, i: any) => {
            return (
              <Grid
                item
                xs={sizeM}
                md={sizeMd}
                sm={sizeSm}
                lg={size}
                key={i}
                className="image_item_grid"
              >
                <Cards cardType="stats">
                  <Box
                    sx={{
                      paddingBottom: "5px",
                    }}
                  >
                    <TextLabels variant="h100" color="#7E8085" weight={600}>
                      {content.heading}
                    </TextLabels>
                  </Box>
                  <Box
                    sx={{
                      paddingBottom: "5px",
                    }}
                  >
                    <TextLabels variant="h300" weight={700}>
                      {content.numbers}
                    </TextLabels>
                  </Box>
                  <Box display="flex" alignItems="center">
                    {content.statType === "+" ? (
                      <TextLabels variant="h200" color="#34B53A" weight={500}>
                        +{content.stats}
                      </TextLabels>
                    ) : (
                      <TextLabels variant="h200" color="#DC4852" weight={500}>
                        -{content.stats}
                      </TextLabels>
                    )}
                    {content.timeline && (
                      <Box
                        sx={{
                          marginLeft: "5px",
                        }}
                      >
                        <TextLabels variant="h200" color="#7E8085" weight={400}>
                          {content.timeline}
                        </TextLabels>
                      </Box>
                    )}
                  </Box>
                </Cards>
              </Grid>
            );
          })}

        {items.gridType === "images" && (
          <>
            {items.items.map((content: any, i:any) => {
              return (
                <>
                  {content.imageCount ? (
                    <>
                      <Grid
                        item
                        xs={sizeM}
                        md={sizeMd}
                        sm={sizeSm}
                        lg={size}
                        key={i}
                        className="image_item_grid"
                        onClick={() => handleEventClick(content.eventId, i)}
                      >
                        <Cards cardType="events">
                          <Box
                            sx={{
                              paddingBottom: "5px",
                            }}
                          >
                            <TextLabels
                              variant="h200"
                              color="#1A1A1A"
                              weight={400}
                              className="content-ellipsis"
                              title={content.eventName}
                            >
                              {content.eventName ? `${content.eventName}` : `Event Details`}
                            </TextLabels>
                          </Box>
                          <div
                          onClick={()=>{imgClick(content.eventId)}}
                            className="event_image_card"
                            style={{
                              backgroundImage: `url(${content.eventImage})`,
                            }}
                          >
                            <Box className="event_detail_box">
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="flex-start"
                              >
                                <Box display="flex" flexDirection="column">
                                  <TextLabels
                                    variant="h100"
                                    color="#ffffff"
                                    weight={400}
                                  >
                                    {content.eventDate}
                                  </TextLabels>
                                  <Box sx={{ paddingBottom: "7.5px" }}></Box>
                                  <TextLabels
                                    variant="h200"
                                    color="#ffffff"
                                    weight={500}
                                  >
                                    {content.imageCount} Images
                                  </TextLabels>
                                </Box>
                                <Box display="flex">
                                  <div
                                    ref={clickRef}
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <img
                                      src={MenuImg}
                                      alt=""
                                      onClick={(e) =>
                                        handleToggleMenu(e, content.eventId)
                                      }
                                    />
                                  </div>
                                  {toggleMenu === content.eventId && (
                                    <Box ref={wrapperRef}>
                                      <ToggleMenu
                                        gallery={true}
                                        handleClickOpenEdit={() => handleClickOpen(content?.eventId)}
                                        handleClickOpenDeleteAll={()=>{handleClickOpenDeleteAll(content)}}
                                      />
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                            </Box>
                          </div>
                        </Cards>
                      </Grid>
                    </>
                  ) : content.bannerImg ? (
                    <>
                      <Grid
                        item
                        xs={sizeM}
                        md={sizeMd}
                        sm={sizeSm}
                        lg={size}
                        key={i}
                        className="event_image_item_grid"
                        onClick={() => handleEventClick(content.eventId, i)}
                      >
                        <Cards cardType="events_item">
                          <div
                            className="event_management_image_card"
                            style={{
                              backgroundImage: `url(${content.eventImage})`,
                            }}
                          >
                            <Box className="event_management_detail_box">
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="flex-start"
                                className="event_management_top_title"
                              >
                                <Box display="flex" flexDirection="column">
                                  <TextLabels
                                    variant="h200"
                                    color="#ffffff"
                                    weight={500}
                                    className="content-ellipsis"
                                    title={content.eventTitle}
                                  >
                                    {content.eventTitle ? content.eventTitle : `Event Title`}
                                  </TextLabels>

                                  <Box sx={{ paddingBottom: "7.5px" }}></Box>
                                  <TextLabels
                                    variant="h100"
                                    color="#ffffff"
                                    weight={400}
                                  >
                                    {content.eventDate}
                                  </TextLabels>
                                </Box>
                                <Box display="flex">
                                  <div
                                    ref={clickRef}
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <img
                                      src={MenuImg}
                                      alt=""
                                      onClick={(e) =>
                                        handleToggleMenu(e, content.eventId)
                                      }
                                    />
                                  </div>
                                  {toggleMenu === content.eventId && (
                                    <Box ref={wrapperRef}>
                                      <ToggleMenu
                                        handleClickOpenEdit={() => {}}
                                        handleClickOpenDeleteAll={
                                          handleClickOpenDeleteAll
                                        }
                                      />
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                              <Box className="event_management_artist_image">
                                <img src={content.bannerImg} />
                              </Box>
                            </Box>
                          </div>
                        </Cards>
                        <Box className="event_management_details">
                          <TextLabels variant="h100" weight={700}>
                            Location
                          </TextLabels>
                          <Box
                            sx={{
                              marginTop: "0px",
                            }}
                          ></Box>
                          <TextLabels variant="h200" weight={400}>
                            {content.location}
                          </TextLabels>
                          <Box
                            sx={{
                              marginTop: "15px",
                            }}
                          ></Box>
                          <TextLabels variant="h100" weight={700}>
                            Tickets
                          </TextLabels>
                          <Box
                            sx={{
                              marginTop: "0px",
                            }}
                          ></Box>
                          <TextLabels variant="h200" weight={400}>
                            {content.tickets}
                          </TextLabels>
                          <Box
                            sx={{
                              marginTop: "15px",
                            }}
                          ></Box>
                          <TextLabels variant="h100" weight={700}>
                            Ticket Bookings
                          </TextLabels>
                          <Box
                            sx={{
                              marginTop: "0px",
                            }}
                          ></Box>
                          <TextLabels variant="h200" weight={400}>
                            {content.ticketBookings}
                          </TextLabels>
                        </Box>
                      </Grid>
                    </>
                  ) : (
                    <Grid
                      item
                      xs={sizeM}
                      md={sizeMd}
                      sm={sizeSm}
                      lg={size}
                      className="image_item_grid"
                    >
                      <Cards cardType="events">
                        <Box
                          sx={{
                            paddingBottom: "5px",
                          }}
                        >
                          <TextLabels
                            variant="h200"
                            color="#1A1A1A"
                            weight={400}
                            className="content-ellipsis"
                            title={content.eventName}
                          >
                            {content.eventName ? `${content.eventName}` : `Event Details`}
                          </TextLabels>
                        </Box>
                        <div
                          className="event_image_card_upload"
                          onClick={() => {
                            if(tab == `past`){
                            handleClickOpen(content?.eventId);
                            }else{
                              return;
                            }
                          }}
                        >
                          <TextLabels
                            variant="h200"
                            weight={500}
                            color="#903713"
                          >
                            +&nbsp;&nbsp;Add Images
                          </TextLabels>
                        </div>
                      </Cards>
                    </Grid>
                  )}
                </>
              );
            })}
          </>
        )}
      </Grid>
      <EventGalleryImageUpload
        open={open}
        handleClose={handleClose}
        heading="Add Image"
        subHeading="Upload Event Images"
        submitBtnText="Publish Gallery"
        eventId={event}
      />
      <ModalPopup
        heading="Delete all images?"
        content="Are you sure you want to delete all images of the event?<br/>
        This is a non-reversible action and can not be undone."
        primaryButton="Yes, Delete"
        secondaryButton="No, Keep Images"
        open={openDeleteAll}
        handleClickOpen={handleClickOpenDeleteAll}
        handleClose={handleCloseDeleteAll}
        closeBtn={true}
        primaryAction={()=>{handleGalleryDelete()}}
      />
    </>
  );
};

// const GridImages = () => {
//   const images: any[] = [Image1, Image2, Image3, Image4, Image5];

//   return (
//     <>
//       <Grid xs={12} md={6} sm={12} lg={6}>
//         <img src={images[0]} />
//       </Grid>
//       <Grid xs={12} sm={12} md={3} lg={3}>
//         <Box>
//           <img src={images[1]} />
//         </Box>
//         <Box>
//           <img src={images[2]} />
//         </Box>
//       </Grid>
//       <Grid xs={12} sm={12} md={3} lg={3}>
//         <Box>
//           <img src={images[3]} />
//         </Box>
//         <Box>
//           <img src={images[4]} />
//         </Box>
//       </Grid>
//     </>
//   );
// };

export default Grids;
