import { useState } from "react";
import { HttpRequestType } from "./types/httpRequestType";
import axios from "axios";
import { useDispatch } from "react-redux";

const useHttp = (transformData: (data: any) => void) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(false);
  const [errorFlag, setErrorFlag] = useState(false);

  const web_Token = localStorage.getItem('web_token') || ""


  const sendRequestWithoutToken = async (reqOptions: HttpRequestType) => {
    setLoading(true);
    setError(false);
    setErrorFlag(false);

    reqOptions.headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Authorization": web_Token,
    };

    // appending base url to all the api calls
    reqOptions.url = `${process.env.REACT_APP_API_URL_DEV}${reqOptions.url}`;

    try {
      const { data } = await axios(reqOptions);
      transformData(data);
    } catch (err: any) {
      setErrorFlag(true);
      setError(err.response.data.message);
    }
    setLoading(false);
  };

  const sendRequest = async (reqOptions: HttpRequestType) => {
    setLoading(true);
    setError(false);

    reqOptions.headers = {
      "Content-Type": reqOptions?.contentType
        ? reqOptions?.contentType
        : "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("token"),
    };

    // appending base url to all the api calls
    reqOptions.url = `${process.env.REACT_APP_API_URL_DEV}${reqOptions.url}`;

    try {
      const { data } = await axios(reqOptions);
      if (data && data?.message == "invalid token") {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("settings");
        localStorage.clear();
        window.location.href = "/login?token=invalid";
      }
      transformData(data);
    } catch (err) {
      setError(true);
    }
    setLoading(false);
  };

  const sendRequestWeb = async (reqOptions: HttpRequestType) => {
    setLoading(true);
    setError(false);

    reqOptions.headers = {
      "Content-Type": reqOptions?.contentType
        ? reqOptions?.contentType
        : "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("web_token"),
    };

    // appending base url to all the api calls
    reqOptions.url = `${process.env.REACT_APP_API_URL_DEV}${reqOptions.url}`;

    try {
      const { data } = await axios(reqOptions);
      if (data && data?.message == "invalid token") {
        localStorage.removeItem("web_token");
        localStorage.removeItem("web_user");
        localStorage.removeItem("settings");
        localStorage.clear();
        window.location.href = "/?token=invalid";
      }
      transformData(data);
    } catch (err) {
      setError(true);
    }
    setLoading(false);
  };


  const sendDeleteRequest = async (reqOptions: HttpRequestType) => {
    setLoading(true);
    setError(false);

    reqOptions.headers = {
      "Content-Type": reqOptions?.contentType
        ? reqOptions?.contentType
        : "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("token"),
    };

    // appending base url to all the api calls
    reqOptions.url = `${process.env.REACT_APP_API_URL_DEV}${reqOptions.url}`;

    try {
      const { data } = await axios(reqOptions);
      if (data && data?.message == "invalid token") {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("settings");
        localStorage.clear();
        window.location.href = "/login?token=invalid";
      }
      transformData(data);
    } catch (err) {
      setError(true);
    }
    setLoading(false);
  };


  return {
    loading,
    error,
    errorFlag,
    sendRequestWithoutToken,
    sendRequest,
    sendRequestWeb,
    sendDeleteRequest
  };
};

export default useHttp;
