import React from "react";
import { Input } from "../../atoms/inputs";
import TextLabels from "../../atoms/textLabels";
import { Box } from "@mui/material";
import { PropType } from "./types/propType";
import { useLocation } from "react-router-dom";
import ErrorMessage from "../../atoms/errorMessage";

const InputsWithLabel = ({
  inputProps,
  label,
  placeholder,
  type,
  name,
  id,
  value,
  labelColor,
  maxRows = 1,
  handleInputChange,
  handleClick,
  handleKeyDown,
  required,
  helperText,
  msg,
  minValue,
}: PropType) => {
  const { pathname } = useLocation();
  //console.log({pathname})
  return (
    <Box sx={{ margin: pathname == "/announcement" ? "15px 0" : "20px 0" }}>
      <TextLabels
        variant="h5"
        color={`${labelColor || "#2C2D30"}`}
        weight={700}
      >
        {label}
      </TextLabels>
      {handleInputChange ? (
        <Input
          id={id}
          label={label}
          type={type}
          minValue={minValue}
          name={name}
          placeholder={placeholder}
          variant="outlined"
          maxRows={maxRows}
          handleChange={handleInputChange}
          handleKeyPress={handleKeyDown}
          value={value}
          inputProps={inputProps}
          sx={{
            marginTop: "10px",
            width: "100%",
            backgroundColor: "#ffffff",
            borderRadius: "4px",
            fontWeight: "400",
            fontSize: "14px",
          }}
          required={required}
        />
      ) : (
        <Input
          value={value}
          minValue={minValue}
          inputProps={inputProps}
          id={id}
          label={label}
          type={type}
          name={name}
          placeholder={placeholder}
          variant="outlined"
          maxRows={maxRows}
          sx={{
            marginTop: "10px",
            width: "100%",
            backgroundColor: "#ffffff",
            borderRadius: "4px",
            fontWeight: "400",
            fontSize: "14px",
          }}
          handleClick={handleClick}
          required={required}
        />
      )}

      {helperText && (
        <ErrorMessage>
          {msg
            ? msg
            : "Please input valid event title, special characters are not allowed and input cannot be empty."}
        </ErrorMessage>
      )}
    </Box>
  );
};

export default InputsWithLabel;
