import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { PropType } from "./types/propTypes";
import ErrorIcon from "./images/error.svg";
import SuccessIcon from "./images/success.svg";
import InfoIcon from "./images/info.svg";
import NotificationIcon from "./images/notification.svg";
import WarningIcon from "./images/warning.svg";
import ErrorCrossIcon from "./images/error_cross.svg";
import NotificationCrossIcon from "./images/notification_cross.svg";
import WarningCrossIcon from "./images/warning_cross.svg";
import SuccessCrossIcon from "./images/success_cross.svg";
import InfoCrossIcon from "./images/info_cross.svg";
import SuccessWebIcon from "./images/success_web.svg";
import SuccessWebCrossIcon from "./images/success_web_cross.svg";
import "./style.css";

function AlertCmp({ children, type, position }: PropType) {
  const [showAlert, setShowAlert] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAlert(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setShowAlert(false);
  };

  return (
    <>
      {showAlert && (
        <>
          {position ? (
            <Box
              className={
                position === "user_side"
                  ? "website_alert_position"
                  : "announcement_alert_position"
              }
            >
              <Box
                className={
                  position === "user_side"
                    ? "web_alert_position_container"
                    : "alert_position_container"
                }
              >
                {type === "alert" && (
                  <Box
                    style={{
                      height: "44px",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#FDF1D3",
                      borderRadius: "4px",
                      color: "#141518",
                      fontWeight: 600,
                      fontSize: "14px",
                      fontFamily: "Inter",
                      justifyContent: "space-between",
                      border: "1px solid #FDD570",
                    }}
                    className="alert_container"
                  >
                    <Box className="alert_content">
                      <img src={WarningIcon} />
                      {children}
                    </Box>
                    <img
                      src={WarningCrossIcon}
                      onClick={handleClose}
                      className="alert_close_img"
                    />
                  </Box>
                )}
                {type === "error" && (
                  <Box
                    style={{
                      height: "44px",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#F9DFE0",
                      borderRadius: "4px",
                      color: "#E5767A",
                      fontWeight: 600,
                      fontSize: "14px",
                      fontFamily: "Inter",
                      justifyContent: "space-between",
                      border: "1px solid #DC4852",
                    }}
                    className="alert_container"
                  >
                    <Box className="alert_content">
                      <img src={ErrorIcon} />
                      {children}
                    </Box>
                    <img
                      src={ErrorCrossIcon}
                      onClick={handleClose}
                      className="alert_close_img"
                    />
                  </Box>
                )}
                {type === "error_web" && (
                  <Box
                    style={{
                      height: "44px",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#F9DFE0",
                      borderRadius: "4px",
                      color: "#E5767A",
                      fontWeight: 600,
                      fontSize: "14px",
                      fontFamily: "Inter",
                      justifyContent: "space-between",
                      border: "1px solid #DC4852",
                    }}
                    className="alert_container"
                  >
                    <Box className="alert_content">
                      <img src={ErrorIcon} />
                      {children}
                    </Box>
                    <img
                      src={ErrorCrossIcon}
                      onClick={handleClose}
                      className="alert_close_img"
                    />
                  </Box>
                )}
                {type === "info" && (
                  <Box
                    style={{
                      height: "44px",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#DEF6FC",
                      borderRadius: "4px",
                      color: "#37CDFA",
                      fontWeight: 600,
                      fontSize: "14px",
                      fontFamily: "Inter",
                      justifyContent: "space-between",
                      border: "1px solid #9EE8FC",
                    }}
                    className="alert_container"
                  >
                    <Box className="alert_content">
                      <img src={InfoIcon} />
                      {children}
                    </Box>
                    <img
                      src={InfoCrossIcon}
                      onClick={handleClose}
                      className="alert_close_img"
                    />
                  </Box>
                )}
                {type === "notification" && (
                  <Box
                    style={{
                      height: "44px",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#F8F9FA",
                      borderRadius: "4px",
                      color: "#404145",
                      fontWeight: 600,
                      fontSize: "14px",
                      fontFamily: "Inter",
                      justifyContent: "space-between",
                      border: "1px solid #D5D7DB",
                    }}
                    className="alert_container"
                  >
                    <Box className="alert_content">
                      <img src={NotificationIcon} />
                      {children}
                    </Box>
                    <img
                      src={NotificationCrossIcon}
                      onClick={handleClose}
                      className="alert_close_img"
                    />
                  </Box>
                )}
                {type === "success" && (
                  <Box
                    style={{
                      height: "44px",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#DCFAE0",
                      borderRadius: "4px",
                      color: "#3DBA4E",
                      fontWeight: 600,
                      fontSize: "14px",
                      fontFamily: "Inter",
                      justifyContent: "space-between",
                      border: "1px solid #8AE896",
                    }}
                    className="alert_container"
                  >
                    <Box className="alert_content">
                      <img src={SuccessIcon} />
                      {children}
                    </Box>
                    <img
                      src={SuccessCrossIcon}
                      onClick={handleClose}
                      className="alert_close_img"
                    />
                  </Box>
                )}
                {type === "success_web" && (
                  <Box
                    style={{
                      height: "44px",
                      display: "flex",
                      alignItems: "center",
                      background: "#171111",
                      borderRadius: "4px",
                      color: "#BABDC2",
                      fontWeight: 600,
                      fontSize: "14px",
                      fontFamily: "Inter",
                      justifyContent: "space-between",
                    }}
                    className="alert_container"
                  >
                    <Box className="alert_content">
                      <img src={SuccessWebIcon} />
                      {children}
                    </Box>
                    <img
                      src={SuccessWebCrossIcon}
                      onClick={handleClose}
                      className="alert_close_img"
                    />
                  </Box>
                )}
              </Box>
            </Box>
          ) : (
            <>
              {type === "alert" && (
                <Box
                  style={{
                    height: "44px",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#FDF1D3",
                    borderRadius: "4px",
                    color: "#141518",
                    fontWeight: 600,
                    fontSize: "14px",
                    fontFamily: "Inter",
                    justifyContent: "space-between",
                    border: "1px solid #FDD570",
                  }}
                  className="alert_container"
                >
                  <Box className="alert_content">
                    <img src={WarningIcon} />
                    {children}
                  </Box>
                  <img
                    src={WarningCrossIcon}
                    onClick={handleClose}
                    className="alert_close_img"
                  />
                </Box>
              )}
              {type === "error" && (
                <Box
                  style={{
                    height: "44px",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#F9DFE0",
                    borderRadius: "4px",
                    color: "#E5767A",
                    fontWeight: 600,
                    fontSize: "14px",
                    fontFamily: "Inter",
                    justifyContent: "space-between",
                    border: "1px solid #DC4852",
                  }}
                  className="alert_container"
                >
                  <Box className="alert_content">
                    <img src={ErrorIcon} />
                    {children}
                  </Box>
                  <img
                    src={ErrorCrossIcon}
                    onClick={handleClose}
                    className="alert_close_img"
                  />
                </Box>
              )}
              {type === "info" && (
                <Box
                  style={{
                    height: "44px",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#DEF6FC",
                    borderRadius: "4px",
                    color: "#37CDFA",
                    fontWeight: 600,
                    fontSize: "14px",
                    fontFamily: "Inter",
                    justifyContent: "space-between",
                    border: "1px solid #9EE8FC",
                  }}
                  className="alert_container"
                >
                  <Box className="alert_content">
                    <img src={InfoIcon} />
                    {children}
                  </Box>
                  <img
                    src={InfoCrossIcon}
                    onClick={handleClose}
                    className="alert_close_img"
                  />
                </Box>
              )}
              {type === "notification" && (
                <Box
                  style={{
                    height: "44px",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#F8F9FA",
                    borderRadius: "4px",
                    color: "#404145",
                    fontWeight: 600,
                    fontSize: "14px",
                    fontFamily: "Inter",
                    justifyContent: "space-between",
                    border: "1px solid #D5D7DB",
                  }}
                  className="alert_container"
                >
                  <Box className="alert_content">
                    <img src={NotificationIcon} />
                    {children}
                  </Box>
                  <img
                    src={NotificationCrossIcon}
                    onClick={handleClose}
                    className="alert_close_img"
                  />
                </Box>
              )}
              {type === "success" && (
                <Box
                  style={{
                    height: "44px",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#DCFAE0",
                    borderRadius: "4px",
                    color: "#3DBA4E",
                    fontWeight: 600,
                    fontSize: "14px",
                    fontFamily: "Inter",
                    justifyContent: "space-between",
                    border: "1px solid #8AE896",
                  }}
                  className="alert_container"
                >
                  <Box className="alert_content">
                    <img src={SuccessIcon} />
                    {children}
                  </Box>
                  <img
                    src={SuccessCrossIcon}
                    onClick={handleClose}
                    className="alert_close_img"
                  />
                </Box>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default AlertCmp;
