import React from "react";
import Layout from "../../organism/layout";
import EventGalleryImages from "../../templates/eventGalleryImages";

const EventGalleryImagesPage = () => {
  return (
    <Layout pageName="Gallery" menuActive="Event Gallery">
      <EventGalleryImages />
    </Layout>
  );
};

export default EventGalleryImagesPage;
