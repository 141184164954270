import { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate, useParams } from "react-router-dom";
import InputsWithLabel from "../../molecules/inputsWithLabel";
import LocationImg from "./images/location.svg";
import TimeImg from "./images/time.svg";
import LineImg from "./images/line.svg";
import useHttp from "../../../utils/hooks/useHttp";
import Loader from "../../atoms/loader";
import AlertCmp from "../../atoms/alert";
import moment from "moment";
import "./style.css";
import PriceDetails from "../priceDetails/priceDetails";
import image from "./images/Group (1).svg";
import CardSection from "../../molecules/cardSection";

const bookingContent = {
  headTitle: "Tabla jugalbandi",
  address: "Recital Hall St. Catherine University, St. Paul",
  time: "Sunday April 23 2023, 5:00 PM",
};

const TicketBooking = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { loading, error, sendRequest, sendRequestWithoutToken } =
    useHttp(transformData);
  const [countTicket, setCountTicket] = useState(0);
  const [eventData, setEventData] = useState<any>(null);
  const [showErrorAlert, setShowErrorAlert] = useState({
    show: false,
    message: "",
  });

  function transformData(data: any) {
    if (data.status) {
      setEventData(data.event);
    } else {
      setShowErrorAlert({
        show: true,
        message: data.message,
      });

      setTimeout(() => {
        setShowErrorAlert({
          show: false,
          message: "",
        });
      }, 5000);
    }
  }

  const handleIncrement = () => {
    setCountTicket(countTicket + 1);
  };

  const handleDecrement = () => {
    if (countTicket > 0) {
      setCountTicket(countTicket - 1);
    }
  };

  useEffect(() => {
    const reqOptions = {
      url: `/get_event`,
      method: "GET",
      params: {
        id,
      },
    };

    sendRequestWithoutToken(reqOptions);
  }, []);
  const handleOpenNewTab = () => {
    const url = eventData.location;
    window.open(url, "_blank");
  };

  return (
    <>
      {loading && <Loader />}
      {showErrorAlert.show && (
        <AlertCmp type="error_web" position="user_side">
          {showErrorAlert.message}
        </AlertCmp>
      )}
      <Box className="ticketBooking_top_website">
        <Button
          className="back_button"
          variant="outlined"
          onClick={() => window.history.back()}
        >
          <ArrowBackIosNewIcon />
        </Button>
        {eventData ? (
          <Box className="ticketBooking">
            <Box className="heading_box">
              <h1 className="heading">{eventData.title}</h1>
              {/* <img
                className="heading_line"
                src={LineImg}
                alt={bookingContent.headTitle}
              /> */}
            </Box>
            <Box className="info">
              <img src={LocationImg} alt={bookingContent.address} />
              <p
                className="address"
                onClick={handleOpenNewTab}
                style={{ cursor: "pointer" }}
              >
                {eventData.venue}
              </p>
            </Box>
            <Box className="info">
              <img src={TimeImg} alt={bookingContent.time} />
              <p className="time">
                {moment.utc(eventData.date).format("MMMM D YYYY")},{" "}
                {eventData.start_date}
              </p>
            </Box>
            {/* <Box className="booking_wrapper">
              <Button
                variant="outlined"
                onClick={handleDecrement}
                color="info"
                size="small"
                className="counter"
              >
                -
              </Button>
              <Box className="input_wrapper">
                <InputsWithLabel
                  label="Number of Tickets"
                  type="number"
                  name="countTicket"
                  id="countTicket"
                  value={countTicket}
                />
              </Box>
              <Button
                variant="outlined"
                onClick={handleIncrement}
                color="info"
                size="small"
                className="counter"
              >
                +
              </Button>
            </Box>
            <Box className="wrapper_button">
              <Button
                className="proceed_button"
                variant="outlined"
                disabled={countTicket === 0 ? true : false}
                color="info"
                size="large"
                onClick={() => {
                  navigate(`/attendee-details/${id}`, {
                    state: {
                      countTicket: countTicket,
                    },
                  });
                }}
              >
                Proceed
              </Button>
            </Box> */}
          </Box>
        ) : (
          <p>No such event Found</p>
        )}
      </Box>
      {/* 
      <Box className="image_container">
        <img src={image} alt="" className="image_one"/>
        <img src={image} alt="" className="image_two" />
        <img src={image} alt="" className="image_three"/>
        <img src={image} alt="" className="image_four"/>

      </Box> */}
      <CardSection>
        <PriceDetails ticketDetails={eventData ?? []} />
      </CardSection>
    </>
  );
};

export default TicketBooking;
