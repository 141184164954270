import React, { useEffect, useState } from "react";
import { PropType } from "./types/propType";
import { Box, Grid } from "@mui/material";
import PencilImg from "./images/pencil.svg";
import CloseImg from "./images/close.svg";
import { Button } from "../../atoms/buttons";
import InputsWithLabel from "../../molecules/inputsWithLabel";
import TickImg from "./images/tick.svg";
import DeleteImg from "./images/delete.svg";
import TicketImg from "./images/ticket.svg";
import CancelImg from "./images/cancel.svg";
import DisabledLocationImg from "./images/location_disabled.svg";
import TimeImg from "./images/time.svg";
import LocationImg from "./images/location.svg";
import HeroImg from "./images/hero.png";
import DisabledTimeImg from "./images/time_disabled.svg";
import "./style.css";
import useHttp from "../../../utils/hooks/useHttp";
import { useWebUser } from "../../../utils/hooks/useUser";
import Loader from "../../atoms/loader";
import useUserEvents from "./useUserEvents";
import PreviewBanner from "../previewBanner";
import EventCardWebsite from "../../organism/eventCardWebsite";
import { NoEvents } from "./NoEvents";

const ticketsData = [
  {
    title: "Tabla Jugalbandi",
    admitStatus: "admits",
    admitNames: ["Javed Jaferi", "Ghoomran Ghobi"],
    location: "Recital Hall St. Catherine University, St. Paul",
    time: "Sunday April 23 2023, 5:00 PM",
  },
  {
    title: "Instrumental Jugalbandi",
    admitStatus: "admitted",
    admitNames: ["Javed Jaferi", "Ghoomran Ghobi"],
    location: "Recital Hall St. Catherine University, St. Paul",
    time: "Sunday April 23 2023, 5:00 PM",
  },
];

const BuyCDStep2 = ({ handleStep }: PropType) => {
  const { user } = useWebUser();
  const userId = user?.id
  const { loading, events } = useUserEvents(userId);
  const renderAttendeeInfo = (ticket: any) => {
    if (ticket.admitStatus === "admits") {
      return (
        <Box className="buy_cd_step3_attendee_details">
          <img src={TicketImg} style={{marginTop:"4px"}} />
          <Box>
            <p>
              Admits {ticket.attendee?.length || ""}
              <br />
              {(ticket.ticketCount > 0) && (
                <span>
                  ({`${ticket.ticketCount} ticket`})
                </span>
              )}
            </p>
            <Box className="buy_cd_step3_attendee_names">
              {ticket.attendee?.map((attendee: any, j: number) => {
                return (
                  <span>
                    {`${attendee?.name} - ${attendee?.user_type}`}
                    {j !== ticket.admitNames?.length - 1 ? ", " : ""}
                  </span>
                );
              })}
            </Box>
          </Box>
        </Box>
      );
    }
    if (ticket.admitStatus === "admitted") {
      return (
        <Box className="buy_cd_step3_attendee_details">
          <img src={TickImg} style={{marginTop:"4px"}} />
          <Box>
            <p>
              Admitted {ticket.admitNames?.length || ""}
              <br />
              {(ticket.ticketCount > 0) && (
                <span>
                  ({`${ticket.ticketCount} ticket`})
                </span>
              )}
            </p>

            <Box className="buy_cd_step3_attendee_names">
              {ticket.admitNames?.map((name: any, j: number) => {
                return (
                  <span>
                    {name}
                    {j !== ticket.admitNames?.length - 1 ? ", " : ""}
                  </span>
                );
              })}
            </Box>
          </Box>
        </Box>
      );
    }

    return (
      <Box className="buy_cd_step3_attendee_details">
        <img src={CancelImg} style={{marginTop:"4px"}} />
        <Box>
          <p>Event Cancelled</p>
          <p>
            {(ticket.ticketCount > 0) && (
              <span style={{ textDecoration: "line-through" }}>
                ({`${ticket.ticketCount} ticket`})
              </span>
            )}

            <br />
            <span>*Refund will be credited to source account.</span>
          </p>
        </Box>
      </Box>
    );
  };

  return (
    <>
    {loading && <Loader />}
    <Box className="buy_cd_step3_box">
      <Grid container spacing={{ sm: 4, xs: 2 }}>
         {events?.length > 0 ? events.map((ticket:any, i:number) => {
          const className = ticket.cancel ? "filter-grayscale":""
          return (
            <Grid item sm={6} xs={12} key={i}>
              <Box className="buy_cd_step3_ticket">
                <h4 className="buy_cd_step3_ticket_title">{ticket.title}</h4>
                {/* <EventCardWebsite data={ticket} hideViewMore previewBanner /> */}
        
                  <Box className="buy_cd_step3_ticket_details">
                    {renderAttendeeInfo(ticket)}
                    <Box className={`buy_cd_step3_ticket_location ${className}`}>
                      <img src={LocationImg}  />
                      <p>{ticket.location}</p>
                    </Box>
                    <Box className={`buy_cd_step3_ticket_time ${className}`}>
                      <img src={TimeImg} />
                      <p>{ticket.time}</p>
                    </Box>
                  </Box>
    

                
              </Box>
            </Grid>
          );
        }) : <NoEvents />}
      </Grid>
    </Box>
    </>
  );
};

export default BuyCDStep2;
