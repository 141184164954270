import React from "react";
import { PropType } from "./types/propType";

import { Button } from "../buttons";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseImg from "./images/close.svg";
import TextLabels from "../textLabels";
import "./style.css";

const ModalPopup = ({
  heading,
  content,
  primaryButton,
  secondaryButton,
  handleClickOpen,
  handleClose,
  open,
  closeBtn,
  primaryAction,
  secondaryAction,
  imagePopup,
}: PropType) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="modal_popup_simple"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
          className="modal_title"
        >
          <TextLabels variant="h400" weight={500}>
            {heading}
          </TextLabels>
          {closeBtn && (
            <img
              src={CloseImg}
              onClick={handleClose}
              style={{ cursor: "pointer" }}
            />
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {imagePopup ? (
              <></>
            ) : (
              <TextLabels variant="h200" weight={400} color="#606266">
                {heading == "Delete all images?" ? (
                  <span dangerouslySetInnerHTML={{ __html: content }}></span>
                ) : (
                  content
                )}
              </TextLabels>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {primaryButton && (
            <>
              <Button handleClick={primaryAction} variant="contained" size="s">
                {primaryButton}
              </Button>
            </>
          )}
          {secondaryButton && (
            <Button
              btnClass={secondaryButton && "do-not-disturb btnPaddingSize"}
              handleClick={secondaryAction ? secondaryAction : handleClose}
              variant="outlined"
              size="s"
            >
              {secondaryButton}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalPopup;
