import React, { useState, useEffect } from "react";
import WebDialog from "../../molecules/webDialog";
import {
  DialogContent,
  DialogContentText,
  Box,
  DialogActions,
} from "@mui/material";
import InputsWithLabel from "../../molecules/inputsWithLabel";
import SectionHeading from "../../molecules/sectionHeading";
import useHttp from "../../../utils/hooks/useHttp";
import { Button } from "../../atoms/buttons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PropType } from "./types/propType";
import { setWebUser } from "../../../utils/store/slices/authSlice";
import Loader from "../../atoms/loader";
import ErrorImg from "./images/error.svg";
import { RootState } from "../../../utils/store";
import { resetPassword } from "../../../utils/store/slices/resetPasswordSlice";

const WebChangePassword = ({
  open,
  handleClose,
  handleAlertBox,
  handleAlertErrorBox,
  handleOpenLoginDialog,
  resetToken,
}: PropType) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    error: resetError,
    isLoading: resetLoading,
    resetResponse,
  } = useSelector((state: RootState) => state.resetPassword);
  const [formData, setFormData] = useState<any>({
    password: {
      value: "",
      validate: true,
      errorMsg: "* Please enter password",
    },
    confirmPassword: {
      value: "",
      validate: true,
      errorMsg: "* Please enter confirm password",
    },
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [disableButton, setDisableButton] = useState(true);



  const handleChangePassword = () => {

    let errorFlag = false;

    const dataObj = JSON.parse(JSON.stringify(formData));

    Object.keys(dataObj).forEach((input, i) => {
      if (dataObj[input].value === "") {
        dataObj[input].validate = false;
        errorFlag = true;
      }
    });

    // regex to satisfy conditions
    // const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&+=()_-+={};:,<.>])(.{9,})$/;
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>]).{8,}$/


    if (dataObj.password.value !== dataObj.confirmPassword.value) {
      dataObj.confirmPassword.validate = false;
      dataObj.confirmPassword.errorMsg = "* Password not matching";
      errorFlag = true;
    }

    if (!regex.test(dataObj.password.value)) {
      dataObj.password.validate = false;
      dataObj.password.errorMsg =
        "Minimum 8 characters are allowed and use atleast one uppercase letter, one lowercase letter, one number and one special character.";
      errorFlag = true;
    }

    setFormData(dataObj); 
    if (!errorFlag) {
      dispatch(
        resetPassword({
          reset_token: resetToken,
          password: dataObj.password.value,
        })
      );
    }
  };

  const handleInputChange = (e: any) => {
    const dataObj = JSON.parse(JSON.stringify(formData));
    dataObj[e.target.name].value = e.target.value;
    if (e.target.value !== "") {
      dataObj[e.target.name].validate = true;
    }
    setFormData(dataObj);
  };

  useEffect(() => {
    if (resetResponse) {
      if (resetResponse.status) {
        handleAlertBox(resetResponse.message);
        window.history.pushState({}, "Home", "/");
        handleOpenLoginDialog();
      } else {
        handleAlertErrorBox(resetResponse.message);
      }
    }
  }, [resetResponse]);

  useEffect(() => {
    let disableFlag = false;
    Object.keys(formData).forEach((key) => {
      if (formData[key].value === "" && formData[key].validate !== undefined) {
        disableFlag = true;
      }
    });

    setDisableButton(disableFlag);
  }, [formData]);

  //   useEffect(() => {
  //     if (resetError) {
  //       handleAlertErrorBox(resetError.message);
  //     }
  //   }, [resetError]);

  return (
    <>
      {resetLoading && <Loader />}
      <WebDialog
        title="Welcome !"
        open={open}
        handleClose={handleClose}
        primaryButtonLabel="Sign In"
        subTitle="Sign in to continue"
      >
        <DialogContent>
          <DialogContentText>
            <SectionHeading title="Reset Password" />
            {/* <p className="login_dialog_subtitle">
              A link will be sent to your email address to change your password.
            </p> */}
          </DialogContentText>
          <Box
            sx={{
              position: "relative",
            }}
          >
            <InputsWithLabel
              label="New Password"
              type={showPassword ? "text" : "password"}
              name="password"
              id="password"
              labelColor="#FDFCFC"
              placeholder="Enter password here"
              handleInputChange={handleInputChange}
            />
            <img
              style={{
                position: "absolute",
                right: "9px",
                top: "40px",
                cursor: "pointer",
              }}
              src="/icons/eye_icon.svg"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            />
          </Box>
          {!formData["password"].validate && (
            <p className="web_register_error_label">
              <img src={ErrorImg} />
              <span>{formData["password"].errorMsg}</span>
            </p>
          )}
          <Box
            sx={{
              marginTop: "45px",
              position: "relative",
            }}
          >
            <InputsWithLabel
              label="Confirm New Password"
              type={showConfirmPassword ? "text" : "password"}
              name="confirmPassword"
              id="confirmPassword"
              labelColor="#FDFCFC"
              placeholder="Enter password here"
              handleInputChange={handleInputChange}
            />
            <img
              style={{
                position: "absolute",
                right: "9px",
                top: "40px",
                cursor: "pointer",
              }}
              src="/icons/eye_icon.svg"
              onClick={() => {
                setShowConfirmPassword(!showConfirmPassword);
              }}
            />
          </Box>
          {!formData["confirmPassword"].validate && (
            <p className="web_register_error_label">
              <img src={ErrorImg} />
              <span>{formData["confirmPassword"].errorMsg}</span>
            </p>
          )}
          <p style={{color:"white", fontSize:"10px"}}>Minimum 8 characters are allowed and use atleast one uppercase letter, one lowercase letter, one number and one special character.</p>
        </DialogContent>
        <DialogActions className="login_dialog_actions">
          <Button
            variant="outlined"
            color="info"
            size="s"
            handleClick={handleChangePassword}
            disable={disableButton}
          >
            Confirm Password
          </Button>
        </DialogActions>
      </WebDialog>
    </>
  );
};

export default WebChangePassword;
