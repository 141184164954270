import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import "./style.css";
import { Button } from "../../atoms/buttons";
import { useNavigate } from "react-router-dom";

const CustomerMembershipsWelcome = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box className="content-centered">
      <Typography variant="h1" className="welcome-heading">
        You Are a Member Now!
      </Typography>
      <Typography variant="body1" className="welcome-message">
        Your payment was successful. You can now avail the benefits of your
        membership and enjoy IMSOM concerts to the fullest!
      </Typography>
      <Button
        variant="outlined"
        size="s"
        color="info"
        handleClick={() => navigate("/")}
      >
        Go to homepage
      </Button>
    </Box>
  );
};

export default CustomerMembershipsWelcome;
