import React from "react";
import Layout from "../../organism/layout";
import EventGallery from "../../templates/eventGallery";

const EventGalleryPage = () => {
  return (
    <Layout pageName="Gallery" menuActive="Event Gallery">
      <EventGallery />
    </Layout>
  );
};

export default EventGalleryPage;
