import { PropType } from "./types/propType";
import { Box } from "@mui/material";
import TextLabels from "../../atoms/textLabels";
import moment from "moment";
import MenuImg from "./images/menu.svg";
// import EditImg from "./images/edit.svg";
import DeleteImg from "./images/delete.svg";
import useHttp from "../../../utils/hooks/useHttp";
import "./style.css";
import ToggleMenu from "../../atoms/toggleMenu";
import React, { useState, useRef, useContext } from "react";
import ModalPopup from "../../atoms/modalPopup";
import { Button } from "../../atoms/buttons";
import DropZone from "../../atoms/dropZone";
import { EventContext } from "../../organism/wizardSteps";
import { ReactCrop, makeAspectCrop, centerCrop, Crop } from "react-image-crop";
import Loader from "../../atoms/loader";

const BannersCard = ({
  banner_image,
  id,
  schedule_date,
  schedule_time,
  end_date, //end date added
  handleSuccessCall,
  handleApiFetchAgain,
  handleCloseOptions,
}: PropType) => {

  // console.log(end_date);
  

  const imgRef = useRef<any[]>([]);
  const previewCanvasRef = useRef<any[]>([]);
  const blobUrlRef = useRef<any>([]);
  const { formData, handleFormDataInput } = useContext(EventContext);
  const [toggleMenu, setToggleMenu] = useState<number | null>(null);
  const [openDeleteAll, setOpenDeleteAll] = React.useState(false);
  const [showAlert, setShowAlert] = useState(true);
  const { loading, error, sendRequest } = useHttp(transformData);
  const [imgSrc, setImgSrc] = useState<any>([]);
  const [modalIndex, setModalIndex] = useState<number>(0);
  const [crop, setCrop] = useState<Crop>();
  const [aspect, setAspect] = useState<number | undefined>(151 / 264);
  const [scale, setScale] = useState(1);
  const [completedCrop, setCompletedCrop] = useState<any[]>([]);
  const [files, setFiles] = useState<any>([]);
  const [openPreview, setOpenPreview] = useState(false);
  const [step2Data, setStep2Data] = useState<any>(
    formData?.event_managment?.artists ? formData?.event_managment?.artists : []
  );
  const wrapperRef = useRef(null);
  // const clickRef = useRef(null);
  const [openBlock, setOpenBlock] = useState(false);

  const handleClickOpenBlock = () => {
    setOpenBlock(true);
  };

  const handleCloseBlock = () => {
    setOpenBlock(false);
  };

  function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          height: 100,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }

  function transformData(data: any) {
    // window.location.reload()
    if (data.delete) {
      setOpenDeleteAll(false);
      handleSuccessCall && handleSuccessCall("Banner deleted successfully");
      handleApiFetchAgain();
    }
    if (data.status) {
      if (handleSuccessCall) {
        handleSuccessCall(data.message || "Banner updated successfully");
      }
      handleApiFetchAgain();
    }
    setOpenPreview(false);
    setToggleMenu(null);
  }

  const handleToggleMenu = (e: React.MouseEvent, id: number) => {
    e.stopPropagation();
    if (id === toggleMenu) {
      setToggleMenu(null);
    } else {
      setToggleMenu(id);
    }
  };

  const handleClickOpenDeleteAll = () => {
    setOpenDeleteAll(true);
  };

  const handleCloseDeleteAll = () => {
    setOpenDeleteAll(false);
  };

  const handleDeleteBanner = () => {
    const reqOptions = {
      // http://localhost:3000/api/banners/1/delete_banner
      url: `/banners/${id}/delete_banner`,
      method: "DELETE",
    };

    sendRequest(reqOptions);
  };

  const handleFiles = (files: any, i: any) => {
    let imgs: any[] = [];
    files.forEach((file: any) => {
      imgs.push(file);
    });
    const stepData = [...step2Data];
    stepData[i]["artist_image"] = imgs[0];
    setStep2Data(stepData);
    setModalIndex(i);
    setOpenBlock(true);
  };


  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  function onDownloadCropClick(index: number) {
    if (!previewCanvasRef.current[index]) {
      throw new Error("Crop canvas does not exist");
    }

    previewCanvasRef.current[index].toBlob((blob: any) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }
      if (blobUrlRef.current[index]) {
        URL.revokeObjectURL(blobUrlRef.current[index]);
      }
      blobUrlRef.current[index] = URL.createObjectURL(blob);
      imgRef.current[index] = blobUrlRef.current[index];
      setOpenBlock(false);

      const file = new File([blob], `croppedImage${index}.jpg`, {
        type: "image/jpeg",
      }); // Adjust the file name and type as needed
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = () => {
        const stepData = [...step2Data];
        stepData[modalIndex]["artist_image"] = file;
        setStep2Data(stepData);
        };
    });
  }

  const handleFileSelection = (files: any, i: number) => {
    if (files && files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        const imgArr = [...imgSrc];
        imgArr[i] = reader.result?.toString() || "";
        setImgSrc(imgArr);
      });
      reader.readAsDataURL(files[0]);
    }
    handleFiles(files, i);
  };

  const handleUpload = (file:any) => {
    setFiles(file);
  }
  function convertToISOFormat(dateString:any) {
    const [month, day, year] = dateString.split('/');
    const isoDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    return isoDate;
  }
  const handleUpdateBanner = () => {
    if(!files || files.length < 1) {
      setOpenPreview(false);
      return
    };
    const formData = new FormData();
    const isoFormattedDate = convertToISOFormat(schedule_date);
    const isoFormattedDate_endDate = convertToISOFormat(end_date);
    formData.append("banner_image", files[0]);
    formData.append("schedule_date", isoFormattedDate);
    formData.append("schedule_time", schedule_time);
    formData.append("end_date", isoFormattedDate_endDate);
    const reqOptions = {
      url: `/banners/${id}/update_banner`,
      method: "PUT",
      contentType: "multipart/form-data",
      data: formData
    };
    // console.log(formData);
    
    // console.log(reqOptions);
    
    sendRequest(reqOptions);
  };
  
  return (
    <>
      {loading && <Loader />}
      <Box className="admin_banner_box">
        <Box
          className="event_image_card admin_banner_image"
          sx={{
            backgroundImage: `url(${
              banner_image.length ? `"${banner_image[0]}"` :
              "/images/imsom.png"
            })`,
          }}
        >
          <Box className="event_detail_box">
            <Box display="flex" flexDirection="column" alignItems="flex-end">
              <Box display="flex">
                <div
                  // ref={clickRef}
                  onClick={(e) => handleToggleMenu(e, id)}
                  style={{
                    display: "flex",
                  }}
                >
                  <img src={MenuImg} alt="" />
                </div>
                {toggleMenu === id && (
                  <Box ref={wrapperRef}>
                    <Box className="toggle_menu">
                      <ul>
                        <li onClick={() => {setOpenPreview(true)}}>
                          <img src="icons/replace.png" alt="" />{" "}
                          <TextLabels
                            variant="h200"
                            weight={500}
                            color="#913713"
                          >
                            Replace
                          </TextLabels>
                        </li>
                        <li onClick={handleClickOpenDeleteAll}>
                          <img src={DeleteImg} alt="" />{" "}
                          <TextLabels
                            variant="h200"
                            weight={500}
                            color="#7E8085"
                          >
                            Delete
                          </TextLabels>
                        </li>
                      </ul>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="admin_banner_content">
          <Box>
            <span className="event_status_scheduled">Scheduled</span>
          </Box>
          <Box className="admin_banner_event_times">
            <Box className="admin_banner_event_date">
              <TextLabels variant="h100" weight={700} color="#503B35">
                Start Date
              </TextLabels>
              <Box sx={{ marginTop: "0px" }}></Box>
              <TextLabels variant="h200" weight={400}>
                {moment(schedule_date).format("MM/DD/YYYY")}
              </TextLabels>
            </Box>
            <Box className="admin_banner_event_date">
              {/* <TextLabels variant="h100" weight={700} color="#503B35">
                End Date
              </TextLabels>
              <Box sx={{ marginTop: "0px" }}></Box>
              <TextLabels variant="h200" weight={400}>
                {moment(schedule_time).format("DD/MM/YYYY")}
              </TextLabels> */}
            </Box>
          </Box>
        </Box>
      </Box>
      {/* <ModalPopup
        heading="Delete announcement?"
        content="Are you sure you want to delete this announcement? This is a non-reversible action and can not be undone."
        primaryButton="Yes, Delete"
        secondaryButton="Cancel"
        open={openDeleteAll}
        handleClickOpen={handleClickOpenDeleteAll}
        handleClose={handleCloseDeleteAll}
        closeBtn={true}
        primaryAction={handleDeleteAnnouncement}
      /> */}
      <ModalPopup
        heading="Delete Banner?"
        content="Are you sure you want to delete this Banner? This is a non-reversible action and can not be undone."
        primaryButton="Yes, Delete"
        secondaryButton="Cancel"
        open={openDeleteAll}
        handleClickOpen={handleClickOpenDeleteAll}
        handleClose={handleCloseDeleteAll}
        closeBtn={true}
        primaryAction={handleDeleteBanner}
      />
      <ModalPopup
        open={openBlock}
        handleClickOpen={handleClickOpenBlock}
        handleClose={handleCloseBlock}
        heading="Select cropped zone"
        closeBtn={true}
        primaryAction={() => {}}
        content={
          <>
            <Box>
              Move and resize the bounded rectangle to select area to be
              displayed on banner. Make sure the individual face comes in the
              frame.
            </Box>
            <Box sx={{ padding: "20px 0px", color: "#000" }}>
              Please preview the event before proceeding.
            </Box>
            <Box>
              {imgSrc[modalIndex] && (
                <ReactCrop
                  crop={crop}
                  onChange={(_, percentCrop) => setCrop(percentCrop)}
                  onComplete={(c) => {
                    const cropCompleteArr = [...completedCrop];
                    cropCompleteArr[modalIndex] = c;
                    setCompletedCrop(cropCompleteArr);
                  }}
                  aspect={aspect}
                >
                  <img
                    ref={(ref: any) => {
                      if (ref) {
                        imgRef.current[modalIndex] = ref;
                      }
                    }}
                    alt="Crop me"
                    src={imgSrc[modalIndex]}
                    style={{
                      transform: `scale(${scale})`,
                    }}
                    onLoad={onImageLoad}
                  />
                </ReactCrop>
              )}
            </Box>
            <Box sx={{ margin: "25px 0" }}>
              <Box
                sx={{
                  position: "relative",
                  margin: "10px 0px",
                }}
              >
                <Button
                  type="button"
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "12px",
                    lineHeight: "14px",
                    textDecoration: "none",
                    backgroundColor: "#913713",
                    padding: "20px 20px !important",
                    "&:hover": {
                      backgroundColor: "#913713",
                    },
                  }}
                  btnClass="nextBtnClass changepwd-nextBtn"
                  handleClick={() => onDownloadCropClick(modalIndex)}
                  color="error"
                  variant="contained"
                >
                  Save Changes
                </Button>
                <DropZone
                  browseAnother={true}
                  multiple={false}
                  files={files}
                  handleFiles={(files) =>
                    handleFileSelection(files, modalIndex)
                  }
                />
              </Box>
            </Box>
          </>
        }
      />
      {/* <ModalFormPopup
        heading={"Edit Announcement"}
        inputs={modalInputs}
        closeBtn={true}
        confirmBtn="Save Changes"
        handleClose={handleCloseEdit}
        apiUrl={`/announcements/${cardDetails.id}`}
        method="PUT"
        open={openEdit}
        handleSuccessCall={handleSuccessCall}
        handleApiFetchAgain={handleApiFetchAgain}
        noDisable={true}
      /> */}
      <ModalPopup
        open={openPreview}
        handleClickOpen={() => {}}
        handleClose={() => {
          setOpenPreview(false);
        }}
        heading="Upload Banner"
        closeBtn={true}
        primaryAction={() => {}}
        content={
          <>
            <Box sx={{ padding: "20px 0px", color: "#000" }}>
              <TextLabels variant="h100" weight={700}>
                Upload Banner Image
              </TextLabels>
            </Box>
            <Box>
              <img
                src={ files.length > 0 ? files[0]?.preview : banner_image}
                alt=""
                width={"100%"}
                height={"277px"}
              />
            </Box>
            <Box sx={{ margin: "25px 0" }}>
              <Box
                sx={{
                  position: "relative",
                  margin: "10px 0px",
                }}
              >
                <Button
                  type="button"
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "12px",
                    lineHeight: "14px",
                    textDecoration: "none",
                    backgroundColor: "#913713",
                    padding: "20px 20px !important",
                    "&:hover": {
                      backgroundColor: "#913713",
                    },
                  }}
                  btnClass="nextBtnClass changepwd-nextBtn"
                  handleClick={() => {
                    handleUpdateBanner()
                    
                  }}
                  color="error"
                  variant="contained"
                >
                  Save changes
                </Button>
                <DropZone
                  browseAnother={true}
                  browseAnotherText={"Reupload Banner"}
                  multiple={false}
                  files={files}
                  handleFiles={(file: any) => {
                    handleUpload(file);
                  }}
                />
              </Box>
            </Box>
          </>
        }
      />
    </>
  );
};

export default BannersCard;
