import React from "react";
import { Box } from "@mui/material";
import { Button } from "../../atoms/buttons";
import "./style.css";

const ThankYou = () => {
  return (
    <Box className="thank_you_box">
      <h4>Thank you!</h4>
      <p>We’ve received your donation.</p>
      <Button variant="outlined" color="info" size="s">
        Go to Homepage
      </Button>
    </Box>
  );
};

export default ThankYou;
